//@ts-nocheck
import React from "react";
import cn from "classnames/bind";
import styles from "./LoadingPage.module.scss";
import loadingProcess from "src/assets/icons/loading-price.svg";

const cx = cn.bind(styles);

const LoadingPage = ({ s }) => {
    return (
        <div className={cx("loading-page")} style={s}>
            <div className={cx("title")}>Please wait</div>
            <div className={cx("icn-spinner")}>
                <img src={loadingProcess} alt="icon_loading" />
            </div>
        </div>
    );
};

export default LoadingPage;
