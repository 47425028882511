import React from "react";

const BackwardIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
        <svg
            className={className}
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 10C8.86866 10.0001 8.73859 9.97434 8.61726 9.92406C8.49593 9.87377 8.38573 9.80001 8.293 9.707L4.293 5.707C4.20012 5.61417 4.12644 5.50395 4.07617 5.38263C4.0259 5.26132 4.00003 5.13129 4.00003 4.99997C4.00003 4.86865 4.0259 4.73862 4.07617 4.61731C4.12644 4.49599 4.20012 4.38577 4.293 4.29294L8.293 0.29294C8.48092 0.107378 8.73462 0.00369768 8.99872 0.00453424C9.26281 0.00537081 9.51585 0.110656 9.70259 0.297405C9.88934 0.484154 9.99461 0.737198 9.99544 1.00129C9.99626 1.26539 9.89257 1.51909 9.707 1.707L6.41406 5L9.70706 8.293C9.84687 8.43286 9.94208 8.61102 9.98065 8.80498C10.0192 8.99894 9.9994 9.19998 9.92373 9.38268C9.84805 9.56538 9.7199 9.72155 9.55548 9.83143C9.39106 9.94131 9.19776 9.99997 9 10ZM1 10C0.734784 10 0.48043 9.89464 0.292893 9.70711C0.105357 9.51957 0 9.26522 0 9V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0C1.26522 0 1.51957 0.105357 1.70711 0.292893C1.89464 0.48043 2 0.734784 2 1V9C2 9.26522 1.89464 9.51957 1.70711 9.70711C1.51957 9.89464 1.26522 10 1 10Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default BackwardIcon;
