import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const RPC_URL_1 = "https://mainnet.infura.io/v3/920d962b397d4646989aa594147ba78c";
const RPC_URL_42 = "https://kovan.infura.io/v3/920d962b397d4646989aa594147ba78c";
// const RPC_URL_56 = "https://bsc-dataseed.binance.org";
const RPC_URL_56 = "https://bsc-dataseed4.defibit.io/";
const RPC_URL_97 = "https://data-seed-prebsc-2-s2.binance.org:8545/";
const RPC_URL_6886 = "https://endpoint1.kawaii.global/";

const POLLING_INTERVAL = 12000;
export const RPC_URLS = {
    1: process.env.REACT_APP_RPC_URL_1 || RPC_URL_1,
    42: process.env.REACT_APP_RPC_URL_42 || RPC_URL_42,
    56: process.env.REACT_APP_RPC_URL_56 || RPC_URL_56,
    97: RPC_URL_97,
    6886: RPC_URL_6886,
};
export const NETWORKS = {
    kawaiiverse: 6886,
    matic: 137,
    mainnet: 56,
    testnet: 97,
    ropsten: 3,
    kovan: 42,
    rinkeby: 4,
    goerli: 5,
    ethereum: 1,
};

export const injected = new InjectedConnector({
    supportedChainIds: [
        NETWORKS["matic"],
        NETWORKS["mainnet"],
        NETWORKS["testnet"],
        NETWORKS["ropsten"],
        NETWORKS["rinkeby"],
        NETWORKS["goerli"],
        NETWORKS["kovan"],
        NETWORKS["ethereum"],
        NETWORKS["kawaiiverse"],
    ],
});

export const walletconnect = new WalletConnectConnector({
    rpc: { 56: RPC_URLS[56], 6886: RPC_URLS[6886] },
    qrcode: true,
    pollingInterval: POLLING_INTERVAL,
});
