//@ts-nocheck
import React, { useEffect, useState } from "react";
import cn from "classnames/bind";
import styles from "./ConnectButton.module.scss";
import discordIcon from "src/assets/icons/discord.svg";
import { CONNECT_DISCORD_ENDPOINT } from "src/consts/address";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import Loading from "src/components/common/Loading/Loading";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { selectDiscordAccount, setDiscordAccount } from "src/store/slices/discordAccountSlide";
import axios from "axios";
import { toast } from "react-toastify";

const cx = cn.bind(styles);

const ConnectDiscordButton = () => {
    const { account } = useWeb3React();
    const dispatch = useDispatch();
    const discordAccount = useSelector(selectDiscordAccount);
    const [loadingDisconnedDiscord, setLoadingDisconnectDiscord] = useState();

    const OAuthScope = ["identify"].join(" ");
    const OAuthData = new URLSearchParams({
        response_type: "code",
        client_id: process.env.REACT_APP_KAWAIIVERSE_DISCORD_CLIENT_ID as string,
        redirect_uri: `${process.env.REACT_APP_KAWAIIVERSE_DOMAIN}/connect-discord-callback`,
        scope: OAuthScope,
    });

    useEffect(() => {
        if (!account) return;
        checkConnectedDiscord();
    }, [account]);

    const checkConnectedDiscord = async () => {
        try {
            const res = await axios.get(`${CONNECT_DISCORD_ENDPOINT}/v1/user-info/${account}`);
                if (res.data?.data?.username) {
                    dispatch(setDiscordAccount(res.data.data.username));
                } else {
                    dispatch(setDiscordAccount(null));
                }  
        } catch (error) {
            console.log(error);
        }
    };

    const disconnectDiscord = async () => {
        setLoadingDisconnectDiscord(true);
        try {
            const res = await axios.delete(`${CONNECT_DISCORD_ENDPOINT}/v1/user-info/${account}`);
            if (res.status === 200) {
                dispatch(setDiscordAccount(null));
                toast.success("Disconnected!");
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message || "Error!");
        } finally {
            setLoadingDisconnectDiscord(false);
        }
    };

    return (
        <div className={cx("connect-discord-button")}>
            {!discordAccount ? (
                <a href={`https://discordapp.com/oauth2/authorize?${OAuthData}`} target="_blank">
                    <div className={cx("connect-discord")}>
                        <img src={discordIcon} alt="discord-icon" />
                        &nbsp; Connect Discord
                    </div>
                </a>
            ) : (
                <div className={cx("disconnect")}>
                    <a href={`https://discord.com/invite/kawaiiverse`} target="_blank" className={cx("discord-username")}>
                        <img src={discordIcon} alt="discord-icon" width={16}/>
                        &nbsp; {discordAccount}
                    </a>
                    <div className={cx("disconnect-discord")} onClick={disconnectDiscord}>
                        {loadingDisconnedDiscord ? <Loading size={20} /> : <ExitToAppRoundedIcon />}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ConnectDiscordButton;
