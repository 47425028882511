import animals from "./animals";
import materials from "./materials";
import plants from "./plants";
import dyes from "./dyes";
import fields from "./fields";
import decors from "./decors";
import fruits from "./fruits";
import eggs from "./eggs";
import fashions from "./fashions";

export default {
    ...animals,
    ...plants,
    ...materials,
    ...dyes,
    ...fields,
    ...decors,
    ...fruits,
    ...eggs,
    ...fashions,
};
