export default {
    207001: {
        name: "Woodie Egg",
    },
    207002: {
        name: "Metalfox Egg",
    },
    207003: {
        name: "Rockie Egg",
    },
    207004: {
        name: "Cloray Egg",
    },
    207005: {
        name: "Leathare Egg",
    },
    207006: {
        name: "Hedglass Egg",
    },
    207007: {
        name: "Rubbie Egg",
    },
    207008: {
        name: "Cellyfish Egg",
    },
    207009: {
        name: "Clayshelly Egg",
    },
    207010: {
        name: "Plasto Egg",
    },
};
