// eslint-disable-next-line import/no-anonymous-default-export
export default {
    101000100: {
        themeID: "99001",
        colors: ["6007", "6001", "6009", "6006", "6004"],
        name: "Urban twin bed",
        size: "XL",
    },
    101000101: {
        themeID: "99001",
        colors: ["6008", "6001", "6009", "6006", "6004"],
        name: "Urban twin bed",
        size: "XL",
    },
    101000102: {
        themeID: "99001",
        colors: ["6011", "6001", "6009", "6005"],
        name: "Urban twin bed",
        size: "XL",
    },
    101000103: {
        themeID: "99001",
        colors: ["6004", "6001", "6002", "6006", "6009"],
        name: "Urban twin bed",
        size: "XL",
    },
    101000200: { themeID: "99002", colors: ["6006", "6009", "6007"], name: "Rustic Bed", size: "XL" },
    101000201: { themeID: "99002", colors: ["6011", "6006", "6009"], name: "Rustic Bed", size: "XL" },
    101000202: { themeID: "99002", colors: ["6008", "6004", "6001"], name: "Rustic Bed", size: "XL" },
    101000203: { themeID: "99002", colors: ["6002", "6004", "6001"], name: "Rustic Bed", size: "XL" },
    101000204: { themeID: "99002", colors: ["6007", "6006", "6005"], name: "Rustic Bed", size: "XL" },
    101000400: { themeID: "99004", colors: ["6007", "6009", "6006"], name: "Rattan Bed", size: "XL" },
    101000401: { themeID: "99004", colors: ["6003", "6001", "6004"], name: "Rattan Bed", size: "XL" },
    101000402: { themeID: "99004", colors: ["6008", "6001", "6004"], name: "Rattan Bed", size: "XL" },
    101000403: { themeID: "99004", colors: ["6010", "6004", "6001"], name: "Rattan Bed", size: "XL" },
    101000500: {
        themeID: "99005",
        colors: ["6011", "6010", "6003", "6001", "6006"],
        name: "Colory Bunk Bed",
        size: "XL",
    },
    101000501: {
        themeID: "99005",
        colors: ["6005", "6007", "6006", "6009", "6001"],
        name: "Colory Bunk Bed",
        size: "XL",
    },
    101000502: {
        themeID: "99005",
        colors: ["6010", "6008", "6011", "6001"],
        name: "Colory Bunk Bed",
        size: "XL",
    },
    101100100: {
        themeID: "99003",
        colors: ["6003", "6011", "6001", "6005"],
        name: "Elegant queen bed",
        size: "XL",
    },
    101100101: {
        themeID: "99003",
        colors: ["6005", "6001", "6009", "6004"],
        name: "Elegant queen bed",
        size: "XL",
    },
    101100102: {
        themeID: "99003",
        colors: ["6010", "6002", "6001", "6011"],
        name: "Elegant queen bed",
        size: "XL",
    },
    101100103: {
        themeID: "99003",
        colors: ["6008", "6001", "6004", "6002"],
        name: "Elegant queen bed",
        size: "XL",
    },
    101200100: { themeID: "99007", colors: ["6005", "6009", "6006"], name: "Country Twin Bed", size: "XL" },
    101200101: { themeID: "99007", colors: ["6011", "6001", "6010"], name: "Country Twin Bed", size: "XL" },
    101200102: { themeID: "99007", colors: ["6006", "6007", "6005"], name: "Country Twin Bed", size: "XL" },
    101300100: {
        themeID: "99009",
        colors: ["6001", "6005", "6003", "6009"],
        name: "Antique double bed",
        size: "XL",
    },
    101300101: {
        themeID: "99009",
        colors: ["6002", "6003", "6007", "6001"],
        name: "Antique double bed",
        size: "XL",
    },
    101300102: {
        themeID: "99009",
        colors: ["6011", "6001", "6004", "6010"],
        name: "Antique double bed",
        size: "XL",
    },
    101400100: { themeID: "99006", colors: ["6007", "6001", "6009"], name: "Vintage twin bed", size: "XL" },
    101400101: { themeID: "99006", colors: ["6008", "6009", "6001"], name: "Vintage twin bed", size: "XL" },
    101400102: {
        themeID: "99006",
        colors: ["6003", "6001", "6011", "6004"],
        name: "Vintage twin bed",
        size: "XL",
    },
    101400103: {
        themeID: "99006",
        colors: ["6009", "6003", "6001", "6011"],
        name: "Vintage twin bed",
        size: "XL",
    },
    101600100: { themeID: "99015", colors: ["6007", "6001", "6009"], name: "Refined twin bed", size: "XL" },
    101600101: { themeID: "99015", colors: ["6007", "6001", "6002"], name: "Refined twin bed", size: "XL" },
    101600102: { themeID: "99015", colors: ["6008", "6009", "6001"], name: "Refined twin bed", size: "XL" },
    101600103: {
        themeID: "99015",
        colors: ["6011", "6003", "6001", "6004"],
        name: "Refined twin bed",
        size: "XL",
    },
    101700100: {
        themeID: "99011",
        colors: ["6005", "6007", "6009", "6006"],
        name: "Dolly bunk beds",
        size: "XL",
    },
    101700101: { themeID: "99011", colors: ["6011", "6010", "6003"], name: "Dolly bunk beds", size: "XL" },
    101700102: { themeID: "99011", colors: ["6008", "6009", "6005"], name: "Dolly bunk beds", size: "XL" },
    101700103: { themeID: "99011", colors: ["6010", "6008", "6011"], name: "Dolly bunk beds", size: "XL" },
    101700200: {
        themeID: "99013",
        colors: ["6006", "6005", "6007", "6008"],
        name: "Champion bunk beds",
        size: "XL",
    },
    101700201: {
        themeID: "99013",
        colors: ["6011", "6008", "6005", "6010"],
        name: "Champion bunk beds",
        size: "XL",
    },
    101700202: {
        themeID: "99013",
        colors: ["6008", "6009", "6010", "6005"],
        name: "Champion bunk beds",
        size: "XL",
    },
    101700203: {
        themeID: "99013",
        colors: ["6010", "6009", "6003", "6011"],
        name: "Champion bunk beds",
        size: "XL",
    },
    102000100: { themeID: "99001", colors: ["6001", "6005"], name: "Urban Table", size: "M" },
    102000101: { themeID: "99001", colors: ["6008", "6005"], name: "Urban Table", size: "M" },
    102000102: { themeID: "99001", colors: ["6011", "6005"], name: "Urban Table", size: "M" },
    102000103: { themeID: "99001", colors: ["6002", "6009", "6005"], name: "Urban Table", size: "M" },
    102000104: { themeID: "99001", colors: ["6007", "6005"], name: "Urban Table", size: "M" },
    102000200: { themeID: "99002", colors: ["6006", "6009"], name: "Rustic Table", size: "S" },
    102000201: { themeID: "99002", colors: ["6009", "6006"], name: "Rustic Table", size: "S" },
    102000202: { themeID: "99002", colors: ["6001", "6004"], name: "Rustic Table", size: "S" },
    102000203: { themeID: "99002", colors: ["6011", "6001"], name: "Rustic Table", size: "S" },
    102000204: { themeID: "99002", colors: ["6008", "6001"], name: "Rustic Table", size: "S" },
    102000205: { themeID: "99002", colors: ["6002", "6004"], name: "Rustic Table", size: "S" },
    102000206: { themeID: "99002", colors: ["6007", "6001"], name: "Rustic Table", size: "S" },
    102000600: { themeID: "99005", colors: ["6009", "6010"], name: "Childlike Table", size: "S" },
    102000601: { themeID: "99005", colors: ["6011", "6010", "6001"], name: "Childlike Table", size: "S" },
    102000602: { themeID: "99005", colors: ["6008", "6010", "6001"], name: "Childlike Table", size: "S" },
    102000603: { themeID: "99005", colors: ["6007", "6006", "6009"], name: "Childlike Table", size: "S" },
    102000700: { themeID: "99006", colors: ["6009"], name: "Vintage Table", size: "S" },
    102000701: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage Table", size: "S" },
    102000702: { themeID: "99006", colors: ["6008", "6002"], name: "Vintage Table", size: "S" },
    102000703: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage Table", size: "S" },
    102000900: { themeID: "99011", colors: ["6006", "6009"], name: "Biscuit Table", size: "M" },
    102000901: { themeID: "99011", colors: ["6011", "6009"], name: "Biscuit Table", size: "M" },
    102000902: { themeID: "99011", colors: ["6008", "6009"], name: "Biscuit Table", size: "M" },
    102000903: { themeID: "99011", colors: ["6010", "6009"], name: "Biscuit Table", size: "M" },
    102001100: { themeID: "99006", colors: ["6001", "6009"], name: "Vintage picnic table", size: "M" },
    102001101: {
        themeID: "99006",
        colors: ["6005", "6009", "6001"],
        name: "Vintage picnic table",
        size: "M",
    },
    102001102: {
        themeID: "99006",
        colors: ["6007", "6009", "6001"],
        name: "Vintage picnic table",
        size: "M",
    },
    102001103: {
        themeID: "99006",
        colors: ["6008", "6009", "6001"],
        name: "Vintage picnic table",
        size: "M",
    },
    102001104: {
        themeID: "99006",
        colors: ["6003", "6009", "6001"],
        name: "Vintage picnic table",
        size: "M",
    },
    102001105: {
        themeID: "99006",
        colors: ["6002", "6004", "6001"],
        name: "Vintage picnic table",
        size: "M",
    },
    102001200: { themeID: "99005", colors: ["6005", "6001", "6006"], name: "Sunflower Table", size: "M" },
    102001201: { themeID: "99005", colors: ["6011", "6010", "6001"], name: "Sunflower Table", size: "M" },
    102001202: { themeID: "99005", colors: ["6010", "6008", "6001"], name: "Sunflower Table", size: "M" },
    102100100: { themeID: "99003", colors: ["6003", "6001"], name: "Girlie round table", size: "M" },
    102100101: { themeID: "99003", colors: ["6005", "6007"], name: "Girlie round table", size: "M" },
    102100102: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie round table", size: "M" },
    102100103: { themeID: "99003", colors: ["6002", "6008", "6004"], name: "Girlie round table", size: "M" },
    102100200: {
        themeID: "99017",
        colors: ["6001", "6003", "6009"],
        name: "Xmas table",
        size: "M",
        xmas: true,
    },
    102100201: {
        themeID: "99017",
        colors: ["6001", "6009", "6007"],
        name: "Xmas table",
        size: "M",
        xmas: true,
    },
    102100202: {
        themeID: "99017",
        colors: ["6001", "6011", "6008"],
        name: "Xmas table",
        size: "M",
        xmas: true,
    },
    102200100: { themeID: "99007", colors: ["6009", "6005"], name: "Country Table", size: "M" },
    102200101: { themeID: "99007", colors: ["6010", "6004"], name: "Country Table", size: "M" },
    102200102: { themeID: "99007", colors: ["6007", "6006"], name: "Country Table", size: "M" },
    102200200: { themeID: "99008", colors: ["6005", "6009"], name: "Bohemian Table", size: "M" },
    102200201: { themeID: "99008", colors: ["6006", "6009"], name: "Bohemian Table", size: "M" },
    102200202: { themeID: "99008", colors: ["6011", "6010"], name: "Bohemian Table", size: "M" },
    102200203: { themeID: "99008", colors: ["6010", "6008"], name: "Bohemian Table", size: "M" },
    102200204: { themeID: "99008", colors: ["6008", "6004"], name: "Bohemian Table", size: "M" },
    102200205: { themeID: "99008", colors: ["6007", "6008", "6006"], name: "Bohemian Table", size: "M" },
    102200206: { themeID: "99008", colors: ["6001", "6006"], name: "Bohemian Table", size: "M" },
    102300100: { themeID: "99009", colors: ["6009", "6003", "6005"], name: "Antique Table", size: "M" },
    102300101: { themeID: "99009", colors: ["6002", "6006", "6007"], name: "Antique Table", size: "M" },
    102300102: { themeID: "99009", colors: ["6011", "6006", "6001"], name: "Antique Table", size: "M" },
    103000100: { themeID: "99002", colors: ["6006", "6009"], name: "Rustic Chair", size: "S" },
    103000101: { themeID: "99002", colors: ["6009", "6006"], name: "Rustic Chair", size: "S" },
    103000102: { themeID: "99002", colors: ["6001", "6004"], name: "Rustic Chair", size: "S" },
    103000103: { themeID: "99002", colors: ["6011", "6001"], name: "Rustic Chair", size: "S" },
    103000104: { themeID: "99002", colors: ["6008", "6001"], name: "Rustic Chair", size: "S" },
    103000105: { themeID: "99002", colors: ["6002", "6004"], name: "Rustic Chair", size: "S" },
    103000106: { themeID: "99002", colors: ["6007", "6001"], name: "Rustic Chair", size: "S" },
    103000200: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie Chair", size: "S" },
    103000201: { themeID: "99003", colors: ["6005", "6009"], name: "Girlie Chair", size: "S" },
    103000202: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie Chair", size: "S" },
    103000203: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie Chair", size: "S" },
    103000300: {
        themeID: "99005",
        colors: ["6009", "6001", "6011", "6010"],
        name: "Childlike Chair",
        size: "S",
    },
    103000301: { themeID: "99005", colors: ["6011", "6010", "6001"], name: "Childlike Chair", size: "S" },
    103000302: { themeID: "99005", colors: ["6008", "6001", "6010"], name: "Childlike Chair", size: "S" },
    103000303: { themeID: "99005", colors: ["6007", "6009", "6006"], name: "Childlike Chair", size: "S" },
    103000500: { themeID: "99006", colors: ["6009"], name: "Vintage Chair", size: "M" },
    103000501: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage Chair", size: "M" },
    103000502: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage Chair", size: "M" },
    103000503: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage Chair", size: "M" },
    103000600: { themeID: "99011", colors: ["6009", "6006"], name: "Dolly Chair", size: "S" },
    103000601: { themeID: "99011", colors: ["6011"], name: "Dolly Chair", size: "S" },
    103000602: { themeID: "99011", colors: ["6008", "6005"], name: "Dolly Chair", size: "S" },
    103000603: { themeID: "99011", colors: ["6010", "6011"], name: "Dolly Chair", size: "S" },
    103100100: { themeID: "99001", colors: ["6001", "6005"], name: "Urban chair", size: "S" },
    103100101: { themeID: "99001", colors: ["6008", "6001", "6005"], name: "Urban chair", size: "S" },
    103100102: { themeID: "99001", colors: ["6011", "6001", "6005"], name: "Urban chair", size: "S" },
    103100103: { themeID: "99001", colors: ["6002", "6001", "6005"], name: "Urban chair", size: "S" },
    103100104: { themeID: "99001", colors: ["6007", "6001", "6005"], name: "Urban chair", size: "S" },
    103100200: { themeID: "99001", colors: ["6001", "6002"], name: "Office chair", size: "S" },
    103100201: { themeID: "99001", colors: ["6008", "6002"], name: "Office chair", size: "S" },
    103100202: { themeID: "99001", colors: ["6011", "6002"], name: "Office chair", size: "S" },
    103100203: { themeID: "99001", colors: ["6004", "6002"], name: "Office chair", size: "S" },
    103100204: { themeID: "99001", colors: ["6007", "6002"], name: "Office chair", size: "S" },
    103200100: { themeID: "99007", colors: ["6005", "6009", "6006"], name: "Country chair", size: "M" },
    103200101: { themeID: "99007", colors: ["6011", "6010", "6001"], name: "Country chair", size: "M" },
    103200102: { themeID: "99007", colors: ["6006", "6007"], name: "Country chair", size: "M" },
    103200103: { themeID: "99007", colors: ["6008", "6004", "6001"], name: "Country chair", size: "M" },
    103200200: { themeID: "99008", colors: ["6003", "6005"], name: "Boho Hammock Chair", size: "S" },
    103200201: { themeID: "99008", colors: ["6005", "6001"], name: "Boho Hammock Chair", size: "S" },
    103200202: { themeID: "99008", colors: ["6010", "6004"], name: "Boho Hammock Chair", size: "S" },
    103200203: { themeID: "99008", colors: ["6006", "6007"], name: "Boho Hammock Chair", size: "S" },
    103200204: { themeID: "99008", colors: ["6004", "6008"], name: "Boho Hammock Chair", size: "S" },
    103300100: {
        themeID: "99009",
        colors: ["6005", "6001", "6007"],
        name: "Antique dining chair",
        size: "M",
    },
    103300101: {
        themeID: "99009",
        colors: ["6002", "6007", "6004"],
        name: "Antique dining chair",
        size: "M",
    },
    103300102: {
        themeID: "99009",
        colors: ["6011", "6001", "6007"],
        name: "Antique dining chair",
        size: "M",
    },
    103800200: { themeID: "99004", colors: ["6009", "6003", "6001"], name: "Rattan floral chair", size: "S" },
    103800201: { themeID: "99004", colors: ["6007", "6003", "6001"], name: "Rattan floral chair", size: "S" },
    103800202: { themeID: "99004", colors: ["6002", "6001", "6003"], name: "Rattan floral chair", size: "S" },
    103800203: { themeID: "99004", colors: ["6004", "6001", "6003"], name: "Rattan floral chair", size: "S" },
    104000100: { themeID: "99001", colors: ["6001", "6008", "6007"], name: "Inox Clothes Rack", size: "M" },
    104000101: {
        themeID: "99001",
        colors: ["6008", "6007", "6005", "6006"],
        name: "Inox Clothes Rack",
        size: "M",
    },
    104000102: {
        themeID: "99001",
        colors: ["6011", "6007", "6005", "6001"],
        name: "Inox Clothes Rack",
        size: "M",
    },
    104000103: {
        themeID: "99001",
        colors: ["6002", "6010", "6011", "6001"],
        name: "Inox Clothes Rack",
        size: "M",
    },
    104000104: {
        themeID: "99001",
        colors: ["6007", "6008", "6005", "6001"],
        name: "Inox Clothes Rack",
        size: "M",
    },
    104000200: { themeID: "99002", colors: ["6006", "6007", "6009"], name: "Rustic Closet", size: "M" },
    104000201: { themeID: "99002", colors: ["6009", "6008"], name: "Rustic Closet", size: "M" },
    104000202: { themeID: "99002", colors: ["6001", "6008"], name: "Rustic Closet", size: "M" },
    104000203: { themeID: "99002", colors: ["6011", "6004"], name: "Rustic Closet", size: "M" },
    104000204: { themeID: "99002", colors: ["6008", "6006"], name: "Rustic Closet", size: "M" },
    104000205: { themeID: "99002", colors: ["6002", "6004"], name: "Rustic Closet", size: "M" },
    104000206: { themeID: "99002", colors: ["6007", "6006", "6001"], name: "Rustic Closet", size: "M" },
    104000300: { themeID: "99003", colors: ["6011", "6003"], name: "Girlie Closet", size: "M" },
    104000301: { themeID: "99003", colors: ["6005", "6009"], name: "Girlie Closet", size: "M" },
    104000302: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie Closet", size: "M" },
    104000303: { themeID: "99003", colors: ["6002", "6008"], name: "Girlie Closet", size: "M" },
    104000400: { themeID: "99004", colors: ["6009", "6005"], name: "Rattan Closet", size: "L" },
    104000401: { themeID: "99004", colors: ["6007", "6006"], name: "Rattan Closet", size: "L" },
    104000402: { themeID: "99004", colors: ["6002", "6009", "6006"], name: "Rattan Closet", size: "L" },
    104000403: { themeID: "99004", colors: ["6004", "6001"], name: "Rattan Closet", size: "L" },
    104000500: { themeID: "99005", colors: ["6005", "6009"], name: "Toybox Closet", size: "M" },
    104000501: { themeID: "99005", colors: ["6011", "6003"], name: "Toybox Closet", size: "M" },
    104000502: { themeID: "99005", colors: ["6010", "6004"], name: "Toybox Closet", size: "M" },
    104000503: { themeID: "99005", colors: ["6008", "6001"], name: "Toybox Closet", size: "M" },
    104000504: { themeID: "99005", colors: ["6006", "6007"], name: "Toybox Closet", size: "M" },
    104000600: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage Closet", size: "M" },
    104000601: { themeID: "99006", colors: ["6008", "6006"], name: "Vintage Closet", size: "M" },
    104000602: { themeID: "99006", colors: ["6003", "6006", "6005"], name: "Vintage Closet", size: "M" },
    104000603: { themeID: "99006", colors: ["6009", "6006"], name: "Vintage Closet", size: "M" },
    104000700: { themeID: "99011", colors: ["6006", "6009", "6011"], name: "Dollhouse Closet", size: "M" },
    104000701: { themeID: "99011", colors: ["6011", "6001", "6010"], name: "Dollhouse Closet", size: "M" },
    104000702: { themeID: "99011", colors: ["6008", "6010", "6004"], name: "Dollhouse Closet", size: "M" },
    104000703: { themeID: "99011", colors: ["6010", "6001", "6011"], name: "Dollhouse Closet", size: "M" },
    104100100: {
        themeID: "99017",
        colors: ["6009", "6007", "6003"],
        name: "Reindeer closet",
        size: "L",
        xmas: true,
    },
    104100101: {
        themeID: "99017",
        colors: ["6002", "6007", "6004"],
        name: "Reindeer closet",
        size: "L",
        xmas: true,
    },
    104100102: {
        themeID: "99017",
        colors: ["6010", "6008", "6011"],
        name: "Reindeer closet",
        size: "L",
        xmas: true,
    },
    104200100: { themeID: "99007", colors: ["6005", "6001", "6006"], name: "Clock Nest Closet", size: "L" },
    104200101: { themeID: "99007", colors: ["6003", "6001", "6005"], name: "Clock Nest Closet", size: "L" },
    104200102: { themeID: "99007", colors: ["6011", "6001", "6010"], name: "Clock Nest Closet", size: "L" },
    104200103: { themeID: "99007", colors: ["6010", "6008", "6001"], name: "Clock Nest Closet", size: "L" },
    104200104: { themeID: "99007", colors: ["6007", "6006", "6001"], name: "Clock Nest Closet", size: "L" },
    104200200: { themeID: "99008", colors: ["6009", "6006"], name: "Boho closet", size: "M" },
    104200201: { themeID: "99008", colors: ["6011", "6004"], name: "Boho closet", size: "M" },
    104200202: { themeID: "99008", colors: ["6005", "6001"], name: "Boho closet", size: "M" },
    104200203: { themeID: "99008", colors: ["6003", "6005"], name: "Boho closet", size: "M" },
    104300100: { themeID: "99009", colors: ["6005", "6006", "6003"], name: "Antique closet", size: "XL" },
    104300101: { themeID: "99009", colors: ["6001", "6007", "6006"], name: "Antique closet", size: "XL" },
    104300102: { themeID: "99009", colors: ["6011", "6004", "6001"], name: "Antique closet", size: "XL" },
    104400100: { themeID: "99019", colors: ["6009", "6002"], name: "Stonehouse closet", size: "L" },
    104400101: { themeID: "99019", colors: ["6002", "6004"], name: "Stonehouse closet", size: "L" },
    104400102: { themeID: "99019", colors: ["6008", "6009", "6002"], name: "Stonehouse closet", size: "L" },
    104600100: {
        themeID: "99015",
        colors: ["6009", "6007", "6001"],
        name: "Refined Natural Closet",
        size: "M",
    },
    104600101: {
        themeID: "99015",
        colors: ["6007", "6009", "6006"],
        name: "Refined Natural Closet",
        size: "M",
    },
    104600102: {
        themeID: "99015",
        colors: ["6008", "6009", "6001"],
        name: "Refined Natural Closet",
        size: "M",
    },
    104600103: {
        themeID: "99015",
        colors: ["6003", "6011", "6001", "6004"],
        name: "Refined Natural Closet",
        size: "M",
    },
    104700100: {
        themeID: "99002",
        colors: ["6006", "6010", "6007", "6001", "6009"],
        name: "Rustic clothes rack",
        size: "M",
    },
    104700101: {
        themeID: "99002",
        colors: ["6008", "6011", "6007", "6001", "6006"],
        name: "Rustic clothes rack",
        size: "M",
    },
    104700102: {
        themeID: "99002",
        colors: ["6011", "6001", "6010", "6008", "6006"],
        name: "Rustic clothes rack",
        size: "M",
    },
    104700200: {
        themeID: "99015",
        colors: ["6006", "6001", "6007", "6003"],
        name: "Charming clothes rack",
        size: "L",
    },
    104700201: {
        themeID: "99015",
        colors: ["6007", "6011", "6006", "6010", "6001"],
        name: "Charming clothes rack",
        size: "L",
    },
    104700202: {
        themeID: "99015",
        colors: ["6008", "6007", "6006"],
        name: "Charming clothes rack",
        size: "L",
    },
    104700203: {
        themeID: "99015",
        colors: ["6011", "6010", "6008"],
        name: "Charming clothes rack",
        size: "L",
    },
    105000100: { themeID: "99002", colors: ["6009", "6006"], name: "Bath Brush", size: "XS" },
    105000101: { themeID: "99002", colors: ["6004", "6001"], name: "Bath Brush", size: "XS" },
    105000102: { themeID: "99002", colors: ["6011", "6001"], name: "Bath Brush", size: "XS" },
    105000103: { themeID: "99002", colors: ["6008", "6001"], name: "Bath Brush", size: "XS" },
    105000104: { themeID: "99002", colors: ["6007", "6001"], name: "Bath Brush", size: "XS" },
    105200100: { themeID: "99007", colors: ["6005", "6009"], name: "Country Dipper", size: "S" },
    105200101: { themeID: "99007", colors: ["6009", "6004"], name: "Country Dipper", size: "S" },
    105200102: { themeID: "99007", colors: ["6006", "6009"], name: "Country Dipper", size: "S" },
    106000200: { themeID: "99002", colors: ["6006", "6001"], name: "Toilet Paper", size: "S" },
    106000201: { themeID: "99002", colors: ["6011", "6001"], name: "Toilet Paper", size: "S" },
    106000202: { themeID: "99002", colors: ["6008", "6001"], name: "Toilet Paper", size: "S" },
    106000203: { themeID: "99002", colors: ["6001", "6004"], name: "Toilet Paper", size: "S" },
    106000204: { themeID: "99002", colors: ["6002", "6001"], name: "Toilet Paper", size: "S" },
    106000205: { themeID: "99002", colors: ["6007", "6001"], name: "Toilet Paper", size: "S" },
    106000300: { themeID: "99003", colors: ["6003", "6001"], name: "Girlie toilet paper", size: "XS" },
    106000301: { themeID: "99003", colors: ["6009", "6005"], name: "Girlie toilet paper", size: "XS" },
    106000302: { themeID: "99003", colors: ["6010"], name: "Girlie toilet paper", size: "XS" },
    106000303: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie toilet paper", size: "XS" },
    107000300: { themeID: "99002", colors: ["6009", "6001"], name: "Wavy Cypress Shelf", size: "M" },
    107000301: { themeID: "99002", colors: ["6001", "6004"], name: "Wavy Cypress Shelf", size: "M" },
    107000302: { themeID: "99002", colors: ["6011", "6001"], name: "Wavy Cypress Shelf", size: "M" },
    107000303: { themeID: "99002", colors: ["6008", "6001"], name: "Wavy Cypress Shelf", size: "M" },
    107000304: { themeID: "99002", colors: ["6004", "6002", "6001"], name: "Wavy Cypress Shelf", size: "M" },
    107000305: { themeID: "99002", colors: ["6007", "6001"], name: "Wavy Cypress Shelf", size: "M" },
    107000400: { themeID: "99002", colors: ["6009", "6006", "6008"], name: "Simple Shelf", size: "S" },
    107000401: { themeID: "99002", colors: ["6001", "6008", "6006"], name: "Simple Shelf", size: "S" },
    107000402: { themeID: "99002", colors: ["6002", "6001", "6010"], name: "Simple Shelf", size: "S" },
    107000403: { themeID: "99002", colors: ["6011", "6001", "6005"], name: "Simple Shelf", size: "S" },
    107000404: { themeID: "99002", colors: ["6008", "6001", "6005"], name: "Simple Shelf", size: "S" },
    107000405: { themeID: "99002", colors: ["6007", "6001", "6006"], name: "Simple Shelf", size: "S" },
    107000500: {
        themeID: "99003",
        colors: ["6003", "6001", "6008", "6002", "6005"],
        name: "Girlie Shoes Shelf",
        size: "L",
    },
    107000501: {
        themeID: "99003",
        colors: ["6009", "6006", "6001", "6007", "6003"],
        name: "Girlie Shoes Shelf",
        size: "L",
    },
    107000502: {
        themeID: "99003",
        colors: ["6010", "6011", "6001", "6002", "6004"],
        name: "Girlie Shoes Shelf",
        size: "L",
    },
    107000503: {
        themeID: "99003",
        colors: ["6002", "6008", "6001", "6005", "6004"],
        name: "Girlie Shoes Shelf",
        size: "L",
    },
    107001600: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie shelf", size: "M" },
    107001601: { themeID: "99003", colors: ["6009", "6005"], name: "Girlie shelf", size: "M" },
    107001602: { themeID: "99003", colors: ["6010", "6002"], name: "Girlie shelf", size: "M" },
    107001603: { themeID: "99003", colors: ["6002", "6004", "6008"], name: "Girlie shelf", size: "M" },
    107001700: { themeID: "99011", colors: ["6006", "6001"], name: "Cherry shelf", size: "M" },
    107001701: { themeID: "99011", colors: ["6011", "6001"], name: "Cherry shelf", size: "M" },
    107001702: { themeID: "99011", colors: ["6008", "6001"], name: "Cherry shelf", size: "M" },
    107001703: { themeID: "99011", colors: ["6010", "6011", "6001"], name: "Cherry shelf", size: "M" },
    107100100: { themeID: "99001", colors: ["6001", "6007", "6003"], name: "Urban shelf", size: "L" },
    107100101: { themeID: "99001", colors: ["6008", "6001", "6005"], name: "Urban shelf", size: "L" },
    107100102: { themeID: "99001", colors: ["6011", "6001", "6007"], name: "Urban shelf", size: "L" },
    107100103: { themeID: "99001", colors: ["6002", "6010", "6001"], name: "Urban shelf", size: "L" },
    107100104: { themeID: "99001", colors: ["6007", "6005", "6001"], name: "Urban shelf", size: "L" },
    107200100: { themeID: "99007", colors: ["6005", "6009"], name: "Country wooden shelf", size: "M" },
    107200101: { themeID: "99007", colors: ["6009", "6004"], name: "Country wooden shelf", size: "M" },
    107200102: { themeID: "99007", colors: ["6006", "6001"], name: "Country wooden shelf", size: "M" },
    107200103: { themeID: "99007", colors: ["6010", "6004"], name: "Country wooden shelf", size: "M" },
    107200104: { themeID: "99007", colors: ["6007", "6009"], name: "Country wooden shelf", size: "M" },
    107200105: { themeID: "99007", colors: ["6008", "6004"], name: "Country wooden shelf", size: "M" },
    107200200: { themeID: "99008", colors: ["6009", "6005"], name: "Bohemian mini shelf", size: "S" },
    107200201: { themeID: "99008", colors: ["6001"], name: "Bohemian mini shelf", size: "S" },
    107200202: { themeID: "99008", colors: ["6003"], name: "Bohemian mini shelf", size: "S" },
    107200203: { themeID: "99008", colors: ["6011"], name: "Bohemian mini shelf", size: "S" },
    107200204: { themeID: "99008", colors: ["6008"], name: "Bohemian mini shelf", size: "S" },
    107200205: { themeID: "99008", colors: ["6007"], name: "Bohemian mini shelf", size: "S" },
    107200300: {
        themeID: "99008",
        colors: ["6003", "6009", "6001"],
        name: "Bohemian hammock shelf",
        size: "M",
    },
    107200301: {
        themeID: "99008",
        colors: ["6010", "6004", "6001"],
        name: "Bohemian hammock shelf",
        size: "M",
    },
    107200302: {
        themeID: "99008",
        colors: ["6005", "6001", "6006"],
        name: "Bohemian hammock shelf",
        size: "M",
    },
    107300100: { themeID: "99009", colors: ["6003", "6009", "6002"], name: "Antique shelf", size: "S" },
    107300101: { themeID: "99009", colors: ["6008", "6001", "6002"], name: "Antique shelf", size: "S" },
    107300102: { themeID: "99009", colors: ["6001", "6002"], name: "Antique shelf", size: "S" },
    107500100: { themeID: "99011", colors: ["6009", "6006"], name: "Wooden Dolly Shelf", size: "M" },
    107500101: { themeID: "99011", colors: ["6011", "6001"], name: "Wooden Dolly Shelf", size: "M" },
    107500102: { themeID: "99011", colors: ["6008", "6004"], name: "Wooden Dolly Shelf", size: "M" },
    107500103: { themeID: "99011", colors: ["6010", "6011", "6004"], name: "Wooden Dolly Shelf", size: "M" },
    107800100: { themeID: "99015", colors: ["6009"], name: "Natural mini shelf", size: "M" },
    107800101: { themeID: "99015", colors: ["6007", "6009"], name: "Natural mini shelf", size: "M" },
    107800102: { themeID: "99015", colors: ["6008", "6009"], name: "Natural mini shelf", size: "M" },
    107800103: { themeID: "99015", colors: ["6003", "6004"], name: "Natural mini shelf", size: "M" },
    108000100: { themeID: "99002", colors: ["6009"], name: "Trio Wall Shelf", size: "S" },
    108000101: { themeID: "99002", colors: ["6007", "6006", "6001"], name: "Trio Wall Shelf", size: "S" },
    108000102: { themeID: "99002", colors: ["6011", "6001", "6010"], name: "Trio Wall Shelf", size: "S" },
    108000103: { themeID: "99002", colors: ["6008", "6001"], name: "Trio Wall Shelf", size: "S" },
    108000104: { themeID: "99002", colors: ["6002", "6004", "6001"], name: "Trio Wall Shelf", size: "S" },
    108000300: { themeID: "99004", colors: ["6009", "6005"], name: "Surfboard Shelf", size: "S" },
    108000301: { themeID: "99004", colors: ["6008", "6001"], name: "Surfboard Shelf", size: "S" },
    108000302: { themeID: "99004", colors: ["6003", "6001"], name: "Surfboard Shelf", size: "S" },
    108000303: { themeID: "99004", colors: ["6010", "6004"], name: "Surfboard Shelf", size: "S" },
    108000400: { themeID: "99004", colors: ["6009", "6003"], name: "Tropical Wall Shelf", size: "S" },
    108000401: { themeID: "99004", colors: ["6007", "6005", "6009"], name: "Tropical Wall Shelf", size: "S" },
    108000402: { themeID: "99004", colors: ["6008", "6001", "6009"], name: "Tropical Wall Shelf", size: "S" },
    108000403: { themeID: "99004", colors: ["6010", "6001", "6011"], name: "Tropical Wall Shelf", size: "S" },
    108000500: {
        themeID: "99006",
        colors: ["6005", "6007", "6006", "6009"],
        name: "Vintage Wall Rack",
        size: "S",
    },
    108000501: {
        themeID: "99006",
        colors: ["6007", "6006", "6009", "6003"],
        name: "Vintage Wall Rack",
        size: "S",
    },
    108000502: {
        themeID: "99006",
        colors: ["6008", "6003", "6001", "6009"],
        name: "Vintage Wall Rack",
        size: "S",
    },
    108000503: { themeID: "99006", colors: ["6009", "6011", "6001"], name: "Vintage Wall Rack", size: "S" },
    108200100: {
        themeID: "99007",
        colors: ["6005", "6001", "6004", "6007"],
        name: "Country Hanging Shelf",
        size: "M",
    },
    108200101: {
        themeID: "99007",
        colors: ["6004", "6010", "6001", "6011"],
        name: "Country Hanging Shelf",
        size: "M",
    },
    108200102: {
        themeID: "99007",
        colors: ["6006", "6007", "6001", "6005"],
        name: "Country Hanging Shelf",
        size: "M",
    },
    108200103: {
        themeID: "99007",
        colors: ["6001", "6008", "6004", "6010"],
        name: "Country Hanging Shelf",
        size: "M",
    },
    108200200: {
        themeID: "99008",
        colors: ["6009", "6006", "6007", "6001"],
        name: "Boho wall shelf",
        size: "S",
    },
    108200201: {
        themeID: "99008",
        colors: ["6001", "6004", "6007", "6003"],
        name: "Boho wall shelf",
        size: "S",
    },
    108200202: {
        themeID: "99008",
        colors: ["6004", "6001", "6003", "6008"],
        name: "Boho wall shelf",
        size: "S",
    },
    108200203: {
        themeID: "99008",
        colors: ["6008", "6011", "6001", "6007"],
        name: "Boho wall shelf",
        size: "S",
    },
    108700200: { themeID: "99011", colors: ["6005", "6001", "6006"], name: "Cute wall shelf", size: "M" },
    108700201: { themeID: "99011", colors: ["6011", "6001", "6007"], name: "Cute wall shelf", size: "M" },
    108700202: { themeID: "99011", colors: ["6008", "6001", "6010"], name: "Cute wall shelf", size: "M" },
    108700203: { themeID: "99011", colors: ["6007", "6009", "6006"], name: "Cute wall shelf", size: "M" },
    108700300: {
        themeID: "99011",
        colors: ["6009", "6006", "6001"],
        name: "Small shelf loves plants",
        size: "M",
    },
    108700301: {
        themeID: "99011",
        colors: ["6011", "6001", "6007"],
        name: "Small shelf loves plants",
        size: "M",
    },
    108700302: {
        themeID: "99011",
        colors: ["6008", "6001", "6004"],
        name: "Small shelf loves plants",
        size: "M",
    },
    108700303: {
        themeID: "99011",
        colors: ["6010", "6001", "6008"],
        name: "Small shelf loves plants",
        size: "M",
    },
    108700400: { themeID: "99011", colors: ["6006", "6009"], name: "Girl's wall shelf", size: "M" },
    108700401: { themeID: "99011", colors: ["6011", "6009"], name: "Girl's wall shelf", size: "M" },
    108700402: { themeID: "99011", colors: ["6008", "6009"], name: "Girl's wall shelf", size: "M" },
    108700403: { themeID: "99011", colors: ["6010", "6011", "6004"], name: "Girl's wall shelf", size: "M" },
    108800100: {
        themeID: "99015",
        colors: ["6009", "6001", "6006"],
        name: "Natural artistic shelf",
        size: "S",
    },
    108800101: {
        themeID: "99015",
        colors: ["6007", "6009", "6001"],
        name: "Natural artistic shelf",
        size: "S",
    },
    108800102: {
        themeID: "99015",
        colors: ["6008", "6001", "6003", "6009"],
        name: "Natural artistic shelf",
        size: "S",
    },
    108800103: {
        themeID: "99015",
        colors: ["6003", "6001", "6006"],
        name: "Natural artistic shelf",
        size: "S",
    },
    108800200: { themeID: "99002", colors: ["6009", "6001"], name: "Rustic artistic shelf", size: "S" },
    108800201: { themeID: "99002", colors: ["6001", "6011"], name: "Rustic artistic shelf", size: "S" },
    108800202: {
        themeID: "99002",
        colors: ["6008", "6001", "6005"],
        name: "Rustic artistic shelf",
        size: "S",
    },
    108800203: {
        themeID: "99002",
        colors: ["6011", "6005", "6001"],
        name: "Rustic artistic shelf",
        size: "S",
    },
    108800300: {
        themeID: "99001",
        colors: ["6002", "6001", "6007"],
        name: "Urban artistic shelf",
        size: "S",
    },
    108800301: { themeID: "99001", colors: ["6001", "6007"], name: "Urban artistic shelf", size: "S" },
    108800302: { themeID: "99001", colors: ["6008", "6007"], name: "Urban artistic shelf", size: "S" },
    108800303: { themeID: "99001", colors: ["6011", "6007"], name: "Urban artistic shelf", size: "S" },
    108800400: {
        themeID: "99015",
        colors: ["6007", "6010", "6005", "6003"],
        name: "Natural souvenir shelf",
        size: "S",
    },
    108800401: {
        themeID: "99015",
        colors: ["6009", "6007", "6006", "6008"],
        name: "Natural souvenir shelf",
        size: "S",
    },
    108800402: {
        themeID: "99015",
        colors: ["6003", "6008", "6010", "6006"],
        name: "Natural souvenir shelf",
        size: "S",
    },
    108800403: {
        themeID: "99015",
        colors: ["6008", "6009", "6007", "6006"],
        name: "Natural souvenir shelf",
        size: "S",
    },
    109100100: {
        themeID: "99003",
        colors: ["6011", "6003", "6001"],
        name: "Girlie sewing machine",
        size: "S",
    },
    109100101: {
        themeID: "99003",
        colors: ["6005", "6006", "6007"],
        name: "Girlie sewing machine",
        size: "S",
    },
    109100102: {
        themeID: "99003",
        colors: ["6010", "6011", "6001"],
        name: "Girlie sewing machine",
        size: "S",
    },
    109100103: {
        themeID: "99003",
        colors: ["6008", "6001", "6005"],
        name: "Girlie sewing machine",
        size: "S",
    },
    109301200: { themeID: "99013", colors: ["6001", "6011", "6007"], name: "Bunny webcam", size: "XS" },
    109301201: { themeID: "99013", colors: ["6011", "6006", "6007"], name: "Bunny webcam", size: "XS" },
    109301202: { themeID: "99013", colors: ["6008", "6006"], name: "Bunny webcam", size: "XS" },
    109301203: { themeID: "99013", colors: ["6010", "6006"], name: "Bunny webcam", size: "XS" },
    109301300: { themeID: "99013", colors: ["6002", "6001"], name: "Digital painting tablet", size: "S" },
    109301301: { themeID: "99013", colors: ["6011"], name: "Digital painting tablet", size: "S" },
    109301302: { themeID: "99013", colors: ["6008"], name: "Digital painting tablet", size: "S" },
    109301303: { themeID: "99013", colors: ["6010"], name: "Digital painting tablet", size: "S" },
    109700100: { themeID: "99001", colors: ["6007", "6006", "6004"], name: "Urban iron", size: "XS" },
    109700101: { themeID: "99001", colors: ["6008", "6006", "6004"], name: "Urban iron", size: "XS" },
    109700102: { themeID: "99001", colors: ["6011", "6006", "6004"], name: "Urban iron", size: "XS" },
    109700103: { themeID: "99001", colors: ["6010", "6008", "6004"], name: "Urban iron", size: "XS" },
    109700200: { themeID: "99015", colors: ["6009", "6004"], name: "Refined iron", size: "XS" },
    109700201: { themeID: "99015", colors: ["6007", "6004"], name: "Refined iron", size: "XS" },
    109700202: { themeID: "99015", colors: ["6008", "6004"], name: "Refined iron", size: "XS" },
    109700203: { themeID: "99015", colors: ["6003", "6004"], name: "Refined iron", size: "XS" },
    109700300: { themeID: "99015", colors: ["6009", "6001"], name: "Refined sewing machine", size: "S" },
    109700301: {
        themeID: "99015",
        colors: ["6007", "6001", "6004"],
        name: "Refined sewing machine",
        size: "S",
    },
    109700302: {
        themeID: "99015",
        colors: ["6008", "6001", "6009"],
        name: "Refined sewing machine",
        size: "S",
    },
    109700303: {
        themeID: "99015",
        colors: ["6003", "6004", "6001"],
        name: "Refined sewing machine",
        size: "S",
    },
    109700400: { themeID: "99001", colors: ["6007", "6004"], name: "Urban sewing machine", size: "S" },
    109700401: { themeID: "99001", colors: ["6008", "6004"], name: "Urban sewing machine", size: "S" },
    109700402: { themeID: "99001", colors: ["6011", "6004"], name: "Urban sewing machine", size: "S" },
    109700403: { themeID: "99001", colors: ["6010", "6004"], name: "Urban sewing machine", size: "S" },
    111000100: { themeID: "99001", colors: ["6001", "6007"], name: "Sansevieria Plant", size: "S" },
    111000101: { themeID: "99001", colors: ["6011", "6007", "6001"], name: "Sansevieria Plant", size: "S" },
    111000102: {
        themeID: "99001",
        colors: ["6004", "6002", "6010", "6008"],
        name: "Sansevieria Plant",
        size: "S",
    },
    111000103: { themeID: "99001", colors: ["6008", "6007"], name: "Sansevieria Plant", size: "S" },
    111000200: { themeID: "99001", colors: ["6001", "6007", "6009"], name: "Urban Ficus Plant", size: "S" },
    111000201: { themeID: "99001", colors: ["6008", "6004", "6001"], name: "Urban Ficus Plant", size: "S" },
    111000202: { themeID: "99001", colors: ["6003", "6011", "6004"], name: "Urban Ficus Plant", size: "S" },
    111000203: { themeID: "99001", colors: ["6002", "6010", "6001"], name: "Urban Ficus Plant", size: "S" },
    111000300: { themeID: "99002", colors: ["6007", "6008"], name: "Little Flower Bud", size: "XS" },
    111000301: { themeID: "99002", colors: ["6001", "6007"], name: "Little Flower Bud", size: "XS" },
    111000302: { themeID: "99002", colors: ["6011", "6007"], name: "Little Flower Bud", size: "XS" },
    111000303: { themeID: "99002", colors: ["6010", "6007"], name: "Little Flower Bud", size: "XS" },
    111000400: { themeID: "99002", colors: ["6009", "6007"], name: "Green Panel", size: "S" },
    111000401: { themeID: "99002", colors: ["6001", "6007"], name: "Green Panel", size: "S" },
    111000402: { themeID: "99002", colors: ["6002", "6007"], name: "Green Panel", size: "S" },
    111000500: { themeID: "99003", colors: ["6003", "6007"], name: "Elegant caged plant", size: "M" },
    111000501: { themeID: "99003", colors: ["6010", "6007"], name: "Elegant caged plant", size: "M" },
    111000502: { themeID: "99003", colors: ["6009", "6007"], name: "Elegant caged plant", size: "M" },
    111000503: { themeID: "99003", colors: ["6002", "6007", "6008"], name: "Elegant caged plant", size: "M" },
    111000600: { themeID: "99004", colors: ["6007", "6009"], name: "Tropical Plant", size: "XS" },
    111000601: { themeID: "99004", colors: ["6008", "6004"], name: "Tropical Plant", size: "XS" },
    111000602: { themeID: "99004", colors: ["6003", "6004", "6011"], name: "Tropical Plant", size: "XS" },
    111000603: { themeID: "99004", colors: ["6010", "6002"], name: "Tropical Plant", size: "XS" },
    111000700: { themeID: "99005", colors: ["6006", "6007"], name: "Flower test-tube", size: "S" },
    111000701: { themeID: "99005", colors: ["6011", "6007"], name: "Flower test-tube", size: "S" },
    111000702: { themeID: "99005", colors: ["6010", "6007"], name: "Flower test-tube", size: "S" },
    111000703: { themeID: "99005", colors: ["6008", "6007"], name: "Flower test-tube", size: "S" },
    111000704: { themeID: "99005", colors: ["6007", "6001"], name: "Flower test-tube", size: "S" },
    111000800: { themeID: "99006", colors: ["6011", "6007"], name: "Vintage bush", size: "S" },
    111000801: { themeID: "99006", colors: ["6001", "6008", "6003"], name: "Vintage bush", size: "S" },
    111000802: { themeID: "99006", colors: ["6008", "6011"], name: "Vintage bush", size: "S" },
    111000803: { themeID: "99006", colors: ["6002", "6011", "6007"], name: "Vintage bush", size: "S" },
    111000804: { themeID: "99006", colors: ["6005", "6007"], name: "Vintage bush", size: "S" },
    111000900: { themeID: "99006", colors: ["6007", "6005"], name: "Vintage Dried Plant", size: "S" },
    111000901: { themeID: "99006", colors: ["6008", "6005"], name: "Vintage Dried Plant", size: "S" },
    111000902: { themeID: "99006", colors: ["6011", "6003"], name: "Vintage Dried Plant", size: "S" },
    111000903: { themeID: "99006", colors: ["6010", "6003"], name: "Vintage Dried Plant", size: "S" },
    111001000: { themeID: "99011", colors: ["6007", "6011"], name: "Little Pottery Vase", size: "S" },
    111001001: { themeID: "99011", colors: ["6003", "6006", "6007"], name: "Little Pottery Vase", size: "S" },
    111001002: { themeID: "99011", colors: ["6008", "6001", "6007"], name: "Little Pottery Vase", size: "S" },
    111001003: { themeID: "99011", colors: ["6001", "6011", "6007"], name: "Little Pottery Vase", size: "S" },
    111001100: { themeID: "99011", colors: ["6006", "6003", "6001"], name: "Dolly Rosy Vase", size: "S" },
    111001101: { themeID: "99011", colors: ["6011", "6001", "6006"], name: "Dolly Rosy Vase", size: "S" },
    111001102: { themeID: "99011", colors: ["6008", "6011", "6001"], name: "Dolly Rosy Vase", size: "S" },
    111001103: { themeID: "99011", colors: ["6010", "6011"], name: "Dolly Rosy Vase", size: "S" },
    111001200: { themeID: "99011", colors: ["6005", "6007", "6003"], name: "Dolly plant", size: "S" },
    111001201: { themeID: "99011", colors: ["6011", "6007"], name: "Dolly plant", size: "S" },
    111001202: { themeID: "99011", colors: ["6008", "6007", "6001"], name: "Dolly plant", size: "S" },
    111001203: { themeID: "99011", colors: ["6010", "6011", "6007"], name: "Dolly plant", size: "S" },
    111001300: { themeID: "99006", colors: ["6009", "6007"], name: "Vintage planter", size: "M" },
    111001301: { themeID: "99006", colors: ["6008", "6003"], name: "Vintage planter", size: "M" },
    111001302: { themeID: "99006", colors: ["6003", "6007"], name: "Vintage planter", size: "M" },
    111001303: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage planter", size: "M" },
    111100100: { themeID: "99003", colors: ["6011", "6001", "6003"], name: "Rose vase", size: "S" },
    111100101: { themeID: "99003", colors: ["6007", "6006", "6003"], name: "Rose vase", size: "S" },
    111100102: { themeID: "99003", colors: ["6010", "6011", "6004"], name: "Rose vase", size: "S" },
    111100103: { themeID: "99003", colors: ["6008", "6005", "6001"], name: "Rose vase", size: "S" },
    111100300: {
        themeID: "99017",
        colors: ["6007", "6006", "6003"],
        name: "Xmas tree",
        size: "L",
        xmas: true,
    },
    111100301: {
        themeID: "99017",
        colors: ["6011", "6001", "6005"],
        name: "Xmas tree",
        size: "L",
        xmas: true,
    },
    111100302: {
        themeID: "99017",
        colors: ["6008", "6010", "6001"],
        name: "Xmas tree",
        size: "L",
        xmas: true,
    },
    111140100: { themeID: "99009", colors: ["6002", "6007", "6011"], name: "Antique Vase", size: "XS" },
    111140101: { themeID: "99009", colors: ["6009", "6007", "6003"], name: "Antique Vase", size: "XS" },
    111200100: {
        themeID: "99007",
        colors: ["6003", "6001", "6007", "6009"],
        name: "Country flower bush",
        size: "S",
    },
    111200101: {
        themeID: "99007",
        colors: ["6010", "6009", "6007", "6001"],
        name: "Country flower bush",
        size: "S",
    },
    111200102: {
        themeID: "99007",
        colors: ["6005", "6007", "6001", "6009"],
        name: "Country flower bush",
        size: "S",
    },
    111200200: { themeID: "99007", colors: ["6006", "6001", "6007"], name: "Country flower can", size: "S" },
    111200201: { themeID: "99007", colors: ["6005", "6007", "6001"], name: "Country flower can", size: "S" },
    111200202: { themeID: "99007", colors: ["6011", "6007", "6001"], name: "Country flower can", size: "S" },
    111200203: { themeID: "99007", colors: ["6010", "6001", "6007"], name: "Country flower can", size: "S" },
    111200204: { themeID: "99007", colors: ["6008", "6007", "6001"], name: "Country flower can", size: "S" },
    111200205: { themeID: "99007", colors: ["6007", "6006", "6001"], name: "Country flower can", size: "S" },
    111200300: { themeID: "99007", colors: ["6006", "6008"], name: "Tulip Jar", size: "S" },
    111200301: { themeID: "99007", colors: ["6003", "6011"], name: "Tulip Jar", size: "S" },
    111200302: { themeID: "99007", colors: ["6011", "6008"], name: "Tulip Jar", size: "S" },
    111200400: {
        themeID: "99007",
        colors: ["6003", "6009", "6011"],
        name: "Country Flower basket",
        size: "XS",
    },
    111200401: {
        themeID: "99007",
        colors: ["6005", "6009", "6006"],
        name: "Country Flower basket",
        size: "XS",
    },
    111200402: {
        themeID: "99007",
        colors: ["6001", "6009", "6010"],
        name: "Country Flower basket",
        size: "XS",
    },
    111200403: { themeID: "99007", colors: ["6010", "6009"], name: "Country Flower basket", size: "XS" },
    111200404: { themeID: "99007", colors: ["6008", "6004"], name: "Country Flower basket", size: "XS" },
    111200405: { themeID: "99007", colors: ["6007", "6009"], name: "Country Flower basket", size: "XS" },
    111200500: { themeID: "99008", colors: ["6006", "6007", "6003"], name: "Pottery Plant", size: "S" },
    111200501: { themeID: "99008", colors: ["6001", "6007", "6003"], name: "Pottery Plant", size: "S" },
    111200502: { themeID: "99008", colors: ["6003", "6007"], name: "Pottery Plant", size: "S" },
    111200503: { themeID: "99008", colors: ["6011", "6010", "6007"], name: "Pottery Plant", size: "S" },
    111200504: { themeID: "99008", colors: ["6010", "6007", "6003"], name: "Pottery Plant", size: "S" },
    111200505: { themeID: "99008", colors: ["6008", "6007", "6011"], name: "Pottery Plant", size: "S" },
    111204000: { themeID: "99008", colors: ["6002", "6007", "6006"], name: "Bohemian planter", size: "M" },
    111300200: {
        themeID: "99009",
        colors: ["6001", "6006", "6007"],
        name: "Elegant flower vase",
        size: "XS",
    },
    111300201: { themeID: "99009", colors: ["6008", "6010"], name: "Elegant flower vase", size: "XS" },
    111300202: { themeID: "99009", colors: ["6011", "6007"], name: "Elegant flower vase", size: "XS" },
    111300300: { themeID: "99009", colors: ["6001", "6007"], name: "Classy weeping fig", size: "M" },
    111300301: { themeID: "99009", colors: ["6002", "6007"], name: "Classy weeping fig", size: "M" },
    111300302: { themeID: "99009", colors: ["6010", "6011"], name: "Classy weeping fig", size: "M" },
    111600300: { themeID: "99015", colors: ["6009"], name: "Refined bamboo pot", size: "S" },
    111600301: { themeID: "99015", colors: ["6007"], name: "Refined bamboo pot", size: "S" },
    111600302: { themeID: "99015", colors: ["6008"], name: "Refined bamboo pot", size: "S" },
    111600303: { themeID: "99015", colors: ["6003"], name: "Refined bamboo pot", size: "S" },
    111700100: { themeID: "99002", colors: ["6007", "6001"], name: "Bush", size: "M" },
    111700101: { themeID: "99002", colors: ["6007", "6006", "6001"], name: "Bush", size: "M" },
    111700102: { themeID: "99002", colors: ["6005", "6001"], name: "Bush", size: "M" },
    111700103: { themeID: "99002", colors: ["6011", "6001"], name: "Bush", size: "M" },
    111700200: { themeID: "99005", colors: ["6011", "6008", "6001"], name: "Candy Tree", size: "M" },
    111700201: { themeID: "99005", colors: ["6008", "6001", "6011"], name: "Candy Tree", size: "M" },
    111700202: { themeID: "99005", colors: ["6006", "6001", "6003"], name: "Candy Tree", size: "M" },
    111700700: { themeID: "99017", colors: ["6007", "6003"], name: "Xmas pine pot", size: "M", xmas: true },
    111700701: { themeID: "99017", colors: ["6011", "6007"], name: "Xmas pine pot", size: "M", xmas: true },
    111700702: { themeID: "99017", colors: ["6008", "6010"], name: "Xmas pine pot", size: "M", xmas: true },
    111700900: {
        themeID: "99019",
        colors: ["6002", "6007", "6001", "6004"],
        name: "Stonehouse Tree",
        size: "S",
    },
    111700901: {
        themeID: "99019",
        colors: ["6009", "6007", "6001", "6006"],
        name: "Stonehouse Tree",
        size: "S",
    },
    111700902: {
        themeID: "99019",
        colors: ["6004", "6006", "6001", "6007"],
        name: "Stonehouse Tree",
        size: "S",
    },
    111701000: {
        themeID: "99004",
        colors: ["6009", "6007", "6001"],
        name: "Rattan flower basket",
        size: "S",
    },
    111701001: {
        themeID: "99004",
        colors: ["6010", "6007", "6001"],
        name: "Rattan flower basket",
        size: "S",
    },
    111701002: {
        themeID: "99004",
        colors: ["6005", "6007", "6001", "6009"],
        name: "Rattan flower basket",
        size: "S",
    },
    111701003: {
        themeID: "99004",
        colors: ["6008", "6007", "6001"],
        name: "Rattan flower basket",
        size: "S",
    },
    111701100: { themeID: "99015", colors: ["6003", "6008"], name: "Charming flower pot", size: "S" },
    111701101: { themeID: "99015", colors: ["6010", "6007"], name: "Charming flower pot", size: "S" },
    111701102: { themeID: "99015", colors: ["6008", "6010"], name: "Charming flower pot", size: "S" },
    111701200: { themeID: "99009", colors: ["6005", "6003"], name: "Antique rosy vase", size: "S" },
    111701201: { themeID: "99009", colors: ["6003", "6006"], name: "Antique rosy vase", size: "S" },
    111701300: {
        themeID: "99004",
        colors: ["6006", "6001", "6007", "6009"],
        name: "Rattan reed basket",
        size: "S",
    },
    111701301: { themeID: "99004", colors: ["6003", "6008", "6010"], name: "Rattan reed basket", size: "S" },
    111701302: { themeID: "99004", colors: ["6010", "6001", "6006"], name: "Rattan reed basket", size: "S" },
    111701400: { themeID: "99003", colors: ["6009", "6007"], name: "Hearty plant", size: "S" },
    111701401: { themeID: "99003", colors: ["6010", "6007", "6006"], name: "Hearty plant", size: "S" },
    111701402: { themeID: "99003", colors: ["6008", "6011"], name: "Hearty plant", size: "S" },
    111701500: { themeID: "99002", colors: ["6009", "6007", "6006"], name: "Simple tulip pot", size: "XS" },
    111701501: { themeID: "99002", colors: ["6007", "6010"], name: "Simple tulip pot", size: "XS" },
    111701502: { themeID: "99002", colors: ["6010", "6007", "6003"], name: "Simple tulip pot", size: "XS" },
    111701600: { themeID: "99019", colors: ["6004", "6008"], name: "Stony plant pot", size: "XS" },
    111701601: { themeID: "99019", colors: ["6009", "6007"], name: "Stony plant pot", size: "XS" },
    111701602: { themeID: "99019", colors: ["6010", "6009"], name: "Stony plant pot", size: "XS" },
    111701700: { themeID: "99019", colors: ["6003", "6009"], name: "Stony twig", size: "S" },
    111701701: { themeID: "99019", colors: ["6008", "6011", "6010"], name: "Stony twig", size: "S" },
    111701702: { themeID: "99019", colors: ["6008", "6006", "6011", "6007"], name: "Stony twig", size: "S" },
    111701703: { themeID: "99019", colors: ["6007", "6008"], name: "Stony twig", size: "S" },
    111701800: { themeID: "99001", colors: ["6001", "6007"], name: "Little Snake Plant", size: "XS" },
    111701801: { themeID: "99001", colors: ["6008", "6007"], name: "Little Snake Plant", size: "XS" },
    111701802: { themeID: "99001", colors: ["6011", "6008", "6007"], name: "Little Snake Plant", size: "XS" },
    111701803: { themeID: "99001", colors: ["6004", "6010", "6008"], name: "Little Snake Plant", size: "XS" },
    111701900: { themeID: "99011", colors: ["6006", "6005", "6007"], name: "Cute Succulent", size: "XS" },
    111701901: { themeID: "99011", colors: ["6010", "6008"], name: "Cute Succulent", size: "XS" },
    111701902: { themeID: "99011", colors: ["6003", "6011", "6010"], name: "Cute Succulent", size: "XS" },
    111702000: { themeID: "99011", colors: ["6006", "6007", "6011"], name: "Small flower buds", size: "XS" },
    111702001: { themeID: "99011", colors: ["6008", "6010"], name: "Small flower buds", size: "XS" },
    111702002: { themeID: "99011", colors: ["6010", "6006", "6007"], name: "Small flower buds", size: "XS" },
    111800100: { themeID: "99004", colors: ["6011", "6009"], name: "Tropical ceramic vase", size: "XS" },
    111800101: {
        themeID: "99004",
        colors: ["6006", "6007", "6011"],
        name: "Tropical ceramic vase",
        size: "XS",
    },
    111800102: {
        themeID: "99004",
        colors: ["6003", "6007", "6009"],
        name: "Tropical ceramic vase",
        size: "XS",
    },
    111800200: { themeID: "99001", colors: ["6007", "6004"], name: "Urban recycle pot", size: "XS" },
    111800201: { themeID: "99001", colors: ["6006", "6004"], name: "Urban recycle pot", size: "XS" },
    111800202: { themeID: "99001", colors: ["6003", "6008"], name: "Urban recycle pot", size: "XS" },
    111800203: { themeID: "99001", colors: ["6008", "6004"], name: "Urban recycle pot", size: "XS" },
    111900100: {
        themeID: "99017",
        colors: ["6007", "6003"],
        name: "Xmas little pine pot",
        size: "S",
        xmas: true,
    },
    112100100: { themeID: "99002", colors: ["6007", "6009"], name: "Rustic wild vine", size: "M" },
    112100101: { themeID: "99002", colors: ["6007", "6008", "6002"], name: "Rustic wild vine", size: "M" },
    112100102: {
        themeID: "99002",
        colors: ["6007", "6006", "6005", "6004"],
        name: "Rustic wild vine",
        size: "M",
    },
    112100200: { themeID: "99001", colors: ["6001", "6007", "6006"], name: "Urban wall plant", size: "S" },
    112100201: {
        themeID: "99001",
        colors: ["6008", "6001", "6007", "6006"],
        name: "Urban wall plant",
        size: "S",
    },
    112100202: {
        themeID: "99001",
        colors: ["6011", "6001", "6003", "6007"],
        name: "Urban wall plant",
        size: "S",
    },
    112100203: {
        themeID: "99001",
        colors: ["6004", "6007", "6001", "6002"],
        name: "Urban wall plant",
        size: "S",
    },
    112100204: { themeID: "99001", colors: ["6009", "6001", "6007"], name: "Urban wall plant", size: "S" },
    112100300: { themeID: "99017", colors: ["6007", "6003"], name: "Xmas wreath", size: "M", xmas: true },
    112100301: { themeID: "99017", colors: ["6008", "6011"], name: "Xmas wreath", size: "M", xmas: true },
    112100302: { themeID: "99017", colors: ["6011", "6010"], name: "Xmas wreath", size: "M", xmas: true },
    112130100: {
        themeID: "99009",
        colors: ["6001", "6007", "6008"],
        name: "Antique wall planter",
        size: "XS",
    },
    112130101: {
        themeID: "99009",
        colors: ["6005", "6007", "6008"],
        name: "Antique wall planter",
        size: "XS",
    },
    112130102: { themeID: "99009", colors: ["6004", "6007"], name: "Antique wall planter", size: "XS" },
    112130103: { themeID: "99009", colors: ["6002", "6007"], name: "Antique wall planter", size: "XS" },
    112200100: {
        themeID: "99007",
        colors: ["6006", "6007", "6009"],
        name: "Country Hanging Plant",
        size: "S",
    },
    112200101: {
        themeID: "99007",
        colors: ["6003", "6005", "6007"],
        name: "Country Hanging Plant",
        size: "S",
    },
    112200102: {
        themeID: "99007",
        colors: ["6010", "6011", "6007"],
        name: "Country Hanging Plant",
        size: "S",
    },
    112200200: { themeID: "99007", colors: ["6006", "6007", "6001"], name: "Laurel wreath", size: "S" },
    112200201: { themeID: "99007", colors: ["6003", "6007", "6001"], name: "Laurel wreath", size: "S" },
    112200202: { themeID: "99007", colors: ["6010", "6007", "6001"], name: "Laurel wreath", size: "S" },
    112200203: { themeID: "99007", colors: ["6005", "6007", "6001"], name: "Laurel wreath", size: "S" },
    112200300: { themeID: "99007", colors: ["6006", "6007", "6001"], name: "Country vine", size: "M" },
    112200301: { themeID: "99007", colors: ["6003", "6001", "6007"], name: "Country vine", size: "M" },
    112200302: { themeID: "99007", colors: ["6010", "6007", "6001"], name: "Country vine", size: "M" },
    112200303: { themeID: "99007", colors: ["6007", "6001"], name: "Country vine", size: "M" },
    112200400: { themeID: "99008", colors: ["6005", "6007"], name: "Boho Hanging Plant", size: "S" },
    112200401: { themeID: "99008", colors: ["6003", "6007"], name: "Boho Hanging Plant", size: "S" },
    112200402: { themeID: "99008", colors: ["6010", "6011", "6007"], name: "Boho Hanging Plant", size: "S" },
    112200403: { themeID: "99008", colors: ["6008", "6007", "6003"], name: "Boho Hanging Plant", size: "S" },
    112200404: { themeID: "99008", colors: ["6004", "6005", "6001"], name: "Boho Hanging Plant", size: "S" },
    112201200: { themeID: "99008", colors: ["6004", "6009"], name: "Boho boot planter", size: "XS" },
    112201201: { themeID: "99008", colors: ["6002", "6006", "6001"], name: "Boho boot planter", size: "XS" },
    112201202: { themeID: "99008", colors: ["6011", "6001"], name: "Boho boot planter", size: "XS" },
    112300100: { themeID: "99009", colors: ["6003", "6007"], name: "Hanging nasturtium", size: "S" },
    112300101: { themeID: "99009", colors: ["6008", "6007"], name: "Hanging nasturtium", size: "S" },
    112300102: { themeID: "99009", colors: ["6006", "6007"], name: "Hanging nasturtium", size: "S" },
    112300200: { themeID: "99009", colors: ["6003", "6007"], name: "Small lotus vines", size: "S" },
    112300201: { themeID: "99009", colors: ["6008", "6007"], name: "Small lotus vines", size: "S" },
    112300202: { themeID: "99009", colors: ["6006", "6007"], name: "Small lotus vines", size: "S" },
    112300300: { themeID: "99009", colors: ["6003", "6002", "6007"], name: "Curling iron vines", size: "M" },
    112300301: { themeID: "99009", colors: ["6008", "6007", "6002"], name: "Curling iron vines", size: "M" },
    112300302: { themeID: "99009", colors: ["6006", "6007", "6002"], name: "Curling iron vines", size: "M" },
    112300400: { themeID: "99009", colors: ["6003", "6007"], name: "Long lotus vines", size: "M" },
    112300401: { themeID: "99009", colors: ["6008", "6007"], name: "Long lotus vines", size: "M" },
    112300402: { themeID: "99009", colors: ["6006", "6007"], name: "Long lotus vines", size: "M" },
    112400100: { themeID: "99019", colors: ["6004", "6007"], name: "Stonehouse wall plant", size: "S" },
    112400101: { themeID: "99019", colors: ["6003", "6007"], name: "Stonehouse wall plant", size: "S" },
    112400102: { themeID: "99019", colors: ["6008", "6007"], name: "Stonehouse wall plant", size: "S" },
    112700100: {
        themeID: "99002",
        colors: ["6009", "6001", "6007", "6008", "6005", "6006"],
        name: "Rustic trio pots",
        size: "S",
    },
    112700101: {
        themeID: "99002",
        colors: ["6003", "6001", "6007", "6011", "6010"],
        name: "Rustic trio pots",
        size: "S",
    },
    112700102: {
        themeID: "99002",
        colors: ["6004", "6001", "6011", "6008"],
        name: "Rustic trio pots",
        size: "S",
    },
    112700200: { themeID: "99011", colors: ["6008", "6004"], name: "Ribbon wall pots", size: "S" },
    112700201: { themeID: "99011", colors: ["6006", "6004"], name: "Ribbon wall pots", size: "S" },
    112700202: { themeID: "99011", colors: ["6004", "6011"], name: "Ribbon wall pots", size: "S" },
    113000100: { themeID: "99001", colors: ["6006", "6009"], name: "Urban Night Lamp", size: "XS" },
    113000101: { themeID: "99001", colors: ["6008", "6009"], name: "Urban Night Lamp", size: "XS" },
    113000102: { themeID: "99001", colors: ["6003", "6009"], name: "Urban Night Lamp", size: "XS" },
    113000103: { themeID: "99001", colors: ["6010", "6002"], name: "Urban Night Lamp", size: "XS" },
    113000104: { themeID: "99001", colors: ["6007", "6009"], name: "Urban Night Lamp", size: "XS" },
    113000200: { themeID: "99001", colors: ["6006"], name: "Urban Desk Lamp", size: "XS" },
    113000201: { themeID: "99001", colors: ["6008", "6006"], name: "Urban Desk Lamp", size: "XS" },
    113000202: { themeID: "99001", colors: ["6011"], name: "Urban Desk Lamp", size: "XS" },
    113000203: { themeID: "99001", colors: ["6007"], name: "Urban Desk Lamp", size: "XS" },
    113000204: { themeID: "99001", colors: ["6010"], name: "Urban Desk Lamp", size: "XS" },
    113000300: { themeID: "99003", colors: ["6003", "6011"], name: "Curly Lamp", size: "M" },
    113000301: { themeID: "99003", colors: ["6009", "6005"], name: "Curly Lamp", size: "M" },
    113000302: { themeID: "99003", colors: ["6010", "6004"], name: "Curly Lamp", size: "M" },
    113000303: { themeID: "99003", colors: ["6002", "6008"], name: "Curly Lamp", size: "M" },
    113000400: { themeID: "99004", colors: ["6005", "6009", "6006"], name: "Pineapple lamp", size: "S" },
    113000401: { themeID: "99004", colors: ["6009", "6006"], name: "Pineapple lamp", size: "S" },
    113000402: { themeID: "99004", colors: ["6007", "6006", "6009"], name: "Pineapple lamp", size: "S" },
    113000403: { themeID: "99004", colors: ["6008", "6006", "6004"], name: "Pineapple lamp", size: "S" },
    113000404: { themeID: "99004", colors: ["6011", "6006", "6010"], name: "Pineapple lamp", size: "S" },
    113000500: { themeID: "99005", colors: ["6009", "6006", "6003"], name: "Fiber Mushroom Lamp", size: "M" },
    113000501: { themeID: "99005", colors: ["6011", "6010"], name: "Fiber Mushroom Lamp", size: "M" },
    113000502: { themeID: "99005", colors: ["6008", "6004"], name: "Fiber Mushroom Lamp", size: "M" },
    113000600: { themeID: "99011", colors: ["6005", "6007"], name: "Bunny Balloon Bulb", size: "S" },
    113000601: { themeID: "99011", colors: ["6011", "6003"], name: "Bunny Balloon Bulb", size: "S" },
    113000602: { themeID: "99011", colors: ["6008", "6011"], name: "Bunny Balloon Bulb", size: "S" },
    113000603: { themeID: "99011", colors: ["6010", "6008"], name: "Bunny Balloon Bulb", size: "S" },
    113000700: { themeID: "99011", colors: ["6005", "6001"], name: "Strawberry Lamp", size: "M" },
    113000701: { themeID: "99011", colors: ["6011", "6001"], name: "Strawberry Lamp", size: "M" },
    113000702: { themeID: "99011", colors: ["6008", "6001"], name: "Strawberry Lamp", size: "M" },
    113000703: { themeID: "99011", colors: ["6010", "6001"], name: "Strawberry Lamp", size: "M" },
    113000800: { themeID: "99011", colors: ["6006", "6007", "6009"], name: "Mushy lamp", size: "XS" },
    113000801: { themeID: "99011", colors: ["6011", "6003", "6001"], name: "Mushy lamp", size: "XS" },
    113000802: { themeID: "99011", colors: ["6008", "6011", "6009"], name: "Mushy lamp", size: "XS" },
    113000803: { themeID: "99011", colors: ["6010", "6011", "6004"], name: "Mushy lamp", size: "XS" },
    113100100: { themeID: "99002", colors: ["6006", "6009", "6007"], name: "Rustic bulb", size: "XS" },
    113100101: { themeID: "99002", colors: ["6011", "6004", "6007"], name: "Rustic bulb", size: "XS" },
    113100102: { themeID: "99002", colors: ["6008", "6004", "6007"], name: "Rustic bulb", size: "XS" },
    113100103: { themeID: "99002", colors: ["6004", "6001", "6007"], name: "Rustic bulb", size: "XS" },
    113100104: { themeID: "99002", colors: ["6007", "6009"], name: "Rustic bulb", size: "XS" },
    113130100: { themeID: "99013", colors: ["6010", "6011"], name: "Streamy night lamp", size: "S" },
    113130101: { themeID: "99013", colors: ["6006", "6009"], name: "Streamy night lamp", size: "S" },
    113130102: { themeID: "99013", colors: ["6003", "6009"], name: "Streamy night lamp", size: "S" },
    113130103: { themeID: "99013", colors: ["6008", "6004"], name: "Streamy night lamp", size: "S" },
    113200100: { themeID: "99007", colors: ["6006"], name: "Country Girl Lamp", size: "S" },
    113200101: { themeID: "99007", colors: ["6003", "6011"], name: "Country Girl Lamp", size: "S" },
    113200102: { themeID: "99007", colors: ["6007"], name: "Country Girl Lamp", size: "S" },
    113200103: { themeID: "99007", colors: ["6010", "6011"], name: "Country Girl Lamp", size: "S" },
    113200104: { themeID: "99007", colors: ["6008"], name: "Country Girl Lamp", size: "S" },
    113201900: {
        themeID: "99008",
        colors: ["6001", "6005"],
        name: "Boho standing lamp",
        size: "S",
        xmas: true,
    },
    113201901: {
        themeID: "99008",
        colors: ["6002", "6006"],
        name: "Boho standing lamp",
        size: "S",
        xmas: true,
    },
    113201902: {
        themeID: "99008",
        colors: ["6004", "6001", "6006"],
        name: "Boho standing lamp",
        size: "S",
        xmas: true,
    },
    113300100: { themeID: "99009", colors: ["6006", "6001"], name: "Antique candles", size: "S" },
    113300101: { themeID: "99009", colors: ["6004", "6001", "6006"], name: "Antique candles", size: "S" },
    113300102: { themeID: "99009", colors: ["6011", "6001"], name: "Antique candles", size: "S" },
    113300200: { themeID: "99009", colors: ["6006", "6001"], name: "Antique candle", size: "XS" },
    113300201: { themeID: "99009", colors: ["6004", "6001"], name: "Antique candle", size: "XS" },
    113300202: { themeID: "99009", colors: ["6011", "6001"], name: "Antique candle", size: "XS" },
    113301200: { themeID: "99013", colors: ["6004", "6001", "6006"], name: "Ring light", size: "L" },
    113301201: { themeID: "99013", colors: ["6011", "6006", "6002"], name: "Ring light", size: "L" },
    113301202: { themeID: "99013", colors: ["6008", "6004", "6002"], name: "Ring light", size: "L" },
    113301203: { themeID: "99013", colors: ["6010", "6007", "6004"], name: "Ring light", size: "L" },
    113400100: { themeID: "99006", colors: ["6009", "6006"], name: "Vintage oil lamp", size: "S" },
    113400101: { themeID: "99006", colors: ["6008", "6006"], name: "Vintage oil lamp", size: "S" },
    113400102: { themeID: "99006", colors: ["6003", "6006"], name: "Vintage oil lamp", size: "S" },
    113400200: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage standing lamp", size: "M" },
    113400201: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage standing lamp", size: "M" },
    113400202: { themeID: "99006", colors: ["6003", "6002"], name: "Vintage standing lamp", size: "M" },
    113400203: { themeID: "99006", colors: ["6009", "6001"], name: "Vintage standing lamp", size: "M" },
    113600200: { themeID: "99015", colors: ["6006", "6009"], name: "Refined Round Bulb", size: "M" },
    113600201: { themeID: "99015", colors: ["6007", "6001"], name: "Refined Round Bulb", size: "M" },
    113600202: { themeID: "99015", colors: ["6008", "6001"], name: "Refined Round Bulb", size: "M" },
    113600203: { themeID: "99015", colors: ["6011", "6003", "6001"], name: "Refined Round Bulb", size: "M" },
    113600300: { themeID: "99015", colors: ["6006", "6007", "6009"], name: "Refined lamp pillar", size: "M" },
    113600301: { themeID: "99015", colors: ["6007", "6009", "6006"], name: "Refined lamp pillar", size: "M" },
    113600302: {
        themeID: "99015",
        colors: ["6008", "6009", "6007", "6006"],
        name: "Refined lamp pillar",
        size: "M",
    },
    113600303: {
        themeID: "99015",
        colors: ["6003", "6011", "6007", "6006"],
        name: "Refined lamp pillar",
        size: "M",
    },
    113600400: {
        themeID: "99015",
        colors: ["6001", "6004", "6009"],
        name: "Refined Standing Nightlight",
        size: "M",
    },
    113600401: { themeID: "99015", colors: ["6007", "6009"], name: "Refined Standing Nightlight", size: "M" },
    113600402: { themeID: "99015", colors: ["6003", "6008"], name: "Refined Standing Nightlight", size: "M" },
    114000100: { themeID: "99003", colors: ["6003", "6011"], name: "Curly Wall Lamp", size: "XS" },
    114000101: { themeID: "99003", colors: ["6010", "6011"], name: "Curly Wall Lamp", size: "XS" },
    114000102: { themeID: "99003", colors: ["6008", "6002"], name: "Curly Wall Lamp", size: "XS" },
    114000103: { themeID: "99003", colors: ["6009", "6006"], name: "Curly Wall Lamp", size: "XS" },
    114000200: { themeID: "99004", colors: ["6008", "6009", "6003"], name: "Rio lamp", size: "S" },
    114000201: { themeID: "99004", colors: ["6003", "6007"], name: "Rio lamp", size: "S" },
    114000202: { themeID: "99004", colors: ["6010", "6011"], name: "Rio lamp", size: "S" },
    114000203: { themeID: "99004", colors: ["6007", "6009", "6006"], name: "Rio lamp", size: "S" },
    114000300: { themeID: "99005", colors: ["6006", "6010"], name: "Funky Neon Light", size: "S" },
    114000301: { themeID: "99005", colors: ["6011", "6006", "6008"], name: "Funky Neon Light", size: "S" },
    114000302: { themeID: "99005", colors: ["6008", "6006"], name: "Funky Neon Light", size: "S" },
    114000400: { themeID: "99006", colors: ["6006", "6009"], name: "Vintage lamp", size: "XS" },
    114000401: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage lamp", size: "XS" },
    114000402: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage lamp", size: "XS" },
    114000403: { themeID: "99006", colors: ["6011", "6002"], name: "Vintage lamp", size: "XS" },
    114000404: { themeID: "99006", colors: ["6010", "6002"], name: "Vintage lamp", size: "XS" },
    114000500: { themeID: "99006", colors: ["6006", "6002"], name: "Flory lamp", size: "XS" },
    114000501: { themeID: "99006", colors: ["6007", "6006"], name: "Flory lamp", size: "XS" },
    114000502: { themeID: "99006", colors: ["6008", "6006"], name: "Flory lamp", size: "XS" },
    114000503: { themeID: "99006", colors: ["6006", "6003"], name: "Flory lamp", size: "XS" },
    114100100: { themeID: "99002", colors: ["6009", "6006"], name: "Rustic hanging bulbs", size: "M" },
    114100101: {
        themeID: "99002",
        colors: ["6004", "6002", "6001"],
        name: "Rustic hanging bulbs",
        size: "M",
    },
    114100102: { themeID: "99002", colors: ["6011", "6001"], name: "Rustic hanging bulbs", size: "M" },
    114100103: {
        themeID: "99002",
        colors: ["6008", "6001", "6004"],
        name: "Rustic hanging bulbs",
        size: "M",
    },
    114100104: { themeID: "99002", colors: ["6007", "6001"], name: "Rustic hanging bulbs", size: "M" },
    114100200: { themeID: "99017", colors: ["6003", "6006"], name: "Hazelnut lamp", size: "S", xmas: true },
    114100201: { themeID: "99017", colors: ["6009", "6006"], name: "Hazelnut lamp", size: "S", xmas: true },
    114100202: { themeID: "99017", colors: ["6008", "6001"], name: "Hazelnut lamp", size: "S", xmas: true },
    114130100: { themeID: "99004", colors: ["6011", "6008"], name: "Pineapple neon light", size: "S" },
    114130101: { themeID: "99004", colors: ["6006", "6007"], name: "Pineapple neon light", size: "S" },
    114130102: { themeID: "99004", colors: ["6008", "6006"], name: "Pineapple neon light", size: "S" },
    114130103: { themeID: "99004", colors: ["6010", "6011"], name: "Pineapple neon light", size: "S" },
    114200100: { themeID: "99007", colors: ["6009", "6006"], name: "Lily Wall Lamp", size: "S" },
    114200101: { themeID: "99007", colors: ["6007", "6006"], name: "Lily Wall Lamp", size: "S" },
    114200102: { themeID: "99007", colors: ["6010", "6011"], name: "Lily Wall Lamp", size: "S" },
    114200103: { themeID: "99007", colors: ["6004", "6005"], name: "Lily Wall Lamp", size: "S" },
    114200104: { themeID: "99007", colors: ["6008", "6004"], name: "Lily Wall Lamp", size: "S" },
    114300100: { themeID: "99009", colors: ["6009", "6005"], name: "Classic wall light", size: "XS" },
    114300101: { themeID: "99009", colors: ["6002", "6001", "6004"], name: "Classic wall light", size: "XS" },
    114300102: { themeID: "99009", colors: ["6011"], name: "Classic wall light", size: "XS" },
    114301200: { themeID: "99011", colors: ["6006"], name: "Bunny line wall light", size: "S" },
    114301201: { themeID: "99011", colors: ["6011"], name: "Bunny line wall light", size: "S" },
    114301202: { themeID: "99011", colors: ["6008"], name: "Bunny line wall light", size: "S" },
    114301203: { themeID: "99011", colors: ["6010"], name: "Bunny line wall light", size: "S" },
    114301300: { themeID: "99013", colors: ["6006"], name: "Lightning wall light", size: "XS" },
    114301301: { themeID: "99013", colors: ["6011"], name: "Lightning wall light", size: "XS" },
    114301302: { themeID: "99013", colors: ["6007"], name: "Lightning wall light", size: "XS" },
    114301303: { themeID: "99013", colors: ["6010"], name: "Lightning wall light", size: "XS" },
    114400100: { themeID: "99019", colors: ["6009", "6006"], name: "Stone wall lamp", size: "S" },
    114400101: { themeID: "99019", colors: ["6002", "6011"], name: "Stone wall lamp", size: "S" },
    114400102: { themeID: "99019", colors: ["6002", "6008"], name: "Stone wall lamp", size: "S" },
    114700100: { themeID: "99001", colors: ["6001", "6004"], name: "Urban tube light", size: "S" },
    114700101: { themeID: "99001", colors: ["6007", "6004"], name: "Urban tube light", size: "S" },
    114700102: { themeID: "99001", colors: ["6008"], name: "Urban tube light", size: "S" },
    114700103: { themeID: "99001", colors: ["6011"], name: "Urban tube light", size: "S" },
    114700104: { themeID: "99001", colors: ["6010"], name: "Urban tube light", size: "S" },
    114700400: {
        themeID: "99017",
        colors: ["6006", "6007"],
        name: "Xmas string lights",
        size: "M",
        xmas: true,
    },
    114700401: {
        themeID: "99017",
        colors: ["6006", "6003"],
        name: "Xmas string lights",
        size: "M",
        xmas: true,
    },
    114700500: { themeID: "99011", colors: ["6005", "6006"], name: "Strawberry wall lamp", size: "S" },
    114700501: { themeID: "99011", colors: ["6003", "6011"], name: "Strawberry wall lamp", size: "S" },
    114700502: { themeID: "99011", colors: ["6008"], name: "Strawberry wall lamp", size: "S" },
    114700503: { themeID: "99011", colors: ["6010"], name: "Strawberry wall lamp", size: "S" },
    115000100: { themeID: "99001", colors: ["6008", "6001"], name: "Urban partition", size: "L" },
    115000101: { themeID: "99001", colors: ["6007", "6001"], name: "Urban partition", size: "L" },
    115000102: { themeID: "99001", colors: ["6011", "6001"], name: "Urban partition", size: "L" },
    115000103: { themeID: "99001", colors: ["6002", "6001", "6010"], name: "Urban partition", size: "L" },
    115000104: {
        themeID: "99001",
        colors: ["6004", "6002", "6001", "6010", "6008"],
        name: "Urban partition",
        size: "L",
    },
    115000200: { themeID: "99002", colors: ["6009", "6006"], name: "Trellis barrier", size: "M" },
    115000201: { themeID: "99002", colors: ["6011", "6001"], name: "Trellis barrier", size: "M" },
    115000202: { themeID: "99002", colors: ["6008", "6001"], name: "Trellis barrier", size: "M" },
    115000203: { themeID: "99002", colors: ["6002", "6001", "6004"], name: "Trellis barrier", size: "M" },
    115000204: { themeID: "99002", colors: ["6007", "6004"], name: "Trellis barrier", size: "M" },
    115000300: { themeID: "99002", colors: ["6009"], name: "Rustic barrier", size: "S" },
    115000301: { themeID: "99002", colors: ["6001"], name: "Rustic barrier", size: "S" },
    115000302: { themeID: "99002", colors: ["6002", "6004"], name: "Rustic barrier", size: "S" },
    115000303: { themeID: "99002", colors: ["6006", "6001"], name: "Rustic barrier", size: "S" },
    115000304: { themeID: "99002", colors: ["6011", "6001"], name: "Rustic barrier", size: "S" },
    115000305: { themeID: "99002", colors: ["6008", "6001"], name: "Rustic barrier", size: "S" },
    115000306: { themeID: "99002", colors: ["6007", "6001"], name: "Rustic barrier", size: "S" },
    115000600: { themeID: "99004", colors: ["6009", "6006"], name: "Bamboo barrier", size: "S" },
    115000601: { themeID: "99004", colors: ["6007"], name: "Bamboo barrier", size: "S" },
    115000602: { themeID: "99004", colors: ["6009"], name: "Bamboo barrier", size: "S" },
    115000603: { themeID: "99004", colors: ["6004"], name: "Bamboo barrier", size: "S" },
    115000604: { themeID: "99004", colors: ["6002"], name: "Bamboo barrier", size: "S" },
    115000700: { themeID: "99005", colors: ["6009", "6008", "6005"], name: "Ducky barrier", size: "S" },
    115000701: { themeID: "99005", colors: ["6011", "6008", "6006"], name: "Ducky barrier", size: "S" },
    115000702: { themeID: "99005", colors: ["6010", "6008", "6005"], name: "Ducky barrier", size: "S" },
    115000703: { themeID: "99005", colors: ["6008", "6004", "6005"], name: "Ducky barrier", size: "S" },
    115000704: { themeID: "99005", colors: ["6007", "6008", "6005"], name: "Ducky barrier", size: "S" },
    115000800: { themeID: "99006", colors: ["6006", "6009"], name: "Vintage barrier", size: "S" },
    115000801: { themeID: "99006", colors: ["6009", "6003"], name: "Vintage barrier", size: "S" },
    115000802: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage barrier", size: "S" },
    115000803: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage barrier", size: "S" },
    115000804: { themeID: "99006", colors: ["6001", "6002"], name: "Vintage barrier", size: "S" },
    115001000: { themeID: "99011", colors: ["6009", "6004"], name: "Dolly Barrier", size: "M" },
    115001001: { themeID: "99011", colors: ["6011", "6004"], name: "Dolly Barrier", size: "M" },
    115001002: { themeID: "99011", colors: ["6008", "6001"], name: "Dolly Barrier", size: "M" },
    115001003: { themeID: "99011", colors: ["6010", "6011"], name: "Dolly Barrier", size: "M" },
    115001004: { themeID: "99011", colors: ["6006", "6001"], name: "Dolly Barrier", size: "M" },
    115001005: { themeID: "99011", colors: ["6004", "6010"], name: "Dolly Barrier", size: "M" },
    115001100: { themeID: "99011", colors: ["6006"], name: "Bunny Barrier", size: "S" },
    115001101: { themeID: "99011", colors: ["6011"], name: "Bunny Barrier", size: "S" },
    115001102: { themeID: "99011", colors: ["6008"], name: "Bunny Barrier", size: "S" },
    115001103: { themeID: "99011", colors: ["6010"], name: "Bunny Barrier", size: "S" },
    115001104: { themeID: "99011", colors: ["6001"], name: "Bunny Barrier", size: "S" },
    115001105: { themeID: "99011", colors: ["6007"], name: "Bunny Barrier", size: "S" },
    115100100: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie barrier", size: "S" },
    115100101: { themeID: "99003", colors: ["6009", "6006"], name: "Girlie barrier", size: "S" },
    115100102: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie barrier", size: "S" },
    115100103: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie barrier", size: "S" },
    115100200: {
        themeID: "99017",
        colors: ["6005", "6001"],
        name: "Ginger pine barrier",
        size: "S",
        xmas: true,
    },
    115100201: {
        themeID: "99017",
        colors: ["6009", "6001"],
        name: "Ginger pine barrier",
        size: "S",
        xmas: true,
    },
    115100202: {
        themeID: "99017",
        colors: ["6003", "6011"],
        name: "Ginger pine barrier",
        size: "S",
        xmas: true,
    },
    115100300: {
        themeID: "99017",
        colors: ["6005", "6003"],
        name: "Ginger boy barrier",
        size: "S",
        xmas: true,
    },
    115100301: {
        themeID: "99017",
        colors: ["6009", "6007"],
        name: "Ginger boy barrier",
        size: "S",
        xmas: true,
    },
    115100302: {
        themeID: "99017",
        colors: ["6003", "6008"],
        name: "Ginger boy barrier",
        size: "S",
        xmas: true,
    },
    115100400: {
        themeID: "99017",
        colors: ["6003", "6001"],
        name: "Candy canes fence",
        size: "S",
        xmas: true,
    },
    115100401: {
        themeID: "99017",
        colors: ["6007", "6001"],
        name: "Candy canes fence",
        size: "S",
        xmas: true,
    },
    115100402: {
        themeID: "99017",
        colors: ["6010", "6008"],
        name: "Candy canes fence",
        size: "S",
        xmas: true,
    },
    115200100: { themeID: "99007", colors: ["6006", "6007", "6009"], name: "Floral Fence", size: "S" },
    115200101: { themeID: "99007", colors: ["6004", "6011", "6009"], name: "Floral Fence", size: "S" },
    115200102: { themeID: "99007", colors: ["6003", "6007", "6006"], name: "Floral Fence", size: "S" },
    115200200: { themeID: "99007", colors: ["6009", "6005"], name: "Rugged Timber Barrier", size: "S" },
    115200201: { themeID: "99007", colors: ["6004", "6009"], name: "Rugged Timber Barrier", size: "S" },
    115200202: { themeID: "99007", colors: ["6006", "6001"], name: "Rugged Timber Barrier", size: "S" },
    115200203: { themeID: "99007", colors: ["6010"], name: "Rugged Timber Barrier", size: "S" },
    115200204: { themeID: "99007", colors: ["6007"], name: "Rugged Timber Barrier", size: "S" },
    115200205: { themeID: "99007", colors: ["6008"], name: "Rugged Timber Barrier", size: "S" },
    115200300: { themeID: "99008", colors: ["6009", "6006"], name: "Bohemian Partition", size: "S" },
    115200301: { themeID: "99008", colors: ["6010", "6004", "6011"], name: "Bohemian Partition", size: "S" },
    115200302: { themeID: "99008", colors: ["6007", "6006"], name: "Bohemian Partition", size: "S" },
    115200303: { themeID: "99008", colors: ["6003", "6001"], name: "Bohemian Partition", size: "S" },
    115300100: { themeID: "99009", colors: ["6001"], name: "Antique barrier", size: "S" },
    115300101: { themeID: "99009", colors: ["6004", "6002"], name: "Antique barrier", size: "S" },
    115300102: { themeID: "99009", colors: ["6011"], name: "Antique barrier", size: "S" },
    115300200: { themeID: "99009", colors: ["6001", "6007"], name: "Antique botanical barrier", size: "S" },
    115300201: { themeID: "99009", colors: ["6002", "6007"], name: "Antique botanical barrier", size: "S" },
    115300202: { themeID: "99009", colors: ["6011", "6007"], name: "Antique botanical barrier", size: "S" },
    115300300: { themeID: "99009", colors: ["6003", "6007"], name: "Antique nasturtium barrier", size: "S" },
    115300301: { themeID: "99009", colors: ["6008", "6007"], name: "Antique nasturtium barrier", size: "S" },
    115300302: { themeID: "99009", colors: ["6006", "6007"], name: "Antique nasturtium barrier", size: "S" },
    115300400: { themeID: "99009", colors: ["6001", "6006"], name: "Classy oak partition", size: "M" },
    115300401: { themeID: "99009", colors: ["6002", "6004"], name: "Classy oak partition", size: "M" },
    115300402: { themeID: "99009", colors: ["6011", "6001"], name: "Classy oak partition", size: "M" },
    115400100: { themeID: "99019", colors: ["6001", "6004"], name: "Stonehouse partition", size: "S" },
    115400101: { themeID: "99019", colors: ["6011", "6004"], name: "Stonehouse partition", size: "S" },
    115400102: { themeID: "99019", colors: ["6008", "6004"], name: "Stonehouse partition", size: "S" },
    115500100: { themeID: "99011", colors: ["6009", "6006"], name: "Dolly mini barrier", size: "S" },
    115500101: { themeID: "99011", colors: ["6003", "6011"], name: "Dolly mini barrier", size: "S" },
    115500102: { themeID: "99011", colors: ["6008", "6009"], name: "Dolly mini barrier", size: "S" },
    115500103: { themeID: "99011", colors: ["6010", "6009"], name: "Dolly mini barrier", size: "S" },
    115600200: { themeID: "99015", colors: ["6009", "6007"], name: "Refined barrier", size: "S" },
    115600201: { themeID: "99015", colors: ["6007", "6006"], name: "Refined barrier", size: "S" },
    115600202: { themeID: "99015", colors: ["6008", "6009"], name: "Refined barrier", size: "S" },
    115600203: { themeID: "99015", colors: ["6003", "6011"], name: "Refined barrier", size: "S" },
    115800100: { themeID: "99015", colors: ["6009", "6006", "6007"], name: "Refined partition", size: "L" },
    115800101: { themeID: "99015", colors: ["6007", "6004"], name: "Refined partition", size: "L" },
    115800102: { themeID: "99015", colors: ["6008", "6009"], name: "Refined partition", size: "L" },
    115800103: { themeID: "99015", colors: ["6002", "6003"], name: "Refined partition", size: "L" },
    115800200: {
        themeID: "99015",
        colors: ["6009", "6007", "6006"],
        name: "Refined partition ending",
        size: "L",
    },
    115800201: { themeID: "99015", colors: ["6007", "6004"], name: "Refined partition ending", size: "L" },
    115800202: { themeID: "99015", colors: ["6008", "6009"], name: "Refined partition ending", size: "L" },
    115800203: { themeID: "99015", colors: ["6002", "6003"], name: "Refined partition ending", size: "L" },
    115900100: { themeID: "99017", colors: ["6003", "6001"], name: "Candy cane ", size: "S", xmas: true },
    116000100: {
        themeID: "99001",
        colors: ["6006", "6009", "6011", "6007"],
        name: "Urban Floral Rug",
        size: "M",
    },
    116000101: {
        themeID: "99001",
        colors: ["6002", "6004", "6011", "6007"],
        name: "Urban Floral Rug",
        size: "M",
    },
    116000102: { themeID: "99001", colors: ["6008", "6001"], name: "Urban Floral Rug", size: "M" },
    116000103: { themeID: "99001", colors: ["6011", "6003", "6007"], name: "Urban Floral Rug", size: "M" },
    116000104: { themeID: "99001", colors: ["6003", "6002", "6004"], name: "Urban Floral Rug", size: "M" },
    116000105: { themeID: "99001", colors: ["6007", "6011"], name: "Urban Floral Rug", size: "M" },
    116000200: { themeID: "99001", colors: ["6001", "6004"], name: "Urban Carpet", size: "L" },
    116000201: { themeID: "99001", colors: ["6008"], name: "Urban Carpet", size: "L" },
    116000202: { themeID: "99001", colors: ["6009", "6001", "6004"], name: "Urban Carpet", size: "L" },
    116000203: { themeID: "99001", colors: ["6011"], name: "Urban Carpet", size: "L" },
    116000204: { themeID: "99001", colors: ["6004", "6002"], name: "Urban Carpet", size: "L" },
    116000205: { themeID: "99001", colors: ["6007"], name: "Urban Carpet", size: "L" },
    116000300: { themeID: "99002", colors: ["6007", "6001", "6011"], name: "Deltoid Carpet", size: "M" },
    116000301: {
        themeID: "99002",
        colors: ["6009", "6001", "6011", "6008"],
        name: "Deltoid Carpet",
        size: "M",
    },
    116000302: { themeID: "99002", colors: ["6011", "6001", "6008"], name: "Deltoid Carpet", size: "M" },
    116000303: { themeID: "99002", colors: ["6008", "6009", "6001"], name: "Deltoid Carpet", size: "M" },
    116000304: {
        themeID: "99002",
        colors: ["6002", "6004", "6003", "6009"],
        name: "Deltoid Carpet",
        size: "M",
    },
    116000400: { themeID: "99003", colors: ["6001"], name: "Fluffy heart carpet", size: "XL" },
    116000401: { themeID: "99003", colors: ["6006", "6005"], name: "Fluffy heart carpet", size: "XL" },
    116000402: { themeID: "99003", colors: ["6011"], name: "Fluffy heart carpet", size: "XL" },
    116000403: { themeID: "99003", colors: ["6008"], name: "Fluffy heart carpet", size: "XL" },
    116000500: { themeID: "99004", colors: ["6006", "6009"], name: "Bamboo mat", size: "M" },
    116000501: { themeID: "99004", colors: ["6007"], name: "Bamboo mat", size: "M" },
    116000502: { themeID: "99004", colors: ["6003", "6011"], name: "Bamboo mat", size: "M" },
    116000503: { themeID: "99004", colors: ["6008"], name: "Bamboo mat", size: "M" },
    116000504: { themeID: "99004", colors: ["6009", "6002"], name: "Bamboo mat", size: "M" },
    116000600: {
        themeID: "99004",
        colors: ["6009", "6001", "6008"],
        name: "Tropical floral carpet",
        size: "M",
    },
    116000601: {
        themeID: "99004",
        colors: ["6002", "6003", "6005", "6004"],
        name: "Tropical floral carpet",
        size: "M",
    },
    116000602: {
        themeID: "99004",
        colors: ["6003", "6001", "6005"],
        name: "Tropical floral carpet",
        size: "M",
    },
    116000603: { themeID: "99004", colors: ["6008", "6001"], name: "Tropical floral carpet", size: "M" },
    116000604: { themeID: "99004", colors: ["6010", "6001"], name: "Tropical floral carpet", size: "M" },
    116000700: { themeID: "99005", colors: ["6005", "6006"], name: "Colory Rug", size: "M" },
    116000701: { themeID: "99005", colors: ["6003", "6011"], name: "Colory Rug", size: "M" },
    116000702: { themeID: "99005", colors: ["6008", "6001"], name: "Colory Rug", size: "M" },
    116000703: { themeID: "99005", colors: ["6007", "6009"], name: "Colory Rug", size: "M" },
    116000800: { themeID: "99006", colors: ["6009", "6001"], name: "Vintage carpet", size: "M" },
    116000801: { themeID: "99006", colors: ["6007", "6001"], name: "Vintage carpet", size: "M" },
    116000802: { themeID: "99006", colors: ["6009", "6001"], name: "Vintage carpet", size: "M" },
    116000900: { themeID: "99011", colors: ["6004", "6001"], name: "Girlish Carpet", size: "M" },
    116000901: { themeID: "99011", colors: ["6006", "6001", "6009"], name: "Girlish Carpet", size: "M" },
    116000902: { themeID: "99011", colors: ["6011", "6001"], name: "Girlish Carpet", size: "M" },
    116000903: { themeID: "99011", colors: ["6008", "6001"], name: "Girlish Carpet", size: "M" },
    116000904: { themeID: "99011", colors: ["6010", "6004"], name: "Girlish Carpet", size: "M" },
    116100100: { themeID: "99003", colors: ["6001"], name: "Girlie carpet", size: "L" },
    116100101: { themeID: "99003", colors: ["6006"], name: "Girlie carpet", size: "L" },
    116100102: { themeID: "99003", colors: ["6011"], name: "Girlie carpet", size: "L" },
    116100103: { themeID: "99003", colors: ["6008"], name: "Girlie carpet", size: "L" },
    116100300: {
        themeID: "99017",
        colors: ["6009", "6006"],
        name: "Noel globe carpet",
        size: "S",
        xmas: true,
    },
    116100301: {
        themeID: "99017",
        colors: ["6005", "6006"],
        name: "Noel globe carpet",
        size: "S",
        xmas: true,
    },
    116100302: {
        themeID: "99017",
        colors: ["6008", "6011"],
        name: "Noel globe carpet",
        size: "S",
        xmas: true,
    },
    116100303: {
        themeID: "99017",
        colors: ["6010", "6011"],
        name: "Noel globe carpet",
        size: "S",
        xmas: true,
    },
    116100400: { themeID: "99017", colors: ["6001", "6004"], name: "Snow carpet", size: "L", xmas: true },
    116100401: { themeID: "99017", colors: ["6001", "6004"], name: "Snow carpet", size: "L", xmas: true },
    116100500: { themeID: "99003", colors: ["6011"], name: "Girlie mini carpet", size: "S" },
    116100501: { themeID: "99003", colors: ["6006"], name: "Girlie mini carpet", size: "S" },
    116100502: { themeID: "99003", colors: ["6010", "6011"], name: "Girlie mini carpet", size: "S" },
    116100503: { themeID: "99003", colors: ["6008", "6006"], name: "Girlie mini carpet", size: "S" },
    116200100: { themeID: "99007", colors: ["6009", "6004"], name: "Countryhouse Carpet", size: "M" },
    116200101: { themeID: "99007", colors: ["6010", "6004"], name: "Countryhouse Carpet", size: "M" },
    116200102: { themeID: "99007", colors: ["6007", "6004"], name: "Countryhouse Carpet", size: "M" },
    116200103: { themeID: "99007", colors: ["6008", "6001"], name: "Countryhouse Carpet", size: "M" },
    116200104: { themeID: "99007", colors: ["6001", "6004"], name: "Countryhouse Carpet", size: "M" },
    116200200: { themeID: "99007", colors: ["6005", "6009"], name: "Country Small Rug", size: "M" },
    116200201: { themeID: "99007", colors: ["6011", "6009"], name: "Country Small Rug", size: "M" },
    116200202: { themeID: "99007", colors: ["6009", "6001"], name: "Country Small Rug", size: "M" },
    116200203: { themeID: "99007", colors: ["6007", "6001"], name: "Country Small Rug", size: "M" },
    116200300: { themeID: "99008", colors: ["6009", "6005"], name: "Bohemian Carpet", size: "M" },
    116200301: { themeID: "99008", colors: ["6006", "6005"], name: "Bohemian Carpet", size: "M" },
    116200302: { themeID: "99008", colors: ["6010", "6011"], name: "Bohemian Carpet", size: "M" },
    116200303: { themeID: "99008", colors: ["6007", "6004"], name: "Bohemian Carpet", size: "M" },
    116200304: { themeID: "99008", colors: ["6004", "6001"], name: "Bohemian Carpet", size: "M" },
    116200305: { themeID: "99008", colors: ["6011", "6001"], name: "Bohemian Carpet", size: "M" },
    116200306: { themeID: "99008", colors: ["6001", "6008"], name: "Bohemian Carpet", size: "M" },
    116300100: { themeID: "99009", colors: ["6006", "6003"], name: "Biscuit carpet", size: "S" },
    116300101: { themeID: "99009", colors: ["6001", "6003"], name: "Biscuit carpet", size: "S" },
    116300102: { themeID: "99009", colors: ["6011", "6003"], name: "Biscuit carpet", size: "S" },
    116600200: {
        themeID: "99015",
        colors: ["6009", "6007", "6001"],
        name: "Refined Natural Pavings",
        size: "M",
    },
    116600201: {
        themeID: "99015",
        colors: ["6007", "6009", "6001"],
        name: "Refined Natural Pavings",
        size: "M",
    },
    116600202: {
        themeID: "99015",
        colors: ["6008", "6001", "6011"],
        name: "Refined Natural Pavings",
        size: "M",
    },
    116600203: {
        themeID: "99015",
        colors: ["6011", "6003", "6001"],
        name: "Refined Natural Pavings",
        size: "M",
    },
    116700100: { themeID: "99008", colors: ["6009", "6001", "6005"], name: "Carpet Modern", size: "M" },
    116700101: { themeID: "99008", colors: ["6007", "6001"], name: "Carpet Modern", size: "M" },
    116700102: { themeID: "99008", colors: ["6008", "6001"], name: "Carpet Modern", size: "M" },
    116700103: { themeID: "99008", colors: ["6010", "6001", "6011"], name: "Carpet Modern", size: "M" },
    116700200: { themeID: "99011", colors: ["6005", "6006"], name: "Cute wool rug", size: "M" },
    116700201: { themeID: "99011", colors: ["6007", "6004"], name: "Cute wool rug", size: "M" },
    116700202: { themeID: "99011", colors: ["6011", "6001"], name: "Cute wool rug", size: "M" },
    116700203: { themeID: "99011", colors: ["6008", "6010"], name: "Cute wool rug", size: "M" },
    116700204: { themeID: "99011", colors: ["6010", "6011"], name: "Cute wool rug", size: "M" },
    116700300: { themeID: "99004", colors: ["6006", "6005"], name: "Tropical leaf carpet", size: "M" },
    116700301: { themeID: "99004", colors: ["6007"], name: "Tropical leaf carpet", size: "M" },
    116700302: { themeID: "99004", colors: ["6011"], name: "Tropical leaf carpet", size: "M" },
    116700303: { themeID: "99004", colors: ["6008", "6010"], name: "Tropical leaf carpet", size: "M" },
    116700304: { themeID: "99004", colors: ["6010"], name: "Tropical leaf carpet", size: "M" },
    117000900: { themeID: "99004", colors: ["6008"], name: "Helix shell", size: "XS" },
    117000901: { themeID: "99004", colors: ["6011"], name: "Helix shell", size: "XS" },
    117000902: { themeID: "99004", colors: ["6007"], name: "Helix shell", size: "XS" },
    117001600: { themeID: "99005", colors: ["6005", "6002"], name: "Mooncats frame", size: "S" },
    117001601: { themeID: "99005", colors: ["6011", "6002"], name: "Mooncats frame", size: "S" },
    117001602: { themeID: "99005", colors: ["6010", "6004"], name: "Mooncats frame", size: "S" },
    117001603: { themeID: "99005", colors: ["6008", "6010"], name: "Mooncats frame", size: "S" },
    117001604: { themeID: "99005", colors: ["6006", "6009"], name: "Mooncats frame", size: "S" },
    117100100: { themeID: "99004", colors: ["6008"], name: "Nacre shell", size: "XS" },
    117100101: { themeID: "99004", colors: ["6010"], name: "Nacre shell", size: "XS" },
    117100102: { themeID: "99004", colors: ["6005", "6006"], name: "Nacre shell", size: "XS" },
    117100300: { themeID: "99017", colors: ["6003", "6001"], name: "Snow globe", size: "S", xmas: true },
    117100301: { themeID: "99017", colors: ["6007", "6001"], name: "Snow globe", size: "S", xmas: true },
    117100302: { themeID: "99017", colors: ["6008", "6001"], name: "Snow globe", size: "S", xmas: true },
    117200100: { themeID: "99007", colors: ["6001", "6009", "6005"], name: "Countryhouse Easel", size: "L" },
    117200101: { themeID: "99007", colors: ["6004", "6006", "6009"], name: "Countryhouse Easel", size: "L" },
    117200102: { themeID: "99007", colors: ["6006", "6007", "6004"], name: "Countryhouse Easel", size: "L" },
    117200200: { themeID: "99007", colors: ["6005", "6009"], name: "Country Windmill", size: "XL" },
    117200201: { themeID: "99007", colors: ["6008", "6009", "6007"], name: "Country Windmill", size: "XL" },
    117200202: { themeID: "99007", colors: ["6009", "6004", "6005"], name: "Country Windmill", size: "XL" },
    117200300: { themeID: "99007", colors: ["6003", "6007", "6006"], name: "Dove nest", size: "M" },
    117200301: { themeID: "99007", colors: ["6010", "6006", "6007"], name: "Dove nest", size: "M" },
    117200302: { themeID: "99007", colors: ["6007", "6006"], name: "Dove nest", size: "M" },
    117200303: { themeID: "99007", colors: ["6008", "6006", "6007"], name: "Dove nest", size: "M" },
    117200400: { themeID: "99008", colors: ["6005", "6007", "6001"], name: "Little Cactus frame", size: "S" },
    117200401: { themeID: "99008", colors: ["6003", "6001", "6007"], name: "Little Cactus frame", size: "S" },
    117200402: { themeID: "99008", colors: ["6010", "6001", "6007"], name: "Little Cactus frame", size: "S" },
    117200403: { themeID: "99008", colors: ["6008", "6001", "6007"], name: "Little Cactus frame", size: "S" },
    117206000: { themeID: "99008", colors: ["6001", "6009", "6007"], name: "Boho Aquarium", size: "L" },
    117206001: { themeID: "99008", colors: ["6006", "6009", "6007"], name: "Boho Aquarium", size: "L" },
    117206002: { themeID: "99008", colors: ["6005", "6007", "6009"], name: "Boho Aquarium", size: "L" },
    117206003: { themeID: "99008", colors: ["6010", "6007", "6009"], name: "Boho Aquarium", size: "L" },
    117206100: {
        themeID: "99008",
        colors: ["6003", "6009", "6007", "6010"],
        name: "Gypsy healing stones",
        size: "L",
    },
    117206101: {
        themeID: "99008",
        colors: ["6010", "6007", "6009"],
        name: "Gypsy healing stones",
        size: "L",
    },
    117206102: {
        themeID: "99008",
        colors: ["6006", "6005", "6007"],
        name: "Gypsy healing stones",
        size: "L",
    },
    117206103: {
        themeID: "99008",
        colors: ["6005", "6006", "6007"],
        name: "Gypsy healing stones",
        size: "L",
    },
    117206104: {
        themeID: "99008",
        colors: ["6004", "6009", "6010", "6007"],
        name: "Gypsy healing stones",
        size: "L",
    },
    117300200: { themeID: "99009", colors: ["6004", "6007", "6008"], name: "Classy fountain", size: "M" },
    117300201: { themeID: "99009", colors: ["6001", "6008", "6007"], name: "Classy fountain", size: "M" },
    117300202: { themeID: "99009", colors: ["6011", "6007", "6001"], name: "Classy fountain", size: "M" },
    117300300: { themeID: "99009", colors: ["6004", "6008"], name: "Lion head fountain", size: "M" },
    117300301: { themeID: "99009", colors: ["6005", "6004", "6003"], name: "Lion head fountain", size: "M" },
    117300302: { themeID: "99009", colors: ["6011", "6004", "6006"], name: "Lion head fountain", size: "M" },
    117300400: {
        themeID: "99009",
        colors: ["6006", "6010", "6007", "6003"],
        name: "Antique flower cage",
        size: "L",
    },
    117300401: {
        themeID: "99009",
        colors: ["6001", "6006", "6007", "6011"],
        name: "Antique flower cage",
        size: "L",
    },
    117300402: {
        themeID: "99009",
        colors: ["6011", "6010", "6006", "6003"],
        name: "Antique flower cage",
        size: "L",
    },
    117300500: { themeID: "99009", colors: ["6007", "6006", "6001"], name: "Classy deer statue", size: "L" },
    117300501: { themeID: "99009", colors: ["6008", "6006", "6001"], name: "Classy deer statue", size: "L" },
    117300502: { themeID: "99009", colors: ["6011", "6006", "6001"], name: "Classy deer statue", size: "L" },
    117400100: { themeID: "99019", colors: ["6009", "6007", "6004"], name: "Stonehouse well", size: "XL" },
    117400101: {
        themeID: "99019",
        colors: ["6003", "6004", "6007", "6009"],
        name: "Stonehouse well",
        size: "XL",
    },
    117400102: {
        themeID: "99019",
        colors: ["6008", "6007", "6004", "6002"],
        name: "Stonehouse well",
        size: "XL",
    },
    117400200: {
        themeID: "99019",
        colors: ["6003", "6008", "6004", "6007", "6001"],
        name: "Whale fountain",
        size: "XL",
    },
    117400201: {
        themeID: "99019",
        colors: ["6004", "6008", "6007", "6001"],
        name: "Whale fountain",
        size: "XL",
    },
    117400202: {
        themeID: "99019",
        colors: ["6002", "6007", "6004", "6008", "6001"],
        name: "Whale fountain",
        size: "XL",
    },
    117400300: {
        themeID: "99019",
        colors: ["6005", "6008", "6007", "6004", "6001"],
        name: "Stonehouse pond",
        size: "L",
    },
    117400301: {
        themeID: "99019",
        colors: ["6006", "6004", "6008", "6007", "6001"],
        name: "Stonehouse pond",
        size: "L",
    },
    117400302: {
        themeID: "99019",
        colors: ["6010", "6007", "6008", "6004", "6001"],
        name: "Stonehouse pond",
        size: "L",
    },
    117600400: { themeID: "99015", colors: ["6009", "6003"], name: "Refined Artistic Root", size: "M" },
    117600401: { themeID: "99015", colors: ["6007", "6004"], name: "Refined Artistic Root", size: "M" },
    117600402: { themeID: "99015", colors: ["6008", "6009"], name: "Refined Artistic Root", size: "M" },
    117600403: { themeID: "99015", colors: ["6003", "6004"], name: "Refined Artistic Root", size: "M" },
    117700200: { themeID: "99002", colors: ["6005", "6006", "6011"], name: "Rustic photo frame", size: "XS" },
    117700201: { themeID: "99002", colors: ["6011", "6005"], name: "Rustic photo frame", size: "XS" },
    117700202: { themeID: "99002", colors: ["6008", "6006"], name: "Rustic photo frame", size: "XS" },
    117700203: { themeID: "99002", colors: ["6001", "6011"], name: "Rustic photo frame", size: "XS" },
    117700204: { themeID: "99002", colors: ["6004", "6010", "6002"], name: "Rustic photo frame", size: "XS" },
    117700400: { themeID: "99011", colors: ["6009", "6007"], name: "Vintage photo frames", size: "XS" },
    117700401: { themeID: "99011", colors: ["6010", "6004"], name: "Vintage photo frames", size: "XS" },
    117700402: { themeID: "99011", colors: ["6003", "6006"], name: "Vintage photo frames", size: "XS" },
    117700500: { themeID: "99003", colors: ["6006"], name: "Couple frame 1", size: "S" },
    117700501: { themeID: "99003", colors: ["6003", "6011"], name: "Couple frame 1", size: "S" },
    117700502: { themeID: "99003", colors: ["6008", "6006"], name: "Couple frame 1", size: "S" },
    117700503: { themeID: "99003", colors: ["6010"], name: "Couple frame 1", size: "S" },
    117700600: { themeID: "99003", colors: ["6009", "6006"], name: "Couple frame 2", size: "S" },
    117700601: { themeID: "99003", colors: ["6003", "6011"], name: "Couple frame 2", size: "S" },
    117700602: { themeID: "99003", colors: ["6008", "6006"], name: "Couple frame 2", size: "S" },
    117700603: { themeID: "99003", colors: ["6010"], name: "Couple frame 2", size: "S" },
    117700700: { themeID: "99003", colors: ["6006", "6003"], name: "Couple frame 3", size: "S" },
    117700701: { themeID: "99003", colors: ["6009", "6006"], name: "Couple frame 3", size: "S" },
    117700702: { themeID: "99003", colors: ["6008", "6006"], name: "Couple frame 3", size: "S" },
    117700703: { themeID: "99003", colors: ["6010", "6011"], name: "Couple frame 3", size: "S" },
    117700800: { themeID: "99003", colors: ["6006"], name: "Couple frame 4", size: "XS" },
    117700801: { themeID: "99003", colors: ["6011"], name: "Couple frame 4", size: "XS" },
    117700802: { themeID: "99003", colors: ["6008"], name: "Couple frame 4", size: "XS" },
    117700803: { themeID: "99003", colors: ["6010"], name: "Couple frame 4", size: "XS" },
    117800100: { themeID: "99006", colors: ["6001"], name: "Vintage statue", size: "S" },
    117800101: { themeID: "99006", colors: ["6001", "6006"], name: "Vintage statue", size: "S" },
    117800102: { themeID: "99006", colors: ["6011", "6001"], name: "Vintage statue", size: "S" },
    117800200: { themeID: "99006", colors: ["6009", "6006"], name: "Artistic lady statue", size: "S" },
    117800201: { themeID: "99006", colors: ["6003", "6009"], name: "Artistic lady statue", size: "S" },
    117800202: { themeID: "99006", colors: ["6001", "6009"], name: "Artistic lady statue", size: "S" },
    117800300: { themeID: "99002", colors: ["6001", "6007"], name: "Rustic little paintings", size: "XS" },
    117800400: { themeID: "99002", colors: ["6007", "6001"], name: "Rustic paintings", size: "S" },
    117800500: { themeID: "99015", colors: ["6009"], name: "Natural clay vase", size: "XS" },
    117800501: { themeID: "99015", colors: ["6010"], name: "Natural clay vase", size: "XS" },
    117800502: { themeID: "99015", colors: ["6008"], name: "Natural clay vase", size: "XS" },
    117800800: { themeID: "99001", colors: ["6001", "6008", "6003"], name: "Urban easel", size: "M" },
    117800801: {
        themeID: "99001",
        colors: ["6002", "6007", "6001", "6004", "6003"],
        name: "Urban easel",
        size: "M",
    },
    117800802: { themeID: "99001", colors: ["6008", "6001", "6009"], name: "Urban easel", size: "M" },
    117800803: { themeID: "99001", colors: ["6011", "6007", "6001", "6003"], name: "Urban easel", size: "M" },
    117800900: {
        themeID: "99002",
        colors: ["6001", "6006", "6007", "6008"],
        name: "Rustic easel",
        size: "M",
    },
    117800901: {
        themeID: "99002",
        colors: ["6002", "6001", "6006", "6007", "6008"],
        name: "Rustic easel",
        size: "M",
    },
    117800902: {
        themeID: "99002",
        colors: ["6008", "6007", "6006", "6001"],
        name: "Rustic easel",
        size: "M",
    },
    117800903: {
        themeID: "99002",
        colors: ["6011", "6001", "6006", "6007", "6008"],
        name: "Rustic easel",
        size: "M",
    },
    117801000: { themeID: "99015", colors: ["6009", "6001", "6010"], name: "Natural charm easel", size: "M" },
    117801001: {
        themeID: "99015",
        colors: ["6007", "6010", "6009", "6001"],
        name: "Natural charm easel",
        size: "M",
    },
    117801002: { themeID: "99015", colors: ["6008", "6009", "6001"], name: "Natural charm easel", size: "M" },
    117801003: {
        themeID: "99015",
        colors: ["6011", "6003", "6010", "6009", "6001"],
        name: "Natural charm easel",
        size: "M",
    },
    117801100: { themeID: "99004", colors: ["6006", "6001", "6010"], name: "Tropical easel", size: "M" },
    117801101: { themeID: "99004", colors: ["6007", "6005", "6001"], name: "Tropical easel", size: "M" },
    117801102: {
        themeID: "99004",
        colors: ["6009", "6011", "6003", "6001"],
        name: "Tropical easel",
        size: "M",
    },
    117801103: {
        themeID: "99004",
        colors: ["6005", "6009", "6011", "6001"],
        name: "Tropical easel",
        size: "M",
    },
    118000200: {
        themeID: "99001",
        colors: ["6007", "6004", "6006"],
        name: "Urban Tulip Painting",
        size: "S",
    },
    118000201: { themeID: "99001", colors: ["6008", "6001"], name: "Urban Tulip Painting", size: "S" },
    118000202: {
        themeID: "99001",
        colors: ["6003", "6001", "6005"],
        name: "Urban Tulip Painting",
        size: "S",
    },
    118000203: {
        themeID: "99001",
        colors: ["6010", "6001", "6011"],
        name: "Urban Tulip Painting",
        size: "S",
    },
    118000300: {
        themeID: "99001",
        colors: ["6005", "6001", "6002", "6007", "6003"],
        name: "Urban Trio Frames",
        size: "S",
    },
    118000301: {
        themeID: "99001",
        colors: ["6008", "6001", "6002", "6005"],
        name: "Urban Trio Frames",
        size: "S",
    },
    118000302: {
        themeID: "99001",
        colors: ["6011", "6001", "6002", "6005", "6007"],
        name: "Urban Trio Frames",
        size: "S",
    },
    118000303: {
        themeID: "99001",
        colors: ["6010", "6002", "6001", "6011", "6007"],
        name: "Urban Trio Frames",
        size: "S",
    },
    118000304: {
        themeID: "99001",
        colors: ["6007", "6001", "6002", "6003"],
        name: "Urban Trio Frames",
        size: "S",
    },
    118000900: { themeID: "99004", colors: ["6009", "6010", "6003"], name: "Tropical fish decor", size: "S" },
    118000901: { themeID: "99004", colors: ["6007", "6003", "6005"], name: "Tropical fish decor", size: "S" },
    118000902: { themeID: "99004", colors: ["6006", "6005", "6008"], name: "Tropical fish decor", size: "S" },
    118001000: {
        themeID: "99004",
        colors: ["6003", "6006", "6008", "6009"],
        name: "Bikini Girl poster",
        size: "M",
    },
    118001001: {
        themeID: "99004",
        colors: ["6006", "6005", "6009", "6008"],
        name: "Bikini Girl poster",
        size: "M",
    },
    118001002: {
        themeID: "99004",
        colors: ["6004", "6009", "6008", "6005"],
        name: "Bikini Girl poster",
        size: "M",
    },
    118001300: {
        themeID: "99004",
        colors: ["6009", "6001", "6004"],
        name: "Tropical fish paintings",
        size: "M",
    },
    118001301: {
        themeID: "99004",
        colors: ["6007", "6005", "6006", "6009"],
        name: "Tropical fish paintings",
        size: "M",
    },
    118001302: {
        themeID: "99004",
        colors: ["6003", "6011", "6006", "6009"],
        name: "Tropical fish paintings",
        size: "M",
    },
    118001303: {
        themeID: "99004",
        colors: ["6002", "6011", "6001", "6004"],
        name: "Tropical fish paintings",
        size: "M",
    },
    118001304: {
        themeID: "99004",
        colors: ["6010", "6001", "6011"],
        name: "Tropical fish paintings",
        size: "M",
    },
    118001400: { themeID: "99004", colors: ["6001", "6009"], name: "Fishbone Décor Plank", size: "S" },
    118001401: { themeID: "99004", colors: ["6008", "6001"], name: "Fishbone Décor Plank", size: "S" },
    118001402: { themeID: "99004", colors: ["6007", "6009"], name: "Fishbone Décor Plank", size: "S" },
    118001403: { themeID: "99004", colors: ["6005", "6009"], name: "Fishbone Décor Plank", size: "S" },
    118001404: { themeID: "99004", colors: ["6002", "6001"], name: "Fishbone Décor Plank", size: "S" },
    118001405: { themeID: "99004", colors: ["6011", "6001"], name: "Fishbone Décor Plank", size: "S" },
    118001500: { themeID: "99005", colors: ["6006", "6011", "6007"], name: "Kitty Doodle", size: "S" },
    118001501: { themeID: "99005", colors: ["6011", "6010"], name: "Kitty Doodle", size: "S" },
    118001502: { themeID: "99005", colors: ["6008", "6010"], name: "Kitty Doodle", size: "S" },
    118001800: { themeID: "99006", colors: ["6002", "6001"], name: "Vintage frame", size: "S" },
    118001801: { themeID: "99006", colors: ["6001", "6004"], name: "Vintage frame", size: "S" },
    118001802: { themeID: "99006", colors: ["6007", "6001"], name: "Vintage frame", size: "S" },
    118001803: { themeID: "99006", colors: ["6008", "6001"], name: "Vintage frame", size: "S" },
    118001804: { themeID: "99006", colors: ["6003", "6001"], name: "Vintage frame", size: "S" },
    118100100: { themeID: "99006", colors: ["6002", "6001", "6007"], name: "Vintage frames set", size: "S" },
    118100101: { themeID: "99006", colors: ["6001", "6003", "6002"], name: "Vintage frames set", size: "S" },
    118100102: { themeID: "99006", colors: ["6007", "6002", "6001"], name: "Vintage frames set", size: "S" },
    118100103: { themeID: "99006", colors: ["6008", "6002", "6001"], name: "Vintage frames set", size: "S" },
    118100104: { themeID: "99006", colors: ["6003", "6001", "6002"], name: "Vintage frames set", size: "S" },
    118100300: { themeID: "99003", colors: ["6003", "6011"], name: "Rabbit picture frame", size: "S" },
    118100301: {
        themeID: "99003",
        colors: ["6009", "6007", "6006"],
        name: "Rabbit picture frame",
        size: "S",
    },
    118100302: {
        themeID: "99003",
        colors: ["6010", "6008", "6004"],
        name: "Rabbit picture frame",
        size: "S",
    },
    118100303: { themeID: "99003", colors: ["6004", "6001"], name: "Rabbit picture frame", size: "S" },
    118100400: { themeID: "99017", colors: ["6001"], name: "Giant snowflake", size: "M", xmas: true },
    118100401: { themeID: "99017", colors: ["6006"], name: "Giant snowflake", size: "M", xmas: true },
    118200100: { themeID: "99007", colors: ["6009", "6010", "6003"], name: "Tiny Flower picture", size: "S" },
    118200101: { themeID: "99007", colors: ["6010", "6003"], name: "Tiny Flower picture", size: "S" },
    118200102: { themeID: "99007", colors: ["6007", "6003"], name: "Tiny Flower picture", size: "S" },
    118200200: {
        themeID: "99007",
        colors: ["6005", "6006", "6007"],
        name: "Timeless Charm picture",
        size: "S",
    },
    118200201: {
        themeID: "99007",
        colors: ["6010", "6004", "6008", "6005"],
        name: "Timeless Charm picture",
        size: "S",
    },
    118200202: {
        themeID: "99007",
        colors: ["6007", "6008", "6006"],
        name: "Timeless Charm picture",
        size: "S",
    },
    118200300: { themeID: "99008", colors: ["6005", "6001"], name: "Bohemian Bull Skull", size: "S" },
    118200301: { themeID: "99008", colors: ["6011", "6001"], name: "Bohemian Bull Skull", size: "S" },
    118200302: { themeID: "99008", colors: ["6001", "6006"], name: "Bohemian Bull Skull", size: "S" },
    118200700: { themeID: "99008", colors: ["6001", "6006"], name: "Gypsy bottle wind chimes", size: "M" },
    118200701: { themeID: "99008", colors: ["6005", "6009"], name: "Gypsy bottle wind chimes", size: "M" },
    118200702: { themeID: "99008", colors: ["6010", "6004"], name: "Gypsy bottle wind chimes", size: "M" },
    118200703: { themeID: "99008", colors: ["6006", "6007"], name: "Gypsy bottle wind chimes", size: "M" },
    118300100: { themeID: "99009", colors: ["6005", "6007", "6003"], name: "Antique wall panel", size: "M" },
    118300101: {
        themeID: "99009",
        colors: ["6002", "6008", "6007", "6004"],
        name: "Antique wall panel",
        size: "M",
    },
    118300102: { themeID: "99009", colors: ["6011", "6007", "6008"], name: "Antique wall panel", size: "M" },
    118300200: {
        themeID: "99009",
        colors: ["6009", "6011", "6007", "6006"],
        name: "Antique floral painting",
        size: "S",
    },
    118300201: {
        themeID: "99009",
        colors: ["6001", "6006", "6007", "6011"],
        name: "Antique floral painting",
        size: "S",
    },
    118300202: {
        themeID: "99009",
        colors: ["6011", "6006", "6007"],
        name: "Antique floral painting",
        size: "S",
    },
    118600300: {
        themeID: "99015",
        colors: ["6003", "6011", "6009"],
        name: "Refined Wall Pavings",
        size: "S",
    },
    118600301: {
        themeID: "99015",
        colors: ["6007", "6009", "6004"],
        name: "Refined Wall Pavings",
        size: "S",
    },
    118600302: {
        themeID: "99015",
        colors: ["6008", "6009", "6003"],
        name: "Refined Wall Pavings",
        size: "S",
    },
    118700100: { themeID: "99011", colors: ["6003", "6006"], name: "Rose sculpture", size: "S" },
    118700101: { themeID: "99011", colors: ["6006", "6011"], name: "Rose sculpture", size: "S" },
    118700102: { themeID: "99011", colors: ["6005", "6008"], name: "Rose sculpture", size: "S" },
    118700103: { themeID: "99011", colors: ["6011", "6010"], name: "Rose sculpture", size: "S" },
    118700200: { themeID: "99011", colors: ["6006", "6009", "6007"], name: "Plant sculpture", size: "S" },
    118700201: { themeID: "99011", colors: ["6011", "6009", "6008"], name: "Plant sculpture", size: "S" },
    118700202: { themeID: "99011", colors: ["6008", "6004", "6010"], name: "Plant sculpture", size: "S" },
    118700203: { themeID: "99011", colors: ["6010", "6004"], name: "Plant sculpture", size: "S" },
    118700300: { themeID: "99011", colors: ["6006", "6009"], name: "Flowers sculpture", size: "S" },
    118700301: { themeID: "99011", colors: ["6011", "6007", "6009"], name: "Flowers sculpture", size: "S" },
    118700302: { themeID: "99011", colors: ["6008", "6010", "6004"], name: "Flowers sculpture", size: "S" },
    118700303: { themeID: "99011", colors: ["6010", "6009", "6003"], name: "Flowers sculpture", size: "S" },
    118700400: { themeID: "99011", colors: ["6006", "6009"], name: "Vertical flowers sculpture", size: "S" },
    118700401: {
        themeID: "99011",
        colors: ["6011", "6009", "6006"],
        name: "Vertical flowers sculpture",
        size: "S",
    },
    118700402: {
        themeID: "99011",
        colors: ["6008", "6009", "6006"],
        name: "Vertical flowers sculpture",
        size: "S",
    },
    118700403: {
        themeID: "99011",
        colors: ["6010", "6009", "6011"],
        name: "Vertical flowers sculpture",
        size: "S",
    },
    118700500: { themeID: "99011", colors: ["6006", "6007"], name: "Art flower painting", size: "S" },
    118700501: { themeID: "99011", colors: ["6009", "6006"], name: "Art flower painting", size: "S" },
    118700600: { themeID: "99011", colors: ["6006", "6009"], name: "Flower painting", size: "XS" },
    118700601: { themeID: "99011", colors: ["6001", "6009"], name: "Flower painting", size: "XS" },
    118700700: { themeID: "99011", colors: ["6009", "6001"], name: "Rabbit painting", size: "S" },
    118700701: { themeID: "99011", colors: ["6001", "6009"], name: "Rabbit painting", size: "S" },
    118700702: { themeID: "99011", colors: ["6006", "6009"], name: "Rabbit painting", size: "S" },
    118700800: { themeID: "99011", colors: ["6009", "6007"], name: "Vegetable painting", size: "XS" },
    118700801: { themeID: "99011", colors: ["6009", "6007", "6006"], name: "Vegetable painting", size: "XS" },
    118700802: { themeID: "99011", colors: ["6009", "6005", "6003"], name: "Vegetable painting", size: "XS" },
    118701000: { themeID: "99011", colors: ["6006", "6005"], name: "Laurel twig", size: "XS" },
    118701001: { themeID: "99011", colors: ["6003", "6011"], name: "Laurel twig", size: "XS" },
    118701002: { themeID: "99011", colors: ["6008"], name: "Laurel twig", size: "XS" },
    118701003: { themeID: "99011", colors: ["6010"], name: "Laurel twig", size: "XS" },
    118701100: { themeID: "99008", colors: ["6001", "6008", "6006"], name: "Gypsy Dreamcatcher", size: "M" },
    118701101: { themeID: "99008", colors: ["6005", "6006", "6001"], name: "Gypsy Dreamcatcher", size: "M" },
    118701102: { themeID: "99008", colors: ["6010", "6007", "6006"], name: "Gypsy Dreamcatcher", size: "M" },
    118800100: { themeID: "99002", colors: ["6009", "6001", "6006"], name: "Rustic board", size: "M" },
    118800101: { themeID: "99002", colors: ["6002", "6009", "6006"], name: "Rustic board", size: "M" },
    118800102: { themeID: "99002", colors: ["6008", "6001", "6003"], name: "Rustic board", size: "M" },
    118800103: { themeID: "99002", colors: ["6006", "6001", "6003"], name: "Rustic board", size: "M" },
    118800200: {
        themeID: "99001",
        colors: ["6008", "6001", "6005", "6002", "6011"],
        name: "Urban board",
        size: "M",
    },
    118800201: { themeID: "99001", colors: ["6004", "6001", "6011", "6005"], name: "Urban board", size: "M" },
    118800202: { themeID: "99001", colors: ["6002", "6008", "6010", "6001"], name: "Urban board", size: "M" },
    118800203: { themeID: "99001", colors: ["6002", "6011", "6005"], name: "Urban board", size: "M" },
    119000100: { themeID: "99002", colors: ["6006"], name: "Rustic window", size: "M" },
    119000101: { themeID: "99002", colors: ["6009"], name: "Rustic window", size: "M" },
    119000102: { themeID: "99002", colors: ["6001"], name: "Rustic window", size: "M" },
    119000103: { themeID: "99002", colors: ["6011"], name: "Rustic window", size: "M" },
    119000104: { themeID: "99002", colors: ["6008"], name: "Rustic window", size: "M" },
    119000105: { themeID: "99002", colors: ["6002"], name: "Rustic window", size: "M" },
    119000106: { themeID: "99002", colors: ["6007"], name: "Rustic window", size: "M" },
    119000200: {
        themeID: "99002",
        colors: ["6006", "6007", "6001", "6011"],
        name: "Trellis window",
        size: "M",
    },
    119000201: {
        themeID: "99002",
        colors: ["6009", "6001", "6008", "6006"],
        name: "Trellis window",
        size: "M",
    },
    119000202: { themeID: "99002", colors: ["6001", "6008", "6006"], name: "Trellis window", size: "M" },
    119000203: { themeID: "99002", colors: ["6011", "6001", "6007"], name: "Trellis window", size: "M" },
    119000204: { themeID: "99002", colors: ["6008", "6001", "6006"], name: "Trellis window", size: "M" },
    119000205: {
        themeID: "99002",
        colors: ["6002", "6001", "6008", "6006"],
        name: "Trellis window",
        size: "M",
    },
    119000206: {
        themeID: "99002",
        colors: ["6007", "6006", "6001", "6011"],
        name: "Trellis window",
        size: "M",
    },
    119000300: { themeID: "99011", colors: ["6006", "6011"], name: "Bunny Windowpane", size: "M" },
    119000301: { themeID: "99011", colors: ["6011", "6007"], name: "Bunny Windowpane", size: "M" },
    119000302: { themeID: "99011", colors: ["6008", "6011"], name: "Bunny Windowpane", size: "M" },
    119000303: { themeID: "99011", colors: ["6010", "6011"], name: "Bunny Windowpane", size: "M" },
    119000700: { themeID: "99003", colors: ["6003", "6001", "6011"], name: "Girlie window", size: "M" },
    119000701: { themeID: "99003", colors: ["6005", "6001", "6009"], name: "Girlie window", size: "M" },
    119000702: { themeID: "99003", colors: ["6010", "6001", "6004"], name: "Girlie window", size: "M" },
    119000703: { themeID: "99003", colors: ["6008", "6001", "6002"], name: "Girlie window", size: "M" },
    119001100: {
        themeID: "99004",
        colors: ["6007", "6001", "6009", "6006"],
        name: "Rattan Window",
        size: "M",
    },
    119001101: { themeID: "99004", colors: ["6002", "6008", "6001"], name: "Rattan Window", size: "M" },
    119001102: {
        themeID: "99004",
        colors: ["6009", "6008", "6001", "6011"],
        name: "Rattan Window",
        size: "M",
    },
    119001103: { themeID: "99004", colors: ["6004", "6001", "6006"], name: "Rattan Window", size: "M" },
    119001104: { themeID: "99004", colors: ["6010", "6011", "6001"], name: "Rattan Window", size: "M" },
    119002200: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie arched window", size: "M" },
    119002201: {
        themeID: "99003",
        colors: ["6009", "6005", "6006"],
        name: "Girlie arched window",
        size: "M",
    },
    119002202: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie arched window", size: "M" },
    119002203: {
        themeID: "99003",
        colors: ["6002", "6008", "6004"],
        name: "Girlie arched window",
        size: "M",
    },
    119100200: {
        themeID: "99017",
        colors: ["6003", "6006"],
        name: "Rattle bell window",
        size: "M",
        xmas: true,
    },
    119100201: {
        themeID: "99017",
        colors: ["6007", "6005"],
        name: "Rattle bell window",
        size: "M",
        xmas: true,
    },
    119100202: {
        themeID: "99017",
        colors: ["6008", "6001", "6011"],
        name: "Rattle bell window",
        size: "M",
        xmas: true,
    },
    119200100: { themeID: "99007", colors: ["6005", "6008"], name: "Country window", size: "S" },
    119200101: { themeID: "99007", colors: ["6004", "6008"], name: "Country window", size: "S" },
    119200102: { themeID: "99007", colors: ["6007", "6008"], name: "Country window", size: "S" },
    119200103: { themeID: "99007", colors: ["6006", "6001", "6008"], name: "Country window", size: "S" },
    119200200: { themeID: "99008", colors: ["6009", "6005"], name: "Boho patterned window", size: "M" },
    119200201: { themeID: "99008", colors: ["6001", "6009"], name: "Boho patterned window", size: "M" },
    119200202: { themeID: "99008", colors: ["6011", "6010"], name: "Boho patterned window", size: "M" },
    119200203: { themeID: "99008", colors: ["6008", "6004"], name: "Boho patterned window", size: "M" },
    119200204: { themeID: "99008", colors: ["6006", "6007"], name: "Boho patterned window", size: "M" },
    119200300: { themeID: "99008", colors: ["6001", "6009", "6006"], name: "Bohemian windowpane", size: "M" },
    119200301: { themeID: "99008", colors: ["6011", "6003", "6006"], name: "Bohemian windowpane", size: "M" },
    119200302: { themeID: "99008", colors: ["6010", "6006", "6009"], name: "Bohemian windowpane", size: "M" },
    119200303: { themeID: "99008", colors: ["6008", "6006", "6005"], name: "Bohemian windowpane", size: "M" },
    119300100: {
        themeID: "99009",
        colors: ["6009", "6001", "6002", "6005", "6007"],
        name: "Classic window",
        size: "L",
    },
    119300101: {
        themeID: "99009",
        colors: ["6001", "6002", "6006", "6007", "6004"],
        name: "Classic window",
        size: "L",
    },
    119300102: {
        themeID: "99009",
        colors: ["6011", "6001", "6006", "6003", "6004"],
        name: "Classic window",
        size: "L",
    },
    119300200: {
        themeID: "99009",
        colors: ["6006", "6008", "6007", "6001"],
        name: "Classic balcony",
        size: "L",
    },
    119300201: { themeID: "99009", colors: ["6002", "6007", "6006"], name: "Classic balcony", size: "L" },
    119300202: {
        themeID: "99009",
        colors: ["6011", "6006", "6001", "6007"],
        name: "Classic balcony",
        size: "L",
    },
    119400100: { themeID: "99019", colors: ["6009", "6004", "6007"], name: "Stone window", size: "M" },
    119400101: { themeID: "99019", colors: ["6003", "6007", "6004"], name: "Stone window", size: "M" },
    119400102: {
        themeID: "99019",
        colors: ["6008", "6009", "6004", "6007"],
        name: "Stone window",
        size: "M",
    },
    119400200: { themeID: "99019", colors: ["6004", "6009", "6006"], name: "Stone light window", size: "M" },
    119400201: { themeID: "99019", colors: ["6003", "6006", "6004"], name: "Stone light window", size: "M" },
    119400202: { themeID: "99019", colors: ["6008", "6006", "6009"], name: "Stone light window", size: "M" },
    119400300: { themeID: "99019", colors: ["6004", "6009"], name: "Stone round window", size: "S" },
    119400301: { themeID: "99019", colors: ["6003", "6007", "6009"], name: "Stone round window", size: "S" },
    119400302: { themeID: "99019", colors: ["6008", "6009"], name: "Stone round window", size: "S" },
    119400400: { themeID: "99006", colors: ["6007", "6009", "6001"], name: "Vintage window", size: "XL" },
    119400401: { themeID: "99006", colors: ["6008", "6009", "6001"], name: "Vintage window", size: "XL" },
    119400402: { themeID: "99006", colors: ["6003", "6009", "6001"], name: "Vintage window", size: "XL" },
    119400403: { themeID: "99006", colors: ["6009", "6004", "6002"], name: "Vintage window", size: "XL" },
    129000100: {
        themeID: "99001",
        colors: ["6001", "6008", "6006", "6007", "6004"],
        name: "Urban Tub",
        size: "M",
    },
    129000101: {
        themeID: "99001",
        colors: ["6006", "6005", "6001", "6007", "6004"],
        name: "Urban Tub",
        size: "M",
    },
    129000102: { themeID: "99001", colors: ["6008", "6001", "6007", "6006"], name: "Urban Tub", size: "M" },
    129000103: {
        themeID: "99001",
        colors: ["6011", "6001", "6007", "6006", "6004"],
        name: "Urban Tub",
        size: "M",
    },
    129000104: { themeID: "99001", colors: ["6010", "6001", "6008", "6006"], name: "Urban Tub", size: "M" },
    129000105: { themeID: "99001", colors: ["6007", "6006", "6001", "6004"], name: "Urban Tub", size: "M" },
    129000200: { themeID: "99002", colors: ["6001", "6008"], name: "Rustic Arched Tub", size: "L" },
    129000201: { themeID: "99002", colors: ["6011", "6008"], name: "Rustic Arched Tub", size: "L" },
    129000202: { themeID: "99002", colors: ["6008", "6005"], name: "Rustic Arched Tub", size: "L" },
    129000203: { themeID: "99002", colors: ["6002", "6011"], name: "Rustic Arched Tub", size: "L" },
    129000300: { themeID: "99003", colors: ["6011", "6001", "6003"], name: "Girlie Tub", size: "XL" },
    129000301: { themeID: "99003", colors: ["6005", "6008", "6009", "6001"], name: "Girlie Tub", size: "XL" },
    129000302: { themeID: "99003", colors: ["6010", "6001", "6002", "6008"], name: "Girlie Tub", size: "XL" },
    129000303: { themeID: "99003", colors: ["6008", "6004", "6005", "6001"], name: "Girlie Tub", size: "XL" },
    129000400: { themeID: "99004", colors: ["6009", "6007"], name: "Bamboo Tub", size: "M" },
    129000401: { themeID: "99004", colors: ["6005", "6011", "6008"], name: "Bamboo Tub", size: "M" },
    129000402: { themeID: "99004", colors: ["6010", "6009", "6011"], name: "Bamboo Tub", size: "M" },
    129000403: { themeID: "99004", colors: ["6001", "6008"], name: "Bamboo Tub", size: "M" },
    129000500: { themeID: "99005", colors: ["6005", "6001", "6006"], name: "Ducky Tub", size: "M" },
    129000501: { themeID: "99005", colors: ["6011", "6001", "6006"], name: "Ducky Tub", size: "M" },
    129000502: { themeID: "99005", colors: ["6010", "6006", "6001"], name: "Ducky Tub", size: "M" },
    129000503: { themeID: "99005", colors: ["6008", "6006", "6001"], name: "Ducky Tub", size: "M" },
    129000600: { themeID: "99006", colors: ["6005", "6001", "6007"], name: "Vintage tub", size: "M" },
    129000601: { themeID: "99006", colors: ["6008", "6001", "6007"], name: "Vintage tub", size: "M" },
    129000602: { themeID: "99006", colors: ["6011", "6008", "6001"], name: "Vintage tub", size: "M" },
    129000603: { themeID: "99006", colors: ["6009", "6008", "6006"], name: "Vintage tub", size: "M" },
    129000604: { themeID: "99006", colors: ["6010", "6001", "6011"], name: "Vintage tub", size: "M" },
    129000700: { themeID: "99011", colors: ["6005", "6001", "6007"], name: "Dolly Oval Tub", size: "M" },
    129000701: { themeID: "99011", colors: ["6011", "6001", "6008"], name: "Dolly Oval Tub", size: "M" },
    129000702: { themeID: "99011", colors: ["6008", "6011", "6001"], name: "Dolly Oval Tub", size: "M" },
    129000703: { themeID: "99011", colors: ["6010", "6001", "6011"], name: "Dolly Oval Tub", size: "M" },
    129100100: {
        themeID: "99017",
        colors: ["6003", "6007", "6009"],
        name: "Hot cocoa tub",
        size: "L",
        xmas: true,
    },
    129100101: {
        themeID: "99017",
        colors: ["6007", "6005", "6009"],
        name: "Hot cocoa tub",
        size: "L",
        xmas: true,
    },
    129100102: {
        themeID: "99017",
        colors: ["6008", "6011", "6010"],
        name: "Hot cocoa tub",
        size: "L",
        xmas: true,
    },
    129100200: {
        themeID: "99017",
        colors: ["6001", "6008", "6003"],
        name: "Xmas hotspring",
        size: "XL",
        xmas: true,
    },
    129100201: {
        themeID: "99017",
        colors: ["6007", "6001", "6006"],
        name: "Xmas hotspring",
        size: "XL",
        xmas: true,
    },
    129100202: {
        themeID: "99017",
        colors: ["6010", "6011", "6001"],
        name: "Xmas hotspring",
        size: "XL",
        xmas: true,
    },
    129200100: { themeID: "99007", colors: ["6005", "6009", "6004"], name: "Country Tub", size: "L" },
    129200101: { themeID: "99007", colors: ["6009", "6006", "6004"], name: "Country Tub", size: "L" },
    129200102: { themeID: "99007", colors: ["6006", "6009", "6004"], name: "Country Tub", size: "L" },
    129200200: { themeID: "99008", colors: ["6005", "6001", "6008"], name: "Bohemian Tub", size: "L" },
    129200201: { themeID: "99008", colors: ["6003", "6001", "6008"], name: "Bohemian Tub", size: "L" },
    129200202: { themeID: "99008", colors: ["6010", "6008", "6001"], name: "Bohemian Tub", size: "L" },
    129200203: { themeID: "99008", colors: ["6007", "6001", "6003"], name: "Bohemian Tub", size: "L" },
    129200204: { themeID: "99008", colors: ["6008", "6001", "6010"], name: "Bohemian Tub", size: "L" },
    129300100: {
        themeID: "99009",
        colors: ["6006", "6001", "6008", "6009", "6002"],
        name: "Classy bathtub",
        size: "L",
    },
    129300101: {
        themeID: "99009",
        colors: ["6004", "6007", "6008", "6001", "6002"],
        name: "Classy bathtub",
        size: "L",
    },
    129300102: {
        themeID: "99009",
        colors: ["6011", "6003", "6001", "6002"],
        name: "Classy bathtub",
        size: "L",
    },
    129400100: {
        themeID: "99019",
        colors: ["6004", "6007", "6009", "6001"],
        name: "Stone bath cabin",
        size: "XL",
    },
    129400101: {
        themeID: "99019",
        colors: ["6003", "6001", "6004", "6007"],
        name: "Stone bath cabin",
        size: "XL",
    },
    129400102: {
        themeID: "99019",
        colors: ["6008", "6009", "6007", "6005"],
        name: "Stone bath cabin",
        size: "XL",
    },
    129600300: { themeID: "99015", colors: ["6009", "6007", "6001"], name: "Refined Bathtub", size: "L" },
    129600301: { themeID: "99015", colors: ["6007", "6001", "6009"], name: "Refined Bathtub", size: "L" },
    129600302: { themeID: "99015", colors: ["6008", "6009", "6001"], name: "Refined Bathtub", size: "L" },
    129600303: {
        themeID: "99015",
        colors: ["6011", "6001", "6008", "6004"],
        name: "Refined Bathtub",
        size: "L",
    },
    131000100: { themeID: "99001", colors: ["6001", "6002", "6008"], name: "Urban Lavabo", size: "S" },
    131000101: {
        themeID: "99001",
        colors: ["6005", "6006", "6002", "6001", "6007"],
        name: "Urban Lavabo",
        size: "S",
    },
    131000102: {
        themeID: "99001",
        colors: ["6011", "6002", "6007", "6001"],
        name: "Urban Lavabo",
        size: "S",
    },
    131000103: {
        themeID: "99001",
        colors: ["6008", "6002", "6001", "6007"],
        name: "Urban Lavabo",
        size: "S",
    },
    131000104: { themeID: "99001", colors: ["6010", "6002", "6001"], name: "Urban Lavabo", size: "S" },
    131000105: { themeID: "99001", colors: ["6007", "6001", "6002"], name: "Urban Lavabo", size: "S" },
    131000200: { themeID: "99002", colors: ["6009", "6006", "6001"], name: "Rustic sink", size: "M" },
    131000201: { themeID: "99002", colors: ["6001", "6004", "6011"], name: "Rustic sink", size: "M" },
    131000202: { themeID: "99002", colors: ["6011", "6001", "6004"], name: "Rustic sink", size: "M" },
    131000203: { themeID: "99002", colors: ["6008", "6001", "6004"], name: "Rustic sink", size: "M" },
    131000204: { themeID: "99002", colors: ["6002", "6001", "6004"], name: "Rustic sink", size: "M" },
    131000205: { themeID: "99002", colors: ["6007", "6001"], name: "Rustic sink", size: "M" },
    131000300: { themeID: "99003", colors: ["6003", "6011", "6008"], name: "Girlie Sink", size: "S" },
    131000301: { themeID: "99003", colors: ["6005", "6009", "6007"], name: "Girlie Sink", size: "S" },
    131000302: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie Sink", size: "S" },
    131000303: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie Sink", size: "S" },
    131000400: { themeID: "99004", colors: ["6007", "6009", "6008"], name: "Coconut sink", size: "M" },
    131000401: { themeID: "99004", colors: ["6011", "6008", "6004"], name: "Coconut sink", size: "M" },
    131000402: { themeID: "99004", colors: ["6008", "6005", "6004"], name: "Coconut sink", size: "M" },
    131000403: { themeID: "99004", colors: ["6010", "6011", "6005"], name: "Coconut sink", size: "M" },
    131000500: { themeID: "99005", colors: ["6005", "6006", "6001"], name: "Ducky Lavabo", size: "M" },
    131000501: { themeID: "99005", colors: ["6003", "6006", "6001"], name: "Ducky Lavabo", size: "M" },
    131000502: { themeID: "99005", colors: ["6010", "6001", "6006"], name: "Ducky Lavabo", size: "M" },
    131000503: { themeID: "99005", colors: ["6008", "6006", "6001"], name: "Ducky Lavabo", size: "M" },
    131000600: { themeID: "99006", colors: ["6009", "6001"], name: "Vintage lavabo", size: "S" },
    131000601: { themeID: "99006", colors: ["6008", "6001", "6009"], name: "Vintage lavabo", size: "S" },
    131000602: { themeID: "99006", colors: ["6003", "6001", "6002"], name: "Vintage lavabo", size: "S" },
    131000603: { themeID: "99006", colors: ["6007", "6009", "6001"], name: "Vintage lavabo", size: "S" },
    131000700: { themeID: "99011", colors: ["6006", "6001"], name: "Dolly Lavabo", size: "S" },
    131000701: { themeID: "99011", colors: ["6011", "6001"], name: "Dolly Lavabo", size: "S" },
    131000702: { themeID: "99011", colors: ["6008", "6001"], name: "Dolly Lavabo", size: "S" },
    131000703: { themeID: "99011", colors: ["6010", "6001"], name: "Dolly Lavabo", size: "S" },
    131100100: { themeID: "99017", colors: ["6003", "6001"], name: "Xmas lavabo", size: "M", xmas: true },
    131100101: {
        themeID: "99017",
        colors: ["6007", "6001", "6009"],
        name: "Xmas lavabo",
        size: "M",
        xmas: true,
    },
    131100102: {
        themeID: "99017",
        colors: ["6008", "6011", "6010"],
        name: "Xmas lavabo",
        size: "M",
        xmas: true,
    },
    131140100: { themeID: "99008", colors: ["6003", "6001", "6004"], name: "Boho marble lavabo", size: "L" },
    131140101: { themeID: "99008", colors: ["6004", "6001", "6011"], name: "Boho marble lavabo", size: "L" },
    131140102: { themeID: "99008", colors: ["6005", "6001", "6004"], name: "Boho marble lavabo", size: "L" },
    131140103: { themeID: "99008", colors: ["6006", "6005", "6001"], name: "Boho marble lavabo", size: "L" },
    131200100: { themeID: "99007", colors: ["6009", "6005", "6011"], name: "Country lavabo", size: "M" },
    131200101: { themeID: "99007", colors: ["6004", "6009", "6010"], name: "Country lavabo", size: "M" },
    131200102: { themeID: "99007", colors: ["6006", "6004", "6005"], name: "Country lavabo", size: "M" },
    131200200: { themeID: "99008", colors: ["6005", "6008", "6001"], name: "Bohemian Lavabo", size: "M" },
    131200201: { themeID: "99008", colors: ["6011", "6008", "6001"], name: "Bohemian Lavabo", size: "M" },
    131200202: { themeID: "99008", colors: ["6007", "6005", "6001"], name: "Bohemian Lavabo", size: "M" },
    131300100: { themeID: "99009", colors: ["6009", "6001", "6005"], name: "Antique lavabo", size: "M" },
    131300101: { themeID: "99009", colors: ["6002", "6001", "6006"], name: "Antique lavabo", size: "M" },
    131300102: { themeID: "99009", colors: ["6011", "6006", "6007"], name: "Antique lavabo", size: "M" },
    131400100: { themeID: "99019", colors: ["6004", "6001", "6006"], name: "Stone lavabo", size: "M" },
    131400101: { themeID: "99019", colors: ["6003", "6005", "6001"], name: "Stone lavabo", size: "M" },
    131400102: { themeID: "99019", colors: ["6008", "6005", "6004"], name: "Stone lavabo", size: "M" },
    131600200: { themeID: "99015", colors: ["6009", "6007", "6001"], name: "Refined Lavabo", size: "L" },
    131600201: { themeID: "99015", colors: ["6007", "6001", "6004"], name: "Refined Lavabo", size: "L" },
    131600202: { themeID: "99015", colors: ["6008", "6001", "6009"], name: "Refined Lavabo", size: "L" },
    131600203: { themeID: "99015", colors: ["6004", "6003", "6008"], name: "Refined Lavabo", size: "L" },
    133000100: { themeID: "99001", colors: ["6007", "6001", "6005"], name: "Urban Toilet", size: "S" },
    133000101: { themeID: "99001", colors: ["6009", "6005", "6001"], name: "Urban Toilet", size: "S" },
    133000102: { themeID: "99001", colors: ["6008", "6001"], name: "Urban Toilet", size: "S" },
    133000103: { themeID: "99001", colors: ["6001", "6011", "6005"], name: "Urban Toilet", size: "S" },
    133000104: { themeID: "99001", colors: ["6002", "6010", "6001"], name: "Urban Toilet", size: "S" },
    133000200: { themeID: "99002", colors: ["6001"], name: "Rustic toilet", size: "S" },
    133000201: { themeID: "99002", colors: ["6011"], name: "Rustic toilet", size: "S" },
    133000202: { themeID: "99002", colors: ["6008"], name: "Rustic toilet", size: "S" },
    133000203: { themeID: "99002", colors: ["6002", "6004"], name: "Rustic toilet", size: "S" },
    133000204: { themeID: "99002", colors: ["6006"], name: "Rustic toilet", size: "S" },
    133000205: { themeID: "99002", colors: ["6007"], name: "Rustic toilet", size: "S" },
    133000300: { themeID: "99003", colors: ["6011", "6001"], name: "Girlie Toilet", size: "S" },
    133000301: { themeID: "99003", colors: ["6005", "6001"], name: "Girlie Toilet", size: "S" },
    133000302: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie Toilet", size: "S" },
    133000303: { themeID: "99003", colors: ["6008", "6001"], name: "Girlie Toilet", size: "S" },
    133000400: { themeID: "99004", colors: ["6007", "6001", "6008"], name: "Tropical toilet", size: "S" },
    133000401: {
        themeID: "99004",
        colors: ["6003", "6001", "6008", "6011"],
        name: "Tropical toilet",
        size: "S",
    },
    133000402: { themeID: "99004", colors: ["6008", "6001"], name: "Tropical toilet", size: "S" },
    133000403: { themeID: "99004", colors: ["6010", "6001", "6008"], name: "Tropical toilet", size: "S" },
    133000500: { themeID: "99005", colors: ["6009", "6001", "6006"], name: "Ducky Toilet", size: "M" },
    133000501: { themeID: "99005", colors: ["6011", "6001", "6006"], name: "Ducky Toilet", size: "M" },
    133000502: { themeID: "99005", colors: ["6010", "6001", "6006"], name: "Ducky Toilet", size: "M" },
    133000503: { themeID: "99005", colors: ["6008", "6001", "6006"], name: "Ducky Toilet", size: "M" },
    133000600: { themeID: "99006", colors: ["6009", "6001"], name: "Vintage toilet", size: "S" },
    133000601: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage toilet", size: "S" },
    133000602: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage toilet", size: "S" },
    133000603: { themeID: "99006", colors: ["6004", "6003"], name: "Vintage toilet", size: "S" },
    133000604: { themeID: "99006", colors: ["6002", "6003"], name: "Vintage toilet", size: "S" },
    133000700: { themeID: "99011", colors: ["6006", "6009"], name: "Dolly Toilet", size: "S" },
    133000701: { themeID: "99011", colors: ["6011", "6003"], name: "Dolly Toilet", size: "S" },
    133000702: { themeID: "99011", colors: ["6008", "6006"], name: "Dolly Toilet", size: "S" },
    133000703: { themeID: "99011", colors: ["6010", "6011"], name: "Dolly Toilet", size: "S" },
    133100100: { themeID: "99017", colors: ["6003", "6001"], name: "Xmas toilet", size: "S", xmas: true },
    133100101: { themeID: "99017", colors: ["6007", "6005"], name: "Xmas toilet", size: "S", xmas: true },
    133100102: { themeID: "99017", colors: ["6008", "6001"], name: "Xmas toilet", size: "S", xmas: true },
    133200100: { themeID: "99008", colors: ["6003", "6001"], name: "Bohemian Toilet Seat", size: "S" },
    133200101: { themeID: "99008", colors: ["6005", "6001"], name: "Bohemian Toilet Seat", size: "S" },
    133200102: { themeID: "99008", colors: ["6011", "6001"], name: "Bohemian Toilet Seat", size: "S" },
    133300100: { themeID: "99009", colors: ["6009", "6007", "6003"], name: "Classy toilet", size: "M" },
    133300101: { themeID: "99009", colors: ["6001", "6002", "6007"], name: "Classy toilet", size: "M" },
    133300102: { themeID: "99009", colors: ["6011", "6006", "6007"], name: "Classy toilet", size: "M" },
    133400100: { themeID: "99019", colors: ["6009", "6004"], name: "Stone toilet", size: "M" },
    133400101: { themeID: "99019", colors: ["6003"], name: "Stone toilet", size: "M" },
    133400102: { themeID: "99019", colors: ["6008", "6009"], name: "Stone toilet", size: "M" },
    133400103: { themeID: "99019", colors: ["6002", "6004"], name: "Stone toilet", size: "M" },
    133600200: { themeID: "99015", colors: ["6009", "6007", "6001"], name: "Refined toilet", size: "S" },
    133600201: { themeID: "99015", colors: ["6007", "6002", "6001"], name: "Refined toilet", size: "S" },
    133600202: {
        themeID: "99015",
        colors: ["6008", "6003", "6001", "6007"],
        name: "Refined toilet",
        size: "S",
    },
    133600203: {
        themeID: "99015",
        colors: ["6004", "6003", "6001", "6007", "6011"],
        name: "Refined toilet",
        size: "S",
    },
    137000100: { themeID: "99001", colors: ["6001", "6008", "6005"], name: "Urban Vanity", size: "L" },
    137000101: { themeID: "99001", colors: ["6008", "6005", "6001"], name: "Urban Vanity", size: "L" },
    137000102: { themeID: "99001", colors: ["6011", "6001", "6007"], name: "Urban Vanity", size: "L" },
    137000103: { themeID: "99001", colors: ["6002", "6010", "6004"], name: "Urban Vanity", size: "L" },
    137000104: { themeID: "99001", colors: ["6007", "6005", "6001"], name: "Urban Vanity", size: "L" },
    137000200: { themeID: "99002", colors: ["6009", "6006", "6007"], name: "Rustic Vanity", size: "M" },
    137000201: {
        themeID: "99002",
        colors: ["6001", "6007", "6009", "6004"],
        name: "Rustic Vanity",
        size: "M",
    },
    137000202: {
        themeID: "99002",
        colors: ["6011", "6001", "6007", "6006", "6005"],
        name: "Rustic Vanity",
        size: "M",
    },
    137000203: {
        themeID: "99002",
        colors: ["6008", "6001", "6005", "6007", "6006"],
        name: "Rustic Vanity",
        size: "M",
    },
    137000204: {
        themeID: "99002",
        colors: ["6002", "6001", "6010", "6011"],
        name: "Rustic Vanity",
        size: "M",
    },
    137000205: {
        themeID: "99002",
        colors: ["6007", "6001", "6005", "6006", "6003"],
        name: "Rustic Vanity",
        size: "M",
    },
    137000300: { themeID: "99003", colors: ["6003", "6011", "6008"], name: "Girlie Vanity", size: "M" },
    137000301: { themeID: "99003", colors: ["6005", "6009", "6007"], name: "Girlie Vanity", size: "M" },
    137000302: { themeID: "99003", colors: ["6010", "6011", "6004"], name: "Girlie Vanity", size: "M" },
    137000303: { themeID: "99003", colors: ["6008", "6006", "6002"], name: "Girlie Vanity", size: "M" },
    137000400: {
        themeID: "99004",
        colors: ["6009", "6003", "6008", "6005"],
        name: "Rattan Vanity",
        size: "M",
    },
    137000401: {
        themeID: "99004",
        colors: ["6007", "6008", "6006", "6009"],
        name: "Rattan Vanity",
        size: "M",
    },
    137000402: { themeID: "99004", colors: ["6002", "6008", "6006"], name: "Rattan Vanity", size: "M" },
    137000403: {
        themeID: "99004",
        colors: ["6004", "6008", "6011", "6006", "6010"],
        name: "Rattan Vanity",
        size: "M",
    },
    137000500: {
        themeID: "99005",
        colors: ["6007", "6009", "6006", "6008"],
        name: "Childlike Vanity",
        size: "S",
    },
    137000501: {
        themeID: "99005",
        colors: ["6011", "6010", "6008", "6006"],
        name: "Childlike Vanity",
        size: "S",
    },
    137000502: { themeID: "99005", colors: ["6008", "6010", "6006"], name: "Childlike Vanity", size: "S" },
    137000600: { themeID: "99006", colors: ["6009", "6007"], name: "Vintage Vanity", size: "M" },
    137000601: { themeID: "99006", colors: ["6007", "6006"], name: "Vintage Vanity", size: "M" },
    137000602: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage Vanity", size: "M" },
    137000603: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage Vanity", size: "M" },
    137000700: { themeID: "99011", colors: ["6005", "6006"], name: "Dolly Vanity", size: "M" },
    137000701: { themeID: "99011", colors: ["6011", "6001"], name: "Dolly Vanity", size: "M" },
    137000702: { themeID: "99011", colors: ["6008", "6001"], name: "Dolly Vanity", size: "M" },
    137000703: { themeID: "99011", colors: ["6010", "6011"], name: "Dolly Vanity", size: "M" },
    137200100: { themeID: "99007", colors: ["6009", "6008", "6005"], name: "Country Vanity", size: "M" },
    137200101: { themeID: "99007", colors: ["6011", "6010", "6008"], name: "Country Vanity", size: "M" },
    137200102: { themeID: "99007", colors: ["6007", "6009", "6006"], name: "Country Vanity", size: "M" },
    137200103: { themeID: "99007", colors: ["6008", "6007", "6006"], name: "Country Vanity", size: "M" },
    137200104: { themeID: "99007", colors: ["6010", "6011"], name: "Country Vanity", size: "M" },
    137300100: {
        themeID: "99009",
        colors: ["6006", "6009", "6001", "6007"],
        name: "Antique Vanity",
        size: "XL",
    },
    137300101: {
        themeID: "99009",
        colors: ["6001", "6007", "6011", "6009"],
        name: "Antique Vanity",
        size: "XL",
    },
    137300102: {
        themeID: "99009",
        colors: ["6011", "6003", "6005", "6004"],
        name: "Antique Vanity",
        size: "XL",
    },
    137700100: { themeID: "99015", colors: ["6009", "6007", "6011"], name: "Refined vanity", size: "L" },
    137700101: { themeID: "99015", colors: ["6007", "6011", "6009"], name: "Refined vanity", size: "L" },
    137700102: {
        themeID: "99015",
        colors: ["6008", "6009", "6011", "6007"],
        name: "Refined vanity",
        size: "L",
    },
    137700103: {
        themeID: "99015",
        colors: ["6011", "6003", "6010", "6007"],
        name: "Refined vanity",
        size: "L",
    },
    140200100: { themeID: "99007", colors: ["6009", "6005"], name: "Country spice shelf", size: "S" },
    140200101: { themeID: "99007", colors: ["6010", "6011"], name: "Country spice shelf", size: "S" },
    140200102: { themeID: "99007", colors: ["6007", "6005", "6006"], name: "Country spice shelf", size: "S" },
    140200103: { themeID: "99007", colors: ["6004", "6010", "6008"], name: "Country spice shelf", size: "S" },
    140200200: { themeID: "99008", colors: ["6005", "6011", "6008"], name: "Boho spice shelf", size: "S" },
    140200201: { themeID: "99008", colors: ["6006", "6009", "6007"], name: "Boho spice shelf", size: "S" },
    140200202: { themeID: "99008", colors: ["6011", "6010", "6008"], name: "Boho spice shelf", size: "S" },
    141000100: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie Kitchen shelf", size: "M" },
    141000101: {
        themeID: "99003",
        colors: ["6009", "6005", "6007"],
        name: "Girlie Kitchen shelf",
        size: "M",
    },
    141000102: {
        themeID: "99003",
        colors: ["6010", "6011", "6004"],
        name: "Girlie Kitchen shelf",
        size: "M",
    },
    141000103: {
        themeID: "99003",
        colors: ["6002", "6006", "6008"],
        name: "Girlie Kitchen shelf",
        size: "M",
    },
    141000200: { themeID: "99006", colors: ["6009"], name: "Vintage Kitchen Shelf", size: "S" },
    141000201: {
        themeID: "99006",
        colors: ["6007", "6001", "6009"],
        name: "Vintage Kitchen Shelf",
        size: "S",
    },
    141000202: { themeID: "99006", colors: ["6001", "6008"], name: "Vintage Kitchen Shelf", size: "S" },
    141000203: { themeID: "99006", colors: ["6003", "6001"], name: "Vintage Kitchen Shelf", size: "S" },
    141000204: { themeID: "99006", colors: ["6009", "6001"], name: "Vintage Kitchen Shelf", size: "S" },
    141200100: { themeID: "99007", colors: ["6005", "6001", "6007"], name: "Country spice rack", size: "S" },
    141200101: { themeID: "99007", colors: ["6010", "6011", "6001"], name: "Country spice rack", size: "S" },
    141200102: { themeID: "99007", colors: ["6007", "6005", "6001"], name: "Country spice rack", size: "S" },
    141200103: { themeID: "99007", colors: ["6004", "6010", "6008"], name: "Country spice rack", size: "S" },
    141300200: {
        themeID: "99009",
        colors: ["6009", "6003", "6007"],
        name: "Antique kitchen cabinet",
        size: "M",
    },
    141300201: {
        themeID: "99009",
        colors: ["6001", "6006", "6007"],
        name: "Antique kitchen cabinet",
        size: "M",
    },
    141300202: {
        themeID: "99009",
        colors: ["6011", "6007", "6001"],
        name: "Antique kitchen cabinet",
        size: "M",
    },
    141500100: { themeID: "99011", colors: ["6006", "6009"], name: "Dolly Kitchen shelf", size: "S" },
    141500101: { themeID: "99011", colors: ["6011", "6009"], name: "Dolly Kitchen shelf", size: "S" },
    141500102: { themeID: "99011", colors: ["6008", "6009"], name: "Dolly Kitchen shelf", size: "S" },
    141500103: { themeID: "99011", colors: ["6009", "6010"], name: "Dolly Kitchen shelf", size: "S" },
    141700100: {
        themeID: "99011",
        colors: ["6009", "6005", "6006"],
        name: "Dolly Kitchen wall shelf",
        size: "M",
    },
    141700101: {
        themeID: "99011",
        colors: ["6003", "6011", "6005"],
        name: "Dolly Kitchen wall shelf",
        size: "M",
    },
    141700102: {
        themeID: "99011",
        colors: ["6008", "6009", "6005"],
        name: "Dolly Kitchen wall shelf",
        size: "M",
    },
    141700103: {
        themeID: "99011",
        colors: ["6010", "6004", "6011"],
        name: "Dolly Kitchen wall shelf",
        size: "M",
    },
    142100100: {
        themeID: "99001",
        colors: ["6004", "6002", "6001", "6008", "6005"],
        name: "Inox pot",
        size: "S",
    },
    142100101: {
        themeID: "99001",
        colors: ["6008", "6002", "6004", "6001", "6005"],
        name: "Inox pot",
        size: "S",
    },
    142100102: {
        themeID: "99001",
        colors: ["6011", "6003", "6002", "6001", "6005", "6008"],
        name: "Inox pot",
        size: "S",
    },
    142100103: { themeID: "99001", colors: ["6002", "6001", "6008", "6005"], name: "Inox pot", size: "S" },
    142100104: {
        themeID: "99001",
        colors: ["6007", "6001", "6002", "6005", "6008"],
        name: "Inox pot",
        size: "S",
    },
    142100200: { themeID: "99001", colors: ["6004", "6002", "6001", "6005"], name: "Inox pan", size: "XS" },
    142100201: { themeID: "99001", colors: ["6008", "6002", "6005", "6001"], name: "Inox pan", size: "XS" },
    142100202: {
        themeID: "99001",
        colors: ["6011", "6003", "6002", "6005", "6001"],
        name: "Inox pan",
        size: "XS",
    },
    142100203: { themeID: "99001", colors: ["6002", "6001", "6005"], name: "Inox pan", size: "XS" },
    142100204: { themeID: "99001", colors: ["6007", "6001", "6002", "6005"], name: "Inox pan", size: "XS" },
    142100300: {
        themeID: "99002",
        colors: ["6006", "6007", "6003", "6009"],
        name: "Basic spices",
        size: "XS",
    },
    142100301: {
        themeID: "99002",
        colors: ["6001", "6005", "6008", "6002"],
        name: "Basic spices",
        size: "XS",
    },
    142100302: {
        themeID: "99002",
        colors: ["6002", "6010", "6008", "6003"],
        name: "Basic spices",
        size: "XS",
    },
    142100303: { themeID: "99002", colors: ["6007", "6011", "6006"], name: "Basic spices", size: "XS" },
    142100400: { themeID: "99002", colors: ["6001"], name: "Simple dishes", size: "S" },
    142100401: { themeID: "99002", colors: ["6007", "6006", "6001"], name: "Simple dishes", size: "S" },
    142100402: { themeID: "99002", colors: ["6011", "6001", "6010"], name: "Simple dishes", size: "S" },
    142100403: { themeID: "99002", colors: ["6006", "6008"], name: "Simple dishes", size: "S" },
    142100500: { themeID: "99002", colors: ["6006", "6007", "6001"], name: "Simple bowls", size: "XS" },
    142100501: { themeID: "99002", colors: ["6011", "6001", "6010"], name: "Simple bowls", size: "XS" },
    142100502: { themeID: "99002", colors: ["6008", "6009", "6006"], name: "Simple bowls", size: "XS" },
    142100503: { themeID: "99002", colors: ["6010", "6004", "6002"], name: "Simple bowls", size: "XS" },
    142100600: { themeID: "99003", colors: ["6011", "6001"], name: "Girlie chopping board", size: "S" },
    142100601: { themeID: "99003", colors: ["6007", "6006"], name: "Girlie chopping board", size: "S" },
    142100602: {
        themeID: "99003",
        colors: ["6010", "6001", "6008"],
        name: "Girlie chopping board",
        size: "S",
    },
    142100603: { themeID: "99003", colors: ["6008", "6001"], name: "Girlie chopping board", size: "S" },
    142200100: {
        themeID: "99007",
        colors: ["6009", "6011", "6007"],
        name: "Country seasoning set",
        size: "XS",
    },
    142200101: {
        themeID: "99007",
        colors: ["6003", "6010", "6007"],
        name: "Country seasoning set",
        size: "XS",
    },
    142200102: {
        themeID: "99007",
        colors: ["6007", "6009", "6006"],
        name: "Country seasoning set",
        size: "XS",
    },
    142200200: { themeID: "99007", colors: ["6001", "6005"], name: "Country cutting board", size: "XS" },
    142200201: { themeID: "99007", colors: ["6005", "6001"], name: "Country cutting board", size: "XS" },
    142200900: { themeID: "99008", colors: ["6001"], name: "Boho little cup", size: "XS" },
    142200901: { themeID: "99008", colors: ["6003"], name: "Boho little cup", size: "XS" },
    142300100: { themeID: "99009", colors: ["6009", "6006", "6008"], name: "Classy spices", size: "S" },
    142300101: { themeID: "99009", colors: ["6001", "6006", "6008"], name: "Classy spices", size: "S" },
    142300102: { themeID: "99009", colors: ["6011", "6006"], name: "Classy spices", size: "S" },
    142400100: { themeID: "99006", colors: ["6005"], name: "Vintage spice ", size: "XS" },
    142400101: { themeID: "99006", colors: ["6007"], name: "Vintage spice ", size: "XS" },
    142400102: { themeID: "99006", colors: ["6008"], name: "Vintage spice ", size: "XS" },
    142400200: { themeID: "99006", colors: ["6005"], name: "Vintage pickles", size: "XS" },
    142400201: { themeID: "99006", colors: ["6008"], name: "Vintage pickles", size: "XS" },
    142400202: { themeID: "99006", colors: ["6009"], name: "Vintage pickles", size: "XS" },
    142700100: { themeID: "99011", colors: ["6006", "6001"], name: "Cute little cup", size: "XS" },
    142700101: { themeID: "99011", colors: ["6001", "6006"], name: "Cute little cup", size: "XS" },
    142700102: { themeID: "99011", colors: ["6009", "6006", "6001"], name: "Cute little cup", size: "XS" },
    142700200: { themeID: "99011", colors: ["6011", "6001"], name: "Little bunny cup", size: "XS" },
    142700201: { themeID: "99011", colors: ["6001", "6011"], name: "Little bunny cup", size: "XS" },
    142700300: { themeID: "99011", colors: ["6006", "6007", "6011"], name: "Dolly spoon holder", size: "XS" },
    142700301: { themeID: "99011", colors: ["6011", "6006", "6007"], name: "Dolly spoon holder", size: "XS" },
    142700302: { themeID: "99011", colors: ["6008", "6011", "6006"], name: "Dolly spoon holder", size: "XS" },
    142700303: { themeID: "99011", colors: ["6010", "6008"], name: "Dolly spoon holder", size: "XS" },
    143000100: { themeID: "99002", colors: ["6005", "6006"], name: "Pizza Board", size: "S" },
    143000101: { themeID: "99002", colors: ["6002", "6006"], name: "Pizza Board", size: "S" },
    143000102: { themeID: "99002", colors: ["6011", "6001"], name: "Pizza Board", size: "S" },
    143000103: { themeID: "99002", colors: ["6001", "6006", "6005"], name: "Pizza Board", size: "S" },
    143000104: { themeID: "99002", colors: ["6007", "6006"], name: "Pizza Board", size: "S" },
    143100100: { themeID: "99001", colors: ["6002", "6004"], name: "Urban kitchen knife set", size: "S" },
    143100101: {
        themeID: "99001",
        colors: ["6008", "6002", "6004"],
        name: "Urban kitchen knife set",
        size: "S",
    },
    143100102: {
        themeID: "99001",
        colors: ["6011", "6002", "6004"],
        name: "Urban kitchen knife set",
        size: "S",
    },
    143100103: {
        themeID: "99001",
        colors: ["6004", "6002", "6001"],
        name: "Urban kitchen knife set",
        size: "S",
    },
    143100104: {
        themeID: "99001",
        colors: ["6007", "6004", "6006"],
        name: "Urban kitchen knife set",
        size: "S",
    },
    143200100: {
        themeID: "99007",
        colors: ["6005", "6006", "6007"],
        name: "Country Kitchen hook",
        size: "S",
    },
    143200101: {
        themeID: "99007",
        colors: ["6011", "6010", "6004"],
        name: "Country Kitchen hook",
        size: "S",
    },
    143200102: {
        themeID: "99007",
        colors: ["6007", "6006", "6004"],
        name: "Country Kitchen hook",
        size: "S",
    },
    143200103: { themeID: "99007", colors: ["6010", "6008"], name: "Country Kitchen hook", size: "S" },
    143200200: {
        themeID: "99008",
        colors: ["6009", "6006", "6002", "6008"],
        name: "Boho Kitchen hook",
        size: "S",
    },
    143200201: {
        themeID: "99008",
        colors: ["6001", "6006", "6002", "6008"],
        name: "Boho Kitchen hook",
        size: "S",
    },
    143200202: {
        themeID: "99008",
        colors: ["6004", "6011", "6002", "6006"],
        name: "Boho Kitchen hook",
        size: "S",
    },
    143300100: {
        themeID: "99009",
        colors: ["6009", "6004", "6007"],
        name: "EAT wall hanging board",
        size: "S",
    },
    143300101: {
        themeID: "99009",
        colors: ["6001", "6008", "6009"],
        name: "EAT wall hanging board",
        size: "S",
    },
    143300102: {
        themeID: "99009",
        colors: ["6011", "6008", "6001"],
        name: "EAT wall hanging board",
        size: "S",
    },
    143700100: { themeID: "99011", colors: ["6006", "6007"], name: "Dolly Kitchen wall rack", size: "S" },
    143700101: { themeID: "99011", colors: ["6011", "6008"], name: "Dolly Kitchen wall rack", size: "S" },
    143700102: { themeID: "99011", colors: ["6008", "6005"], name: "Dolly Kitchen wall rack", size: "S" },
    143700103: { themeID: "99011", colors: ["6010", "6011"], name: "Dolly Kitchen wall rack", size: "S" },
    144000100: { themeID: "99003", colors: ["6003", "6001"], name: "Girlie Tea Table", size: "S" },
    144000101: { themeID: "99003", colors: ["6010", "6002", "6004"], name: "Girlie Tea Table", size: "S" },
    144000102: { themeID: "99003", colors: ["6009", "6007"], name: "Girlie Tea Table", size: "S" },
    144000103: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie Tea Table", size: "S" },
    144000200: { themeID: "99004", colors: ["6006", "6009"], name: "Rattan Glass Table", size: "S" },
    144000201: { themeID: "99004", colors: ["6008", "6004"], name: "Rattan Glass Table", size: "S" },
    144000202: { themeID: "99004", colors: ["6011", "6003"], name: "Rattan Glass Table", size: "S" },
    144000203: { themeID: "99004", colors: ["6002", "6004"], name: "Rattan Glass Table", size: "S" },
    144000204: { themeID: "99004", colors: ["6010", "6004"], name: "Rattan Glass Table", size: "S" },
    144000300: { themeID: "99005", colors: ["6005", "6007", "6001"], name: "Comfy Kotatsu", size: "M" },
    144000301: { themeID: "99005", colors: ["6011", "6001", "6010"], name: "Comfy Kotatsu", size: "M" },
    144000302: { themeID: "99005", colors: ["6010", "6008", "6001"], name: "Comfy Kotatsu", size: "M" },
    144000303: { themeID: "99005", colors: ["6008", "6001", "6004"], name: "Comfy Kotatsu", size: "M" },
    144000304: { themeID: "99005", colors: ["6007", "6009", "6001"], name: "Comfy Kotatsu", size: "M" },
    144000500: { themeID: "99004", colors: ["6001", "6009"], name: "Surfboard mini table", size: "S" },
    144000501: {
        themeID: "99004",
        colors: ["6011", "6001", "6009"],
        name: "Surfboard mini table",
        size: "S",
    },
    144000502: {
        themeID: "99004",
        colors: ["6008", "6001", "6009"],
        name: "Surfboard mini table",
        size: "S",
    },
    144000503: {
        themeID: "99004",
        colors: ["6007", "6009", "6005"],
        name: "Surfboard mini table",
        size: "S",
    },
    144000504: {
        themeID: "99004",
        colors: ["6010", "6011", "6009", "6001"],
        name: "Surfboard mini table",
        size: "S",
    },
    144000900: {
        themeID: "99005",
        colors: ["6009", "6005", "6010"],
        name: "Childlike Mini Table",
        size: "S",
    },
    144000901: { themeID: "99005", colors: ["6011", "6010"], name: "Childlike Mini Table", size: "S" },
    144000902: { themeID: "99005", colors: ["6010", "6008"], name: "Childlike Mini Table", size: "S" },
    144000903: { themeID: "99005", colors: ["6008", "6010"], name: "Childlike Mini Table", size: "S" },
    144000904: { themeID: "99005", colors: ["6007", "6009"], name: "Childlike Mini Table", size: "S" },
    144001000: { themeID: "99011", colors: ["6006", "6009"], name: "Dolly Tea Table", size: "M" },
    144001001: { themeID: "99011", colors: ["6011", "6009"], name: "Dolly Tea Table", size: "M" },
    144001002: { themeID: "99011", colors: ["6008", "6009"], name: "Dolly Tea Table", size: "M" },
    144001003: { themeID: "99011", colors: ["6010", "6011"], name: "Dolly Tea Table", size: "M" },
    144100100: { themeID: "99001", colors: ["6001", "6008", "6005"], name: "Urban sofa table", size: "M" },
    144100101: { themeID: "99001", colors: ["6008", "6007", "6005"], name: "Urban sofa table", size: "M" },
    144100102: { themeID: "99001", colors: ["6011", "6007", "6005"], name: "Urban sofa table", size: "M" },
    144100103: { themeID: "99001", colors: ["6002", "6010", "6005"], name: "Urban sofa table", size: "M" },
    144100104: { themeID: "99001", colors: ["6007", "6008", "6005"], name: "Urban sofa table", size: "M" },
    144100200: { themeID: "99002", colors: ["6006", "6007", "6009"], name: "Rustic low table", size: "M" },
    144100201: { themeID: "99002", colors: ["6001", "6007", "6004"], name: "Rustic low table", size: "M" },
    144100202: { themeID: "99002", colors: ["6011", "6001", "6007"], name: "Rustic low table", size: "M" },
    144100203: { themeID: "99002", colors: ["6008", "6001", "6007"], name: "Rustic low table", size: "M" },
    144100204: {
        themeID: "99002",
        colors: ["6002", "6001", "6004", "6008"],
        name: "Rustic low table",
        size: "M",
    },
    144100205: { themeID: "99002", colors: ["6007", "6001", "6006"], name: "Rustic low table", size: "M" },
    144100300: {
        themeID: "99017",
        colors: ["6006", "6009"],
        name: "Tree log table",
        size: "S",
        xmas: true,
    },
    144100301: { themeID: "99017", colors: ["6009"], name: "Tree log table", size: "S", xmas: true },
    144100302: { themeID: "99017", colors: ["6004"], name: "Tree log table", size: "S", xmas: true },
    144200100: { themeID: "99007", colors: ["6009", "6005", "6004"], name: "Country tea table", size: "M" },
    144200101: { themeID: "99007", colors: ["6004", "6001", "6010"], name: "Country tea table", size: "M" },
    144200102: { themeID: "99007", colors: ["6006", "6001", "6005"], name: "Country tea table", size: "M" },
    144200200: { themeID: "99008", colors: ["6001", "6005", "6009"], name: "Boho tea table", size: "M" },
    144200201: { themeID: "99008", colors: ["6005", "6009", "6006"], name: "Boho tea table", size: "M" },
    144200202: { themeID: "99008", colors: ["6002", "6004", "6009"], name: "Boho tea table", size: "M" },
    144300200: { themeID: "99009", colors: ["6009", "6003", "6005"], name: "Antique tea table", size: "S" },
    144300201: { themeID: "99009", colors: ["6002", "6007", "6001"], name: "Antique tea table", size: "S" },
    144300202: { themeID: "99009", colors: ["6011", "6001"], name: "Antique tea table", size: "S" },
    144400100: { themeID: "99006", colors: ["6009", "6007"], name: "Vintage tea table", size: "S" },
    144400101: { themeID: "99006", colors: ["6008", "6006", "6009"], name: "Vintage tea table", size: "S" },
    144400102: { themeID: "99006", colors: ["6003", "6006"], name: "Vintage tea table", size: "S" },
    144400103: { themeID: "99006", colors: ["6002", "6004"], name: "Vintage tea table", size: "S" },
    144400200: { themeID: "99019", colors: ["6002", "6004"], name: "Stonehouse tea table", size: "M" },
    144400201: { themeID: "99019", colors: ["6003", "6009"], name: "Stonehouse tea table", size: "M" },
    144400202: { themeID: "99019", colors: ["6009", "6002"], name: "Stonehouse tea table", size: "M" },
    144400300: { themeID: "99019", colors: ["6004"], name: "Stonehouse Igo table", size: "S" },
    144400301: {
        themeID: "99019",
        colors: ["6003", "6004", "6011"],
        name: "Stonehouse Igo table",
        size: "S",
    },
    144400302: { themeID: "99019", colors: ["6008"], name: "Stonehouse Igo table", size: "S" },
    144600300: { themeID: "99015", colors: ["6009"], name: "Refined tea table", size: "S" },
    144600301: { themeID: "99015", colors: ["6007", "6004"], name: "Refined tea table", size: "S" },
    144600302: { themeID: "99015", colors: ["6009", "6008"], name: "Refined tea table", size: "S" },
    144600303: { themeID: "99015", colors: ["6003", "6009"], name: "Refined tea table", size: "S" },
    144800100: { themeID: "99015", colors: ["6009"], name: "Natural sofa table", size: "M" },
    144800101: { themeID: "99015", colors: ["6007", "6009"], name: "Natural sofa table", size: "M" },
    144800102: { themeID: "99015", colors: ["6003", "6004"], name: "Natural sofa table", size: "M" },
    144800103: { themeID: "99015", colors: ["6008", "6009"], name: "Natural sofa table", size: "M" },
    145000200: {
        themeID: "99001",
        colors: ["6001", "6009", "6006", "6004"],
        name: "Urban Lounge Sofa",
        size: "M",
    },
    145000201: {
        themeID: "99001",
        colors: ["6008", "6001", "6004", "6006", "6009"],
        name: "Urban Lounge Sofa",
        size: "M",
    },
    145000202: {
        themeID: "99001",
        colors: ["6011", "6001", "6005", "6009"],
        name: "Urban Lounge Sofa",
        size: "M",
    },
    145000203: {
        themeID: "99001",
        colors: ["6002", "6001", "6004", "6006", "6009"],
        name: "Urban Lounge Sofa",
        size: "M",
    },
    145000204: {
        themeID: "99001",
        colors: ["6007", "6001", "6004", "6006"],
        name: "Urban Lounge Sofa",
        size: "M",
    },
    145000300: { themeID: "99003", colors: ["6003", "6001", "6011"], name: "Girlie Sofa", size: "L" },
    145000301: { themeID: "99003", colors: ["6005", "6007", "6009"], name: "Girlie Sofa", size: "L" },
    145000302: { themeID: "99003", colors: ["6010", "6001", "6004"], name: "Girlie Sofa", size: "L" },
    145000303: { themeID: "99003", colors: ["6008", "6001", "6005", "6002"], name: "Girlie Sofa", size: "L" },
    145100100: {
        themeID: "99002",
        colors: ["6006", "6007", "6009", "6001", "6011"],
        name: "Rustic sofa",
        size: "M",
    },
    145100101: { themeID: "99002", colors: ["6001", "6004", "6011", "6008"], name: "Rustic sofa", size: "M" },
    145100102: { themeID: "99002", colors: ["6011", "6007", "6001"], name: "Rustic sofa", size: "M" },
    145100103: { themeID: "99002", colors: ["6008", "6001", "6011"], name: "Rustic sofa", size: "M" },
    145100104: {
        themeID: "99002",
        colors: ["6002", "6004", "6009", "6001", "6008"],
        name: "Rustic sofa",
        size: "M",
    },
    145100105: { themeID: "99002", colors: ["6007", "6001", "6005", "6011"], name: "Rustic sofa", size: "M" },
    145100200: {
        themeID: "99017",
        colors: ["6003", "6001", "6007"],
        name: "Xmas sofa",
        size: "L",
        xmas: true,
    },
    145100201: {
        themeID: "99017",
        colors: ["6007", "6001", "6005"],
        name: "Xmas sofa",
        size: "L",
        xmas: true,
    },
    145100202: {
        themeID: "99017",
        colors: ["6008", "6011", "6001"],
        name: "Xmas sofa",
        size: "L",
        xmas: true,
    },
    145200100: { themeID: "99007", colors: ["6005", "6006"], name: "Country sofa", size: "M" },
    145200101: { themeID: "99007", colors: ["6004", "6006"], name: "Country sofa", size: "M" },
    145200102: { themeID: "99007", colors: ["6006", "6004", "6005"], name: "Country sofa", size: "M" },
    145200200: { themeID: "99008", colors: ["6003", "6006", "6005"], name: "Boho sofa", size: "M" },
    145200201: { themeID: "99008", colors: ["6005", "6009", "6006"], name: "Boho sofa", size: "M" },
    145200202: { themeID: "99008", colors: ["6011", "6001", "6004"], name: "Boho sofa", size: "M" },
    145300100: { themeID: "99009", colors: ["6006", "6009"], name: "Antique sofa", size: "M" },
    145300101: { themeID: "99009", colors: ["6001", "6002"], name: "Antique sofa", size: "M" },
    145300102: { themeID: "99009", colors: ["6011", "6001"], name: "Antique sofa", size: "M" },
    145400100: { themeID: "99006", colors: ["6007", "6001", "6009"], name: "Vintage sofa", size: "M" },
    145400101: { themeID: "99006", colors: ["6008", "6009", "6005"], name: "Vintage sofa", size: "M" },
    145400102: { themeID: "99006", colors: ["6003", "6001", "6011"], name: "Vintage sofa", size: "M" },
    145400103: { themeID: "99006", colors: ["6009", "6001"], name: "Vintage sofa", size: "M" },
    145500100: { themeID: "99011", colors: ["6005", "6009", "6006"], name: "Dolly sofa", size: "M" },
    145500101: { themeID: "99011", colors: ["6003", "6011", "6004"], name: "Dolly sofa", size: "M" },
    145500102: { themeID: "99011", colors: ["6008", "6006", "6005"], name: "Dolly sofa", size: "M" },
    145500103: { themeID: "99011", colors: ["6010", "6011", "6001"], name: "Dolly sofa", size: "M" },
    145600300: {
        themeID: "99015",
        colors: ["6009", "6007", "6001"],
        name: "Refined Natural Sofa",
        size: "M",
    },
    145600301: {
        themeID: "99015",
        colors: ["6007", "6001", "6004"],
        name: "Refined Natural Sofa",
        size: "M",
    },
    145600302: {
        themeID: "99015",
        colors: ["6008", "6009", "6001"],
        name: "Refined Natural Sofa",
        size: "M",
    },
    145600303: {
        themeID: "99015",
        colors: ["6003", "6001", "6011", "6004"],
        name: "Refined Natural Sofa",
        size: "M",
    },
    146000300: {
        themeID: "99003",
        colors: ["6011", "6003", "6001", "6005"],
        name: "Girlie Single Bed",
        size: "XL",
    },
    146000301: {
        themeID: "99003",
        colors: ["6005", "6001", "6009", "6004", "6007"],
        name: "Girlie Single Bed",
        size: "XL",
    },
    146000302: {
        themeID: "99003",
        colors: ["6010", "6011", "6004", "6001"],
        name: "Girlie Single Bed",
        size: "XL",
    },
    146000303: {
        themeID: "99003",
        colors: ["6008", "6001", "6002", "6005", "6004"],
        name: "Girlie Single Bed",
        size: "XL",
    },
    146000700: { themeID: "99006", colors: ["6009", "6001", "6007"], name: "Vintage Bed", size: "XL" },
    146000701: { themeID: "99006", colors: ["6008", "6009", "6001"], name: "Vintage Bed", size: "XL" },
    146000702: {
        themeID: "99006",
        colors: ["6009", "6002", "6001", "6003"],
        name: "Vintage Bed",
        size: "XL",
    },
    146000703: { themeID: "99006", colors: ["6003", "6011", "6001"], name: "Vintage Bed", size: "XL" },
    146000800: { themeID: "99011", colors: ["6006", "6001", "6005"], name: "Dolly Bed", size: "L" },
    146000801: { themeID: "99011", colors: ["6011", "6001", "6003"], name: "Dolly Bed", size: "L" },
    146000802: { themeID: "99011", colors: ["6008", "6001", "6010"], name: "Dolly Bed", size: "L" },
    146000803: { themeID: "99011", colors: ["6010", "6001", "6011"], name: "Dolly Bed", size: "L" },
    146100100: {
        themeID: "99001",
        colors: ["6007", "6001", "6006", "6009", "6004"],
        name: "Urban single bed",
        size: "L",
    },
    146100101: {
        themeID: "99001",
        colors: ["6008", "6001", "6004", "6006", "6009"],
        name: "Urban single bed",
        size: "L",
    },
    146100102: {
        themeID: "99001",
        colors: ["6011", "6001", "6005", "6009"],
        name: "Urban single bed",
        size: "L",
    },
    146100103: {
        themeID: "99001",
        colors: ["6002", "6004", "6001", "6009", "6006"],
        name: "Urban single bed",
        size: "L",
    },
    146100200: {
        themeID: "99002",
        colors: ["6009", "6006", "6007", "6001"],
        name: "Rustic single bed",
        size: "L",
    },
    146100201: { themeID: "99002", colors: ["6011", "6001"], name: "Rustic single bed", size: "L" },
    146100202: { themeID: "99002", colors: ["6008", "6001", "6004"], name: "Rustic single bed", size: "L" },
    146100203: { themeID: "99002", colors: ["6004", "6001", "6002"], name: "Rustic single bed", size: "L" },
    146100204: { themeID: "99002", colors: ["6001", "6007", "6005"], name: "Rustic single bed", size: "L" },
    146100300: {
        themeID: "99017",
        colors: ["6009", "6003", "6006"],
        name: "Yule log cake bed",
        size: "XL",
        xmas: true,
    },
    146100301: {
        themeID: "99017",
        colors: ["6007", "6002", "6009"],
        name: "Yule log cake bed",
        size: "XL",
        xmas: true,
    },
    146100302: {
        themeID: "99017",
        colors: ["6004", "6008", "6011"],
        name: "Yule log cake bed",
        size: "XL",
        xmas: true,
    },
    146200100: { themeID: "99007", colors: ["6005", "6009", "6006"], name: "Country Single Bed", size: "L" },
    146200101: { themeID: "99007", colors: ["6011", "6001", "6010"], name: "Country Single Bed", size: "L" },
    146200102: { themeID: "99007", colors: ["6006", "6007", "6005"], name: "Country Single Bed", size: "L" },
    146200200: {
        themeID: "99008",
        colors: ["6005", "6003", "6001", "6006", "6009"],
        name: "Boho Single Bed",
        size: "XL",
    },
    146200201: {
        themeID: "99008",
        colors: ["6006", "6005", "6007", "6003", "6001"],
        name: "Boho Single Bed",
        size: "XL",
    },
    146200202: {
        themeID: "99008",
        colors: ["6011", "6010", "6004", "6001", "6009"],
        name: "Boho Single Bed",
        size: "XL",
    },
    146300100: {
        themeID: "99009",
        colors: ["6001", "6003", "6005", "6009"],
        name: "Antique single bed",
        size: "XL",
    },
    146300101: {
        themeID: "99009",
        colors: ["6002", "6003", "6007", "6001"],
        name: "Antique single bed",
        size: "XL",
    },
    146300102: {
        themeID: "99009",
        colors: ["6011", "6001", "6010", "6004"],
        name: "Antique single bed",
        size: "XL",
    },
    146400100: { themeID: "99019", colors: ["6009", "6004", "6001", "6007"], name: "Stone bed", size: "XL" },
    146400101: { themeID: "99019", colors: ["6003", "6001", "6004", "6007"], name: "Stone bed", size: "XL" },
    146400102: { themeID: "99019", colors: ["6008", "6001", "6004", "6009"], name: "Stone bed", size: "XL" },
    146600300: { themeID: "99015", colors: ["6009", "6001", "6007"], name: "Refined single bed", size: "L" },
    146600301: { themeID: "99015", colors: ["6007", "6001", "6004"], name: "Refined single bed", size: "L" },
    146600302: { themeID: "99015", colors: ["6008", "6009", "6001"], name: "Refined single bed", size: "L" },
    146600303: {
        themeID: "99015",
        colors: ["6003", "6011", "6004", "6001"],
        name: "Refined single bed",
        size: "L",
    },
    147000600: {
        themeID: "99005",
        colors: ["6006", "6001", "6005", "6007", "6003"],
        name: "Colory Cradle",
        size: "L",
    },
    147000601: {
        themeID: "99005",
        colors: ["6003", "6001", "6010", "6008"],
        name: "Colory Cradle",
        size: "L",
    },
    147000602: {
        themeID: "99005",
        colors: ["6008", "6001", "6010", "6011"],
        name: "Colory Cradle",
        size: "L",
    },
    147100100: {
        themeID: "99001",
        colors: ["6007", "6006", "6001", "6009", "6004", "6003"],
        name: "Urban baby crib",
        size: "M",
    },
    147100101: {
        themeID: "99001",
        colors: ["6008", "6001", "6006", "6003", "6004"],
        name: "Urban baby crib",
        size: "M",
    },
    147100102: {
        themeID: "99001",
        colors: ["6011", "6003", "6001", "6006", "6004"],
        name: "Urban baby crib",
        size: "M",
    },
    147100103: {
        themeID: "99001",
        colors: ["6004", "6001", "6002", "6006", "6003"],
        name: "Urban baby crib",
        size: "M",
    },
    147100200: {
        themeID: "99002",
        colors: ["6006", "6007", "6009", "6005"],
        name: "Rustic cradle",
        size: "M",
    },
    147100201: { themeID: "99002", colors: ["6011", "6001", "6003"], name: "Rustic cradle", size: "M" },
    147100202: { themeID: "99002", colors: ["6008", "6001", "6005"], name: "Rustic cradle", size: "M" },
    147100203: { themeID: "99002", colors: ["6004", "6002", "6001"], name: "Rustic cradle", size: "M" },
    147100204: { themeID: "99002", colors: ["6007", "6001", "6006"], name: "Rustic cradle", size: "M" },
    147100400: { themeID: "99003", colors: ["6003", "6011", "6001"], name: "Lovely crib", size: "L" },
    147100401: { themeID: "99003", colors: ["6005", "6009", "6006"], name: "Lovely crib", size: "L" },
    147100402: { themeID: "99003", colors: ["6010", "6001", "6002"], name: "Lovely crib", size: "L" },
    147100403: { themeID: "99003", colors: ["6008", "6004", "6001"], name: "Lovely crib", size: "L" },
    147200100: {
        themeID: "99007",
        colors: ["6009", "6005", "6001", "6006"],
        name: "Country Cradle",
        size: "M",
    },
    147200101: {
        themeID: "99007",
        colors: ["6010", "6011", "6001", "6006"],
        name: "Country Cradle",
        size: "M",
    },
    147200102: { themeID: "99007", colors: ["6007", "6001", "6006"], name: "Country Cradle", size: "M" },
    147400100: { themeID: "99006", colors: ["6009", "6001"], name: "Vintage cradle", size: "L" },
    147400101: { themeID: "99006", colors: ["6007", "6009", "6001"], name: "Vintage cradle", size: "L" },
    147400102: { themeID: "99006", colors: ["6008", "6001", "6009"], name: "Vintage cradle", size: "L" },
    147400103: { themeID: "99006", colors: ["6003", "6009", "6001"], name: "Vintage cradle", size: "L" },
    147400104: {
        themeID: "99006",
        colors: ["6002", "6009", "6001", "6004"],
        name: "Vintage cradle",
        size: "L",
    },
    147700100: {
        themeID: "99015",
        colors: ["6009", "6007", "6006", "6001"],
        name: "Refined cradle",
        size: "M",
    },
    147700101: {
        themeID: "99015",
        colors: ["6007", "6009", "6006", "6001"],
        name: "Refined cradle",
        size: "M",
    },
    147700102: {
        themeID: "99015",
        colors: ["6008", "6009", "6011", "6001"],
        name: "Refined cradle",
        size: "M",
    },
    147700103: {
        themeID: "99015",
        colors: ["6003", "6011", "6007", "6004", "6001"],
        name: "Refined cradle",
        size: "M",
    },
    147700200: { themeID: "99011", colors: ["6006", "6007", "6011"], name: "Dolly baby nest", size: "L" },
    147700201: { themeID: "99011", colors: ["6011", "6010", "6005"], name: "Dolly baby nest", size: "L" },
    147700202: {
        themeID: "99011",
        colors: ["6008", "6005", "6011", "6001"],
        name: "Dolly baby nest",
        size: "L",
    },
    147700203: { themeID: "99011", colors: ["6010", "6008", "6003"], name: "Dolly baby nest", size: "L" },
    148000800: {
        themeID: "99005",
        colors: ["6009", "6005", "6010"],
        name: "Kiddy Bedside cabinet",
        size: "S",
    },
    148000801: { themeID: "99005", colors: ["6011", "6010"], name: "Kiddy Bedside cabinet", size: "S" },
    148000802: { themeID: "99005", colors: ["6010", "6008"], name: "Kiddy Bedside cabinet", size: "S" },
    148000803: { themeID: "99005", colors: ["6008", "6010"], name: "Kiddy Bedside cabinet", size: "S" },
    148000804: { themeID: "99005", colors: ["6007", "6009"], name: "Kiddy Bedside cabinet", size: "S" },
    148100100: { themeID: "99001", colors: ["6001"], name: "Urban bedside shelf", size: "S" },
    148100101: { themeID: "99001", colors: ["6008"], name: "Urban bedside shelf", size: "S" },
    148100102: { themeID: "99001", colors: ["6011"], name: "Urban bedside shelf", size: "S" },
    148100103: { themeID: "99001", colors: ["6002"], name: "Urban bedside shelf", size: "S" },
    148100104: { themeID: "99001", colors: ["6007"], name: "Urban bedside shelf", size: "S" },
    148100200: { themeID: "99002", colors: ["6009", "6006"], name: "Rustic bedside", size: "S" },
    148100201: { themeID: "99002", colors: ["6001", "6002"], name: "Rustic bedside", size: "S" },
    148100202: { themeID: "99002", colors: ["6011", "6001"], name: "Rustic bedside", size: "S" },
    148100203: { themeID: "99002", colors: ["6008", "6001"], name: "Rustic bedside", size: "S" },
    148100204: { themeID: "99002", colors: ["6002", "6001"], name: "Rustic bedside", size: "S" },
    148100205: { themeID: "99002", colors: ["6007", "6001"], name: "Rustic bedside", size: "S" },
    148100300: { themeID: "99003", colors: ["6011", "6003"], name: "Elegant bedside", size: "S" },
    148100301: { themeID: "99003", colors: ["6006", "6009"], name: "Elegant bedside", size: "S" },
    148100302: { themeID: "99003", colors: ["6004", "6010"], name: "Elegant bedside", size: "S" },
    148100303: { themeID: "99003", colors: ["6008", "6002"], name: "Elegant bedside", size: "S" },
    148200100: { themeID: "99007", colors: ["6009", "6005"], name: "Country Bedside", size: "S" },
    148200101: { themeID: "99007", colors: ["6010", "6011"], name: "Country Bedside", size: "S" },
    148200102: { themeID: "99007", colors: ["6006", "6007", "6005"], name: "Country Bedside", size: "S" },
    148200200: { themeID: "99008", colors: ["6005", "6009"], name: "Boho bedside", size: "S" },
    148200201: { themeID: "99008", colors: ["6006", "6007"], name: "Boho bedside", size: "S" },
    148200202: { themeID: "99008", colors: ["6011", "6010"], name: "Boho bedside", size: "S" },
    148300100: { themeID: "99009", colors: ["6005"], name: "Antique Bedside", size: "S" },
    148300101: { themeID: "99009", colors: ["6001"], name: "Antique Bedside", size: "S" },
    148300102: { themeID: "99009", colors: ["6011", "6001"], name: "Antique Bedside", size: "S" },
    148400100: { themeID: "99019", colors: ["6004", "6009", "6005"], name: "Stone bedside", size: "S" },
    148400101: { themeID: "99019", colors: ["6003", "6009", "6005"], name: "Stone bedside", size: "S" },
    148400102: { themeID: "99019", colors: ["6008", "6009", "6005"], name: "Stone bedside", size: "S" },
    148600100: { themeID: "99015", colors: ["6009", "6007"], name: "Refined bedside", size: "S" },
    148600101: { themeID: "99015", colors: ["6007", "6009"], name: "Refined bedside", size: "S" },
    148600102: { themeID: "99015", colors: ["6008", "6009"], name: "Refined bedside", size: "S" },
    148600103: { themeID: "99015", colors: ["6003", "6004"], name: "Refined bedside", size: "S" },
    149000100: { themeID: "99001", colors: ["6004", "6001", "6002"], name: "Urban Fridge", size: "L" },
    149000101: {
        themeID: "99001",
        colors: ["6001", "6002", "6007", "6004"],
        name: "Urban Fridge",
        size: "L",
    },
    149000102: { themeID: "99001", colors: ["6011", "6001", "6004"], name: "Urban Fridge", size: "L" },
    149000103: { themeID: "99001", colors: ["6008", "6001", "6004"], name: "Urban Fridge", size: "L" },
    149000104: { themeID: "99001", colors: ["6002", "6001", "6004"], name: "Urban Fridge", size: "L" },
    149000700: { themeID: "99002", colors: ["6005", "6009"], name: "Little Fridge", size: "M" },
    149000701: {
        themeID: "99002",
        colors: ["6001", "6005", "6007", "6008", "6003"],
        name: "Little Fridge",
        size: "M",
    },
    149000702: { themeID: "99002", colors: ["6011", "6001", "6004"], name: "Little Fridge", size: "M" },
    149000703: { themeID: "99002", colors: ["6008", "6001", "6004"], name: "Little Fridge", size: "M" },
    149000704: {
        themeID: "99002",
        colors: ["6002", "6006", "6007", "6005", "6011", "6004"],
        name: "Little Fridge",
        size: "M",
    },
    149000705: { themeID: "99002", colors: ["6007", "6009", "6001"], name: "Little Fridge", size: "M" },
    149001000: { themeID: "99005", colors: ["6005", "6001", "6007"], name: "Chubby Fridge", size: "M" },
    149001001: { themeID: "99005", colors: ["6011", "6001"], name: "Chubby Fridge", size: "M" },
    149001002: { themeID: "99005", colors: ["6010", "6001"], name: "Chubby Fridge", size: "M" },
    149001003: { themeID: "99005", colors: ["6008", "6001"], name: "Chubby Fridge", size: "M" },
    149001004: { themeID: "99005", colors: ["6007", "6001", "6005"], name: "Chubby Fridge", size: "M" },
    149001005: { themeID: "99005", colors: ["6003", "6001"], name: "Chubby Fridge", size: "M" },
    149001006: { themeID: "99005", colors: ["6009", "6001", "6003"], name: "Chubby Fridge", size: "M" },
    149001400: { themeID: "99006", colors: ["6009"], name: "Vintage Fridge", size: "XL" },
    149001401: { themeID: "99006", colors: ["6007", "6006"], name: "Vintage Fridge", size: "XL" },
    149001402: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage Fridge", size: "XL" },
    149001403: { themeID: "99006", colors: ["6009", "6003"], name: "Vintage Fridge", size: "XL" },
    149100100: { themeID: "99003", colors: ["6011", "6003", "6001"], name: "Girlie fridge", size: "M" },
    149100101: {
        themeID: "99003",
        colors: ["6006", "6005", "6001", "6009"],
        name: "Girlie fridge",
        size: "M",
    },
    149100102: { themeID: "99003", colors: ["6010", "6001", "6004"], name: "Girlie fridge", size: "M" },
    149100103: { themeID: "99003", colors: ["6008", "6001", "6002"], name: "Girlie fridge", size: "M" },
    149100104: { themeID: "99003", colors: ["6001", "6004", "6002"], name: "Girlie fridge", size: "M" },
    149200100: { themeID: "99007", colors: ["6009", "6001", "6005"], name: "Country Fridge", size: "M" },
    149200101: { themeID: "99007", colors: ["6011", "6001"], name: "Country Fridge", size: "M" },
    149200102: { themeID: "99007", colors: ["6007", "6006", "6009"], name: "Country Fridge", size: "M" },
    149200103: { themeID: "99007", colors: ["6008", "6001", "6010"], name: "Country Fridge", size: "M" },
    149300100: {
        themeID: "99009",
        colors: ["6009", "6006", "6001"],
        name: "Classic refrigerator",
        size: "L",
    },
    149300101: {
        themeID: "99009",
        colors: ["6001", "6002", "6008"],
        name: "Classic refrigerator",
        size: "L",
    },
    149300102: {
        themeID: "99009",
        colors: ["6011", "6001", "6004"],
        name: "Classic refrigerator",
        size: "L",
    },
    149500100: { themeID: "99011", colors: ["6006", "6009", "6004"], name: "Biscuit Fridge", size: "L" },
    149500101: {
        themeID: "99011",
        colors: ["6011", "6009", "6001", "6004"],
        name: "Biscuit Fridge",
        size: "L",
    },
    149500102: { themeID: "99011", colors: ["6008", "6009", "6004"], name: "Biscuit Fridge", size: "L" },
    149500103: { themeID: "99011", colors: ["6009", "6010", "6004"], name: "Biscuit Fridge", size: "L" },
    149700100: { themeID: "99015", colors: ["6009", "6007"], name: "Refined fridge", size: "M" },
    149700101: { themeID: "99015", colors: ["6007", "6009", "6006"], name: "Refined fridge", size: "M" },
    149700102: { themeID: "99015", colors: ["6008", "6009", "6004"], name: "Refined fridge", size: "M" },
    149700103: {
        themeID: "99015",
        colors: ["6003", "6011", "6004", "6001"],
        name: "Refined fridge",
        size: "M",
    },
    149700200: { themeID: "99013", colors: ["6006", "6005", "6001"], name: "Strawberry Fridge", size: "L" },
    149700201: { themeID: "99013", colors: ["6003", "6011", "6001"], name: "Strawberry Fridge", size: "L" },
    149700202: { themeID: "99013", colors: ["6008", "6001", "6005"], name: "Strawberry Fridge", size: "L" },
    149700203: { themeID: "99013", colors: ["6010", "6003", "6001"], name: "Strawberry Fridge", size: "L" },
    150000200: { themeID: "99001", colors: ["6001", "6002"], name: "Urban Microwave", size: "S" },
    150000201: { themeID: "99001", colors: ["6008", "6001", "6002"], name: "Urban Microwave", size: "S" },
    150000202: { themeID: "99001", colors: ["6002", "6001", "6004"], name: "Urban Microwave", size: "S" },
    150000203: { themeID: "99001", colors: ["6011", "6001", "6002"], name: "Urban Microwave", size: "S" },
    150000204: { themeID: "99001", colors: ["6007", "6001", "6002"], name: "Urban Microwave", size: "S" },
    150000300: { themeID: "99001", colors: ["6001", "6005", "6003"], name: "Urban Toaster", size: "XS" },
    150000301: { themeID: "99001", colors: ["6008", "6005"], name: "Urban Toaster", size: "XS" },
    150000302: { themeID: "99001", colors: ["6011", "6005"], name: "Urban Toaster", size: "XS" },
    150000303: {
        themeID: "99001",
        colors: ["6002", "6004", "6010", "6005"],
        name: "Urban Toaster",
        size: "XS",
    },
    150000304: { themeID: "99001", colors: ["6007", "6005"], name: "Urban Toaster", size: "XS" },
    150000600: { themeID: "99002", colors: ["6009", "6006", "6002"], name: "Rustic Stove", size: "M" },
    150000601: {
        themeID: "99002",
        colors: ["6011", "6001", "6009", "6002", "6007"],
        name: "Rustic Stove",
        size: "M",
    },
    150000602: {
        themeID: "99002",
        colors: ["6008", "6001", "6009", "6006", "6002"],
        name: "Rustic Stove",
        size: "M",
    },
    150000603: {
        themeID: "99002",
        colors: ["6002", "6001", "6004", "6003"],
        name: "Rustic Stove",
        size: "M",
    },
    150000604: {
        themeID: "99002",
        colors: ["6001", "6004", "6002", "6011"],
        name: "Rustic Stove",
        size: "M",
    },
    150000605: {
        themeID: "99002",
        colors: ["6007", "6001", "6009", "6006", "6002"],
        name: "Rustic Stove",
        size: "M",
    },
    150000800: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie stove", size: "M" },
    150000801: { themeID: "99003", colors: ["6009", "6005"], name: "Girlie stove", size: "M" },
    150000802: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie stove", size: "M" },
    150000803: { themeID: "99003", colors: ["6008", "6004", "6002"], name: "Girlie stove", size: "M" },
    150000900: {
        themeID: "99004",
        colors: ["6009", "6003", "6004", "6002"],
        name: "Tropical stove",
        size: "M",
    },
    150000901: { themeID: "99004", colors: ["6007", "6009", "6005"], name: "Tropical stove", size: "M" },
    150000902: { themeID: "99004", colors: ["6005", "6009", "6004"], name: "Tropical stove", size: "M" },
    150000903: { themeID: "99004", colors: ["6004", "6003", "6002"], name: "Tropical stove", size: "M" },
    150001100: { themeID: "99005", colors: ["6005", "6009", "6007"], name: "Childlike stove", size: "M" },
    150001101: { themeID: "99005", colors: ["6011", "6008", "6004"], name: "Childlike stove", size: "M" },
    150001102: { themeID: "99005", colors: ["6008", "6010", "6004"], name: "Childlike stove", size: "M" },
    150001103: { themeID: "99005", colors: ["6007", "6009", "6003"], name: "Childlike stove", size: "M" },
    150001200: { themeID: "99006", colors: ["6005", "6006", "6002"], name: "Vintage Microwave", size: "S" },
    150001201: { themeID: "99006", colors: ["6007", "6002"], name: "Vintage Microwave", size: "S" },
    150001202: { themeID: "99006", colors: ["6008", "6002"], name: "Vintage Microwave", size: "S" },
    150001203: { themeID: "99006", colors: ["6003", "6002"], name: "Vintage Microwave", size: "S" },
    150001204: { themeID: "99006", colors: ["6009", "6002"], name: "Vintage Microwave", size: "S" },
    150001300: { themeID: "99006", colors: ["6005"], name: "Vintage Toaster", size: "S" },
    150001301: { themeID: "99006", colors: ["6007", "6005"], name: "Vintage Toaster", size: "S" },
    150001302: { themeID: "99006", colors: ["6008", "6005"], name: "Vintage Toaster", size: "S" },
    150001303: { themeID: "99006", colors: ["6003", "6005"], name: "Vintage Toaster", size: "S" },
    150001304: { themeID: "99006", colors: ["6002", "6005"], name: "Vintage Toaster", size: "S" },
    150001500: { themeID: "99011", colors: ["6006", "6009"], name: "Biscuit stove", size: "M" },
    150001501: { themeID: "99011", colors: ["6011", "6003"], name: "Biscuit stove", size: "M" },
    150001502: { themeID: "99011", colors: ["6008", "6009"], name: "Biscuit stove", size: "M" },
    150001503: { themeID: "99011", colors: ["6010", "6004"], name: "Biscuit stove", size: "M" },
    150100100: { themeID: "99001", colors: ["6001", "6002", "6008"], name: "Urban stove", size: "M" },
    150100101: { themeID: "99001", colors: ["6008", "6002", "6001", "6007"], name: "Urban stove", size: "M" },
    150100102: { themeID: "99001", colors: ["6011", "6002", "6001", "6007"], name: "Urban stove", size: "M" },
    150100103: { themeID: "99001", colors: ["6002", "6010", "6001"], name: "Urban stove", size: "M" },
    150100104: { themeID: "99001", colors: ["6007", "6002", "6006", "6001"], name: "Urban stove", size: "M" },
    150100200: { themeID: "99003", colors: ["6011", "6003", "6001"], name: "Girlie oven", size: "S" },
    150100201: { themeID: "99003", colors: ["6006", "6009", "6001"], name: "Girlie oven", size: "S" },
    150100202: { themeID: "99003", colors: ["6010", "6001", "6004"], name: "Girlie oven", size: "S" },
    150100203: { themeID: "99003", colors: ["6008", "6001", "6002"], name: "Girlie oven", size: "S" },
    150200100: {
        themeID: "99007",
        colors: ["6005", "6009", "6003", "6007"],
        name: "Country stove",
        size: "M",
    },
    150200101: { themeID: "99007", colors: ["6010", "6011", "6008"], name: "Country stove", size: "M" },
    150200102: {
        themeID: "99007",
        colors: ["6007", "6005", "6006", "6009"],
        name: "Country stove",
        size: "M",
    },
    150200103: {
        themeID: "99007",
        colors: ["6008", "6010", "6004", "6009"],
        name: "Country stove",
        size: "M",
    },
    150200200: { themeID: "99008", colors: ["6003", "6001", "6002", "6005"], name: "Boho cooker", size: "M" },
    150200201: { themeID: "99008", colors: ["6005", "6009", "6006", "6002"], name: "Boho cooker", size: "M" },
    150200202: { themeID: "99008", colors: ["6010", "6011", "6001", "6004"], name: "Boho cooker", size: "M" },
    150300100: { themeID: "99009", colors: ["6009", "6005", "6004"], name: "Antique stove", size: "M" },
    150300101: { themeID: "99009", colors: ["6002", "6001", "6004"], name: "Antique stove", size: "M" },
    150300102: { themeID: "99009", colors: ["6011", "6001", "6010"], name: "Antique stove", size: "M" },
    150400100: { themeID: "99006", colors: ["6007", "6009", "6002"], name: "Vintage stove", size: "XL" },
    150400101: { themeID: "99006", colors: ["6008", "6009", "6002"], name: "Vintage stove", size: "XL" },
    150400102: { themeID: "99006", colors: ["6003", "6009", "6002"], name: "Vintage stove", size: "XL" },
    150400103: { themeID: "99006", colors: ["6002", "6004"], name: "Vintage stove", size: "XL" },
    150700100: { themeID: "99015", colors: ["6009", "6007"], name: "Refined oven", size: "S" },
    150700101: { themeID: "99015", colors: ["6007", "6009"], name: "Refined oven", size: "S" },
    150700102: { themeID: "99015", colors: ["6008", "6011", "6009"], name: "Refined oven", size: "S" },
    150700103: { themeID: "99015", colors: ["6003", "6004"], name: "Refined oven", size: "S" },
    150700200: { themeID: "99015", colors: ["6009", "6007", "6008"], name: "Refined cooker", size: "M" },
    150700201: { themeID: "99015", colors: ["6007", "6009"], name: "Refined cooker", size: "M" },
    150700202: { themeID: "99015", colors: ["6008", "6009"], name: "Refined cooker", size: "M" },
    150700203: { themeID: "99015", colors: ["6003", "6011", "6002"], name: "Refined cooker", size: "M" },
    150700300: { themeID: "99015", colors: ["6009", "6007"], name: "Refined griller", size: "S" },
    150700301: { themeID: "99015", colors: ["6007", "6006", "6009"], name: "Refined griller", size: "S" },
    150700302: { themeID: "99015", colors: ["6008", "6009"], name: "Refined griller", size: "S" },
    150700303: { themeID: "99015", colors: ["6003", "6011"], name: "Refined griller", size: "S" },
    150700400: { themeID: "99011", colors: ["6005", "6004"], name: "Cute oven", size: "S" },
    150700401: { themeID: "99011", colors: ["6011", "6004"], name: "Cute oven", size: "S" },
    150700402: { themeID: "99011", colors: ["6008", "6009"], name: "Cute oven", size: "S" },
    150700403: { themeID: "99011", colors: ["6010", "6004"], name: "Cute oven", size: "S" },
    151000400: { themeID: "99001", colors: ["6008", "6001"], name: "Urban Liquidizer", size: "S" },
    151000401: { themeID: "99001", colors: ["6011", "6001"], name: "Urban Liquidizer", size: "S" },
    151000402: { themeID: "99001", colors: ["6007", "6001"], name: "Urban Liquidizer", size: "S" },
    151000403: { themeID: "99001", colors: ["6002", "6010"], name: "Urban Liquidizer", size: "S" },
    151000500: { themeID: "99001", colors: ["6009", "6001", "6005"], name: "Urban Coffee Maker", size: "S" },
    151000501: { themeID: "99001", colors: ["6008", "6001", "6009"], name: "Urban Coffee Maker", size: "S" },
    151000502: { themeID: "99001", colors: ["6011", "6001", "6009"], name: "Urban Coffee Maker", size: "S" },
    151000503: { themeID: "99001", colors: ["6002", "6010", "6001"], name: "Urban Coffee Maker", size: "S" },
    151000504: { themeID: "99001", colors: ["6007", "6001", "6009"], name: "Urban Coffee Maker", size: "S" },
    151100100: {
        themeID: "99001",
        colors: ["6001", "6008", "6003", "6004"],
        name: "Urban water dispenser",
        size: "M",
    },
    151100101: {
        themeID: "99001",
        colors: ["6008", "6007", "6001", "6003"],
        name: "Urban water dispenser",
        size: "M",
    },
    151100102: {
        themeID: "99001",
        colors: ["6011", "6007", "6003", "6008", "6001"],
        name: "Urban water dispenser",
        size: "M",
    },
    151100103: {
        themeID: "99001",
        colors: ["6002", "6004", "6010", "6001", "6003"],
        name: "Urban water dispenser",
        size: "M",
    },
    151100104: {
        themeID: "99001",
        colors: ["6007", "6008", "6001", "6003"],
        name: "Urban water dispenser",
        size: "M",
    },
    151300100: {
        themeID: "99009",
        colors: ["6003", "6001", "6006"],
        name: "Antique water dispenser",
        size: "S",
    },
    151300101: {
        themeID: "99009",
        colors: ["6007", "6001", "6009"],
        name: "Antique water dispenser",
        size: "S",
    },
    151300102: {
        themeID: "99009",
        colors: ["6011", "6001", "6010"],
        name: "Antique water dispenser",
        size: "S",
    },
    151700100: { themeID: "99015", colors: ["6009"], name: "Refined coffee maker", size: "S" },
    151700101: { themeID: "99015", colors: ["6007"], name: "Refined coffee maker", size: "S" },
    151700102: { themeID: "99015", colors: ["6008"], name: "Refined coffee maker", size: "S" },
    151700103: { themeID: "99015", colors: ["6003", "6011"], name: "Refined coffee maker", size: "S" },
    151700200: { themeID: "99015", colors: ["6009", "6004"], name: "Refined blender", size: "S" },
    151700201: { themeID: "99015", colors: ["6007", "6004"], name: "Refined blender", size: "S" },
    151700202: { themeID: "99015", colors: ["6008", "6009"], name: "Refined blender", size: "S" },
    151700203: { themeID: "99015", colors: ["6003", "6011"], name: "Refined blender", size: "S" },
    151700300: { themeID: "99011", colors: ["6006", "6009"], name: "Dolly Ice-cream maker", size: "M" },
    151700301: { themeID: "99011", colors: ["6011", "6003"], name: "Dolly Ice-cream maker", size: "M" },
    151700302: { themeID: "99011", colors: ["6008", "6009"], name: "Dolly Ice-cream maker", size: "M" },
    151700303: { themeID: "99011", colors: ["6010", "6004"], name: "Dolly Ice-cream maker", size: "M" },
    152000100: { themeID: "99001", colors: ["6001"], name: "Urban Corner Worktable", size: "M" },
    152000101: { themeID: "99001", colors: ["6008"], name: "Urban Corner Worktable", size: "M" },
    152000102: { themeID: "99001", colors: ["6011"], name: "Urban Corner Worktable", size: "M" },
    152000103: { themeID: "99001", colors: ["6002"], name: "Urban Corner Worktable", size: "M" },
    152000104: { themeID: "99001", colors: ["6007"], name: "Urban Corner Worktable", size: "M" },
    152000200: { themeID: "99001", colors: ["6001", "6004"], name: "Urban Worktable", size: "M" },
    152000201: { themeID: "99001", colors: ["6008", "6001"], name: "Urban Worktable", size: "M" },
    152000202: { themeID: "99001", colors: ["6011", "6001"], name: "Urban Worktable", size: "M" },
    152000203: { themeID: "99001", colors: ["6002", "6004", "6001"], name: "Urban Worktable", size: "M" },
    152000204: { themeID: "99001", colors: ["6007", "6001", "6004"], name: "Urban Worktable", size: "M" },
    152000700: {
        themeID: "99011",
        colors: ["6006", "6009", "6004"],
        name: "Biscuit long worktable",
        size: "L",
    },
    152000701: {
        themeID: "99011",
        colors: ["6011", "6009", "6001"],
        name: "Biscuit long worktable",
        size: "L",
    },
    152000702: {
        themeID: "99011",
        colors: ["6008", "6009", "6004"],
        name: "Biscuit long worktable",
        size: "L",
    },
    152000703: {
        themeID: "99011",
        colors: ["6010", "6009", "6004"],
        name: "Biscuit long worktable",
        size: "L",
    },
    152100100: { themeID: "99002", colors: ["6009", "6006"], name: "Rustic worktable", size: "M" },
    152100101: { themeID: "99002", colors: ["6001", "6004"], name: "Rustic worktable", size: "M" },
    152100102: { themeID: "99002", colors: ["6011", "6001"], name: "Rustic worktable", size: "M" },
    152100103: { themeID: "99002", colors: ["6008", "6001"], name: "Rustic worktable", size: "M" },
    152100104: { themeID: "99002", colors: ["6002", "6001"], name: "Rustic worktable", size: "M" },
    152100105: { themeID: "99002", colors: ["6007", "6001"], name: "Rustic worktable", size: "M" },
    152100200: { themeID: "99002", colors: ["6009", "6006"], name: "Rustic long worktable", size: "M" },
    152100201: { themeID: "99002", colors: ["6001"], name: "Rustic long worktable", size: "M" },
    152100202: { themeID: "99002", colors: ["6001", "6011"], name: "Rustic long worktable", size: "M" },
    152100203: { themeID: "99002", colors: ["6001", "6008"], name: "Rustic long worktable", size: "M" },
    152100204: {
        themeID: "99002",
        colors: ["6001", "6002", "6004"],
        name: "Rustic long worktable",
        size: "M",
    },
    152100205: { themeID: "99002", colors: ["6001", "6007"], name: "Rustic long worktable", size: "M" },
    152100300: { themeID: "99011", colors: ["6006", "6009"], name: "Biscuit worktable", size: "M" },
    152100301: { themeID: "99011", colors: ["6011", "6003"], name: "Biscuit worktable", size: "M" },
    152100302: { themeID: "99011", colors: ["6008", "6009"], name: "Biscuit worktable", size: "M" },
    152100303: { themeID: "99011", colors: ["6010", "6004"], name: "Biscuit worktable", size: "M" },
    152100400: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie worktable", size: "S" },
    152100401: { themeID: "99003", colors: ["6009", "6005"], name: "Girlie worktable", size: "S" },
    152100402: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie worktable", size: "S" },
    152100403: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie worktable", size: "S" },
    152100500: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie corner worktable", size: "S" },
    152100501: { themeID: "99003", colors: ["6009", "6005"], name: "Girlie corner worktable", size: "S" },
    152100502: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie corner worktable", size: "S" },
    152100503: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie corner worktable", size: "S" },
    152200100: { themeID: "99007", colors: ["6005", "6007", "6003"], name: "Country Worktable", size: "M" },
    152200101: { themeID: "99007", colors: ["6010", "6008", "6011"], name: "Country Worktable", size: "M" },
    152200102: { themeID: "99007", colors: ["6007", "6006", "6003"], name: "Country Worktable", size: "M" },
    152200103: { themeID: "99007", colors: ["6008", "6004", "6009"], name: "Country Worktable", size: "M" },
    152200200: {
        themeID: "99007",
        colors: ["6005", "6009", "6003"],
        name: "Country corner worktable",
        size: "S",
    },
    152200201: { themeID: "99007", colors: ["6010", "6011"], name: "Country corner worktable", size: "S" },
    152200202: {
        themeID: "99007",
        colors: ["6007", "6005", "6006"],
        name: "Country corner worktable",
        size: "S",
    },
    152200203: {
        themeID: "99007",
        colors: ["6008", "6004", "6005"],
        name: "Country corner worktable",
        size: "S",
    },
    152200300: { themeID: "99008", colors: ["6003", "6005", "6001"], name: "Boho worktable ", size: "M" },
    152200301: { themeID: "99008", colors: ["6009", "6001", "6005"], name: "Boho worktable ", size: "M" },
    152200302: {
        themeID: "99008",
        colors: ["6010", "6011", "6001", "6009"],
        name: "Boho worktable ",
        size: "M",
    },
    152200400: {
        themeID: "99008",
        colors: ["6003", "6005", "6001"],
        name: "Boho corner worktable",
        size: "M",
    },
    152200401: {
        themeID: "99008",
        colors: ["6009", "6005", "6001"],
        name: "Boho corner worktable",
        size: "M",
    },
    152200402: {
        themeID: "99008",
        colors: ["6010", "6009", "6011"],
        name: "Boho corner worktable",
        size: "M",
    },
    152300100: { themeID: "99009", colors: ["6009", "6005"], name: "Antique worktable", size: "M" },
    152300101: { themeID: "99009", colors: ["6002", "6001"], name: "Antique worktable", size: "M" },
    152300102: { themeID: "99009", colors: ["6011", "6001"], name: "Antique worktable", size: "M" },
    153000300: {
        themeID: "99002",
        colors: ["6009", "6006", "6001", "6007"],
        name: "Rustic Kitchen sink",
        size: "M",
    },
    153000301: { themeID: "99002", colors: ["6001", "6004", "6011"], name: "Rustic Kitchen sink", size: "M" },
    153000302: { themeID: "99002", colors: ["6011", "6001", "6007"], name: "Rustic Kitchen sink", size: "M" },
    153000303: {
        themeID: "99002",
        colors: ["6008", "6001", "6004", "6006"],
        name: "Rustic Kitchen sink",
        size: "M",
    },
    153000304: {
        themeID: "99002",
        colors: ["6002", "6004", "6001", "6008"],
        name: "Rustic Kitchen sink",
        size: "M",
    },
    153000305: { themeID: "99002", colors: ["6007", "6001", "6006"], name: "Rustic Kitchen sink", size: "M" },
    153000600: {
        themeID: "99005",
        colors: ["6005", "6009", "6001"],
        name: "Childlike kitchen sink",
        size: "M",
    },
    153000601: {
        themeID: "99005",
        colors: ["6011", "6001", "6004"],
        name: "Childlike kitchen sink",
        size: "M",
    },
    153000602: {
        themeID: "99005",
        colors: ["6008", "6001", "6004"],
        name: "Childlike kitchen sink",
        size: "M",
    },
    153000603: {
        themeID: "99005",
        colors: ["6007", "6001", "6006"],
        name: "Childlike kitchen sink",
        size: "M",
    },
    153100100: {
        themeID: "99001",
        colors: ["6001", "6004", "6002"],
        name: "Automatic dishwasher",
        size: "M",
    },
    153100101: {
        themeID: "99001",
        colors: ["6008", "6001", "6002"],
        name: "Automatic dishwasher",
        size: "M",
    },
    153100102: {
        themeID: "99001",
        colors: ["6011", "6001", "6002"],
        name: "Automatic dishwasher",
        size: "M",
    },
    153100103: {
        themeID: "99001",
        colors: ["6002", "6004", "6001"],
        name: "Automatic dishwasher",
        size: "M",
    },
    153100104: {
        themeID: "99001",
        colors: ["6007", "6001", "6002"],
        name: "Automatic dishwasher",
        size: "M",
    },
    153100200: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie kitchen sink", size: "M" },
    153100201: { themeID: "99003", colors: ["6009", "6005"], name: "Girlie kitchen sink", size: "M" },
    153100202: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie kitchen sink", size: "M" },
    153100203: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie kitchen sink", size: "M" },
    153200100: {
        themeID: "99007",
        colors: ["6005", "6009", "6007"],
        name: "Country kitchen sink",
        size: "M",
    },
    153200101: {
        themeID: "99007",
        colors: ["6007", "6005", "6006"],
        name: "Country kitchen sink",
        size: "M",
    },
    153200102: {
        themeID: "99007",
        colors: ["6010", "6011", "6008"],
        name: "Country kitchen sink",
        size: "M",
    },
    153200103: {
        themeID: "99007",
        colors: ["6008", "6007", "6009"],
        name: "Country kitchen sink",
        size: "M",
    },
    153200200: {
        themeID: "99008",
        colors: ["6003", "6011", "6009", "6001"],
        name: "Boho kitchen sink",
        size: "M",
    },
    153200201: {
        themeID: "99008",
        colors: ["6005", "6009", "6001", "6003"],
        name: "Boho kitchen sink",
        size: "M",
    },
    153200202: {
        themeID: "99008",
        colors: ["6010", "6011", "6001", "6004"],
        name: "Boho kitchen sink",
        size: "M",
    },
    153300100: {
        themeID: "99009",
        colors: ["6009", "6002", "6006"],
        name: "Antique kitchen sink",
        size: "L",
    },
    153300101: {
        themeID: "99009",
        colors: ["6002", "6001", "6006"],
        name: "Antique kitchen sink",
        size: "L",
    },
    153300102: {
        themeID: "99009",
        colors: ["6011", "6002", "6001"],
        name: "Antique kitchen sink",
        size: "L",
    },
    153400100: {
        themeID: "99006",
        colors: ["6009", "6007", "6001", "6002"],
        name: "Vintage Kitchen sink",
        size: "L",
    },
    153400101: {
        themeID: "99006",
        colors: ["6008", "6009", "6001", "6002"],
        name: "Vintage Kitchen sink",
        size: "L",
    },
    153400102: {
        themeID: "99006",
        colors: ["6003", "6009", "6001", "6007", "6002"],
        name: "Vintage Kitchen sink",
        size: "L",
    },
    153400103: {
        themeID: "99006",
        colors: ["6004", "6001", "6002"],
        name: "Vintage Kitchen sink",
        size: "L",
    },
    153700100: {
        themeID: "99015",
        colors: ["6009", "6007", "6001"],
        name: "Refined Kitchen sink",
        size: "M",
    },
    153700101: {
        themeID: "99015",
        colors: ["6007", "6009", "6001"],
        name: "Refined Kitchen sink",
        size: "M",
    },
    153700102: {
        themeID: "99015",
        colors: ["6008", "6009", "6001"],
        name: "Refined Kitchen sink",
        size: "M",
    },
    153700103: {
        themeID: "99015",
        colors: ["6003", "6011", "6004"],
        name: "Refined Kitchen sink",
        size: "M",
    },
    153700200: {
        themeID: "99011",
        colors: ["6006", "6009", "6001"],
        name: "Biscuit kitchen sink",
        size: "M",
    },
    153700201: {
        themeID: "99011",
        colors: ["6011", "6003", "6001"],
        name: "Biscuit kitchen sink",
        size: "M",
    },
    153700202: {
        themeID: "99011",
        colors: ["6008", "6009", "6001"],
        name: "Biscuit kitchen sink",
        size: "M",
    },
    153700203: {
        themeID: "99011",
        colors: ["6010", "6009", "6001"],
        name: "Biscuit kitchen sink",
        size: "M",
    },
    154100100: { themeID: "99001", colors: ["6001", "6008", "6004"], name: "Urban cupboard", size: "L" },
    154100101: { themeID: "99001", colors: ["6008", "6007", "6001"], name: "Urban cupboard", size: "L" },
    154100102: { themeID: "99001", colors: ["6011", "6007", "6001"], name: "Urban cupboard", size: "L" },
    154100103: {
        themeID: "99001",
        colors: ["6002", "6010", "6001", "6004"],
        name: "Urban cupboard",
        size: "L",
    },
    154100104: { themeID: "99001", colors: ["6007", "6001", "6008"], name: "Urban cupboard", size: "L" },
    154100200: { themeID: "99002", colors: ["6009", "6006", "6007"], name: "Rustic cupboard", size: "M" },
    154100201: { themeID: "99002", colors: ["6001", "6004", "6011"], name: "Rustic cupboard", size: "M" },
    154100202: {
        themeID: "99002",
        colors: ["6011", "6001", "6006", "6007"],
        name: "Rustic cupboard",
        size: "M",
    },
    154100203: { themeID: "99002", colors: ["6008", "6001", "6006"], name: "Rustic cupboard", size: "M" },
    154100204: {
        themeID: "99002",
        colors: ["6002", "6001", "6004", "6008"],
        name: "Rustic cupboard",
        size: "M",
    },
    154100205: { themeID: "99002", colors: ["6007", "6001", "6006"], name: "Rustic cupboard", size: "M" },
    154100300: { themeID: "99003", colors: ["6003", "6007", "6001"], name: "Girlie cupboard", size: "M" },
    154100301: { themeID: "99003", colors: ["6009", "6006", "6007"], name: "Girlie cupboard", size: "M" },
    154100302: { themeID: "99003", colors: ["6010", "6007", "6001"], name: "Girlie cupboard", size: "M" },
    154100303: {
        themeID: "99003",
        colors: ["6008", "6007", "6002", "6006"],
        name: "Girlie cupboard",
        size: "M",
    },
    154200100: { themeID: "99007", colors: ["6005", "6007", "6003"], name: "Country cupboard", size: "L" },
    154200101: { themeID: "99007", colors: ["6010", "6011", "6004"], name: "Country cupboard", size: "L" },
    154200102: { themeID: "99007", colors: ["6007", "6006", "6005"], name: "Country cupboard", size: "L" },
    154200103: { themeID: "99007", colors: ["6008", "6001", "6004"], name: "Country cupboard", size: "L" },
    154200104: { themeID: "99007", colors: ["6009", "6005", "6003"], name: "Country cupboard", size: "L" },
    154300100: {
        themeID: "99009",
        colors: ["6009", "6011", "6007", "6001"],
        name: "Antique Cupboard",
        size: "XL",
    },
    154300101: { themeID: "99009", colors: ["6001", "6007", "6008"], name: "Antique Cupboard", size: "XL" },
    154300102: {
        themeID: "99009",
        colors: ["6011", "6001", "6003", "6006"],
        name: "Antique Cupboard",
        size: "XL",
    },
    154400100: { themeID: "99006", colors: ["6009", "6007", "6005"], name: "Vintage cupboard", size: "L" },
    154400101: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage cupboard", size: "L" },
    154400102: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage cupboard", size: "L" },
    154400103: { themeID: "99006", colors: ["6002", "6004", "6009"], name: "Vintage cupboard", size: "L" },
    155100100: { themeID: "99001", colors: ["6001", "6004"], name: "Urban wall cupboard", size: "S" },
    155100101: { themeID: "99001", colors: ["6008", "6001"], name: "Urban wall cupboard", size: "S" },
    155100102: { themeID: "99001", colors: ["6011", "6001"], name: "Urban wall cupboard", size: "S" },
    155100103: { themeID: "99001", colors: ["6002", "6001", "6004"], name: "Urban wall cupboard", size: "S" },
    155100104: { themeID: "99001", colors: ["6007", "6001"], name: "Urban wall cupboard", size: "S" },
    155100200: { themeID: "99001", colors: ["6008", "6001"], name: "Urban wall cupboard", size: "S" },
    155100201: { themeID: "99001", colors: ["6008", "6007"], name: "Urban wall cupboard", size: "S" },
    155100202: { themeID: "99001", colors: ["6011", "6007"], name: "Urban wall cupboard", size: "S" },
    155100203: { themeID: "99001", colors: ["6002", "6010"], name: "Urban wall cupboard", size: "S" },
    155100204: { themeID: "99001", colors: ["6007", "6008"], name: "Urban wall cupboard", size: "S" },
    155100300: { themeID: "99002", colors: ["6009", "6006"], name: "Rustic wall cupboard", size: "S" },
    155100301: { themeID: "99002", colors: ["6001", "6004"], name: "Rustic wall cupboard", size: "S" },
    155100302: { themeID: "99002", colors: ["6011", "6006"], name: "Rustic wall cupboard", size: "S" },
    155100303: { themeID: "99002", colors: ["6008", "6006"], name: "Rustic wall cupboard", size: "S" },
    155100304: { themeID: "99002", colors: ["6002", "6004"], name: "Rustic wall cupboard", size: "S" },
    155100305: { themeID: "99002", colors: ["6007", "6006"], name: "Rustic wall cupboard", size: "S" },
    155200100: {
        themeID: "99007",
        colors: ["6005", "6009", "6001"],
        name: "Country Wall Cupboard",
        size: "S",
    },
    155200101: {
        themeID: "99007",
        colors: ["6010", "6004", "6011"],
        name: "Country Wall Cupboard",
        size: "S",
    },
    155200102: {
        themeID: "99007",
        colors: ["6007", "6005", "6001"],
        name: "Country Wall Cupboard",
        size: "S",
    },
    155200103: {
        themeID: "99007",
        colors: ["6004", "6001", "6008"],
        name: "Country Wall Cupboard",
        size: "S",
    },
    155200200: { themeID: "99008", colors: ["6005", "6001", "6006"], name: "Boho wall cupboard", size: "M" },
    155200201: { themeID: "99008", colors: ["6009", "6006", "6007"], name: "Boho wall cupboard", size: "M" },
    155200202: { themeID: "99008", colors: ["6004", "6010", "6001"], name: "Boho wall cupboard", size: "M" },
    155300100: {
        themeID: "99009",
        colors: ["6009", "6001", "6002", "6007"],
        name: "Antique Kitchen shelf",
        size: "S",
    },
    155300101: {
        themeID: "99009",
        colors: ["6001", "6002", "6008"],
        name: "Antique Kitchen shelf",
        size: "S",
    },
    155300102: {
        themeID: "99009",
        colors: ["6011", "6001", "6002", "6008"],
        name: "Antique Kitchen shelf",
        size: "S",
    },
    155500100: { themeID: "99011", colors: ["6006", "6009"], name: "Dolly wall cupboard", size: "S" },
    155500101: { themeID: "99011", colors: ["6011", "6009"], name: "Dolly wall cupboard", size: "S" },
    155500102: { themeID: "99011", colors: ["6008", "6009"], name: "Dolly wall cupboard", size: "S" },
    155500103: { themeID: "99011", colors: ["6010", "6009", "6001"], name: "Dolly wall cupboard", size: "S" },
    155500200: { themeID: "99011", colors: ["6006", "6009"], name: "Dolly mini cupboard", size: "S" },
    155500201: { themeID: "99011", colors: ["6011", "6009"], name: "Dolly mini cupboard", size: "S" },
    155500202: { themeID: "99011", colors: ["6008", "6009"], name: "Dolly mini cupboard", size: "S" },
    155500203: { themeID: "99011", colors: ["6010", "6011", "6009"], name: "Dolly mini cupboard", size: "S" },
    162000800: { themeID: "99006", colors: ["6009", "6006"], name: "Vintage Desk", size: "M" },
    162000801: { themeID: "99006", colors: ["6007", "6006"], name: "Vintage Desk", size: "M" },
    162000802: { themeID: "99006", colors: ["6008", "6006"], name: "Vintage Desk", size: "M" },
    162000803: { themeID: "99006", colors: ["6003", "6002", "6009"], name: "Vintage Desk", size: "M" },
    162100100: { themeID: "99001", colors: ["6001", "6004"], name: "Urban Desk", size: "L" },
    162100101: { themeID: "99001", colors: ["6008", "6001"], name: "Urban Desk", size: "L" },
    162100102: { themeID: "99001", colors: ["6011", "6001"], name: "Urban Desk", size: "L" },
    162100103: { themeID: "99001", colors: ["6004", "6002", "6001"], name: "Urban Desk", size: "L" },
    162100104: { themeID: "99001", colors: ["6007", "6001"], name: "Urban Desk", size: "L" },
    162100200: { themeID: "99002", colors: ["6006", "6009"], name: "Rustic desk", size: "M" },
    162100201: { themeID: "99002", colors: ["6001", "6004"], name: "Rustic desk", size: "M" },
    162100202: { themeID: "99002", colors: ["6011", "6001"], name: "Rustic desk", size: "M" },
    162100203: { themeID: "99002", colors: ["6008", "6001"], name: "Rustic desk", size: "M" },
    162100204: { themeID: "99002", colors: ["6002", "6004"], name: "Rustic desk", size: "M" },
    162100205: { themeID: "99002", colors: ["6007", "6001"], name: "Rustic desk", size: "M" },
    162100300: { themeID: "99003", colors: ["6003", "6001", "6011"], name: "Girlie desk", size: "M" },
    162100301: { themeID: "99003", colors: ["6009", "6006"], name: "Girlie desk", size: "M" },
    162100302: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie desk", size: "M" },
    162100303: { themeID: "99003", colors: ["6002", "6008", "6001"], name: "Girlie desk", size: "M" },
    162200100: { themeID: "99007", colors: ["6009", "6005"], name: "Country Desk", size: "M" },
    162200101: { themeID: "99007", colors: ["6011", "6004", "6010"], name: "Country Desk", size: "M" },
    162200102: { themeID: "99007", colors: ["6006", "6005", "6007"], name: "Country Desk", size: "M" },
    162200200: { themeID: "99008", colors: ["6005", "6006"], name: "Boho desk", size: "M" },
    162200201: { themeID: "99008", colors: ["6006", "6001"], name: "Boho desk", size: "M" },
    162200202: { themeID: "99008", colors: ["6002", "6009"], name: "Boho desk", size: "M" },
    162300100: { themeID: "99009", colors: ["6005", "6001"], name: "Antique desk", size: "M" },
    162300101: { themeID: "99009", colors: ["6002", "6006"], name: "Antique desk", size: "M" },
    162300102: { themeID: "99009", colors: ["6011", "6001"], name: "Antique desk", size: "M" },
    162301200: { themeID: "99011", colors: ["6006", "6009", "6011"], name: "Dolly desk", size: "L" },
    162301201: { themeID: "99011", colors: ["6003", "6011", "6009", "6001"], name: "Dolly desk", size: "L" },
    162301202: { themeID: "99011", colors: ["6009", "6008", "6001"], name: "Dolly desk", size: "L" },
    162301203: { themeID: "99011", colors: ["6010", "6004", "6011"], name: "Dolly desk", size: "L" },
    162400100: { themeID: "99006", colors: ["6009", "6007"], name: "Vintage desk", size: "L" },
    162400101: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage desk", size: "L" },
    162400102: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage desk", size: "L" },
    162400103: { themeID: "99006", colors: ["6003", "6009", "6007"], name: "Vintage desk", size: "L" },
    162700100: { themeID: "99015", colors: ["6009", "6007"], name: "Refined desk", size: "M" },
    162700101: { themeID: "99015", colors: ["6007", "6009"], name: "Refined desk", size: "M" },
    162700102: { themeID: "99015", colors: ["6008", "6009"], name: "Refined desk", size: "M" },
    162700103: { themeID: "99015", colors: ["6003", "6004"], name: "Refined desk", size: "M" },
    163000600: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie accent table", size: "S" },
    163000601: { themeID: "99003", colors: ["6009", "6005"], name: "Girlie accent table", size: "S" },
    163000602: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie accent table", size: "S" },
    163000603: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie accent table", size: "S" },
    163001100: { themeID: "99006", colors: ["6009"], name: "Vintage accent table", size: "S" },
    163001101: { themeID: "99006", colors: ["6007", "6002"], name: "Vintage accent table", size: "S" },
    163001102: { themeID: "99006", colors: ["6008", "6002"], name: "Vintage accent table", size: "S" },
    163001103: { themeID: "99006", colors: ["6003", "6002"], name: "Vintage accent table", size: "S" },
    163100100: { themeID: "99001", colors: ["6001", "6004", "6005"], name: "Urban Accent table", size: "S" },
    163100101: { themeID: "99001", colors: ["6008", "6001", "6005"], name: "Urban Accent table", size: "S" },
    163100102: { themeID: "99001", colors: ["6011", "6001", "6005"], name: "Urban Accent table", size: "S" },
    163100103: {
        themeID: "99001",
        colors: ["6004", "6002", "6001", "6005"],
        name: "Urban Accent table",
        size: "S",
    },
    163100104: { themeID: "99001", colors: ["6007", "6001", "6005"], name: "Urban Accent table", size: "S" },
    163100200: { themeID: "99002", colors: ["6009", "6006", "6007"], name: "Rustic accent table", size: "S" },
    163100201: { themeID: "99002", colors: ["6001", "6007"], name: "Rustic accent table", size: "S" },
    163100202: { themeID: "99002", colors: ["6011", "6001", "6007"], name: "Rustic accent table", size: "S" },
    163100203: { themeID: "99002", colors: ["6008", "6001"], name: "Rustic accent table", size: "S" },
    163100204: {
        themeID: "99002",
        colors: ["6002", "6001", "6008", "6004"],
        name: "Rustic accent table",
        size: "S",
    },
    163100205: { themeID: "99002", colors: ["6007", "6001", "6006"], name: "Rustic accent table", size: "S" },
    163200100: { themeID: "99007", colors: ["6005", "6006"], name: "Country Accent Table", size: "S" },
    163200101: { themeID: "99007", colors: ["6004", "6006"], name: "Country Accent Table", size: "S" },
    163200102: { themeID: "99007", colors: ["6006", "6001"], name: "Country Accent Table", size: "S" },
    163200200: { themeID: "99008", colors: ["6006", "6001", "6009"], name: "Boho accent table", size: "S" },
    163200201: { themeID: "99008", colors: ["6005", "6009", "6006"], name: "Boho accent table", size: "S" },
    163200202: { themeID: "99008", colors: ["6009", "6002", "6004"], name: "Boho accent table", size: "S" },
    163300100: { themeID: "99009", colors: ["6003", "6009"], name: "Antique accent table", size: "S" },
    163300101: { themeID: "99009", colors: ["6008", "6004"], name: "Antique accent table", size: "S" },
    163300102: { themeID: "99009", colors: ["6001"], name: "Antique accent table", size: "S" },
    163800100: { themeID: "99015", colors: ["6009"], name: "Natural accent table", size: "S" },
    163800101: { themeID: "99015", colors: ["6007", "6009"], name: "Natural accent table", size: "S" },
    163800102: { themeID: "99015", colors: ["6008", "6009"], name: "Natural accent table", size: "S" },
    163800103: { themeID: "99015", colors: ["6003", "6009"], name: "Natural accent table", size: "S" },
    163800200: { themeID: "99004", colors: ["6004", "6008"], name: "Rattan glass accent table", size: "M" },
    163800201: { themeID: "99004", colors: ["6007", "6008"], name: "Rattan glass accent table", size: "M" },
    163800202: { themeID: "99004", colors: ["6002", "6008"], name: "Rattan glass accent table", size: "M" },
    163800203: { themeID: "99004", colors: ["6009", "6008"], name: "Rattan glass accent table", size: "M" },
    163800300: { themeID: "99004", colors: ["6004", "6009"], name: "Rattan accent table", size: "S" },
    163800301: { themeID: "99004", colors: ["6002", "6009"], name: "Rattan accent table", size: "S" },
    163800302: { themeID: "99004", colors: ["6009"], name: "Rattan accent table", size: "S" },
    163800303: { themeID: "99004", colors: ["6007"], name: "Rattan accent table", size: "S" },
    164000300: {
        themeID: "99003",
        colors: ["6003", "6002", "6011"],
        name: "Girlie Jewelry table",
        size: "M",
    },
    164000301: {
        themeID: "99003",
        colors: ["6010", "6002", "6004"],
        name: "Girlie Jewelry table",
        size: "M",
    },
    164000302: {
        themeID: "99003",
        colors: ["6002", "6008", "6004"],
        name: "Girlie Jewelry table",
        size: "M",
    },
    164000303: {
        themeID: "99003",
        colors: ["6009", "6005", "6002", "6007"],
        name: "Girlie Jewelry table",
        size: "M",
    },
    164000400: { themeID: "99003", colors: ["6003", "6001"], name: "Girlie console table", size: "S" },
    164000401: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie console table", size: "S" },
    164000402: { themeID: "99003", colors: ["6002", "6008"], name: "Girlie console table", size: "S" },
    164000403: { themeID: "99003", colors: ["6009", "6007"], name: "Girlie console table", size: "S" },
    164000700: { themeID: "99004", colors: ["6009", "6005"], name: "Bamboo console table", size: "M" },
    164000701: { themeID: "99004", colors: ["6007", "6006"], name: "Bamboo console table", size: "M" },
    164000702: { themeID: "99004", colors: ["6002", "6006"], name: "Bamboo console table", size: "M" },
    164000703: { themeID: "99004", colors: ["6004", "6001"], name: "Bamboo console table", size: "M" },
    164100100: { themeID: "99002", colors: ["6006", "6009"], name: "Rustic console table", size: "S" },
    164100101: {
        themeID: "99002",
        colors: ["6001", "6004", "6002"],
        name: "Rustic console table",
        size: "S",
    },
    164100102: {
        themeID: "99002",
        colors: ["6011", "6001", "6003"],
        name: "Rustic console table",
        size: "S",
    },
    164100103: { themeID: "99002", colors: ["6008", "6001"], name: "Rustic console table", size: "S" },
    164100104: { themeID: "99002", colors: ["6002", "6004"], name: "Rustic console table", size: "S" },
    164100105: { themeID: "99002", colors: ["6007", "6001"], name: "Rustic console table", size: "S" },
    164100200: { themeID: "99001", colors: ["6001"], name: "Urban console table", size: "M" },
    164100201: { themeID: "99001", colors: ["6008"], name: "Urban console table", size: "M" },
    164100202: { themeID: "99001", colors: ["6011"], name: "Urban console table", size: "M" },
    164100203: { themeID: "99001", colors: ["6004", "6002"], name: "Urban console table", size: "M" },
    164100204: { themeID: "99001", colors: ["6007"], name: "Urban console table", size: "M" },
    164100300: {
        themeID: "99017",
        colors: ["6003", "6006"],
        name: "Xmas buffet table",
        size: "M",
        xmas: true,
    },
    164100301: {
        themeID: "99017",
        colors: ["6007", "6006"],
        name: "Xmas buffet table",
        size: "M",
        xmas: true,
    },
    164100302: {
        themeID: "99017",
        colors: ["6008", "6011"],
        name: "Xmas buffet table",
        size: "M",
        xmas: true,
    },
    164200100: {
        themeID: "99007",
        colors: ["6005", "6009", "6002"],
        name: "Country Console Table",
        size: "M",
    },
    164200101: { themeID: "99007", colors: ["6009", "6002"], name: "Country Console Table", size: "M" },
    164200102: { themeID: "99007", colors: ["6002", "6008"], name: "Country Console Table", size: "M" },
    164200200: { themeID: "99008", colors: ["6009", "6001", "6005"], name: "Boho console table", size: "M" },
    164200201: { themeID: "99008", colors: ["6006", "6001"], name: "Boho console table", size: "M" },
    164200202: { themeID: "99008", colors: ["6002", "6009"], name: "Boho console table", size: "M" },
    164300100: {
        themeID: "99009",
        colors: ["6009", "6005", "6003"],
        name: "Antique console table",
        size: "M",
    },
    164300101: {
        themeID: "99009",
        colors: ["6007", "6006", "6002"],
        name: "Antique console table",
        size: "M",
    },
    164300102: {
        themeID: "99009",
        colors: ["6011", "6001", "6006"],
        name: "Antique console table",
        size: "M",
    },
    164600100: { themeID: "99015", colors: ["6007", "6009"], name: "Refined console table", size: "S" },
    164600101: { themeID: "99015", colors: ["6007", "6002"], name: "Refined console table", size: "S" },
    164600102: { themeID: "99015", colors: ["6008", "6009"], name: "Refined console table", size: "S" },
    164600103: { themeID: "99015", colors: ["6003", "6009"], name: "Refined console table", size: "S" },
    164700100: { themeID: "99015", colors: ["6007", "6009"], name: "Refined iron table", size: "M" },
    164700101: { themeID: "99015", colors: ["6007", "6002"], name: "Refined iron table", size: "M" },
    164700102: { themeID: "99015", colors: ["6008", "6009"], name: "Refined iron table", size: "M" },
    164700103: { themeID: "99015", colors: ["6003", "6011", "6004"], name: "Refined iron table", size: "M" },
    164700200: { themeID: "99001", colors: ["6001", "6004"], name: "Urban iron table", size: "M" },
    164700201: { themeID: "99001", colors: ["6004", "6006", "6001"], name: "Urban iron table", size: "M" },
    164700202: { themeID: "99001", colors: ["6008", "6001", "6004"], name: "Urban iron table", size: "M" },
    164700203: { themeID: "99001", colors: ["6011", "6004", "6001"], name: "Urban iron table", size: "M" },
    164700204: { themeID: "99001", colors: ["6002", "6004", "6001"], name: "Urban iron table", size: "M" },
    165000100: {
        themeID: "99001",
        colors: ["6001", "6006", "6004", "6009"],
        name: "Urban Armchair",
        size: "M",
    },
    165000101: {
        themeID: "99001",
        colors: ["6008", "6001", "6004", "6006", "6009"],
        name: "Urban Armchair",
        size: "M",
    },
    165000102: {
        themeID: "99001",
        colors: ["6011", "6001", "6009", "6005"],
        name: "Urban Armchair",
        size: "M",
    },
    165000103: {
        themeID: "99001",
        colors: ["6002", "6001", "6004", "6006", "6009"],
        name: "Urban Armchair",
        size: "M",
    },
    165000104: {
        themeID: "99001",
        colors: ["6007", "6001", "6004", "6006", "6009"],
        name: "Urban Armchair",
        size: "M",
    },
    165000400: { themeID: "99004", colors: ["6005", "6009", "6006"], name: "Rattan armchair", size: "M" },
    165000401: { themeID: "99004", colors: ["6007", "6002"], name: "Rattan armchair", size: "M" },
    165000402: { themeID: "99004", colors: ["6003", "6009", "6005"], name: "Rattan armchair", size: "M" },
    165000403: { themeID: "99004", colors: ["6010", "6011"], name: "Rattan armchair", size: "M" },
    165000404: { themeID: "99004", colors: ["6008", "6004"], name: "Rattan armchair", size: "M" },
    165000500: { themeID: "99004", colors: ["6001", "6009"], name: "Papasan chair", size: "S" },
    165000501: { themeID: "99004", colors: ["6011", "6009", "6001"], name: "Papasan chair", size: "S" },
    165000502: { themeID: "99004", colors: ["6008", "6011", "6009"], name: "Papasan chair", size: "S" },
    165000503: { themeID: "99004", colors: ["6008", "6001", "6009"], name: "Papasan chair", size: "S" },
    165000504: { themeID: "99004", colors: ["6007", "6009", "6001"], name: "Papasan chair", size: "S" },
    165000600: { themeID: "99011", colors: ["6009", "6005"], name: "Dolly armchair", size: "M" },
    165000601: { themeID: "99011", colors: ["6011", "6004"], name: "Dolly armchair", size: "M" },
    165000602: { themeID: "99011", colors: ["6008", "6006"], name: "Dolly armchair", size: "M" },
    165000603: { themeID: "99011", colors: ["6010", "6011"], name: "Dolly armchair", size: "M" },
    165000700: { themeID: "99006", colors: ["6009", "6001"], name: "Vintage Rocking Chair", size: "S" },
    165000701: { themeID: "99006", colors: ["6007", "6006"], name: "Vintage Rocking Chair", size: "S" },
    165000702: { themeID: "99006", colors: ["6008", "6001"], name: "Vintage Rocking Chair", size: "S" },
    165000703: { themeID: "99006", colors: ["6003", "6001"], name: "Vintage Rocking Chair", size: "S" },
    165100100: {
        themeID: "99002",
        colors: ["6006", "6007", "6009", "6001"],
        name: "Rustic armchair",
        size: "M",
    },
    165100101: { themeID: "99002", colors: ["6001", "6004", "6011"], name: "Rustic armchair", size: "M" },
    165100102: { themeID: "99002", colors: ["6011", "6001", "6007"], name: "Rustic armchair", size: "M" },
    165100103: { themeID: "99002", colors: ["6008", "6001", "6011"], name: "Rustic armchair", size: "M" },
    165100104: {
        themeID: "99002",
        colors: ["6002", "6001", "6004", "6009"],
        name: "Rustic armchair",
        size: "M",
    },
    165100105: {
        themeID: "99002",
        colors: ["6007", "6001", "6005", "6011"],
        name: "Rustic armchair",
        size: "M",
    },
    165100200: { themeID: "99003", colors: ["6003", "6001", "6011"], name: "Girlie armchair", size: "L" },
    165100201: {
        themeID: "99003",
        colors: ["6005", "6009", "6007", "6001"],
        name: "Girlie armchair",
        size: "L",
    },
    165100202: { themeID: "99003", colors: ["6010", "6001", "6002"], name: "Girlie armchair", size: "L" },
    165100203: {
        themeID: "99003",
        colors: ["6008", "6001", "6005", "6004"],
        name: "Girlie armchair",
        size: "L",
    },
    165100300: { themeID: "99017", colors: ["6003", "6001"], name: "Xmas armchair", size: "M", xmas: true },
    165100301: {
        themeID: "99017",
        colors: ["6007", "6005", "6001"],
        name: "Xmas armchair",
        size: "M",
        xmas: true,
    },
    165100302: {
        themeID: "99017",
        colors: ["6008", "6001", "6011"],
        name: "Xmas armchair",
        size: "M",
        xmas: true,
    },
    165200100: {
        themeID: "99007",
        colors: ["6005", "6006", "6009"],
        name: "Country rocking chair",
        size: "M",
    },
    165200101: { themeID: "99007", colors: ["6004", "6010"], name: "Country rocking chair", size: "M" },
    165200102: { themeID: "99007", colors: ["6006", "6001"], name: "Country rocking chair", size: "M" },
    165200103: { themeID: "99007", colors: ["6011", "6006"], name: "Country rocking chair", size: "M" },
    165200104: { themeID: "99007", colors: ["6008", "6006"], name: "Country rocking chair", size: "M" },
    165200200: { themeID: "99008", colors: ["6003", "6005", "6001"], name: "Boho armchair", size: "M" },
    165200201: { themeID: "99008", colors: ["6005", "6009", "6001"], name: "Boho armchair", size: "M" },
    165200202: { themeID: "99008", colors: ["6011", "6004", "6001"], name: "Boho armchair", size: "M" },
    165300100: { themeID: "99009", colors: ["6006", "6003", "6008"], name: "Antique Armchair", size: "M" },
    165300101: { themeID: "99009", colors: ["6001", "6003", "6008"], name: "Antique Armchair", size: "M" },
    165300102: { themeID: "99009", colors: ["6003", "6001", "6011"], name: "Antique Armchair", size: "M" },
    165301200: {
        themeID: "99013",
        colors: ["6005", "6006", "6001", "6009"],
        name: "Streamer bunny chair",
        size: "L",
    },
    165301201: {
        themeID: "99013",
        colors: ["6011", "6001", "6004"],
        name: "Streamer bunny chair",
        size: "L",
    },
    165301202: {
        themeID: "99013",
        colors: ["6008", "6001", "6009", "6004"],
        name: "Streamer bunny chair",
        size: "L",
    },
    165301203: {
        themeID: "99013",
        colors: ["6010", "6011", "6001"],
        name: "Streamer bunny chair",
        size: "L",
    },
    165400100: { themeID: "99006", colors: ["6007", "6009", "6001"], name: "Vintage armchair ", size: "M" },
    165400101: { themeID: "99006", colors: ["6008", "6001", "6009"], name: "Vintage armchair ", size: "M" },
    165400102: {
        themeID: "99006",
        colors: ["6003", "6009", "6011", "6001"],
        name: "Vintage armchair ",
        size: "M",
    },
    165400103: { themeID: "99006", colors: ["6009", "6004", "6001"], name: "Vintage armchair ", size: "M" },
    166100100: { themeID: "99003", colors: ["6003", "6001", "6011"], name: "Girlie chaise", size: "M" },
    166100101: { themeID: "99003", colors: ["6005", "6006", "6009"], name: "Girlie chaise", size: "M" },
    166100102: { themeID: "99003", colors: ["6010", "6001", "6004"], name: "Girlie chaise", size: "M" },
    166100103: {
        themeID: "99003",
        colors: ["6004", "6008", "6001", "6002"],
        name: "Girlie chaise",
        size: "M",
    },
    166200100: { themeID: "99007", colors: ["6005", "6009", "6006"], name: "Country Chaise", size: "M" },
    166200101: { themeID: "99007", colors: ["6004", "6010", "6006"], name: "Country Chaise", size: "M" },
    166200102: { themeID: "99007", colors: ["6006", "6007", "6005"], name: "Country Chaise", size: "M" },
    166201300: {
        themeID: "99008",
        colors: ["6009", "6005", "6006"],
        name: "Gypsy leather chaise",
        size: "L",
    },
    166201301: {
        themeID: "99008",
        colors: ["6004", "6001", "6005"],
        name: "Gypsy leather chaise",
        size: "L",
    },
    166201302: {
        themeID: "99008",
        colors: ["6007", "6006", "6005"],
        name: "Gypsy leather chaise",
        size: "L",
    },
    166201303: {
        themeID: "99008",
        colors: ["6002", "6009", "6006"],
        name: "Gypsy leather chaise",
        size: "L",
    },
    166300100: { themeID: "99009", colors: ["6006", "6008", "6009"], name: "Antique chaise", size: "M" },
    166300101: { themeID: "99009", colors: ["6001", "6002", "6007"], name: "Antique chaise", size: "M" },
    166300102: { themeID: "99009", colors: ["6011", "6003", "6001"], name: "Antique chaise", size: "M" },
    166500100: { themeID: "99011", colors: ["6005", "6006", "6009"], name: "Dolly chaise", size: "M" },
    166500101: { themeID: "99011", colors: ["6011", "6007", "6003"], name: "Dolly chaise", size: "M" },
    166500102: { themeID: "99011", colors: ["6008", "6005", "6007"], name: "Dolly chaise", size: "M" },
    166500103: { themeID: "99011", colors: ["6010", "6011", "6007"], name: "Dolly chaise", size: "M" },
    166700100: {
        themeID: "99001",
        colors: ["6001", "6004", "6006", "6009"],
        name: "Urban chaise",
        size: "M",
    },
    166700101: {
        themeID: "99001",
        colors: ["6008", "6009", "6006", "6001", "6004"],
        name: "Urban chaise",
        size: "M",
    },
    166700102: {
        themeID: "99001",
        colors: ["6011", "6001", "6009", "6005"],
        name: "Urban chaise",
        size: "M",
    },
    166700103: {
        themeID: "99001",
        colors: ["6002", "6004", "6001", "6009", "6006"],
        name: "Urban chaise",
        size: "M",
    },
    167000800: { themeID: "99006", colors: ["6001", "6009"], name: "Vintage picnic stool", size: "S" },
    167000801: { themeID: "99006", colors: ["6009", "6006"], name: "Vintage picnic stool", size: "S" },
    167000802: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage picnic stool", size: "S" },
    167000803: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage picnic stool", size: "S" },
    167000804: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage picnic stool", size: "S" },
    167000805: { themeID: "99006", colors: ["6002", "6004"], name: "Vintage picnic stool", size: "S" },
    167000900: { themeID: "99005", colors: ["6005", "6001", "6007"], name: "Leafie pouf", size: "S" },
    167000901: { themeID: "99005", colors: ["6011", "6001"], name: "Leafie pouf", size: "S" },
    167000902: { themeID: "99005", colors: ["6010", "6008", "6001"], name: "Leafie pouf", size: "S" },
    167100100: { themeID: "99001", colors: ["6001", "6005"], name: "Urban stool", size: "S" },
    167100101: { themeID: "99001", colors: ["6008", "6005"], name: "Urban stool", size: "S" },
    167100102: { themeID: "99001", colors: ["6011", "6005"], name: "Urban stool", size: "S" },
    167100103: { themeID: "99001", colors: ["6002", "6005"], name: "Urban stool", size: "S" },
    167100104: { themeID: "99001", colors: ["6007", "6005"], name: "Urban stool", size: "S" },
    167100200: {
        themeID: "99002",
        colors: ["6009", "6001", "6007", "6011"],
        name: "Rustic stool",
        size: "S",
    },
    167100201: { themeID: "99002", colors: ["6001", "6011", "6008"], name: "Rustic stool", size: "S" },
    167100202: { themeID: "99002", colors: ["6011", "6001", "6007"], name: "Rustic stool", size: "S" },
    167100203: { themeID: "99002", colors: ["6008", "6001", "6011"], name: "Rustic stool", size: "S" },
    167100204: { themeID: "99002", colors: ["6002", "6004", "6009"], name: "Rustic stool", size: "S" },
    167100205: { themeID: "99002", colors: ["6007", "6001", "6011"], name: "Rustic stool", size: "S" },
    167100300: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie footstool", size: "XS" },
    167100301: { themeID: "99003", colors: ["6005", "6009"], name: "Girlie footstool", size: "XS" },
    167100302: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie footstool", size: "XS" },
    167100303: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie footstool", size: "XS" },
    167100400: { themeID: "99003", colors: ["6011", "6001"], name: "Girlie pouf", size: "S" },
    167100401: { themeID: "99003", colors: ["6006", "6001"], name: "Girlie pouf", size: "S" },
    167100402: { themeID: "99003", colors: ["6010", "6001"], name: "Girlie pouf", size: "S" },
    167100403: { themeID: "99003", colors: ["6008", "6001"], name: "Girlie pouf", size: "S" },
    167100500: {
        themeID: "99017",
        colors: ["6003", "6001"],
        name: "Alpenliebe candy stool",
        size: "S",
        xmas: true,
    },
    167100501: {
        themeID: "99017",
        colors: ["6007", "6006"],
        name: "Alpenliebe candy stool",
        size: "S",
        xmas: true,
    },
    167100502: {
        themeID: "99017",
        colors: ["6011", "6008"],
        name: "Alpenliebe candy stool",
        size: "S",
        xmas: true,
    },
    167100600: {
        themeID: "99017",
        colors: ["6009", "6006"],
        name: "Tree log stool",
        size: "S",
        xmas: true,
    },
    167100601: { themeID: "99017", colors: ["6004"], name: "Tree log stool", size: "S", xmas: true },
    167100602: {
        themeID: "99017",
        colors: ["6009", "6004"],
        name: "Tree log stool",
        size: "S",
        xmas: true,
    },
    167200100: { themeID: "99007", colors: ["6009"], name: "Little chair", size: "S" },
    167200101: { themeID: "99007", colors: ["6004"], name: "Little chair", size: "S" },
    167200102: { themeID: "99007", colors: ["6006"], name: "Little chair", size: "S" },
    167300200: { themeID: "99009", colors: ["6003", "6009"], name: "Antique footstool", size: "S" },
    167300201: { themeID: "99009", colors: ["6001", "6009"], name: "Antique footstool", size: "S" },
    167300202: { themeID: "99009", colors: ["6011", "6009"], name: "Antique footstool", size: "S" },
    167400100: { themeID: "99019", colors: ["6002", "6004"], name: "Metal stool", size: "XS" },
    167400101: { themeID: "99019", colors: ["6003", "6002"], name: "Metal stool", size: "XS" },
    167400102: { themeID: "99019", colors: ["6009", "6002"], name: "Metal stool", size: "XS" },
    167400200: { themeID: "99019", colors: ["6004"], name: "Stone stool", size: "XS" },
    167400201: { themeID: "99019", colors: ["6003", "6004", "6011"], name: "Stone stool", size: "XS" },
    167400202: { themeID: "99019", colors: ["6008"], name: "Stone stool", size: "XS" },
    167500200: { themeID: "99011", colors: ["6009", "6006"], name: "Dolly ottoman", size: "S" },
    167500201: { themeID: "99011", colors: ["6011", "6004"], name: "Dolly ottoman", size: "S" },
    167500202: { themeID: "99011", colors: ["6008", "6006"], name: "Dolly ottoman", size: "S" },
    167500203: { themeID: "99011", colors: ["6010", "6011"], name: "Dolly ottoman", size: "S" },
    167600200: {
        themeID: "99015",
        colors: ["6009", "6001", "6002"],
        name: "Refined Natural stool",
        size: "S",
    },
    167600201: {
        themeID: "99015",
        colors: ["6007", "6002", "6001"],
        name: "Refined Natural stool",
        size: "S",
    },
    167600202: {
        themeID: "99015",
        colors: ["6008", "6009", "6001"],
        name: "Refined Natural stool",
        size: "S",
    },
    167600203: {
        themeID: "99015",
        colors: ["6011", "6009", "6001"],
        name: "Refined Natural stool",
        size: "S",
    },
    167800100: { themeID: "99004", colors: ["6009"], name: "Rattan stool", size: "S" },
    167800101: { themeID: "99004", colors: ["6007"], name: "Rattan stool", size: "S" },
    167800102: { themeID: "99004", colors: ["6002", "6009"], name: "Rattan stool", size: "S" },
    167800103: { themeID: "99004", colors: ["6004"], name: "Rattan stool", size: "S" },
    168000700: { themeID: "99002", colors: ["6006", "6009"], name: "Wooden bench", size: "M" },
    168000701: { themeID: "99002", colors: ["6001", "6004"], name: "Wooden bench", size: "M" },
    168000702: { themeID: "99002", colors: ["6011", "6001"], name: "Wooden bench", size: "M" },
    168000703: { themeID: "99002", colors: ["6008", "6001"], name: "Wooden bench", size: "M" },
    168000704: { themeID: "99002", colors: ["6002", "6004"], name: "Wooden bench", size: "M" },
    168000705: { themeID: "99002", colors: ["6007", "6001"], name: "Wooden bench", size: "M" },
    168100100: { themeID: "99003", colors: ["6003", "6011"], name: "Elegant bench", size: "M" },
    168100101: { themeID: "99003", colors: ["6005", "6009"], name: "Elegant bench", size: "M" },
    168100102: { themeID: "99003", colors: ["6010", "6004"], name: "Elegant bench", size: "M" },
    168100103: { themeID: "99003", colors: ["6001", "6004", "6002"], name: "Elegant bench", size: "M" },
    168200100: { themeID: "99007", colors: ["6005", "6009"], name: "Country Bench", size: "M" },
    168200101: { themeID: "99007", colors: ["6004"], name: "Country Bench", size: "M" },
    168200102: { themeID: "99007", colors: ["6001", "6006"], name: "Country Bench", size: "M" },
    168200200: {
        themeID: "99008",
        colors: ["6005", "6001", "6011"],
        name: "Bohemian inner bench",
        size: "M",
    },
    168200201: {
        themeID: "99008",
        colors: ["6010", "6011", "6005"],
        name: "Bohemian inner bench",
        size: "M",
    },
    168200202: {
        themeID: "99008",
        colors: ["6007", "6001", "6006"],
        name: "Bohemian inner bench",
        size: "M",
    },
    168300100: { themeID: "99009", colors: ["6006", "6009"], name: "Classic couch", size: "M" },
    168300101: { themeID: "99009", colors: ["6001", "6002"], name: "Classic couch", size: "M" },
    168300102: { themeID: "99009", colors: ["6011", "6001"], name: "Classic couch", size: "M" },
    168500100: { themeID: "99011", colors: ["6006", "6009"], name: "Dolly bench", size: "S" },
    168500101: { themeID: "99011", colors: ["6011", "6003"], name: "Dolly bench", size: "S" },
    168500102: { themeID: "99011", colors: ["6008", "6006"], name: "Dolly bench", size: "S" },
    168500103: { themeID: "99011", colors: ["6010", "6011"], name: "Dolly bench", size: "S" },
    168700100: { themeID: "99001", colors: ["6001", "6005"], name: "Urban otto bench", size: "M" },
    168700101: { themeID: "99001", colors: ["6008", "6001", "6005"], name: "Urban otto bench", size: "M" },
    168700102: { themeID: "99001", colors: ["6001", "6011", "6005"], name: "Urban otto bench", size: "M" },
    168700103: { themeID: "99001", colors: ["6002", "6001", "6005"], name: "Urban otto bench", size: "M" },
    169002200: { themeID: "99002", colors: ["6007"], name: "Striped Cushion", size: "S" },
    169002201: { themeID: "99002", colors: ["6008"], name: "Striped Cushion", size: "S" },
    169002202: { themeID: "99002", colors: ["6011"], name: "Striped Cushion", size: "S" },
    169002203: { themeID: "99002", colors: ["6006"], name: "Striped Cushion", size: "S" },
    169002204: { themeID: "99002", colors: ["6001"], name: "Striped Cushion", size: "S" },
    169002300: { themeID: "99006", colors: ["6001", "6004"], name: "Chequeury Cushion", size: "S" },
    169002301: { themeID: "99006", colors: ["6007"], name: "Chequeury Cushion", size: "S" },
    169002302: { themeID: "99006", colors: ["6008"], name: "Chequeury Cushion", size: "S" },
    169002303: { themeID: "99006", colors: ["6011", "6003"], name: "Chequeury Cushion", size: "S" },
    169002304: { themeID: "99006", colors: ["6009"], name: "Chequeury Cushion", size: "S" },
    169100100: { themeID: "99003", colors: ["6011", "6001"], name: "Girlie cushion", size: "S" },
    169100101: { themeID: "99003", colors: ["6005", "6001"], name: "Girlie cushion", size: "S" },
    169100102: { themeID: "99003", colors: ["6010", "6001"], name: "Girlie cushion", size: "S" },
    169100103: { themeID: "99003", colors: ["6008", "6001"], name: "Girlie cushion", size: "S" },
    169300200: { themeID: "99009", colors: ["6003"], name: "Classic low cushion", size: "S" },
    169300201: { themeID: "99009", colors: ["6001"], name: "Classic low cushion", size: "S" },
    169300202: { themeID: "99009", colors: ["6011"], name: "Classic low cushion", size: "S" },
    169500100: { themeID: "99011", colors: ["6006"], name: "Biscuit Cushion", size: "S" },
    169500101: { themeID: "99011", colors: ["6011"], name: "Biscuit Cushion", size: "S" },
    169500102: { themeID: "99011", colors: ["6008"], name: "Biscuit Cushion", size: "S" },
    169500103: { themeID: "99011", colors: ["6010"], name: "Biscuit Cushion", size: "S" },
    170002900: { themeID: "99003", colors: ["6003", "6011", "6001"], name: "Girlie swing", size: "XL" },
    170002901: { themeID: "99003", colors: ["6009", "6005", "6007"], name: "Girlie swing", size: "XL" },
    170002902: { themeID: "99003", colors: ["6010", "6002", "6004"], name: "Girlie swing", size: "XL" },
    170002903: {
        themeID: "99003",
        colors: ["6002", "6008", "6001", "6005", "6004"],
        name: "Girlie swing",
        size: "XL",
    },
    170003100: { themeID: "99005", colors: ["6011", "6010", "6001"], name: "Childlike Swing", size: "XL" },
    170003101: { themeID: "99005", colors: ["6010", "6001", "6004"], name: "Childlike Swing", size: "XL" },
    170003102: { themeID: "99005", colors: ["6008", "6001", "6004"], name: "Childlike Swing", size: "XL" },
    170003103: { themeID: "99005", colors: ["6006", "6001", "6004"], name: "Childlike Swing", size: "XL" },
    170100100: { themeID: "99002", colors: ["6009", "6007", "6006"], name: "Wheel swings", size: "L" },
    170100101: { themeID: "99002", colors: ["6011", "6001", "6007"], name: "Wheel swings", size: "L" },
    170100102: {
        themeID: "99002",
        colors: ["6008", "6007", "6001", "6006"],
        name: "Wheel swings",
        size: "L",
    },
    170100103: {
        themeID: "99002",
        colors: ["6002", "6007", "6001", "6010"],
        name: "Wheel swings",
        size: "L",
    },
    170100104: {
        themeID: "99002",
        colors: ["6007", "6006", "6001", "6004"],
        name: "Wheel swings",
        size: "L",
    },
    170100200: {
        themeID: "99017",
        colors: ["6003", "6009", "6006"],
        name: "Reindeer carriage",
        size: "XL",
        xmas: true,
    },
    170100201: {
        themeID: "99017",
        colors: ["6007", "6005", "6009"],
        name: "Reindeer carriage",
        size: "XL",
        xmas: true,
    },
    170100202: {
        themeID: "99017",
        colors: ["6008", "6010", "6004"],
        name: "Reindeer carriage",
        size: "XL",
        xmas: true,
    },
    170200100: { themeID: "99007", colors: ["6005", "6007", "6001"], name: "Country Swing", size: "XL" },
    170200101: { themeID: "99007", colors: ["6011", "6010", "6007"], name: "Country Swing", size: "XL" },
    170200102: {
        themeID: "99007",
        colors: ["6001", "6006", "6007", "6008"],
        name: "Country Swing",
        size: "XL",
    },
    170300100: { themeID: "99009", colors: ["6009", "6003", "6007"], name: "Classy swing", size: "XL" },
    170300101: { themeID: "99009", colors: ["6001", "6008", "6007"], name: "Classy swing", size: "XL" },
    170300102: { themeID: "99009", colors: ["6011", "6007", "6006"], name: "Classy swing", size: "XL" },
    170400100: { themeID: "99006", colors: ["6009", "6007", "6001"], name: "Vintage swing", size: "L" },
    170400101: { themeID: "99006", colors: ["6007", "6005", "6001"], name: "Vintage swing", size: "L" },
    170400102: { themeID: "99006", colors: ["6008", "6001", "6007"], name: "Vintage swing", size: "L" },
    170400103: { themeID: "99006", colors: ["6003", "6001", "6002"], name: "Vintage swing", size: "L" },
    171001000: {
        themeID: "99005",
        colors: ["6009", "6001", "6011", "6010"],
        name: "Little Cushion Cabinet",
        size: "S",
    },
    171001001: {
        themeID: "99005",
        colors: ["6011", "6001", "6010"],
        name: "Little Cushion Cabinet",
        size: "S",
    },
    171001002: {
        themeID: "99005",
        colors: ["6010", "6001", "6008"],
        name: "Little Cushion Cabinet",
        size: "S",
    },
    171001003: {
        themeID: "99005",
        colors: ["6008", "6001", "6010"],
        name: "Little Cushion Cabinet",
        size: "S",
    },
    171001004: {
        themeID: "99005",
        colors: ["6007", "6001", "6009"],
        name: "Little Cushion Cabinet",
        size: "S",
    },
    171001300: { themeID: "99006", colors: ["6009"], name: "Vintage Glass Cabinet", size: "M" },
    171001301: { themeID: "99006", colors: ["6007", "6006"], name: "Vintage Glass Cabinet", size: "M" },
    171001302: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage Glass Cabinet", size: "M" },
    171001303: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage Glass Cabinet", size: "M" },
    171001400: {
        themeID: "99006",
        colors: ["6009", "6007", "6003", "6006"],
        name: "Vintage Cabinet",
        size: "M",
    },
    171001401: { themeID: "99006", colors: ["6007", "6006"], name: "Vintage Cabinet", size: "M" },
    171001402: { themeID: "99006", colors: ["6008", "6009", "6006"], name: "Vintage Cabinet", size: "M" },
    171001403: { themeID: "99006", colors: ["6003", "6009", "6006"], name: "Vintage Cabinet", size: "M" },
    171100100: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie cabinet", size: "M" },
    171100101: { themeID: "99003", colors: ["6009", "6006"], name: "Girlie cabinet", size: "M" },
    171100102: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie cabinet", size: "M" },
    171100103: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie cabinet", size: "M" },
    171200100: { themeID: "99007", colors: ["6005", "6009", "6001"], name: "Country Cabinet", size: "M" },
    171200101: { themeID: "99007", colors: ["6011", "6001", "6010"], name: "Country Cabinet", size: "M" },
    171200102: { themeID: "99007", colors: ["6006", "6005", "6007"], name: "Country Cabinet", size: "M" },
    171200103: { themeID: "99007", colors: ["6008", "6001", "6010"], name: "Country Cabinet", size: "M" },
    171300100: { themeID: "99009", colors: ["6009", "6005"], name: "Antique cabinet", size: "M" },
    171300101: { themeID: "99009", colors: ["6002", "6001"], name: "Antique cabinet", size: "M" },
    171300102: { themeID: "99009", colors: ["6011", "6001"], name: "Antique cabinet", size: "M" },
    171700100: { themeID: "99001", colors: ["6001", "6008", "6004"], name: "Urban cabinet", size: "L" },
    171700101: { themeID: "99001", colors: ["6008", "6001", "6007"], name: "Urban cabinet", size: "L" },
    171700102: { themeID: "99001", colors: ["6011", "6007", "6001"], name: "Urban cabinet", size: "L" },
    171700103: {
        themeID: "99001",
        colors: ["6002", "6004", "6001", "6010"],
        name: "Urban cabinet",
        size: "L",
    },
    171700200: { themeID: "99011", colors: ["6006", "6009"], name: "Girl's wardrobe", size: "M" },
    171700201: { themeID: "99011", colors: ["6011", "6003"], name: "Girl's wardrobe", size: "M" },
    171700202: { themeID: "99011", colors: ["6008", "6009"], name: "Girl's wardrobe", size: "M" },
    171700203: { themeID: "99011", colors: ["6010", "6009", "6004"], name: "Girl's wardrobe", size: "M" },
    172400100: {
        themeID: "99006",
        colors: ["6009", "6005", "6007"],
        name: "Vintage wall cabinet",
        size: "S",
    },
    172400101: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage wall cabinet", size: "S" },
    172400102: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage wall cabinet", size: "S" },
    172400103: { themeID: "99006", colors: ["6002", "6004"], name: "Vintage wall cabinet", size: "S" },
    172700100: { themeID: "99001", colors: ["6001", "6003", "6008"], name: "Medicine cabinet", size: "S" },
    172700101: {
        themeID: "99001",
        colors: ["6008", "6007", "6001", "6003"],
        name: "Medicine cabinet",
        size: "S",
    },
    172700102: {
        themeID: "99001",
        colors: ["6011", "6007", "6003", "6001"],
        name: "Medicine cabinet",
        size: "S",
    },
    172700103: {
        themeID: "99001",
        colors: ["6002", "6010", "6001", "6003"],
        name: "Medicine cabinet",
        size: "S",
    },
    173000100: { themeID: "99001", colors: ["6001", "6006", "6003"], name: "Urban sideboard", size: "M" },
    173000101: { themeID: "99001", colors: ["6008", "6001", "6006"], name: "Urban sideboard", size: "M" },
    173000102: { themeID: "99001", colors: ["6011", "6001", "6006"], name: "Urban sideboard", size: "M" },
    173000103: { themeID: "99001", colors: ["6002", "6001", "6004"], name: "Urban sideboard", size: "M" },
    173000104: { themeID: "99001", colors: ["6007", "6001", "6006"], name: "Urban sideboard", size: "M" },
    173200100: { themeID: "99007", colors: ["6005", "6001", "6009"], name: "Country Sideboard", size: "M" },
    173200101: { themeID: "99007", colors: ["6011", "6008", "6010"], name: "Country Sideboard", size: "M" },
    173200102: { themeID: "99007", colors: ["6008", "6001"], name: "Country Sideboard", size: "M" },
    173200103: { themeID: "99007", colors: ["6007", "6006", "6009"], name: "Country Sideboard", size: "M" },
    173300100: { themeID: "99009", colors: ["6009", "6006"], name: "Antique sideboard", size: "M" },
    173300101: { themeID: "99009", colors: ["6001", "6003"], name: "Antique sideboard", size: "M" },
    173300102: { themeID: "99009", colors: ["6001", "6011"], name: "Antique sideboard", size: "M" },
    173500100: { themeID: "99011", colors: ["6006", "6009"], name: "Dolly Sideboard", size: "M" },
    173500101: { themeID: "99011", colors: ["6011", "6009"], name: "Dolly Sideboard", size: "M" },
    173500102: { themeID: "99011", colors: ["6008", "6009"], name: "Dolly Sideboard", size: "M" },
    173500103: { themeID: "99011", colors: ["6010", "6011", "6004"], name: "Dolly Sideboard", size: "M" },
    173600100: { themeID: "99015", colors: ["6009", "6007"], name: "Refined TV sideboard", size: "M" },
    173600101: { themeID: "99015", colors: ["6007", "6009"], name: "Refined TV sideboard", size: "M" },
    173600102: { themeID: "99015", colors: ["6008", "6009"], name: "Refined TV sideboard", size: "M" },
    173600103: { themeID: "99015", colors: ["6003", "6011"], name: "Refined TV sideboard", size: "M" },
    175000200: {
        themeID: "99002",
        colors: ["6009", "6007", "6003"],
        name: "Rustic magazine shelf",
        size: "S",
    },
    175000201: {
        themeID: "99002",
        colors: ["6001", "6011", "6008", "6004"],
        name: "Rustic magazine shelf",
        size: "S",
    },
    175000202: {
        themeID: "99002",
        colors: ["6002", "6008", "6005", "6001"],
        name: "Rustic magazine shelf",
        size: "S",
    },
    175000203: {
        themeID: "99002",
        colors: ["6011", "6001", "6008", "6004"],
        name: "Rustic magazine shelf",
        size: "S",
    },
    175000204: {
        themeID: "99002",
        colors: ["6008", "6001", "6005", "6009"],
        name: "Rustic magazine shelf",
        size: "S",
    },
    175000205: {
        themeID: "99002",
        colors: ["6007", "6001", "6006", "6005", "6003"],
        name: "Rustic magazine shelf",
        size: "S",
    },
    175001200: { themeID: "99006", colors: ["6009"], name: "Vintage Bookshelf", size: "L" },
    175001201: { themeID: "99006", colors: ["6006", "6007"], name: "Vintage Bookshelf", size: "L" },
    175001202: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage Bookshelf", size: "L" },
    175001203: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage Bookshelf", size: "L" },
    175001500: {
        themeID: "99003",
        colors: ["6003", "6011", "6007"],
        name: "Girlie magazine rack",
        size: "M",
    },
    175001501: {
        themeID: "99003",
        colors: ["6010", "6002", "6011"],
        name: "Girlie magazine rack",
        size: "M",
    },
    175001502: { themeID: "99003", colors: ["6009", "6007"], name: "Girlie magazine rack", size: "M" },
    175001503: {
        themeID: "99003",
        colors: ["6002", "6005", "6008"],
        name: "Girlie magazine rack",
        size: "M",
    },
    175200100: { themeID: "99007", colors: ["6005", "6009", "6006"], name: "Country Bookshelf", size: "L" },
    175200101: { themeID: "99007", colors: ["6011", "6010", "6004"], name: "Country Bookshelf", size: "L" },
    175200102: { themeID: "99007", colors: ["6006", "6007", "6009"], name: "Country Bookshelf", size: "L" },
    175200103: { themeID: "99007", colors: ["6010", "6008", "6004"], name: "Country Bookshelf", size: "L" },
    175300100: { themeID: "99009", colors: ["6009", "6006", "6011"], name: "Antique bookshelf", size: "M" },
    175300101: { themeID: "99009", colors: ["6001", "6011", "6003"], name: "Antique bookshelf", size: "M" },
    175300102: { themeID: "99009", colors: ["6011", "6005", "6004"], name: "Antique bookshelf", size: "M" },
    175700100: {
        themeID: "99011",
        colors: ["6005", "6006", "6009", "6011", "6008"],
        name: "Children's bookshelf",
        size: "XL",
    },
    175700101: {
        themeID: "99011",
        colors: ["6011", "6006", "6007", "6008", "6010"],
        name: "Children's bookshelf",
        size: "XL",
    },
    175700102: {
        themeID: "99011",
        colors: ["6008", "6009", "6005", "6011"],
        name: "Children's bookshelf",
        size: "XL",
    },
    175700103: {
        themeID: "99011",
        colors: ["6010", "6011", "6008", "6004", "6006"],
        name: "Children's bookshelf",
        size: "XL",
    },
    175700200: {
        themeID: "99005",
        colors: ["6005", "6006", "6007", "6011"],
        name: "Multi-use wooden shelf",
        size: "L",
    },
    175700201: {
        themeID: "99005",
        colors: ["6011", "6007", "6005"],
        name: "Multi-use wooden shelf",
        size: "L",
    },
    175700202: {
        themeID: "99005",
        colors: ["6008", "6009", "6011", "6010"],
        name: "Multi-use wooden shelf",
        size: "L",
    },
    175700203: {
        themeID: "99005",
        colors: ["6010", "6009", "6004", "6007"],
        name: "Multi-use wooden shelf",
        size: "L",
    },
    176000200: {
        themeID: "99002",
        colors: ["6009", "6006", "6003", "6007"],
        name: "Rustic bookshelf",
        size: "XS",
    },
    176000201: {
        themeID: "99002",
        colors: ["6001", "6011", "6008", "6002"],
        name: "Rustic bookshelf",
        size: "XS",
    },
    176000202: {
        themeID: "99002",
        colors: ["6002", "6008", "6005", "6001"],
        name: "Rustic bookshelf",
        size: "XS",
    },
    176000203: {
        themeID: "99002",
        colors: ["6011", "6008", "6004", "6001"],
        name: "Rustic bookshelf",
        size: "XS",
    },
    176000204: {
        themeID: "99002",
        colors: ["6008", "6005", "6009", "6001"],
        name: "Rustic bookshelf",
        size: "XS",
    },
    176000205: {
        themeID: "99002",
        colors: ["6007", "6003", "6005", "6006"],
        name: "Rustic bookshelf",
        size: "XS",
    },
    176300100: {
        themeID: "99009",
        colors: ["6009", "6006", "6004"],
        name: "Antique hanging bookshelf",
        size: "M",
    },
    176300101: {
        themeID: "99009",
        colors: ["6001", "6004", "6006"],
        name: "Antique hanging bookshelf",
        size: "M",
    },
    176300102: {
        themeID: "99009",
        colors: ["6011", "6006", "6004"],
        name: "Antique hanging bookshelf",
        size: "M",
    },
    176400100: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage hanging bookshelf", size: "S" },
    176400101: {
        themeID: "99006",
        colors: ["6008", "6007", "6001"],
        name: "Vintage hanging bookshelf",
        size: "S",
    },
    176400102: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage hanging bookshelf", size: "S" },
    176400103: {
        themeID: "99006",
        colors: ["6002", "6001", "6008"],
        name: "Vintage hanging bookshelf",
        size: "S",
    },
    176800100: {
        themeID: "99015",
        colors: ["6007", "6011", "6004"],
        name: "Natural hanging bookshelf",
        size: "S",
    },
    176800101: {
        themeID: "99015",
        colors: ["6009", "6011", "6003", "6004"],
        name: "Natural hanging bookshelf",
        size: "S",
    },
    176800102: {
        themeID: "99015",
        colors: ["6008", "6009", "6004", "6007"],
        name: "Natural hanging bookshelf",
        size: "S",
    },
    176800103: {
        themeID: "99015",
        colors: ["6003", "6007", "6004", "6008"],
        name: "Natural hanging bookshelf",
        size: "S",
    },
    176800200: { themeID: "99001", colors: ["6002", "6001", "6011"], name: "Urban bookself", size: "S" },
    176800201: { themeID: "99001", colors: ["6001", "6011", "6004"], name: "Urban bookself", size: "S" },
    176800202: { themeID: "99001", colors: ["6008", "6011", "6001"], name: "Urban bookself", size: "S" },
    176800203: { themeID: "99001", colors: ["6011", "6005", "6001"], name: "Urban bookself", size: "S" },
    177000100: { themeID: "99001", colors: ["6001", "6002", "6004"], name: "Modern LED TV", size: "S" },
    177000101: { themeID: "99001", colors: ["6004", "6002"], name: "Modern LED TV", size: "S" },
    177000102: { themeID: "99001", colors: ["6008", "6004", "6002"], name: "Modern LED TV", size: "S" },
    177000103: { themeID: "99001", colors: ["6003", "6002", "6004"], name: "Modern LED TV", size: "S" },
    177000104: { themeID: "99001", colors: ["6011", "6004", "6002"], name: "Modern LED TV", size: "S" },
    177000105: { themeID: "99001", colors: ["6007", "6002", "6004"], name: "Modern LED TV", size: "S" },
    177000800: { themeID: "99006", colors: ["6005", "6004", "6006"], name: "Vintage Television", size: "S" },
    177000801: { themeID: "99006", colors: ["6007", "6004", "6006"], name: "Vintage Television", size: "S" },
    177000802: { themeID: "99006", colors: ["6008", "6001", "6004"], name: "Vintage Television", size: "S" },
    177000803: { themeID: "99006", colors: ["6003", "6008", "6001"], name: "Vintage Television", size: "S" },
    177000804: { themeID: "99006", colors: ["6002", "6006", "6004"], name: "Vintage Television", size: "S" },
    177001000: { themeID: "99011", colors: ["6006", "6001", "6004"], name: "Dolly TV", size: "S" },
    177001001: { themeID: "99011", colors: ["6011", "6004", "6001"], name: "Dolly TV", size: "S" },
    177001002: { themeID: "99011", colors: ["6008", "6004", "6001"], name: "Dolly TV", size: "S" },
    177001003: { themeID: "99011", colors: ["6010", "6004", "6001"], name: "Dolly TV", size: "S" },
    178700100: { themeID: "99001", colors: ["6001", "6002", "6004"], name: "Urban wall screen", size: "S" },
    178700101: { themeID: "99001", colors: ["6008", "6004", "6002"], name: "Urban wall screen", size: "S" },
    178700102: { themeID: "99001", colors: ["6011", "6002", "6004"], name: "Urban wall screen", size: "S" },
    178700103: { themeID: "99001", colors: ["6003", "6002", "6004"], name: "Urban wall screen", size: "S" },
    178700104: { themeID: "99001", colors: ["6004", "6002"], name: "Urban wall screen", size: "S" },
    178700200: {
        themeID: "99011",
        colors: ["6009", "6011", "6003"],
        name: "Cute Television wall",
        size: "S",
    },
    178700201: {
        themeID: "99011",
        colors: ["6003", "6011", "6002"],
        name: "Cute Television wall",
        size: "S",
    },
    178700202: {
        themeID: "99011",
        colors: ["6008", "6006", "6002"],
        name: "Cute Television wall",
        size: "S",
    },
    178700203: {
        themeID: "99011",
        colors: ["6010", "6011", "6002"],
        name: "Cute Television wall",
        size: "S",
    },
    179000400: { themeID: "99001", colors: ["6001"], name: "Urban Telephone", size: "XS" },
    179000401: { themeID: "99001", colors: ["6008"], name: "Urban Telephone", size: "XS" },
    179000402: { themeID: "99001", colors: ["6011"], name: "Urban Telephone", size: "XS" },
    179000403: { themeID: "99001", colors: ["6007"], name: "Urban Telephone", size: "XS" },
    179000600: { themeID: "99003", colors: ["6001", "6004"], name: "Girlie telephone", size: "XS" },
    179000601: { themeID: "99003", colors: ["6005", "6002"], name: "Girlie telephone", size: "XS" },
    179000602: { themeID: "99003", colors: ["6011", "6003"], name: "Girlie telephone", size: "XS" },
    179000603: { themeID: "99003", colors: ["6010", "6002"], name: "Girlie telephone", size: "XS" },
    179000700: { themeID: "99006", colors: ["6005", "6006"], name: "Vintage Telephone", size: "S" },
    179000701: { themeID: "99006", colors: ["6007", "6006"], name: "Vintage Telephone", size: "S" },
    179000702: { themeID: "99006", colors: ["6008", "6001"], name: "Vintage Telephone", size: "S" },
    179000703: { themeID: "99006", colors: ["6003", "6006"], name: "Vintage Telephone", size: "S" },
    179000704: { themeID: "99006", colors: ["6001", "6004"], name: "Vintage Telephone", size: "S" },
    179001200: { themeID: "99011", colors: ["6006"], name: "Dolly telephone", size: "XS" },
    179001201: { themeID: "99011", colors: ["6011"], name: "Dolly telephone", size: "XS" },
    179001202: { themeID: "99011", colors: ["6008", "6001"], name: "Dolly telephone", size: "XS" },
    179001203: { themeID: "99011", colors: ["6010", "6001"], name: "Dolly telephone", size: "XS" },
    179300100: { themeID: "99009", colors: ["6009", "6006"], name: "Antique telephone", size: "S" },
    179300101: { themeID: "99009", colors: ["6004", "6001"], name: "Antique telephone", size: "S" },
    179300102: { themeID: "99009", colors: ["6011", "6005"], name: "Antique telephone", size: "S" },
    180100100: { themeID: "99002", colors: ["6006", "6005", "6009"], name: "Rustic phone", size: "XS" },
    180100101: { themeID: "99002", colors: ["6004", "6002"], name: "Rustic phone", size: "XS" },
    180100102: { themeID: "99002", colors: ["6011", "6003"], name: "Rustic phone", size: "XS" },
    180100103: { themeID: "99002", colors: ["6008"], name: "Rustic phone", size: "XS" },
    180100104: { themeID: "99002", colors: ["6002"], name: "Rustic phone", size: "XS" },
    180100105: { themeID: "99002", colors: ["6007", "6006"], name: "Rustic phone", size: "XS" },
    180200100: { themeID: "99007", colors: ["6009", "6005", "6001"], name: "Country Telephone", size: "S" },
    180200101: { themeID: "99007", colors: ["6011", "6001"], name: "Country Telephone", size: "S" },
    180200102: { themeID: "99007", colors: ["6006", "6007"], name: "Country Telephone", size: "S" },
    180200103: { themeID: "99007", colors: ["6008", "6004"], name: "Country Telephone", size: "S" },
    180200104: { themeID: "99007", colors: ["6005", "6001", "6006"], name: "Country Telephone", size: "S" },
    180400100: { themeID: "99006", colors: ["6009", "6006"], name: "Vintage wall telephone", size: "S" },
    180400101: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage wall telephone", size: "S" },
    180400102: {
        themeID: "99006",
        colors: ["6007", "6009", "6006"],
        name: "Vintage wall telephone",
        size: "S",
    },
    180400103: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage wall telephone", size: "S" },
    181301200: { themeID: "99011", colors: ["6006", "6007"], name: "Bunny ear fan", size: "S" },
    181301201: { themeID: "99011", colors: ["6011", "6008"], name: "Bunny ear fan", size: "S" },
    181301202: { themeID: "99011", colors: ["6008", "6006"], name: "Bunny ear fan", size: "S" },
    181301203: { themeID: "99011", colors: ["6010", "6006"], name: "Bunny ear fan", size: "S" },
    181301300: { themeID: "99013", colors: ["6006", "6009"], name: "Cat ear fan", size: "S" },
    181301301: { themeID: "99013", colors: ["6011", "6006"], name: "Cat ear fan", size: "S" },
    181301302: { themeID: "99013", colors: ["6008", "6011"], name: "Cat ear fan", size: "S" },
    181301303: { themeID: "99013", colors: ["6010", "6011"], name: "Cat ear fan", size: "S" },
    181700100: {
        themeID: "99001",
        colors: ["6001", "6006", "6004", "6002"],
        name: "Electric fan",
        size: "M",
    },
    181700101: {
        themeID: "99001",
        colors: ["6008", "6001", "6007", "6004", "6005", "6002"],
        name: "Electric fan",
        size: "M",
    },
    181700102: {
        themeID: "99001",
        colors: ["6011", "6002", "6001", "6007", "6004"],
        name: "Electric fan",
        size: "M",
    },
    181700103: {
        themeID: "99001",
        colors: ["6010", "6002", "6004", "6001"],
        name: "Electric fan",
        size: "M",
    },
    182100100: { themeID: "99001", colors: ["6001", "6002"], name: "Urban air conditioner", size: "S" },
    182100101: { themeID: "99001", colors: ["6008", "6002"], name: "Urban air conditioner", size: "S" },
    182100102: { themeID: "99001", colors: ["6011", "6003"], name: "Urban air conditioner", size: "S" },
    182100103: { themeID: "99001", colors: ["6004", "6002"], name: "Urban air conditioner", size: "S" },
    182100104: { themeID: "99001", colors: ["6007", "6001"], name: "Urban air conditioner", size: "S" },
    182200100: { themeID: "99007", colors: ["6005"], name: "Country Fan", size: "XS" },
    182200101: { themeID: "99007", colors: ["6011"], name: "Country Fan", size: "XS" },
    182200102: { themeID: "99007", colors: ["6006"], name: "Country Fan", size: "XS" },
    182200103: { themeID: "99007", colors: ["6004"], name: "Country Fan", size: "XS" },
    183000500: { themeID: "99001", colors: ["6007", "6002", "6001"], name: "Urban Laptop", size: "S" },
    183000501: { themeID: "99001", colors: ["6008", "6001", "6002"], name: "Urban Laptop", size: "S" },
    183000502: { themeID: "99001", colors: ["6011", "6001", "6002"], name: "Urban Laptop", size: "S" },
    183000503: { themeID: "99001", colors: ["6010", "6002", "6001"], name: "Urban Laptop", size: "S" },
    183001100: { themeID: "99011", colors: ["6006", "6004"], name: "Dolly mini laptop", size: "XS" },
    183001101: { themeID: "99011", colors: ["6011", "6004"], name: "Dolly mini laptop", size: "XS" },
    183001102: { themeID: "99011", colors: ["6008", "6001"], name: "Dolly mini laptop", size: "XS" },
    183001103: { themeID: "99011", colors: ["6010", "6001"], name: "Dolly mini laptop", size: "XS" },
    183100100: { themeID: "99001", colors: ["6001", "6008", "6002"], name: "Urban PC set", size: "S" },
    183100101: { themeID: "99001", colors: ["6008", "6001", "6002"], name: "Urban PC set", size: "S" },
    183100102: { themeID: "99001", colors: ["6011", "6002", "6001"], name: "Urban PC set", size: "S" },
    183100103: { themeID: "99001", colors: ["6002", "6010"], name: "Urban PC set", size: "S" },
    183100104: { themeID: "99001", colors: ["6007", "6001", "6002"], name: "Urban PC set", size: "S" },
    183301200: { themeID: "99013", colors: ["6006", "6008"], name: "Streamer PC", size: "M" },
    183301201: { themeID: "99013", colors: ["6011", "6010"], name: "Streamer PC", size: "M" },
    183301202: { themeID: "99013", colors: ["6008", "6007"], name: "Streamer PC", size: "M" },
    183301203: { themeID: "99013", colors: ["6010", "6008"], name: "Streamer PC", size: "M" },
    184000900: { themeID: "99006", colors: ["6006", "6001", "6009"], name: "Vintage Radio", size: "XS" },
    184000901: { themeID: "99006", colors: ["6007", "6006"], name: "Vintage Radio", size: "XS" },
    184000902: { themeID: "99006", colors: ["6008", "6001"], name: "Vintage Radio", size: "XS" },
    184000903: { themeID: "99006", colors: ["6003", "6006"], name: "Vintage Radio", size: "XS" },
    184000904: { themeID: "99006", colors: ["6009", "6006"], name: "Vintage Radio", size: "XS" },
    184200100: { themeID: "99007", colors: ["6002", "6004"], name: "Country Radio", size: "S" },
    184200101: { themeID: "99007", colors: ["6007", "6002"], name: "Country Radio", size: "S" },
    184200102: { themeID: "99007", colors: ["6006", "6002"], name: "Country Radio", size: "S" },
    184700100: { themeID: "99001", colors: ["6001"], name: "Urban radio", size: "S" },
    184700101: { themeID: "99001", colors: ["6007"], name: "Urban radio", size: "S" },
    184700102: { themeID: "99001", colors: ["6008"], name: "Urban radio", size: "S" },
    184700103: { themeID: "99001", colors: ["6011"], name: "Urban radio", size: "S" },
    184700104: { themeID: "99001", colors: ["6002"], name: "Urban radio", size: "S" },
    185000200: {
        themeID: "99001",
        colors: ["6001", "6004", "6002"],
        name: "Urban Audio Speakers",
        size: "M",
    },
    185000201: {
        themeID: "99001",
        colors: ["6008", "6009", "6001", "6002"],
        name: "Urban Audio Speakers",
        size: "M",
    },
    185000202: {
        themeID: "99001",
        colors: ["6011", "6009", "6001", "6002"],
        name: "Urban Audio Speakers",
        size: "M",
    },
    185000203: { themeID: "99001", colors: ["6002", "6010"], name: "Urban Audio Speakers", size: "M" },
    185000204: {
        themeID: "99001",
        colors: ["6007", "6009", "6001", "6002"],
        name: "Urban Audio Speakers",
        size: "M",
    },
    185100100: { themeID: "99003", colors: ["6011", "6003"], name: "Girlie speaker", size: "S" },
    185100101: { themeID: "99003", colors: ["6006", "6009"], name: "Girlie speaker", size: "S" },
    185100102: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie speaker", size: "S" },
    185100103: { themeID: "99003", colors: ["6008", "6002", "6001"], name: "Girlie speaker", size: "S" },
    185300100: { themeID: "99009", colors: ["6009", "6004"], name: "Antique speaker", size: "S" },
    185300101: { themeID: "99009", colors: ["6006", "6001", "6004"], name: "Antique speaker", size: "S" },
    185300102: { themeID: "99009", colors: ["6011", "6006", "6004"], name: "Antique speaker", size: "S" },
    186700100: { themeID: "99001", colors: ["6001", "6002"], name: "Urban Wall speaker", size: "XS" },
    186700101: { themeID: "99001", colors: ["6008", "6001"], name: "Urban Wall speaker", size: "XS" },
    186700102: { themeID: "99001", colors: ["6011", "6001"], name: "Urban Wall speaker", size: "XS" },
    186700103: { themeID: "99001", colors: ["6010", "6001"], name: "Urban Wall speaker", size: "XS" },
    186700200: { themeID: "99011", colors: ["6006"], name: "Bunny wall speaker", size: "S" },
    186700201: { themeID: "99011", colors: ["6011"], name: "Bunny wall speaker", size: "S" },
    186700202: { themeID: "99011", colors: ["6008"], name: "Bunny wall speaker", size: "S" },
    186700203: { themeID: "99011", colors: ["6010"], name: "Bunny wall speaker", size: "S" },
    187100100: { themeID: "99001", colors: ["6001"], name: "Urban alarm clock", size: "XS" },
    187100101: { themeID: "99001", colors: ["6008"], name: "Urban alarm clock", size: "XS" },
    187100102: { themeID: "99001", colors: ["6011"], name: "Urban alarm clock", size: "XS" },
    187100103: { themeID: "99001", colors: ["6002"], name: "Urban alarm clock", size: "XS" },
    187100104: { themeID: "99001", colors: ["6007"], name: "Urban alarm clock", size: "XS" },
    187130100: { themeID: "99013", colors: ["6011", "6010"], name: "Bunny Clock", size: "S" },
    187130101: { themeID: "99013", colors: ["6006", "6008"], name: "Bunny Clock", size: "S" },
    187130102: { themeID: "99013", colors: ["6008", "6010", "6007"], name: "Bunny Clock", size: "S" },
    187130103: { themeID: "99013", colors: ["6010", "6008"], name: "Bunny Clock", size: "S" },
    187200100: { themeID: "99007", colors: ["6009", "6008", "6007"], name: "Timber Clocktower", size: "M" },
    187200101: { themeID: "99007", colors: ["6005", "6007", "6006"], name: "Timber Clocktower", size: "M" },
    187200102: { themeID: "99007", colors: ["6004", "6010", "6011"], name: "Timber Clocktower", size: "M" },
    187200103: {
        themeID: "99007",
        colors: ["6006", "6009", "6008", "6007"],
        name: "Timber Clocktower",
        size: "M",
    },
    187300100: { themeID: "99009", colors: ["6009", "6006"], name: "Grandfather clock", size: "M" },
    187300101: { themeID: "99009", colors: ["6001", "6004"], name: "Grandfather clock", size: "M" },
    187300102: { themeID: "99009", colors: ["6011", "6004"], name: "Grandfather clock", size: "M" },
    187301200: { themeID: "99011", colors: ["6006"], name: "Dolly clock", size: "XS" },
    187301201: { themeID: "99011", colors: ["6011"], name: "Dolly clock", size: "XS" },
    187301202: { themeID: "99011", colors: ["6008", "6001"], name: "Dolly clock", size: "XS" },
    187301203: { themeID: "99011", colors: ["6010"], name: "Dolly clock", size: "XS" },
    187400100: {
        themeID: "99019",
        colors: ["6009", "6001", "6006", "6002"],
        name: "Stone clock tower",
        size: "M",
    },
    187400101: {
        themeID: "99019",
        colors: ["6002", "6004", "6001", "6006"],
        name: "Stone clock tower",
        size: "M",
    },
    187400102: {
        themeID: "99019",
        colors: ["6008", "6001", "6005", "6002"],
        name: "Stone clock tower",
        size: "M",
    },
    188000100: { themeID: "99002", colors: ["6009", "6001"], name: "Common clock", size: "S" },
    188000101: { themeID: "99002", colors: ["6011", "6001"], name: "Common clock", size: "S" },
    188000102: { themeID: "99002", colors: ["6008", "6001"], name: "Common clock", size: "S" },
    188000103: { themeID: "99002", colors: ["6002", "6001"], name: "Common clock", size: "S" },
    188000104: { themeID: "99002", colors: ["6001", "6004"], name: "Common clock", size: "S" },
    188000105: { themeID: "99002", colors: ["6007", "6001"], name: "Common clock", size: "S" },
    188000200: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie clock", size: "S" },
    188000201: { themeID: "99003", colors: ["6007", "6009"], name: "Girlie clock", size: "S" },
    188000202: { themeID: "99003", colors: ["6010", "6002"], name: "Girlie clock", size: "S" },
    188000203: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie clock", size: "S" },
    188000300: { themeID: "99005", colors: ["6003", "6005", "6001"], name: "Atomic Clock", size: "S" },
    188000301: { themeID: "99005", colors: ["6008", "6001", "6006"], name: "Atomic Clock", size: "S" },
    188000302: { themeID: "99005", colors: ["6010", "6001", "6011"], name: "Atomic Clock", size: "S" },
    188000400: { themeID: "99004", colors: ["6009", "6007", "6006"], name: "Wooden clock", size: "S" },
    188000401: { themeID: "99004", colors: ["6008", "6001", "6004"], name: "Wooden clock", size: "S" },
    188000402: { themeID: "99004", colors: ["6003", "6011", "6001"], name: "Wooden clock", size: "S" },
    188000403: { themeID: "99004", colors: ["6009", "6005", "6004"], name: "Wooden clock", size: "S" },
    188100100: { themeID: "99006", colors: ["6005", "6009", "6001"], name: "Vintage clock shelf", size: "S" },
    188100101: { themeID: "99006", colors: ["6007", "6006", "6001"], name: "Vintage clock shelf", size: "S" },
    188100102: { themeID: "99006", colors: ["6008", "6005", "6001"], name: "Vintage clock shelf", size: "S" },
    188100103: { themeID: "99006", colors: ["6003", "6009", "6001"], name: "Vintage clock shelf", size: "S" },
    188100104: { themeID: "99006", colors: ["6009", "6005", "6001"], name: "Vintage clock shelf", size: "S" },
    188100200: {
        themeID: "99017",
        colors: ["6003", "6001"],
        name: "Sweet house clock",
        size: "S",
        xmas: true,
    },
    188100201: {
        themeID: "99017",
        colors: ["6006", "6005"],
        name: "Sweet house clock",
        size: "S",
        xmas: true,
    },
    188100202: {
        themeID: "99017",
        colors: ["6010", "6011"],
        name: "Sweet house clock",
        size: "S",
        xmas: true,
    },
    188200100: { themeID: "99007", colors: ["6008", "6005"], name: "Cuckoo Hanging Clock", size: "S" },
    188200101: {
        themeID: "99007",
        colors: ["6010", "6006", "6001"],
        name: "Cuckoo Hanging Clock",
        size: "S",
    },
    188200102: { themeID: "99007", colors: ["6007", "6006"], name: "Cuckoo Hanging Clock", size: "S" },
    188200103: { themeID: "99007", colors: ["6009", "6005"], name: "Cuckoo Hanging Clock", size: "S" },
    188200200: { themeID: "99008", colors: ["6005", "6006"], name: "Bohemian Hanging Clock", size: "S" },
    188200201: { themeID: "99008", colors: ["6003", "6011"], name: "Bohemian Hanging Clock", size: "S" },
    188200202: { themeID: "99008", colors: ["6011", "6010"], name: "Bohemian Hanging Clock", size: "S" },
    188200203: { themeID: "99008", colors: ["6008", "6010"], name: "Bohemian Hanging Clock", size: "S" },
    188200204: { themeID: "99008", colors: ["6001", "6006"], name: "Bohemian Hanging Clock", size: "S" },
    188400100: { themeID: "99019", colors: ["6004", "6001"], name: "Stone wall clock", size: "S" },
    188400101: { themeID: "99019", colors: ["6006", "6001", "6002"], name: "Stone wall clock", size: "S" },
    188400102: { themeID: "99019", colors: ["6002", "6001", "6009"], name: "Stone wall clock", size: "S" },
    188400103: { themeID: "99019", colors: ["6008", "6001"], name: "Stone wall clock", size: "S" },
    189000300: {
        themeID: "99001",
        colors: ["6005", "6004", "6001"],
        name: "Urban Vacuum Cleaner",
        size: "M",
    },
    189000301: {
        themeID: "99001",
        colors: ["6008", "6004", "6001"],
        name: "Urban Vacuum Cleaner",
        size: "M",
    },
    189000302: {
        themeID: "99001",
        colors: ["6011", "6004", "6001"],
        name: "Urban Vacuum Cleaner",
        size: "M",
    },
    189000303: {
        themeID: "99001",
        colors: ["6007", "6004", "6001"],
        name: "Urban Vacuum Cleaner",
        size: "M",
    },
    189000304: {
        themeID: "99001",
        colors: ["6010", "6004", "6001"],
        name: "Urban Vacuum Cleaner",
        size: "M",
    },
    189400100: { themeID: "99006", colors: ["6007", "6009", "6004"], name: "Vintage vacuum", size: "M" },
    189400101: { themeID: "99006", colors: ["6008", "6009", "6004"], name: "Vintage vacuum", size: "M" },
    189400102: { themeID: "99006", colors: ["6003", "6009", "6004"], name: "Vintage vacuum", size: "M" },
    189400103: { themeID: "99006", colors: ["6009", "6002", "6004"], name: "Vintage vacuum", size: "M" },
    190100100: {
        themeID: "99001",
        colors: ["6001", "6008", "6002"],
        name: "Urban washing machine",
        size: "L",
    },
    190100101: {
        themeID: "99001",
        colors: ["6008", "6001", "6007"],
        name: "Urban washing machine",
        size: "L",
    },
    190100102: {
        themeID: "99001",
        colors: ["6011", "6007", "6001", "6002"],
        name: "Urban washing machine",
        size: "L",
    },
    190100103: {
        themeID: "99001",
        colors: ["6002", "6010", "6001"],
        name: "Urban washing machine",
        size: "L",
    },
    190100104: {
        themeID: "99001",
        colors: ["6007", "6001", "6008"],
        name: "Urban washing machine",
        size: "L",
    },
    190700100: {
        themeID: "99011",
        colors: ["6006", "6007", "6009"],
        name: "Dolly washing machine",
        size: "M",
    },
    190700101: {
        themeID: "99011",
        colors: ["6011", "6001", "6006"],
        name: "Dolly washing machine",
        size: "M",
    },
    190700102: {
        themeID: "99011",
        colors: ["6008", "6011", "6009"],
        name: "Dolly washing machine",
        size: "M",
    },
    190700103: {
        themeID: "99011",
        colors: ["6010", "6011", "6009"],
        name: "Dolly washing machine",
        size: "M",
    },
    190700200: {
        themeID: "99015",
        colors: ["6009", "6007", "6006"],
        name: "Refined washing machine",
        size: "M",
    },
    190700201: {
        themeID: "99015",
        colors: ["6007", "6006", "6011", "6009"],
        name: "Refined washing machine",
        size: "M",
    },
    190700202: {
        themeID: "99015",
        colors: ["6008", "6004", "6011", "6007"],
        name: "Refined washing machine",
        size: "M",
    },
    190700203: {
        themeID: "99015",
        colors: ["6003", "6011", "6007", "6008"],
        name: "Refined washing machine",
        size: "M",
    },
    193301200: { themeID: "99013", colors: ["6004", "6003", "6001"], name: "Game console", size: "XS" },
    193301201: { themeID: "99013", colors: ["6011", "6006", "6002"], name: "Game console", size: "XS" },
    193301202: { themeID: "99013", colors: ["6008", "6006", "6003"], name: "Game console", size: "XS" },
    193301203: { themeID: "99013", colors: ["6010", "6006", "6002"], name: "Game console", size: "XS" },
    193301300: { themeID: "99013", colors: ["6001", "6002", "6004"], name: "Game pad", size: "S" },
    193301301: { themeID: "99013", colors: ["6011", "6001", "6008"], name: "Game pad", size: "S" },
    193301302: { themeID: "99013", colors: ["6008", "6006", "6002"], name: "Game pad", size: "S" },
    193301303: { themeID: "99013", colors: ["6010", "6006", "6004"], name: "Game pad", size: "S" },
    194100100: { themeID: "99003", colors: ["6011", "6003"], name: "Girlie dryer", size: "S" },
    194100101: { themeID: "99003", colors: ["6005", "6007"], name: "Girlie dryer", size: "S" },
    194100102: { themeID: "99003", colors: ["6010", "6008"], name: "Girlie dryer", size: "S" },
    194100103: { themeID: "99003", colors: ["6008", "6001", "6005"], name: "Girlie dryer", size: "S" },
    194500100: { themeID: "99011", colors: ["6005", "6006"], name: "Dolly Hairdryer", size: "S" },
    194500101: { themeID: "99011", colors: ["6011"], name: "Dolly Hairdryer", size: "S" },
    194500102: { themeID: "99011", colors: ["6008"], name: "Dolly Hairdryer", size: "S" },
    194500103: { themeID: "99011", colors: ["6010"], name: "Dolly Hairdryer", size: "S" },
    195000100: { themeID: "99001", colors: ["6003", "6001"], name: "Urban House model", size: "XS" },
    195000101: { themeID: "99001", colors: ["6005", "6007", "6001"], name: "Urban House model", size: "XS" },
    195000102: { themeID: "99001", colors: ["6004", "6001", "6008"], name: "Urban House model", size: "XS" },
    195000600: { themeID: "99004", colors: ["6007", "6008"], name: "Little Crab", size: "XS" },
    195000601: { themeID: "99004", colors: ["6009", "6005"], name: "Little Crab", size: "XS" },
    195000602: { themeID: "99004", colors: ["6003"], name: "Little Crab", size: "XS" },
    195000700: { themeID: "99004", colors: ["6007"], name: "Bigger Crab", size: "S" },
    195000701: { themeID: "99004", colors: ["6011"], name: "Bigger Crab", size: "S" },
    195000702: { themeID: "99004", colors: ["6005"], name: "Bigger Crab", size: "S" },
    195000703: { themeID: "99004", colors: ["6009", "6003"], name: "Bigger Crab", size: "S" },
    195001200: { themeID: "99005", colors: ["6005", "6007", "6001"], name: "Little Teddybear", size: "S" },
    195001201: { themeID: "99005", colors: ["6011", "6008", "6001"], name: "Little Teddybear", size: "S" },
    195001202: { themeID: "99005", colors: ["6010", "6008", "6001"], name: "Little Teddybear", size: "S" },
    195001203: { themeID: "99005", colors: ["6008", "6010", "6004"], name: "Little Teddybear", size: "S" },
    195001204: { themeID: "99005", colors: ["6007", "6005", "6001"], name: "Little Teddybear", size: "S" },
    195001300: { themeID: "99005", colors: ["6005", "6009"], name: "Childlike Hobbyhorse", size: "S" },
    195001301: { themeID: "99005", colors: ["6011", "6003"], name: "Childlike Hobbyhorse", size: "S" },
    195001302: { themeID: "99005", colors: ["6010"], name: "Childlike Hobbyhorse", size: "S" },
    195001303: { themeID: "99005", colors: ["6008"], name: "Childlike Hobbyhorse", size: "S" },
    195001400: { themeID: "99005", colors: ["6005", "6001", "6006"], name: "Fluffy Sheepie", size: "S" },
    195001401: { themeID: "99005", colors: ["6011", "6001", "6006"], name: "Fluffy Sheepie", size: "S" },
    195001402: { themeID: "99005", colors: ["6008", "6004", "6006"], name: "Fluffy Sheepie", size: "S" },
    195001403: { themeID: "99005", colors: ["6002", "6011", "6006"], name: "Fluffy Sheepie", size: "S" },
    195001500: { themeID: "99005", colors: ["6007", "6005"], name: "Colored Pencils Box", size: "S" },
    195001501: {
        themeID: "99005",
        colors: ["6010", "6008", "6003", "6007"],
        name: "Colored Pencils Box",
        size: "S",
    },
    195001502: {
        themeID: "99005",
        colors: ["6005", "6008", "6006", "6011"],
        name: "Colored Pencils Box",
        size: "S",
    },
    195001700: { themeID: "99005", colors: ["6006", "6009"], name: "Vespa scooter toy", size: "S" },
    195001701: { themeID: "99005", colors: ["6011", "6010"], name: "Vespa scooter toy", size: "S" },
    195001702: { themeID: "99005", colors: ["6008", "6002"], name: "Vespa scooter toy", size: "S" },
    195001703: { themeID: "99005", colors: ["6007", "6009"], name: "Vespa scooter toy", size: "S" },
    195001704: { themeID: "99005", colors: ["6010", "6002"], name: "Vespa scooter toy", size: "S" },
    195001705: { themeID: "99005", colors: ["6001", "6003"], name: "Vespa scooter toy", size: "S" },
    195001800: { themeID: "99005", colors: ["6005", "6008", "6002"], name: "Cab model", size: "S" },
    195001801: { themeID: "99005", colors: ["6003", "6010", "6002"], name: "Cab model", size: "S" },
    195001802: { themeID: "99005", colors: ["6011", "6010", "6002"], name: "Cab model", size: "S" },
    195001803: { themeID: "99005", colors: ["6008", "6002", "6001"], name: "Cab model", size: "S" },
    195001804: { themeID: "99005", colors: ["6007", "6002", "6006"], name: "Cab model", size: "S" },
    195001900: { themeID: "99005", colors: ["6006", "6008", "6002"], name: "Porsche model", size: "S" },
    195001901: { themeID: "99005", colors: ["6005", "6008", "6002"], name: "Porsche model", size: "S" },
    195001902: { themeID: "99005", colors: ["6011", "6008", "6002"], name: "Porsche model", size: "S" },
    195001903: { themeID: "99005", colors: ["6007", "6006", "6002"], name: "Porsche model", size: "S" },
    195001904: { themeID: "99005", colors: ["6008", "6006", "6002"], name: "Porsche model", size: "S" },
    195002000: { themeID: "99005", colors: ["6003", "6001"], name: "Big Sheepie", size: "M" },
    195002001: { themeID: "99005", colors: ["6002", "6003"], name: "Big Sheepie", size: "M" },
    195002002: { themeID: "99005", colors: ["6008", "6004"], name: "Big Sheepie", size: "M" },
    195002003: { themeID: "99005", colors: ["6005", "6001"], name: "Big Sheepie", size: "M" },
    195002100: { themeID: "99001", colors: ["6003"], name: "Uno Cards", size: "XS" },
    195002101: { themeID: "99001", colors: ["6007"], name: "Uno Cards", size: "XS" },
    195002102: { themeID: "99001", colors: ["6002"], name: "Uno Cards", size: "XS" },
    195002103: { themeID: "99001", colors: ["6008"], name: "Uno Cards", size: "XS" },
    195003200: { themeID: "99005", colors: ["6005", "6001", "6010"], name: "Childlike Slide", size: "XL" },
    195003201: {
        themeID: "99005",
        colors: ["6011", "6001", "6010", "6005"],
        name: "Childlike Slide",
        size: "XL",
    },
    195003202: {
        themeID: "99005",
        colors: ["6008", "6001", "6010", "6005"],
        name: "Childlike Slide",
        size: "XL",
    },
    195003203: {
        themeID: "99005",
        colors: ["6007", "6001", "6005", "6011"],
        name: "Childlike Slide",
        size: "XL",
    },
    195003300: {
        themeID: "99005",
        colors: ["6009", "6005", "6010", "6001"],
        name: "Playground Seesaw",
        size: "L",
    },
    195003301: {
        themeID: "99005",
        colors: ["6011", "6010", "6008", "6001"],
        name: "Playground Seesaw",
        size: "L",
    },
    195003302: {
        themeID: "99005",
        colors: ["6008", "6010", "6004", "6001"],
        name: "Playground Seesaw",
        size: "L",
    },
    195003303: {
        themeID: "99005",
        colors: ["6007", "6001", "6009", "6005"],
        name: "Playground Seesaw",
        size: "L",
    },
    195003500: { themeID: "99011", colors: ["6005", "6001"], name: "Dolly Bunny", size: "S" },
    195003501: { themeID: "99011", colors: ["6011", "6001"], name: "Dolly Bunny", size: "S" },
    195003502: { themeID: "99011", colors: ["6008", "6001"], name: "Dolly Bunny", size: "S" },
    195003503: { themeID: "99011", colors: ["6010", "6001"], name: "Dolly Bunny", size: "S" },
    195003600: { themeID: "99008", colors: ["6003", "6010"], name: "Tarot cards", size: "XS" },
    195003601: { themeID: "99008", colors: ["6010"], name: "Tarot cards", size: "XS" },
    195003602: { themeID: "99008", colors: ["6010", "6006"], name: "Tarot cards", size: "XS" },
    195100100: { themeID: "99003", colors: ["6011", "6009"], name: "Rabbit plushie", size: "XS" },
    195100101: { themeID: "99003", colors: ["6006", "6005"], name: "Rabbit plushie", size: "XS" },
    195100102: { themeID: "99003", colors: ["6001", "6002"], name: "Rabbit plushie", size: "XS" },
    195100103: { themeID: "99003", colors: ["6008", "6009"], name: "Rabbit plushie", size: "XS" },
    195100400: { themeID: "99017", colors: [""], name: "Snow bunny", size: "L", xmas: true },
    195100401: { themeID: "99017", colors: [""], name: "Snow bunny", size: "L", xmas: true },
    195100402: { themeID: "99017", colors: [""], name: "Snow bunny", size: "L", xmas: true },
    195100500: {
        themeID: "99017",
        colors: ["6003", "6002", "6001"],
        name: "Big boi penguin",
        size: "L",
        xmas: true,
    },
    195100501: {
        themeID: "99017",
        colors: ["6007", "6002", "6001"],
        name: "Big boi penguin",
        size: "L",
        xmas: true,
    },
    195100502: {
        themeID: "99017",
        colors: ["6008", "6001", "6002"],
        name: "Big boi penguin",
        size: "L",
        xmas: true,
    },
    195100600: { themeID: "99017", colors: ["6001"], name: "Lively penguin", size: "XS", xmas: true },
    195100601: { themeID: "99017", colors: ["6002"], name: "Lively penguin", size: "XS", xmas: true },
    195100602: { themeID: "99017", colors: ["6003"], name: "Lively penguin", size: "XS", xmas: true },
    195100700: {
        themeID: "99017",
        colors: ["6003", "6001"],
        name: "Chillin' penguin",
        size: "S",
        xmas: true,
    },
    195100701: {
        themeID: "99017",
        colors: ["6007", "6001"],
        name: "Chillin' penguin",
        size: "S",
        xmas: true,
    },
    195100702: {
        themeID: "99017",
        colors: ["6008", "6001"],
        name: "Chillin' penguin",
        size: "S",
        xmas: true,
    },
    195100800: { themeID: "99003", colors: ["6005", "6008", "6003"], name: "Girlie Doll", size: "S" },
    195100801: { themeID: "99003", colors: ["6011", "6003", "6001"], name: "Girlie Doll", size: "S" },
    195100802: { themeID: "99003", colors: ["6009", "6006", "6003"], name: "Girlie Doll", size: "S" },
    195100803: { themeID: "99003", colors: ["6010", "6001", "6011"], name: "Girlie Doll", size: "S" },
    195130100: { themeID: "99013", colors: ["6002", "6001", "6008"], name: "Fiordland penguin", size: "XS" },
    195130101: { themeID: "99013", colors: ["6004", "6001", "6008"], name: "Fiordland penguin", size: "XS" },
    195130102: { themeID: "99013", colors: ["6005", "6001", "6007"], name: "Fiordland penguin", size: "XS" },
    195130200: { themeID: "99013", colors: ["6011", "6010"], name: "Stuffed Unicorn", size: "S" },
    195130201: { themeID: "99013", colors: ["6008", "6001"], name: "Stuffed Unicorn", size: "S" },
    195130202: { themeID: "99013", colors: ["6010", "6001"], name: "Stuffed Unicorn", size: "S" },
    195130203: { themeID: "99013", colors: ["6006", "6001"], name: "Stuffed Unicorn", size: "S" },
    195130300: { themeID: "99013", colors: ["6008", "6010"], name: "Kitty UFO", size: "S" },
    195130301: { themeID: "99013", colors: ["6006", "6008", "6011"], name: "Kitty UFO", size: "S" },
    195130302: { themeID: "99013", colors: ["6011", "6007", "6006"], name: "Kitty UFO", size: "S" },
    195130303: { themeID: "99013", colors: ["6010", "6006"], name: "Kitty UFO", size: "S" },
    195130400: { themeID: "99013", colors: ["6011", "6001"], name: "Magical Idol figure", size: "S" },
    195130401: { themeID: "99013", colors: ["6008", "6010", "6001"], name: "Magical Idol figure", size: "S" },
    195130402: { themeID: "99013", colors: ["6006", "6001", "6005"], name: "Magical Idol figure", size: "S" },
    195130403: { themeID: "99013", colors: ["6010", "6001"], name: "Magical Idol figure", size: "S" },
    195130500: {
        themeID: "99013",
        colors: ["6011", "6001", "6006"],
        name: "Daruma tumbler doll",
        size: "XS",
    },
    195130501: {
        themeID: "99013",
        colors: ["6006", "6003", "6001"],
        name: "Daruma tumbler doll",
        size: "XS",
    },
    195130502: {
        themeID: "99013",
        colors: ["6008", "6001", "6005"],
        name: "Daruma tumbler doll",
        size: "XS",
    },
    195130503: {
        themeID: "99013",
        colors: ["6010", "6005", "6001"],
        name: "Daruma tumbler doll",
        size: "XS",
    },
    195130504: { themeID: "99013", colors: ["6001", "6008"], name: "Daruma tumbler doll", size: "XS" },
    195140100: { themeID: "99005", colors: ["6004", "6011"], name: "Childlike tent", size: "M" },
    195201100: { themeID: "99013", colors: ["6002"], name: "Polka pup", size: "XS" },
    195201101: { themeID: "99013", colors: ["6004"], name: "Polka pup", size: "XS" },
    195301200: {
        themeID: "99013",
        colors: ["6006", "6007", "6002"],
        name: "Ninja Bat Cat figure",
        size: "XS",
    },
    195301201: {
        themeID: "99013",
        colors: ["6011", "6007", "6002"],
        name: "Ninja Bat Cat figure",
        size: "XS",
    },
    195301202: {
        themeID: "99013",
        colors: ["6008", "6006", "6007"],
        name: "Ninja Bat Cat figure",
        size: "XS",
    },
    195301203: {
        themeID: "99013",
        colors: ["6010", "6011", "6005"],
        name: "Ninja Bat Cat figure",
        size: "XS",
    },
    195301300: { themeID: "99011", colors: ["6006", "6004"], name: "Little Girl figure", size: "XS" },
    195301301: { themeID: "99011", colors: ["6011", "6006"], name: "Little Girl figure", size: "XS" },
    195301302: { themeID: "99011", colors: ["6010", "6008"], name: "Little Girl figure", size: "XS" },
    195301303: { themeID: "99011", colors: ["6007", "6006", "6011"], name: "Little Girl figure", size: "XS" },
    195400100: { themeID: "99006", colors: ["6008", "6003", "6002"], name: "Vintage car toy", size: "S" },
    195400101: { themeID: "99006", colors: ["6007", "6005", "6009"], name: "Vintage car toy", size: "S" },
    195400102: { themeID: "99006", colors: ["6005", "6004", "6009"], name: "Vintage car toy", size: "S" },
    195700100: { themeID: "99001", colors: ["6007", "6006", "6001"], name: "Urban party balloon", size: "S" },
    195700101: {
        themeID: "99001",
        colors: ["6008", "6007", "6001", "6006"],
        name: "Urban party balloon",
        size: "S",
    },
    195700102: {
        themeID: "99001",
        colors: ["6011", "6005", "6001", "6006"],
        name: "Urban party balloon",
        size: "S",
    },
    195700103: {
        themeID: "99001",
        colors: ["6010", "6011", "6001", "6006"],
        name: "Urban party balloon",
        size: "S",
    },
    195700800: { themeID: "99017", colors: [""], name: "Xmas mini giftbox", size: "XS", xmas: true },
    195700801: { themeID: "99017", colors: [""], name: "Xmas mini giftbox", size: "XS", xmas: true },
    195700802: { themeID: "99017", colors: [""], name: "Xmas mini giftbox", size: "XS", xmas: true },
    195700900: { themeID: "99017", colors: [""], name: "Xmas duo giftboxes", size: "S", xmas: true },
    195700901: { themeID: "99017", colors: [""], name: "Xmas duo giftboxes", size: "S", xmas: true },
    195700902: { themeID: "99017", colors: [""], name: "Xmas duo giftboxes", size: "S", xmas: true },
    195701000: { themeID: "99011", colors: ["6010", "6001", "6011"], name: "Bewildered Cat", size: "XS" },
    195701001: { themeID: "99011", colors: ["6006", "6005", "6001"], name: "Bewildered Cat", size: "XS" },
    195701002: { themeID: "99011", colors: ["6011", "6005"], name: "Bewildered Cat", size: "XS" },
    195701100: { themeID: "99011", colors: ["6007", "6006", "6003"], name: "Nonchalant Dolly", size: "S" },
    195701101: { themeID: "99011", colors: ["6011", "6004", "6008"], name: "Nonchalant Dolly", size: "S" },
    195701102: { themeID: "99011", colors: ["6008", "6011", "6006"], name: "Nonchalant Dolly", size: "S" },
    195701103: { themeID: "99011", colors: ["6010", "6011", "6001"], name: "Nonchalant Dolly", size: "S" },
    195800100: { themeID: "99001", colors: ["6005", "6006"], name: "Urban Piggy bank", size: "S" },
    195800101: { themeID: "99001", colors: ["6006", "6001"], name: "Urban Piggy bank", size: "S" },
    195800102: { themeID: "99001", colors: ["6011", "6006"], name: "Urban Piggy bank", size: "S" },
    195800200: { themeID: "99001", colors: ["6006", "6001"], name: "Urban little piggy bank", size: "XS" },
    195800201: { themeID: "99001", colors: ["6011", "6006"], name: "Urban little piggy bank", size: "XS" },
    195800202: { themeID: "99001", colors: ["6005", "6006"], name: "Urban little piggy bank", size: "XS" },
    195800300: { themeID: "99015", colors: ["6006"], name: "Natural birdie", size: "XS" },
    195800301: { themeID: "99015", colors: ["6007"], name: "Natural birdie", size: "XS" },
    195800302: { themeID: "99015", colors: ["6008"], name: "Natural birdie", size: "XS" },
    195800303: { themeID: "99015", colors: ["6003"], name: "Natural birdie", size: "XS" },
    195900100: {
        themeID: "99017",
        colors: ["6009", "6007"],
        name: "Wakie Reindeer",
        size: "S",
        xmas: true,
    },
    195900200: {
        themeID: "99017",
        colors: ["6009", "6008"],
        name: "Sleepie Reindeer",
        size: "S",
        xmas: true,
    },
    195900300: { themeID: "99017", colors: ["6001", "6003"], name: "Snowman", size: "S", xmas: true },
    195900400: { themeID: "99017", colors: ["6001", "6011"], name: "Snowgirl", size: "S", xmas: true },
    195900500: { themeID: "99017", colors: [""], name: "Xmas big giftbox", size: "S", xmas: true },
    195900501: { themeID: "99017", colors: [""], name: "Xmas big giftbox", size: "S", xmas: true },
    195900502: { themeID: "99017", colors: [""], name: "Xmas big giftbox", size: "S", xmas: true },
    195900503: { themeID: "99017", colors: [""], name: "Xmas big giftbox", size: "S", xmas: true },
    195900504: { themeID: "99017", colors: [""], name: "Xmas big giftbox", size: "S", xmas: true },
    195900600: { themeID: "99017", colors: [""], name: "Xmas medium giftbox", size: "S", xmas: true },
    195900601: { themeID: "99017", colors: [""], name: "Xmas medium giftbox", size: "S", xmas: true },
    195900602: { themeID: "99017", colors: [""], name: "Xmas medium giftbox", size: "S", xmas: true },
    195900603: { themeID: "99017", colors: [""], name: "Xmas medium giftbox", size: "S", xmas: true },
    195900700: { themeID: "99017", colors: [""], name: "Xmas little giftbox", size: "XS", xmas: true },
    195900701: { themeID: "99017", colors: [""], name: "Xmas little giftbox", size: "XS", xmas: true },
    195900702: { themeID: "99017", colors: [""], name: "Xmas little giftbox", size: "XS", xmas: true },
    195900703: { themeID: "99017", colors: [""], name: "Xmas little giftbox", size: "XS", xmas: true },
    196100100: { themeID: "99001", colors: ["6001"], name: "Popular music player", size: "S" },
    196100101: { themeID: "99001", colors: ["6007"], name: "Popular music player", size: "S" },
    196100102: { themeID: "99001", colors: ["6008"], name: "Popular music player", size: "S" },
    196100103: { themeID: "99001", colors: ["6011"], name: "Popular music player", size: "S" },
    196100104: { themeID: "99001", colors: ["6002"], name: "Popular music player", size: "S" },
    196200100: { themeID: "99007", colors: ["6003", "6006", "6002"], name: "Country Gramophone", size: "S" },
    196200101: { themeID: "99007", colors: ["6004", "6002", "6006"], name: "Country Gramophone", size: "S" },
    196200102: { themeID: "99007", colors: ["6006", "6002", "6004"], name: "Country Gramophone", size: "S" },
    196300100: {
        themeID: "99009",
        colors: ["6009", "6007", "6006", "6001", "6003"],
        name: "Antique Piano",
        size: "XL",
    },
    196300101: {
        themeID: "99009",
        colors: ["6008", "6003", "6001", "6006"],
        name: "Antique Piano",
        size: "XL",
    },
    196300102: {
        themeID: "99009",
        colors: ["6011", "6003", "6006", "6008"],
        name: "Antique Piano",
        size: "XL",
    },
    196300200: { themeID: "99009", colors: ["6006", "6009", "6007"], name: "Micro creepers", size: "S" },
    196300201: { themeID: "99009", colors: ["6004", "6001", "6007"], name: "Micro creepers", size: "S" },
    196300202: { themeID: "99009", colors: ["6011", "6001", "6007"], name: "Micro creepers", size: "S" },
    196300300: { themeID: "99009", colors: ["6006", "6009", "6002"], name: "Antique Gramophone", size: "S" },
    196300301: { themeID: "99009", colors: ["6004", "6001", "6008"], name: "Antique Gramophone", size: "S" },
    196300302: { themeID: "99009", colors: ["6011", "6002", "6001"], name: "Antique Gramophone", size: "S" },
    196300500: { themeID: "99009", colors: ["6009", "6002"], name: "Antique Cello", size: "M" },
    196300501: { themeID: "99009", colors: ["6006", "6002"], name: "Antique Cello", size: "M" },
    196300502: { themeID: "99009", colors: ["6011", "6002"], name: "Antique Cello", size: "M" },
    196300600: { themeID: "99009", colors: ["6009", "6007"], name: "Antique Harp", size: "S" },
    196300601: { themeID: "99009", colors: ["6001", "6006", "6007"], name: "Antique Harp", size: "S" },
    196300602: { themeID: "99009", colors: ["6011", "6003"], name: "Antique Harp", size: "S" },
    196301200: { themeID: "99013", colors: ["6006", "6007"], name: "Cat ear headphone", size: "S" },
    196301201: { themeID: "99013", colors: ["6011", "6008"], name: "Cat ear headphone", size: "S" },
    196301202: { themeID: "99013", colors: ["6008", "6006"], name: "Cat ear headphone", size: "S" },
    196301203: { themeID: "99013", colors: ["6010", "6011"], name: "Cat ear headphone", size: "S" },
    196400100: {
        themeID: "99006",
        colors: ["6007", "6005", "6009", "6001"],
        name: "Vintage piano",
        size: "XL",
    },
    196400101: {
        themeID: "99006",
        colors: ["6008", "6006", "6007", "6001", "6009"],
        name: "Vintage piano",
        size: "XL",
    },
    196400102: {
        themeID: "99006",
        colors: ["6002", "6010", "6007", "6004"],
        name: "Vintage piano",
        size: "XL",
    },
    196400103: {
        themeID: "99006",
        colors: ["6004", "6007", "6006", "6002"],
        name: "Vintage piano",
        size: "XL",
    },
    196400104: {
        themeID: "99006",
        colors: ["6003", "6009", "6007", "6001"],
        name: "Vintage piano",
        size: "XL",
    },
    196400200: { themeID: "99006", colors: ["6006", "6002"], name: "Vintage saxophone", size: "M" },
    196400201: { themeID: "99006", colors: ["6004", "6002"], name: "Vintage saxophone", size: "M" },
    196400202: { themeID: "99006", colors: ["6007", "6002"], name: "Vintage saxophone", size: "M" },
    196700100: {
        themeID: "99011",
        colors: ["6005", "6001", "6009", "6011"],
        name: "Girly piano",
        size: "XL",
    },
    196700101: {
        themeID: "99011",
        colors: ["6003", "6011", "6001", "6010"],
        name: "Girly piano",
        size: "XL",
    },
    196700102: {
        themeID: "99011",
        colors: ["6008", "6009", "6001", "6011"],
        name: "Girly piano",
        size: "XL",
    },
    196700103: {
        themeID: "99011",
        colors: ["6010", "6001", "6009", "6011"],
        name: "Girly piano",
        size: "XL",
    },
    196700200: {
        themeID: "99011",
        colors: ["6006", "6007", "6003"],
        name: "Bunny fairy music box",
        size: "M",
    },
    196700201: {
        themeID: "99011",
        colors: ["6011", "6010", "6007"],
        name: "Bunny fairy music box",
        size: "M",
    },
    196700202: {
        themeID: "99011",
        colors: ["6008", "6005", "6010"],
        name: "Bunny fairy music box",
        size: "M",
    },
    196700203: {
        themeID: "99011",
        colors: ["6010", "6011", "6008"],
        name: "Bunny fairy music box",
        size: "M",
    },
    197100100: {
        themeID: "99017",
        colors: ["6003", "6009", "6006"],
        name: "Locomotive fireplace",
        size: "L",
        xmas: true,
    },
    197100101: {
        themeID: "99017",
        colors: ["6007", "6005", "6002"],
        name: "Locomotive fireplace",
        size: "L",
        xmas: true,
    },
    197100102: {
        themeID: "99017",
        colors: ["6008", "6010", "6011"],
        name: "Locomotive fireplace",
        size: "L",
        xmas: true,
    },
    197140100: { themeID: "99015", colors: ["6002", "6001", "6007"], name: "Refined mantel", size: "M" },
    197200100: {
        themeID: "99007",
        colors: ["6005", "6001", "6004", "6009"],
        name: "Country Mantel",
        size: "M",
    },
    197200101: { themeID: "99007", colors: ["6004", "6001", "6009"], name: "Country Mantel", size: "M" },
    197200102: {
        themeID: "99007",
        colors: ["6006", "6001", "6004", "6009"],
        name: "Country Mantel",
        size: "M",
    },
    197300100: {
        themeID: "99009",
        colors: ["6009", "6004", "6002", "6005"],
        name: "Antique mantel",
        size: "L",
    },
    197300101: {
        themeID: "99009",
        colors: ["6002", "6004", "6009", "6008"],
        name: "Antique mantel",
        size: "L",
    },
    197300102: {
        themeID: "99009",
        colors: ["6011", "6004", "6009", "6001"],
        name: "Antique mantel",
        size: "L",
    },
    197400100: { themeID: "99006", colors: ["6007", "6005", "6009"], name: "Vintage mantel", size: "L" },
    197400101: {
        themeID: "99006",
        colors: ["6008", "6003", "6009", "6005"],
        name: "Vintage mantel",
        size: "L",
    },
    197400102: { themeID: "99006", colors: ["6003", "6009", "6002"], name: "Vintage mantel", size: "L" },
    197400103: { themeID: "99006", colors: ["6001", "6004", "6002"], name: "Vintage mantel", size: "L" },
    197400200: { themeID: "99019", colors: ["6004", "6011", "6009"], name: "Stone mantel", size: "XL" },
    197400201: { themeID: "99019", colors: ["6003", "6009", "6004"], name: "Stone mantel", size: "XL" },
    197400202: { themeID: "99019", colors: ["6008", "6009", "6004"], name: "Stone mantel", size: "XL" },
    197700100: { themeID: "99001", colors: ["6001", "6009"], name: "Electric heater", size: "S" },
    197700101: { themeID: "99001", colors: ["6006", "6008", "6001"], name: "Electric heater", size: "S" },
    197700102: { themeID: "99001", colors: ["6011", "6001", "6006"], name: "Electric heater", size: "S" },
    197700103: {
        themeID: "99001",
        colors: ["6002", "6010", "6004", "6001"],
        name: "Electric heater",
        size: "S",
    },
    197700200: { themeID: "99011", colors: ["6006", "6009"], name: "Cute heater", size: "S" },
    197700201: { themeID: "99011", colors: ["6011", "6009"], name: "Cute heater", size: "S" },
    197700202: { themeID: "99011", colors: ["6008", "6009"], name: "Cute heater", size: "S" },
    197700203: { themeID: "99011", colors: ["6010", "6009"], name: "Cute heater", size: "S" },
    198100100: { themeID: "99001", colors: ["6008", "6001"], name: "Urban standing mirror", size: "M" },
    198100101: { themeID: "99001", colors: ["6008", "6007"], name: "Urban standing mirror", size: "M" },
    198100102: { themeID: "99001", colors: ["6011", "6007"], name: "Urban standing mirror", size: "M" },
    198100103: {
        themeID: "99001",
        colors: ["6004", "6010", "6002"],
        name: "Urban standing mirror",
        size: "M",
    },
    198100104: {
        themeID: "99001",
        colors: ["6007", "6008", "6005"],
        name: "Urban standing mirror",
        size: "M",
    },
    198100200: { themeID: "99003", colors: ["6003", "6008", "6011"], name: "Lady's floor mirror", size: "M" },
    198100201: { themeID: "99003", colors: ["6009", "6007", "6005"], name: "Lady's floor mirror", size: "M" },
    198100202: { themeID: "99003", colors: ["6010", "6011", "6004"], name: "Lady's floor mirror", size: "M" },
    198100203: { themeID: "99003", colors: ["6002", "6008", "6004"], name: "Lady's floor mirror", size: "M" },
    198200100: { themeID: "99007", colors: ["6009", "6008", "6005"], name: "Country Mirror", size: "L" },
    198200101: {
        themeID: "99007",
        colors: ["6010", "6001", "6008", "6011"],
        name: "Country Mirror",
        size: "L",
    },
    198200102: { themeID: "99007", colors: ["6007", "6008", "6006"], name: "Country Mirror", size: "L" },
    198200103: { themeID: "99007", colors: ["6008", "6010", "6007"], name: "Country Mirror", size: "L" },
    198300100: {
        themeID: "99009",
        colors: ["6009", "6007", "6008", "6004"],
        name: "Antique mirror",
        size: "L",
    },
    198300101: {
        themeID: "99009",
        colors: ["6001", "6008", "6007", "6004"],
        name: "Antique mirror",
        size: "L",
    },
    198300102: {
        themeID: "99009",
        colors: ["6011", "6008", "6007", "6004"],
        name: "Antique mirror",
        size: "L",
    },
    198400100: {
        themeID: "99006",
        colors: ["6009", "6008", "6007"],
        name: "Vintage floor mirror",
        size: "L",
    },
    198400101: {
        themeID: "99006",
        colors: ["6008", "6009", "6004"],
        name: "Vintage floor mirror",
        size: "L",
    },
    198400102: {
        themeID: "99006",
        colors: ["6004", "6002", "6001"],
        name: "Vintage floor mirror",
        size: "L",
    },
    198400103: {
        themeID: "99006",
        colors: ["6002", "6004", "6001"],
        name: "Vintage floor mirror",
        size: "L",
    },
    198400104: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage floor mirror", size: "L" },
    198700100: { themeID: "99011", colors: ["6006", "6007", "6001"], name: "Bunny fairy mirror", size: "L" },
    198700101: { themeID: "99011", colors: ["6011", "6001", "6006"], name: "Bunny fairy mirror", size: "L" },
    198700102: { themeID: "99011", colors: ["6008", "6005", "6010"], name: "Bunny fairy mirror", size: "L" },
    198700103: { themeID: "99011", colors: ["6010", "6011", "6001"], name: "Bunny fairy mirror", size: "L" },
    199000100: { themeID: "99004", colors: ["6005", "6001", "6009"], name: "Volleyball Net", size: "L" },
    199000101: {
        themeID: "99004",
        colors: ["6008", "6006", "6004", "6001"],
        name: "Volleyball Net",
        size: "L",
    },
    199000102: { themeID: "99004", colors: ["6007", "6001", "6011"], name: "Volleyball Net", size: "L" },
    199000103: { themeID: "99004", colors: ["6010", "6011", "6001"], name: "Volleyball Net", size: "L" },
    199003000: { themeID: "99004", colors: ["6009", "6001", "6006"], name: "Volleyball", size: "S" },
    199003001: { themeID: "99004", colors: ["6005", "6007", "6001"], name: "Volleyball", size: "S" },
    199003002: { themeID: "99004", colors: ["6011", "6001", "6008"], name: "Volleyball", size: "S" },
    199003003: { themeID: "99004", colors: ["6008", "6006", "6001"], name: "Volleyball", size: "S" },
    199100100: {
        themeID: "99001",
        colors: ["6001", "6002", "6004", "6008"],
        name: "Jogging machine",
        size: "XL",
    },
    199100101: {
        themeID: "99001",
        colors: ["6008", "6002", "6004", "6007"],
        name: "Jogging machine",
        size: "XL",
    },
    199100102: {
        themeID: "99001",
        colors: ["6011", "6002", "6004", "6006"],
        name: "Jogging machine",
        size: "XL",
    },
    199100103: { themeID: "99001", colors: ["6002", "6004", "6008"], name: "Jogging machine", size: "XL" },
    199100104: { themeID: "99001", colors: ["6007", "6002", "6004"], name: "Jogging machine", size: "XL" },
    199100200: { themeID: "99002", colors: ["6006", "6009"], name: "Baseball bat", size: "S" },
    199100201: { themeID: "99002", colors: ["6001", "6009"], name: "Baseball bat", size: "S" },
    199100202: { themeID: "99002", colors: ["6009", "6002"], name: "Baseball bat", size: "S" },
    199100203: { themeID: "99002", colors: ["6007", "6005"], name: "Baseball bat", size: "S" },
    199100300: { themeID: "99002", colors: ["6001", "6009"], name: "Baseball", size: "XS" },
    199100301: { themeID: "99002", colors: ["6006", "6009"], name: "Baseball", size: "XS" },
    199100302: { themeID: "99002", colors: ["6007"], name: "Baseball", size: "XS" },
    199140100: { themeID: "99013", colors: ["6002", "6006", "6003"], name: "Swan swim ring", size: "M" },
    199200600: { themeID: "99013", colors: ["6002", "6011"], name: "Yoga Ball", size: "M" },
    199200601: { themeID: "99013", colors: ["6001", "6011"], name: "Yoga Ball", size: "M" },
    199200700: { themeID: "99013", colors: ["6002"], name: "Polka hand weight", size: "XS" },
    199200701: { themeID: "99013", colors: ["6004"], name: "Polka hand weight", size: "XS" },
    199400100: {
        themeID: "99006",
        colors: ["6009", "6008", "6002", "6001"],
        name: "Vintage chess board",
        size: "S",
    },
    199400101: { themeID: "99006", colors: ["6007", "6009", "6001"], name: "Vintage chess board", size: "S" },
    199400102: {
        themeID: "99006",
        colors: ["6008", "6001", "6009", "6002"],
        name: "Vintage chess board",
        size: "S",
    },
    199400103: { themeID: "99006", colors: ["6004", "6002", "6001"], name: "Vintage chess board", size: "S" },
    200000300: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie mannequin", size: "M" },
    200000301: { themeID: "99003", colors: ["6009", "6007", "6006"], name: "Girlie mannequin", size: "M" },
    200000302: { themeID: "99003", colors: ["6010", "6011"], name: "Girlie mannequin", size: "M" },
    200000303: { themeID: "99003", colors: ["6002", "6008", "6001"], name: "Girlie mannequin", size: "M" },
    200000400: { themeID: "99003", colors: ["6011", "6001", "6008"], name: "Girlie makeup kit", size: "S" },
    200000401: { themeID: "99003", colors: ["6005", "6001", "6008"], name: "Girlie makeup kit", size: "S" },
    200000402: { themeID: "99003", colors: ["6008", "6001"], name: "Girlie makeup kit", size: "S" },
    200000403: { themeID: "99003", colors: ["6010", "6011"], name: "Girlie makeup kit", size: "S" },
    200000500: {
        themeID: "99003",
        colors: ["6011", "6001", "6003"],
        name: "Birdcage dressing tent",
        size: "XL",
    },
    200000501: {
        themeID: "99003",
        colors: ["6006", "6009", "6001", "6007", "6005"],
        name: "Birdcage dressing tent",
        size: "XL",
    },
    200000502: {
        themeID: "99003",
        colors: ["6010", "6011", "6002", "6004"],
        name: "Birdcage dressing tent",
        size: "XL",
    },
    200000503: {
        themeID: "99003",
        colors: ["6008", "6001", "6004", "6005", "6002"],
        name: "Birdcage dressing tent",
        size: "XL",
    },
    200100100: { themeID: "99003", colors: ["6003", "6007"], name: "Perfume bottles", size: "XS" },
    200100101: { themeID: "99003", colors: ["6006", "6003"], name: "Perfume bottles", size: "XS" },
    200100102: { themeID: "99003", colors: ["6010", "6008"], name: "Perfume bottles", size: "XS" },
    200100103: { themeID: "99003", colors: ["6008", "6005"], name: "Perfume bottles", size: "XS" },
    200140100: { themeID: "99001", colors: ["6002"], name: "Urban cap", size: "XS" },
    200140101: { themeID: "99001", colors: ["6004"], name: "Urban cap", size: "XS" },
    200140200: { themeID: "99006", colors: ["6002", "6004"], name: "Vintage handbag", size: "XS" },
    200140201: { themeID: "99006", colors: ["6004", "6007"], name: "Vintage handbag", size: "XS" },
    200140202: { themeID: "99006", colors: ["6010"], name: "Vintage handbag", size: "XS" },
    200140203: { themeID: "99006", colors: ["6009"], name: "Vintage handbag", size: "XS" },
    200140300: { themeID: "99011", colors: ["6005", "6011"], name: "Dolly perfume", size: "XS" },
    200200100: {
        themeID: "99007",
        colors: ["6009", "6005", "6008", "6011"],
        name: "Country Mannequin",
        size: "M",
    },
    200200101: { themeID: "99007", colors: ["6011", "6008", "6006"], name: "Country Mannequin", size: "M" },
    200200102: {
        themeID: "99007",
        colors: ["6007", "6011", "6009", "6006"],
        name: "Country Mannequin",
        size: "M",
    },
    200200700: { themeID: "99008", colors: ["6004", "6005"], name: "Boho diffuser", size: "XS" },
    200300100: { themeID: "99009", colors: ["6006", "6001"], name: "Antique cosmetic shelf", size: "M" },
    200300101: { themeID: "99009", colors: ["6002", "6001"], name: "Antique cosmetic shelf", size: "M" },
    200300102: { themeID: "99009", colors: ["6011", "6001"], name: "Antique cosmetic shelf", size: "M" },
    200700100: { themeID: "99001", colors: ["6001"], name: "Mineral water", size: "XS" },
    200700101: { themeID: "99001", colors: ["6008"], name: "Mineral water", size: "XS" },
    200700102: { themeID: "99001", colors: ["6011"], name: "Mineral water", size: "XS" },
    200700103: { themeID: "99001", colors: ["6010"], name: "Mineral water", size: "XS" },
    200700200: { themeID: "99011", colors: ["6001"], name: "Makeup brushes", size: "S" },
    200700201: { themeID: "99011", colors: ["6011"], name: "Makeup brushes", size: "S" },
    200700202: { themeID: "99011", colors: ["6008"], name: "Makeup brushes", size: "S" },
    200700203: { themeID: "99011", colors: ["6010"], name: "Makeup brushes", size: "S" },
    200700300: { themeID: "99011", colors: ["6005", "6006", "6003"], name: "Hat hanger", size: "S" },
    200700301: { themeID: "99011", colors: ["6011", "6006"], name: "Hat hanger", size: "S" },
    200700302: { themeID: "99011", colors: ["6008", "6011"], name: "Hat hanger", size: "S" },
    200700303: { themeID: "99011", colors: ["6010", "6005"], name: "Hat hanger", size: "S" },
    201000200: {
        themeID: "99002",
        colors: ["6011", "6007", "6008", "6005"],
        name: "4 Books In A Row",
        size: "S",
    },
    201000201: { themeID: "99002", colors: ["6008", "6005", "6003"], name: "4 Books In A Row", size: "S" },
    201000202: {
        themeID: "99002",
        colors: ["6007", "6005", "6003", "6009"],
        name: "4 Books In A Row",
        size: "S",
    },
    201000203: { themeID: "99002", colors: ["6010", "6011", "6005"], name: "4 Books In A Row", size: "S" },
    201002500: { themeID: "99006", colors: ["6001"], name: "Opened book", size: "XS" },
    201002501: { themeID: "99006", colors: ["6006"], name: "Opened book", size: "XS" },
    201002600: { themeID: "99006", colors: ["6009"], name: "Rare books", size: "S" },
    201002601: { themeID: "99006", colors: ["6009", "6007"], name: "Rare books", size: "S" },
    201002602: { themeID: "99006", colors: ["6003", "6007", "6002"], name: "Rare books", size: "S" },
    201002603: { themeID: "99006", colors: ["6009", "6007", "6002", "6004"], name: "Rare books", size: "S" },
    201100100: { themeID: "99001", colors: ["6007", "6001"], name: "Document clipboard", size: "XS" },
    201100101: { themeID: "99001", colors: ["6001", "6008"], name: "Document clipboard", size: "XS" },
    201100102: { themeID: "99001", colors: ["6001", "6011"], name: "Document clipboard", size: "XS" },
    201100103: { themeID: "99001", colors: ["6002", "6001"], name: "Document clipboard", size: "XS" },
    201300100: { themeID: "99009", colors: ["6011", "6006"], name: "Antique book", size: "XS" },
    201300101: { themeID: "99009", colors: ["6007", "6003"], name: "Antique book", size: "XS" },
    201300102: { themeID: "99009", colors: ["6006", "6003"], name: "Antique book", size: "XS" },
    201500100: { themeID: "99009", colors: ["6003"], name: "Antique novel", size: "S" },
    201500200: { themeID: "99007", colors: ["6009"], name: "Country notepad", size: "S" },
    201500300: { themeID: "99009", colors: ["6009"], name: "Antique Notebook", size: "S" },
    201500400: { themeID: "99005", colors: [""], name: "Doraemon manga", size: "S" },
    201500500: { themeID: "99003", colors: ["6011"], name: "Shoujo manga", size: "S" },
    201700100: { themeID: "99011", colors: ["6003"], name: "Popup fairytale book", size: "XS" },
    201700101: { themeID: "99011", colors: ["6011"], name: "Popup fairytale book", size: "XS" },
    201700102: { themeID: "99011", colors: ["6008"], name: "Popup fairytale book", size: "XS" },
    201700103: { themeID: "99011", colors: ["6010"], name: "Popup fairytale book", size: "XS" },
    201800100: { themeID: "99015", colors: ["6009", "6005", "6004"], name: "Natural charm books", size: "S" },
    201800101: { themeID: "99015", colors: ["6003", "6004"], name: "Natural charm books", size: "S" },
    201800102: { themeID: "99015", colors: ["6008", "6004"], name: "Natural charm books", size: "S" },
    201800103: { themeID: "99015", colors: ["6011", "6004"], name: "Natural charm books", size: "S" },
    201800200: { themeID: "99004", colors: ["6009", "6007"], name: "Tropical book set", size: "XS" },
    201800201: { themeID: "99004", colors: ["6011", "6003"], name: "Tropical book set", size: "XS" },
    201800202: { themeID: "99004", colors: ["6005", "6009"], name: "Tropical book set", size: "XS" },
    202000100: { themeID: "99001", colors: ["6007"], name: "Inox Trash Can", size: "S" },
    202000101: { themeID: "99001", colors: ["6008", "6004", "6001"], name: "Inox Trash Can", size: "S" },
    202000102: { themeID: "99001", colors: ["6011"], name: "Inox Trash Can", size: "S" },
    202000103: { themeID: "99001", colors: ["6001", "6004"], name: "Inox Trash Can", size: "S" },
    202000104: { themeID: "99001", colors: ["6010"], name: "Inox Trash Can", size: "S" },
    202000200: {
        themeID: "99001",
        colors: ["6008", "6006", "6001", "6009"],
        name: "Urban basket",
        size: "S",
    },
    202000201: {
        themeID: "99001",
        colors: ["6009", "6005", "6007", "6001"],
        name: "Urban basket",
        size: "S",
    },
    202000202: {
        themeID: "99001",
        colors: ["6001", "6008", "6011", "6009"],
        name: "Urban basket",
        size: "S",
    },
    202000203: {
        themeID: "99001",
        colors: ["6007", "6005", "6006", "6009"],
        name: "Urban basket",
        size: "S",
    },
    202000300: { themeID: "99002", colors: ["6009", "6001"], name: "Wooden tool box", size: "S" },
    202000301: { themeID: "99002", colors: ["6001", "6007"], name: "Wooden tool box", size: "S" },
    202000302: {
        themeID: "99002",
        colors: ["6011", "6001", "6006", "6005"],
        name: "Wooden tool box",
        size: "S",
    },
    202000303: {
        themeID: "99002",
        colors: ["6008", "6001", "6005", "6006"],
        name: "Wooden tool box",
        size: "S",
    },
    202000304: { themeID: "99002", colors: ["6002", "6004", "6010"], name: "Wooden tool box", size: "S" },
    202000305: {
        themeID: "99002",
        colors: ["6007", "6001", "6011", "6006"],
        name: "Wooden tool box",
        size: "S",
    },
    202001000: { themeID: "99004", colors: ["6008"], name: "Little empty bottle", size: "XS" },
    202001001: { themeID: "99004", colors: ["6011"], name: "Little empty bottle", size: "XS" },
    202001002: { themeID: "99004", colors: ["6006"], name: "Little empty bottle", size: "XS" },
    202001100: { themeID: "99004", colors: ["6008", "6009"], name: "Bottled letter", size: "XS" },
    202001101: { themeID: "99004", colors: ["6007", "6009", "6006"], name: "Bottled letter", size: "XS" },
    202001102: { themeID: "99004", colors: ["6010", "6005"], name: "Bottled letter", size: "XS" },
    202002400: { themeID: "99006", colors: ["6009", "6005"], name: "Vintage Suitcases", size: "S" },
    202002401: { themeID: "99006", colors: ["6007", "6009", "6005"], name: "Vintage Suitcases", size: "S" },
    202002402: {
        themeID: "99006",
        colors: ["6008", "6004", "6009", "6005"],
        name: "Vintage Suitcases",
        size: "S",
    },
    202002403: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage Suitcases", size: "S" },
    202100100: { themeID: "99001", colors: ["6007", "6001"], name: "Table calendar", size: "XS" },
    202100101: { themeID: "99001", colors: ["6008", "6001"], name: "Table calendar", size: "XS" },
    202100102: { themeID: "99001", colors: ["6011", "6001"], name: "Table calendar", size: "XS" },
    202100103: { themeID: "99001", colors: ["6010", "6001"], name: "Table calendar", size: "XS" },
    202100200: { themeID: "99003", colors: ["6011", "6009", "6001"], name: "Girlie waste basket", size: "S" },
    202100201: { themeID: "99003", colors: ["6009", "6005", "6001"], name: "Girlie waste basket", size: "S" },
    202100202: { themeID: "99003", colors: ["6010", "6001", "6004"], name: "Girlie waste basket", size: "S" },
    202100203: { themeID: "99003", colors: ["6004", "6008", "6001"], name: "Girlie waste basket", size: "S" },
    202300100: { themeID: "99009", colors: ["6006"], name: "Antique ink pot", size: "S" },
    202300101: { themeID: "99009", colors: ["6001", "6008"], name: "Antique ink pot", size: "S" },
    202300102: { themeID: "99009", colors: ["6011", "6003"], name: "Antique ink pot", size: "S" },
    202300200: { themeID: "99009", colors: ["6005", "6002"], name: "Trash bin", size: "S" },
    202300201: { themeID: "99009", colors: ["6006", "6002"], name: "Trash bin", size: "S" },
    202300202: { themeID: "99009", colors: ["6011", "6002"], name: "Trash bin", size: "S" },
    202400100: { themeID: "99019", colors: ["6002"], name: "Metal watering can", size: "XS" },
    202400101: { themeID: "99019", colors: ["6009"], name: "Metal watering can", size: "XS" },
    202400102: { themeID: "99019", colors: ["6008"], name: "Metal watering can", size: "XS" },
    202500100: { themeID: "99011", colors: ["6006", "6009", "6011"], name: "Dolly Suitcase", size: "S" },
    202500101: { themeID: "99011", colors: ["6011", "6009"], name: "Dolly Suitcase", size: "S" },
    202500102: { themeID: "99011", colors: ["6008", "6010"], name: "Dolly Suitcase", size: "S" },
    202500103: { themeID: "99011", colors: ["6007", "6009"], name: "Dolly Suitcase", size: "S" },
    202500400: { themeID: "99002", colors: ["6006"], name: "Wooden Mallet", size: "XS" },
    202500500: { themeID: "99002", colors: ["6009"], name: "Rustic broom", size: "S" },
    202500600: { themeID: "99007", colors: ["6004"], name: "Inox watering can", size: "S" },
    202500700: {
        themeID: "99007",
        colors: ["6001", "6007", "6003", "6008"],
        name: "Color palette",
        size: "S",
    },
    202500800: { themeID: "99006", colors: ["6003"], name: "Vintage Pen", size: "XS" },
    202500900: { themeID: "99005", colors: ["6008", "6011"], name: "Bunny pen", size: "XS" },
    202501000: { themeID: "99002", colors: ["6004", "6009"], name: "Chisel", size: "XS" },
    202501100: { themeID: "99007", colors: ["6009"], name: "Paint brush", size: "XS" },
    202700100: {
        themeID: "99001",
        colors: ["6001", "6008", "6011", "6006"],
        name: "Urban clothes basket",
        size: "S",
    },
    202700101: {
        themeID: "99001",
        colors: ["6007", "6005", "6006", "6010"],
        name: "Urban clothes basket",
        size: "S",
    },
    202700102: {
        themeID: "99001",
        colors: ["6006", "6007", "6010", "6001"],
        name: "Urban clothes basket",
        size: "S",
    },
    202700103: {
        themeID: "99001",
        colors: ["6008", "6001", "6010", "6006"],
        name: "Urban clothes basket",
        size: "S",
    },
    202700200: {
        themeID: "99015",
        colors: ["6009", "6007", "6001"],
        name: "Refined clothes basket",
        size: "S",
    },
    202700201: {
        themeID: "99015",
        colors: ["6007", "6002", "6006", "6001"],
        name: "Refined clothes basket",
        size: "S",
    },
    202700202: {
        themeID: "99015",
        colors: ["6008", "6002", "6001"],
        name: "Refined clothes basket",
        size: "S",
    },
    202700203: {
        themeID: "99015",
        colors: ["6011", "6004", "6008"],
        name: "Refined clothes basket",
        size: "S",
    },
    202700300: { themeID: "99005", colors: ["6009", "6006"], name: "Mushroom trash can", size: "S" },
    202700301: { themeID: "99005", colors: ["6011", "6003"], name: "Mushroom trash can", size: "S" },
    202700302: { themeID: "99005", colors: ["6008", "6001"], name: "Mushroom trash can", size: "S" },
    202700303: { themeID: "99005", colors: ["6010", "6001"], name: "Mushroom trash can", size: "S" },
    202700400: { themeID: "99011", colors: ["6005", "6001"], name: "Cute trash can", size: "XS" },
    202700401: { themeID: "99011", colors: ["6011"], name: "Cute trash can", size: "XS" },
    202700402: { themeID: "99011", colors: ["6008", "6001"], name: "Cute trash can", size: "XS" },
    202700403: { themeID: "99011", colors: ["6010", "6011"], name: "Cute trash can", size: "XS" },
    202700500: { themeID: "99011", colors: ["6007", "6003"], name: "Pen and Paper", size: "S" },
    202700501: { themeID: "99011", colors: ["6011"], name: "Pen and Paper", size: "S" },
    202700502: { themeID: "99011", colors: ["6008"], name: "Pen and Paper", size: "S" },
    202700503: { themeID: "99011", colors: ["6010"], name: "Pen and Paper", size: "S" },
    203001600: { themeID: "99005", colors: ["6001", "6006", "6008"], name: "Colory Clouds", size: "M" },
    203001601: { themeID: "99005", colors: ["6011", "6010", "6007"], name: "Colory Clouds", size: "M" },
    203001602: { themeID: "99005", colors: ["6008", "6007", "6010"], name: "Colory Clouds", size: "M" },
    203001603: { themeID: "99005", colors: ["6006", "6007", "6010"], name: "Colory Clouds", size: "M" },
    203001700: {
        themeID: "99005",
        colors: ["6006", "6011", "6007", "6008"],
        name: "Colory pennants",
        size: "S",
    },
    203001701: { themeID: "99005", colors: ["6011", "6010"], name: "Colory pennants", size: "S" },
    203001702: { themeID: "99005", colors: ["6008", "6010", "6011"], name: "Colory pennants", size: "S" },
    203100200: { themeID: "99017", colors: ["6003", "6001"], name: "Xmas stocking", size: "S", xmas: true },
    203100201: { themeID: "99017", colors: ["6007", "6006"], name: "Xmas stocking", size: "S", xmas: true },
    203100202: { themeID: "99017", colors: ["6008", "6011"], name: "Xmas stocking", size: "S", xmas: true },
    203100300: { themeID: "99003", colors: ["6011", "6001"], name: "Lovely fluff animal", size: "S" },
    203100301: { themeID: "99003", colors: ["6009", "6007"], name: "Lovely fluff animal", size: "S" },
    203100302: { themeID: "99003", colors: ["6001", "6002", "6010"], name: "Lovely fluff animal", size: "S" },
    203100303: { themeID: "99003", colors: ["6002", "6001", "6008"], name: "Lovely fluff animal", size: "S" },
    203200100: {
        themeID: "99005",
        colors: ["6008", "6004", "6001", "6003"],
        name: "Childlike class board",
        size: "M",
    },
    203200101: {
        themeID: "99005",
        colors: ["6007", "6001", "6006"],
        name: "Childlike class board",
        size: "M",
    },
    203200102: {
        themeID: "99005",
        colors: ["6004", "6001", "6010", "6006"],
        name: "Childlike class board",
        size: "M",
    },
    203400100: { themeID: "99006", colors: ["6006", "6003", "6009"], name: "Vintage plane", size: "S" },
    203400101: { themeID: "99006", colors: ["6009", "6007"], name: "Vintage plane", size: "S" },
    203400102: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage plane", size: "S" },
    203400103: { themeID: "99006", colors: ["6002", "6004"], name: "Vintage plane", size: "S" },
    203700100: {
        themeID: "99001",
        colors: ["6007", "6011", "6004", "6006"],
        name: "Urban hanging balloon",
        size: "S",
    },
    203700101: {
        themeID: "99001",
        colors: ["6008", "6004", "6011", "6006"],
        name: "Urban hanging balloon",
        size: "S",
    },
    203700102: {
        themeID: "99001",
        colors: ["6011", "6004", "6006"],
        name: "Urban hanging balloon",
        size: "S",
    },
    203700103: {
        themeID: "99001",
        colors: ["6010", "6004", "6011", "6006"],
        name: "Urban hanging balloon",
        size: "S",
    },
    203700200: { themeID: "99011", colors: ["6006", "6001"], name: "Star bunny plushie", size: "M" },
    203700201: { themeID: "99011", colors: ["6003", "6011", "6001"], name: "Star bunny plushie", size: "M" },
    203700202: { themeID: "99011", colors: ["6008", "6001"], name: "Star bunny plushie", size: "M" },
    203700203: { themeID: "99011", colors: ["6010", "6001"], name: "Star bunny plushie", size: "M" },
    204200100: { themeID: "99007", colors: ["6002", "6003"], name: "Country vinyl records", size: "S" },
    204200101: { themeID: "99007", colors: ["6002", "6006"], name: "Country vinyl records", size: "S" },
    204200102: { themeID: "99007", colors: ["6002", "6010"], name: "Country vinyl records", size: "S" },
    204300100: { themeID: "99009", colors: ["6009", "6002"], name: "Antique Violin", size: "S" },
    204300101: { themeID: "99009", colors: ["6001", "6006", "6002"], name: "Antique Violin", size: "S" },
    204300102: { themeID: "99009", colors: ["6011", "6002"], name: "Antique Violin", size: "S" },
    204700100: {
        themeID: "99001",
        colors: ["6004", "6001", "6002"],
        name: "Hanging electric guitar",
        size: "S",
    },
    204700101: {
        themeID: "99001",
        colors: ["6007", "6004", "6001", "6002"],
        name: "Hanging electric guitar",
        size: "S",
    },
    204700102: {
        themeID: "99001",
        colors: ["6008", "6004", "6001", "6002"],
        name: "Hanging electric guitar",
        size: "S",
    },
    204700103: {
        themeID: "99001",
        colors: ["6011", "6004", "6001", "6002"],
        name: "Hanging electric guitar",
        size: "S",
    },
    204700300: { themeID: "99011", colors: ["6006", "6009"], name: "Cute Ukulele", size: "S" },
    204700301: { themeID: "99011", colors: ["6003", "6011"], name: "Cute Ukulele", size: "S" },
    204700302: { themeID: "99011", colors: ["6008"], name: "Cute Ukulele", size: "S" },
    204700303: { themeID: "99011", colors: ["6010"], name: "Cute Ukulele", size: "S" },
    206000100: { themeID: "99001", colors: ["6001", "6008"], name: "Urban Wall Mirror", size: "S" },
    206000101: { themeID: "99001", colors: ["6008", "6007"], name: "Urban Wall Mirror", size: "S" },
    206000102: { themeID: "99001", colors: ["6011", "6007"], name: "Urban Wall Mirror", size: "S" },
    206000103: { themeID: "99001", colors: ["6010", "6004", "6002"], name: "Urban Wall Mirror", size: "S" },
    206000104: { themeID: "99001", colors: ["6007", "6008"], name: "Urban Wall Mirror", size: "S" },
    206000600: { themeID: "99003", colors: ["6003", "6008"], name: "Girlie Wall Mirror", size: "S" },
    206000601: { themeID: "99003", colors: ["6009", "6007"], name: "Girlie Wall Mirror", size: "S" },
    206000602: { themeID: "99003", colors: ["6010", "6011"], name: "Girlie Wall Mirror", size: "S" },
    206000603: { themeID: "99003", colors: ["6002", "6006"], name: "Girlie Wall Mirror", size: "S" },
    206001200: { themeID: "99004", colors: ["6009", "6008"], name: "Rattan Wall Mirror", size: "S" },
    206001201: { themeID: "99004", colors: ["6007", "6006"], name: "Rattan Wall Mirror", size: "S" },
    206001202: { themeID: "99004", colors: ["6002", "6009", "6008"], name: "Rattan Wall Mirror", size: "S" },
    206001203: { themeID: "99004", colors: ["6004", "6007"], name: "Rattan Wall Mirror", size: "S" },
    206001204: { themeID: "99004", colors: ["6010", "6011"], name: "Rattan Wall Mirror", size: "S" },
    206001900: { themeID: "99011", colors: ["6006", "6007"], name: "Cherry Mirror", size: "S" },
    206001901: { themeID: "99011", colors: ["6011", "6008"], name: "Cherry Mirror", size: "S" },
    206001902: { themeID: "99011", colors: ["6008", "6001"], name: "Cherry Mirror", size: "S" },
    206001903: { themeID: "99011", colors: ["6010", "6011", "6008"], name: "Cherry Mirror", size: "S" },
    206002000: { themeID: "99011", colors: ["6005", "6007", "6001"], name: "Strawberry Mirror", size: "S" },
    206002001: { themeID: "99011", colors: ["6011", "6007", "6008"], name: "Strawberry Mirror", size: "S" },
    206002002: { themeID: "99011", colors: ["6008", "6005"], name: "Strawberry Mirror", size: "S" },
    206002003: { themeID: "99011", colors: ["6010", "6008", "6011"], name: "Strawberry Mirror", size: "S" },
    206100100: {
        themeID: "99017",
        colors: ["6005", "6003"],
        name: "Ginger boy mirror",
        size: "M",
        xmas: true,
    },
    206100101: {
        themeID: "99017",
        colors: ["6009", "6007"],
        name: "Ginger boy mirror",
        size: "M",
        xmas: true,
    },
    206100102: {
        themeID: "99017",
        colors: ["6003", "6011", "6008"],
        name: "Ginger boy mirror",
        size: "M",
        xmas: true,
    },
    206200100: { themeID: "99007", colors: ["6005", "6007"], name: "Mounty Mirror", size: "S" },
    206200101: { themeID: "99007", colors: ["6010", "6004"], name: "Mounty Mirror", size: "S" },
    206200102: { themeID: "99007", colors: ["6007", "6006"], name: "Mounty Mirror", size: "S" },
    206200103: { themeID: "99007", colors: ["6008", "6001"], name: "Mounty Mirror", size: "S" },
    206300100: { themeID: "99009", colors: ["6001", "6008"], name: "Antique mirror", size: "M" },
    206300101: { themeID: "99009", colors: ["6004", "6007"], name: "Antique mirror", size: "M" },
    206300102: { themeID: "99009", colors: ["6011", "6007"], name: "Antique mirror", size: "M" },
    206400100: { themeID: "99019", colors: ["6009", "6004"], name: "Stone wall mirror", size: "M" },
    206400101: { themeID: "99019", colors: ["6003", "6002"], name: "Stone wall mirror", size: "M" },
    206400102: { themeID: "99019", colors: ["6008", "6009"], name: "Stone wall mirror", size: "M" },
    206400200: { themeID: "99006", colors: ["6009", "6008"], name: "Vintage wall mirror", size: "S" },
    206400201: { themeID: "99006", colors: ["6007", "6008"], name: "Vintage wall mirror", size: "S" },
    206400202: { themeID: "99006", colors: ["6008"], name: "Vintage wall mirror", size: "S" },
    206400203: { themeID: "99006", colors: ["6003", "6008"], name: "Vintage wall mirror", size: "S" },
    206400204: { themeID: "99006", colors: ["6002", "6008"], name: "Vintage wall mirror", size: "S" },
    207000800: { themeID: "99004", colors: ["6009"], name: "Wooden Paddle", size: "S" },
    207000801: { themeID: "99004", colors: ["6001", "6004"], name: "Wooden Paddle", size: "S" },
    207000802: { themeID: "99004", colors: ["6008", "6001"], name: "Wooden Paddle", size: "S" },
    207000803: { themeID: "99004", colors: ["6007", "6001"], name: "Wooden Paddle", size: "S" },
    207100100: { themeID: "99002", colors: ["6006", "6001"], name: "Wall-mounted wooden sword", size: "S" },
    207100101: { themeID: "99002", colors: ["6001", "6009"], name: "Wall-mounted wooden sword", size: "S" },
    207100102: { themeID: "99002", colors: ["6007", "6001"], name: "Wall-mounted wooden sword", size: "S" },
    207100200: { themeID: "99002", colors: ["6006", "6009"], name: "Stand baseball bat", size: "S" },
    207100201: { themeID: "99002", colors: ["6009", "6001"], name: "Stand baseball bat", size: "S" },
    207100202: { themeID: "99002", colors: ["6009", "6002"], name: "Stand baseball bat", size: "S" },
    207100203: { themeID: "99002", colors: ["6007", "6005"], name: "Stand baseball bat", size: "S" },
    208100100: { themeID: "99001", colors: ["6001", "6004", "6006"], name: "Urban shower", size: "M" },
    208100101: { themeID: "99001", colors: ["6008", "6006", "6001"], name: "Urban shower", size: "M" },
    208100102: { themeID: "99001", colors: ["6011", "6006", "6001"], name: "Urban shower", size: "M" },
    208100103: { themeID: "99001", colors: ["6010", "6011", "6006"], name: "Urban shower", size: "M" },
    208100104: { themeID: "99001", colors: ["6007", "6001", "6006"], name: "Urban shower", size: "M" },
    208100200: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie shower", size: "S" },
    208100201: { themeID: "99003", colors: ["6007", "6009"], name: "Girlie shower", size: "S" },
    208100202: { themeID: "99003", colors: ["6010", "6008"], name: "Girlie shower", size: "S" },
    208100203: { themeID: "99003", colors: ["6008", "6002"], name: "Girlie shower", size: "S" },
    208300100: {
        themeID: "99009",
        colors: ["6006", "6007", "6003", "6009"],
        name: "Classy shower",
        size: "M",
    },
    208300101: {
        themeID: "99009",
        colors: ["6002", "6007", "6008", "6009"],
        name: "Classy shower",
        size: "M",
    },
    208300102: {
        themeID: "99009",
        colors: ["6011", "6007", "6006", "6009"],
        name: "Classy shower",
        size: "M",
    },
    208400100: { themeID: "99006", colors: ["6007", "6009", "6001"], name: "Vintage shower", size: "S" },
    208400101: {
        themeID: "99006",
        colors: ["6008", "6009", "6007", "6006"],
        name: "Vintage shower",
        size: "S",
    },
    208400102: {
        themeID: "99006",
        colors: ["6003", "6009", "6007", "6001"],
        name: "Vintage shower",
        size: "S",
    },
    208400103: {
        themeID: "99006",
        colors: ["6002", "6004", "6007", "6010"],
        name: "Vintage shower",
        size: "S",
    },
    209000200: { themeID: "99002", colors: ["6006"], name: "Common Soap", size: "XS" },
    209000201: { themeID: "99002", colors: ["6011"], name: "Common Soap", size: "XS" },
    209000202: { themeID: "99002", colors: ["6008"], name: "Common Soap", size: "XS" },
    209000203: { themeID: "99002", colors: ["6007"], name: "Common Soap", size: "XS" },
    209000300: {
        themeID: "99004",
        colors: ["6009", "6007", "6003", "6005"],
        name: "Rattan shampoo set",
        size: "S",
    },
    209000301: {
        themeID: "99004",
        colors: ["6002", "6010", "6006", "6011"],
        name: "Rattan shampoo set",
        size: "S",
    },
    209000302: {
        themeID: "99004",
        colors: ["6008", "6007", "6005", "6004"],
        name: "Rattan shampoo set",
        size: "S",
    },
    209000303: {
        themeID: "99004",
        colors: ["6010", "6008", "6006", "6011"],
        name: "Rattan shampoo set",
        size: "S",
    },
    209100100: { themeID: "99003", colors: ["6011", "6009"], name: "Elegant shampoo", size: "XS" },
    209100101: { themeID: "99003", colors: ["6005", "6009"], name: "Elegant shampoo", size: "XS" },
    209100102: { themeID: "99003", colors: ["6010", "6009"], name: "Elegant shampoo", size: "XS" },
    209100103: { themeID: "99003", colors: ["6008", "6009"], name: "Elegant shampoo", size: "XS" },
    209500100: { themeID: "99011", colors: ["6003", "6001"], name: "Strawberry Soap", size: "S" },
    209500101: { themeID: "99011", colors: ["6005", "6001"], name: "Strawberry Soap", size: "S" },
    209500102: { themeID: "99011", colors: ["6008", "6001"], name: "Strawberry Soap", size: "S" },
    209500103: { themeID: "99011", colors: ["6010", "6004"], name: "Strawberry Soap", size: "S" },
    209700100: { themeID: "99001", colors: ["6007", "6001"], name: "Urban washing powder", size: "XS" },
    209700101: { themeID: "99001", colors: ["6008", "6001"], name: "Urban washing powder", size: "XS" },
    209700102: { themeID: "99001", colors: ["6011", "6001"], name: "Urban washing powder", size: "XS" },
    209700103: { themeID: "99001", colors: ["6010", "6001"], name: "Urban washing powder", size: "XS" },
    209700200: { themeID: "99001", colors: ["6007", "6001"], name: "Urban fabric softener", size: "XS" },
    209700201: { themeID: "99001", colors: ["6008", "6001"], name: "Urban fabric softener", size: "XS" },
    209700202: { themeID: "99001", colors: ["6011", "6001"], name: "Urban fabric softener", size: "XS" },
    209700203: { themeID: "99001", colors: ["6010", "6001"], name: "Urban fabric softener", size: "XS" },
    210000100: { themeID: "99001", colors: ["6006", "6007", "6001"], name: "Rolled towel set", size: "XS" },
    210000101: { themeID: "99001", colors: ["6009", "6006", "6008"], name: "Rolled towel set", size: "XS" },
    210000102: { themeID: "99001", colors: ["6011", "6008", "6009"], name: "Rolled towel set", size: "XS" },
    210000103: { themeID: "99001", colors: ["6010", "6004", "6001"], name: "Rolled towel set", size: "XS" },
    210000200: { themeID: "99001", colors: ["6011", "6008", "6010"], name: "Folded Towel Set", size: "S" },
    210000201: { themeID: "99001", colors: ["6009", "6005", "6001"], name: "Folded Towel Set", size: "S" },
    210000202: { themeID: "99001", colors: ["6008", "6007", "6005"], name: "Folded Towel Set", size: "S" },
    210000203: { themeID: "99001", colors: ["6010", "6001", "6011"], name: "Folded Towel Set", size: "S" },
    210500100: { themeID: "99011", colors: ["6005", "6001"], name: "Bunny Towel", size: "XS" },
    210500101: { themeID: "99011", colors: ["6011", "6001"], name: "Bunny Towel", size: "XS" },
    210500102: { themeID: "99011", colors: ["6008", "6001"], name: "Bunny Towel", size: "XS" },
    210500103: { themeID: "99011", colors: ["6010", "6011"], name: "Bunny Towel", size: "XS" },
    211000100: { themeID: "99002", colors: ["6006", "6009"], name: "Rustic Towel", size: "S" },
    211000101: { themeID: "99002", colors: ["6011", "6009"], name: "Rustic Towel", size: "S" },
    211000102: { themeID: "99002", colors: ["6008", "6002"], name: "Rustic Towel", size: "S" },
    211000103: { themeID: "99002", colors: ["6001", "6004", "6002"], name: "Rustic Towel", size: "S" },
    211000104: { themeID: "99002", colors: ["6007", "6009"], name: "Rustic Towel", size: "S" },
    211000400: { themeID: "99004", colors: ["6009", "6008"], name: "Surfboard Towel Hanger", size: "S" },
    211000401: { themeID: "99004", colors: ["6008", "6005"], name: "Surfboard Towel Hanger", size: "S" },
    211000402: {
        themeID: "99004",
        colors: ["6007", "6011", "6009"],
        name: "Surfboard Towel Hanger",
        size: "S",
    },
    211000403: {
        themeID: "99004",
        colors: ["6010", "6008", "6011"],
        name: "Surfboard Towel Hanger",
        size: "S",
    },
    211000500: { themeID: "99006", colors: ["6006", "6009"], name: "Vintage towel", size: "S" },
    211000501: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage towel", size: "S" },
    211000502: { themeID: "99006", colors: ["6008", "6002"], name: "Vintage towel", size: "S" },
    211000503: { themeID: "99006", colors: ["6003", "6002"], name: "Vintage towel", size: "S" },
    211000504: { themeID: "99006", colors: ["6009", "6002"], name: "Vintage towel", size: "S" },
    211500100: { themeID: "99011", colors: ["6005", "6001"], name: "Dolly Towel", size: "S" },
    211500101: { themeID: "99011", colors: ["6003", "6001"], name: "Dolly Towel", size: "S" },
    211500102: { themeID: "99011", colors: ["6008", "6004"], name: "Dolly Towel", size: "S" },
    211500103: { themeID: "99011", colors: ["6010", "6004"], name: "Dolly Towel", size: "S" },
    212000400: { themeID: "99005", colors: ["6005", "6001"], name: "Childlike Bath Shelf", size: "M" },
    212000401: { themeID: "99005", colors: ["6010", "6001"], name: "Childlike Bath Shelf", size: "M" },
    212000402: { themeID: "99005", colors: ["6008", "6001"], name: "Childlike Bath Shelf", size: "M" },
    212200100: {
        themeID: "99007",
        colors: ["6005", "6009", "6007", "6001"],
        name: "Country bathroom cabinet",
        size: "L",
    },
    212200101: {
        themeID: "99007",
        colors: ["6002", "6004", "6008", "6001"],
        name: "Country bathroom cabinet",
        size: "L",
    },
    212200102: {
        themeID: "99007",
        colors: ["6006", "6007", "6004", "6010"],
        name: "Country bathroom cabinet",
        size: "L",
    },
    212200200: { themeID: "99008", colors: ["6009", "6006"], name: "Boho Bathroom Cabinet", size: "M" },
    212200201: { themeID: "99008", colors: ["6001", "6006"], name: "Boho Bathroom Cabinet", size: "M" },
    212200202: { themeID: "99008", colors: ["6011", "6006"], name: "Boho Bathroom Cabinet", size: "M" },
    212400100: { themeID: "99006", colors: ["6009", "6007", "6006"], name: "Vintage bathshelf", size: "M" },
    212400101: { themeID: "99006", colors: ["6008", "6002", "6009"], name: "Vintage bathshelf", size: "M" },
    212400102: { themeID: "99006", colors: ["6003", "6009"], name: "Vintage bathshelf", size: "M" },
    212400103: { themeID: "99006", colors: ["6004", "6002"], name: "Vintage bathshelf", size: "M" },
    212700100: {
        themeID: "99001",
        colors: ["6007", "6001", "6008"],
        name: "Urban bathroom shelf",
        size: "M",
    },
    212700101: {
        themeID: "99001",
        colors: ["6008", "6001", "6011"],
        name: "Urban bathroom shelf",
        size: "M",
    },
    212700102: {
        themeID: "99001",
        colors: ["6011", "6008", "6001"],
        name: "Urban bathroom shelf",
        size: "M",
    },
    212700103: {
        themeID: "99001",
        colors: ["6004", "6001", "6010"],
        name: "Urban bathroom shelf",
        size: "M",
    },
    213200100: {
        themeID: "99007",
        colors: ["6008", "6005", "6009"],
        name: "Country bathroom rack",
        size: "M",
    },
    213200101: {
        themeID: "99007",
        colors: ["6004", "6010", "6008"],
        name: "Country bathroom rack",
        size: "M",
    },
    213200102: {
        themeID: "99007",
        colors: ["6007", "6006", "6009"],
        name: "Country bathroom rack",
        size: "M",
    },
    213700100: {
        themeID: "99001",
        colors: ["6007", "6001", "6006", "6009", "6008"],
        name: "Urban hanging bath shelf",
        size: "S",
    },
    213700101: {
        themeID: "99001",
        colors: ["6008", "6001", "6006", "6007", "6011"],
        name: "Urban hanging bath shelf",
        size: "S",
    },
    213700102: {
        themeID: "99001",
        colors: ["6011", "6001", "6005", "6008", "6006"],
        name: "Urban hanging bath shelf",
        size: "S",
    },
    213700103: {
        themeID: "99001",
        colors: ["6004", "6001", "6006", "6011", "6010"],
        name: "Urban hanging bath shelf",
        size: "S",
    },
    214000500: { themeID: "99005", colors: ["6005"], name: "Kiddy Slippers", size: "XS" },
    214000501: { themeID: "99005", colors: ["6011", "6010"], name: "Kiddy Slippers", size: "XS" },
    214000502: { themeID: "99005", colors: ["6008"], name: "Kiddy Slippers", size: "XS" },
    214000503: { themeID: "99005", colors: ["6006"], name: "Kiddy Slippers", size: "XS" },
    214000504: { themeID: "99005", colors: ["6007"], name: "Kiddy Slippers", size: "XS" },
    214000505: { themeID: "99005", colors: ["6003", "6011"], name: "Kiddy Slippers", size: "XS" },
    214000800: { themeID: "99004", colors: ["6006", "6003"], name: "Beach Slippers", size: "XS" },
    214000801: { themeID: "99004", colors: ["6011", "6003"], name: "Beach Slippers", size: "XS" },
    214000802: { themeID: "99004", colors: ["6008", "6005"], name: "Beach Slippers", size: "XS" },
    214100100: { themeID: "99001", colors: ["6002", "6001"], name: "Urban slippers", size: "XS" },
    214100101: { themeID: "99001", colors: ["6008", "6001"], name: "Urban slippers", size: "XS" },
    214100102: { themeID: "99001", colors: ["6003", "6001"], name: "Urban slippers", size: "XS" },
    214100103: { themeID: "99001", colors: ["6010"], name: "Urban slippers", size: "XS" },
    214100104: { themeID: "99001", colors: ["6007", "6001"], name: "Urban slippers", size: "XS" },
    214100200: { themeID: "99003", colors: ["6003", "6001", "6011"], name: "Girlie slippers", size: "S" },
    214100201: { themeID: "99003", colors: ["6009", "6006", "6001"], name: "Girlie slippers", size: "S" },
    214100202: { themeID: "99003", colors: ["6010", "6001"], name: "Girlie slippers", size: "S" },
    214100203: { themeID: "99003", colors: ["6008", "6001", "6002"], name: "Girlie slippers", size: "S" },
    214130100: { themeID: "99013", colors: ["6008", "6010"], name: "Unicorn slippers", size: "S" },
    214130101: { themeID: "99013", colors: ["6005", "6007"], name: "Unicorn slippers", size: "S" },
    214130102: { themeID: "99013", colors: ["6011", "6010"], name: "Unicorn slippers", size: "S" },
    214130103: { themeID: "99013", colors: ["6007", "6006"], name: "Unicorn slippers", size: "S" },
    214301200: { themeID: "99013", colors: ["6006", "6011", "6007"], name: "Roller skates", size: "S" },
    214301201: { themeID: "99013", colors: ["6011", "6006"], name: "Roller skates", size: "S" },
    214301202: { themeID: "99013", colors: ["6008", "6006"], name: "Roller skates", size: "S" },
    214301203: { themeID: "99013", colors: ["6010", "6007", "6011"], name: "Roller skates", size: "S" },
    214500100: { themeID: "99011", colors: ["6005"], name: "Bunny Slipper", size: "S" },
    214500101: { themeID: "99011", colors: ["6011"], name: "Bunny Slipper", size: "S" },
    214500102: { themeID: "99011", colors: ["6008"], name: "Bunny Slipper", size: "S" },
    214500103: { themeID: "99011", colors: ["6010"], name: "Bunny Slipper", size: "S" },
    215000400: { themeID: "99003", colors: ["6003", "6011"], name: "Girlie Partition", size: "L" },
    215000401: { themeID: "99003", colors: ["6009", "6005"], name: "Girlie Partition", size: "L" },
    215000402: { themeID: "99003", colors: ["6010", "6004"], name: "Girlie Partition", size: "L" },
    215000403: { themeID: "99003", colors: ["6002", "6008"], name: "Girlie Partition", size: "L" },
    215000500: { themeID: "99004", colors: ["6011", "6006", "6003"], name: "Rattan Screen", size: "M" },
    215000501: { themeID: "99004", colors: ["6007", "6009", "6006"], name: "Rattan Screen", size: "M" },
    215000502: {
        themeID: "99004",
        colors: ["6007", "6008", "6009", "6006", "6004"],
        name: "Rattan Screen",
        size: "M",
    },
    215000503: { themeID: "99004", colors: ["6008", "6004", "6009"], name: "Rattan Screen", size: "M" },
    215200100: { themeID: "99007", colors: ["6009", "6006"], name: "Country Screen", size: "L" },
    215200101: { themeID: "99007", colors: ["6003", "6011"], name: "Country Screen", size: "L" },
    215200102: { themeID: "99007", colors: ["6007", "6006"], name: "Country Screen", size: "L" },
    215200103: { themeID: "99007", colors: ["6008", "6004"], name: "Country Screen", size: "L" },
    215300100: { themeID: "99009", colors: ["6001", "6006", "6004"], name: "Classy oak screen", size: "L" },
    215300101: { themeID: "99009", colors: ["6002", "6004"], name: "Classy oak screen", size: "L" },
    215300102: { themeID: "99009", colors: ["6011", "6001", "6004"], name: "Classy oak screen", size: "L" },
    215500200: { themeID: "99011", colors: ["6006", "6007", "6009"], name: "Dolly screen", size: "XL" },
    215500201: {
        themeID: "99011",
        colors: ["6003", "6011", "6009", "6007"],
        name: "Dolly screen",
        size: "XL",
    },
    215500202: { themeID: "99011", colors: ["6008", "6009", "6005"], name: "Dolly screen", size: "XL" },
    215500203: {
        themeID: "99011",
        colors: ["6010", "6009", "6011", "6004"],
        name: "Dolly screen",
        size: "XL",
    },
    215700100: { themeID: "99001", colors: ["6007", "6001"], name: "Urban bulkhead", size: "L" },
    215700101: { themeID: "99001", colors: ["6008", "6001"], name: "Urban bulkhead", size: "L" },
    215700102: { themeID: "99001", colors: ["6011", "6001"], name: "Urban bulkhead", size: "L" },
    215700103: { themeID: "99001", colors: ["6002", "6010", "6001"], name: "Urban bulkhead", size: "L" },
    215700104: { themeID: "99001", colors: ["6004", "6002", "6001"], name: "Urban bulkhead", size: "L" },
    217100100: { themeID: "99002", colors: ["6007", "6009"], name: "Rustic vine gate", size: "L" },
    217100101: {
        themeID: "99002",
        colors: ["6006", "6007", "6005", "6004"],
        name: "Rustic vine gate",
        size: "L",
    },
    217100102: { themeID: "99002", colors: ["6008", "6007", "6002"], name: "Rustic vine gate", size: "L" },
    217100200: {
        themeID: "99017",
        colors: ["6003", "6001", "6007"],
        name: "Candy dorm gate",
        size: "L",
        xmas: true,
    },
    217100201: {
        themeID: "99017",
        colors: ["6007", "6005", "6009"],
        name: "Candy dorm gate",
        size: "L",
        xmas: true,
    },
    217100202: {
        themeID: "99017",
        colors: ["6011", "6008", "6001"],
        name: "Candy dorm gate",
        size: "L",
        xmas: true,
    },
    217300100: { themeID: "99009", colors: ["6001", "6007", "6003"], name: "Antique gate", size: "L" },
    217300101: {
        themeID: "99009",
        colors: ["6002", "6008", "6007", "6004"],
        name: "Antique gate",
        size: "L",
    },
    217300102: { themeID: "99009", colors: ["6011", "6006", "6007"], name: "Antique gate", size: "L" },
    217400100: {
        themeID: "99019",
        colors: ["6009", "6004", "6001"],
        name: "Stonehouse Dome gate",
        size: "XL",
    },
    217400101: {
        themeID: "99019",
        colors: ["6003", "6001", "6004"],
        name: "Stonehouse Dome gate",
        size: "XL",
    },
    217400102: {
        themeID: "99019",
        colors: ["6008", "6002", "6004"],
        name: "Stonehouse Dome gate",
        size: "XL",
    },
    217400200: { themeID: "99019", colors: ["6004", "6009", "6006"], name: "Stonehouse gate", size: "XL" },
    217400201: {
        themeID: "99019",
        colors: ["6003", "6004", "6005", "6006"],
        name: "Stonehouse gate",
        size: "XL",
    },
    217400202: {
        themeID: "99019",
        colors: ["6008", "6006", "6009", "6004"],
        name: "Stonehouse gate",
        size: "XL",
    },
    217400400: { themeID: "99006", colors: ["6007", "6009"], name: "Vintage gate", size: "L" },
    217400401: { themeID: "99006", colors: ["6008", "6009"], name: "Vintage gate", size: "L" },
    217400402: { themeID: "99006", colors: ["6003", "6002"], name: "Vintage gate", size: "L" },
    217400403: { themeID: "99006", colors: ["6002", "6004"], name: "Vintage gate", size: "L" },
    220100100: { themeID: "99002", colors: ["6006"], name: "Planky Tile", size: "XL" },
    220100101: { themeID: "99002", colors: ["6009"], name: "Planky Tile", size: "XL" },
    220100102: { themeID: "99002", colors: ["6001"], name: "Planky Tile", size: "XL" },
    220100103: { themeID: "99002", colors: ["6008"], name: "Planky Tile", size: "XL" },
    220100104: { themeID: "99002", colors: ["6011"], name: "Planky Tile", size: "XL" },
    220100105: { themeID: "99002", colors: ["6002", "6004"], name: "Planky Tile", size: "XL" },
    220100200: { themeID: "99002", colors: ["6006"], name: "Rustic Tile", size: "XL" },
    220100201: { themeID: "99002", colors: ["6009", "6004"], name: "Rustic Tile", size: "XL" },
    220100202: { themeID: "99002", colors: ["6001", "6004"], name: "Rustic Tile", size: "XL" },
    220100203: { themeID: "99002", colors: ["6007", "6004"], name: "Rustic Tile", size: "XL" },
    220100204: { themeID: "99002", colors: ["6002", "6004"], name: "Rustic Tile", size: "XL" },
    220100300: { themeID: "99001", colors: ["6011"], name: "Urban carpeted tile", size: "XL" },
    220100301: { themeID: "99001", colors: ["6007"], name: "Urban carpeted tile", size: "XL" },
    220100302: { themeID: "99001", colors: ["6001"], name: "Urban carpeted tile", size: "XL" },
    220100303: { themeID: "99001", colors: ["6008"], name: "Urban carpeted tile", size: "XL" },
    220100304: { themeID: "99001", colors: ["6008", "6004"], name: "Urban carpeted tile", size: "XL" },
    220100305: { themeID: "99001", colors: ["6002"], name: "Urban carpeted tile", size: "XL" },
    220100306: { themeID: "99001", colors: ["6003"], name: "Urban carpeted tile", size: "XL" },
    220100400: { themeID: "99006", colors: ["6007", "6001"], name: "Vintage Chequeur", size: "XL" },
    220100401: { themeID: "99006", colors: ["6009", "6004"], name: "Vintage Chequeur", size: "XL" },
    220100402: { themeID: "99006", colors: ["6008"], name: "Vintage Chequeur", size: "XL" },
    220100403: { themeID: "99006", colors: ["6011", "6004"], name: "Vintage Chequeur", size: "XL" },
    220100404: { themeID: "99006", colors: ["6006", "6004"], name: "Vintage Chequeur", size: "XL" },
    220100405: { themeID: "99006", colors: ["6001", "6004"], name: "Vintage Chequeur", size: "XL" },
    220100500: { themeID: "99003", colors: ["6011", "6001"], name: "Chequery heart tile", size: "XL" },
    220100501: { themeID: "99003", colors: ["6006", "6001"], name: "Chequery heart tile", size: "XL" },
    220100502: {
        themeID: "99003",
        colors: ["6004", "6001", "6010"],
        name: "Chequery heart tile",
        size: "XL",
    },
    220100600: { themeID: "99004", colors: ["6006", "6009", "6001"], name: "Rattan floor", size: "XL" },
    220100601: { themeID: "99004", colors: ["6009", "6004"], name: "Rattan floor", size: "XL" },
    220100602: { themeID: "99004", colors: ["6001"], name: "Rattan floor", size: "XL" },
    220100603: { themeID: "99004", colors: ["6007"], name: "Rattan floor", size: "XL" },
    220100604: { themeID: "99004", colors: ["6011"], name: "Rattan floor", size: "XL" },
    220100605: { themeID: "99004", colors: ["6008"], name: "Rattan floor", size: "XL" },
    220100606: { themeID: "99004", colors: ["6010"], name: "Rattan floor", size: "XL" },
    220100700: { themeID: "99015", colors: ["6001", "6009"], name: "Refined marble tile", size: "XL" },
    220100701: { themeID: "99015", colors: ["6008"], name: "Refined marble tile", size: "XL" },
    220100702: { themeID: "99015", colors: ["6009"], name: "Refined marble tile", size: "XL" },
    220100703: { themeID: "99015", colors: ["6010", "6004"], name: "Refined marble tile", size: "XL" },
    220100704: { themeID: "99015", colors: ["6007"], name: "Refined marble tile", size: "XL" },
    220101000: { themeID: "99009", colors: ["6004", "6009", "6005"], name: "Classy rock tile", size: "XL" },
    220101001: { themeID: "99009", colors: ["6008", "6004"], name: "Classy rock tile", size: "XL" },
    220101002: { themeID: "99009", colors: ["6009", "6004"], name: "Classy rock tile", size: "XL" },
    220101100: { themeID: "99009", colors: ["6009", "6006", "6001"], name: "Classy tile", size: "XL" },
    220101101: { themeID: "99009", colors: ["6002", "6004"], name: "Classy tile", size: "XL" },
    220101102: { themeID: "99009", colors: ["6011", "6001"], name: "Classy tile", size: "XL" },
    220700100: { themeID: "99011", colors: ["6001", "6004", "6009"], name: "Polka tile", size: "XL" },
    220700101: { themeID: "99011", colors: ["6006", "6009", "6001"], name: "Polka tile", size: "XL" },
    220700102: { themeID: "99011", colors: ["6011", "6001"], name: "Polka tile", size: "XL" },
    220700103: { themeID: "99011", colors: ["6008", "6001"], name: "Polka tile", size: "XL" },
    220700104: { themeID: "99011", colors: ["6010", "6001"], name: "Polka tile", size: "XL" },
    220700105: { themeID: "99011", colors: ["6004", "6002", "6001"], name: "Polka tile", size: "XL" },
    220700200: { themeID: "99011", colors: ["6004", "6011"], name: "Dolly wooden tile", size: "XL" },
    220700201: { themeID: "99011", colors: ["6009", "6006"], name: "Dolly wooden tile", size: "XL" },
    220700202: { themeID: "99011", colors: ["6011", "6009"], name: "Dolly wooden tile", size: "XL" },
    220700203: { themeID: "99011", colors: ["6008", "6001"], name: "Dolly wooden tile", size: "XL" },
    220700204: { themeID: "99011", colors: ["6010", "6004"], name: "Dolly wooden tile", size: "XL" },
    220700205: { themeID: "99011", colors: ["6002", "6004"], name: "Dolly wooden tile", size: "XL" },
    220700300: {
        themeID: "99007",
        colors: ["6005", "6009", "6003"],
        name: "Little squares tile",
        size: "XL",
    },
    220700301: {
        themeID: "99007",
        colors: ["6003", "6011", "6004"],
        name: "Little squares tile",
        size: "XL",
    },
    220700302: {
        themeID: "99007",
        colors: ["6010", "6005", "6004"],
        name: "Little squares tile",
        size: "XL",
    },
    220700303: {
        themeID: "99007",
        colors: ["6007", "6006", "6001"],
        name: "Little squares tile",
        size: "XL",
    },
    220700400: { themeID: "99007", colors: ["6006", "6004", "6009"], name: "Circular Tile", size: "XL" },
    220700401: { themeID: "99007", colors: ["6010", "6004"], name: "Circular Tile", size: "XL" },
    220700402: { themeID: "99007", colors: ["6007", "6004"], name: "Circular Tile", size: "XL" },
    220701000: {
        themeID: "99017",
        colors: ["6007", "6001", "6006"],
        name: "Light snowflake tile",
        size: "XL",
        xmas: true,
    },
    220701001: {
        themeID: "99017",
        colors: ["6008", "6004", "6001"],
        name: "Light snowflake tile",
        size: "XL",
        xmas: true,
    },
    220701002: {
        themeID: "99017",
        colors: ["6011", "6001", "6003"],
        name: "Light snowflake tile",
        size: "XL",
        xmas: true,
    },
    220701003: {
        themeID: "99017",
        colors: ["6010", "6011", "6001"],
        name: "Light snowflake tile",
        size: "XL",
        xmas: true,
    },
    220701100: {
        themeID: "99017",
        colors: ["6009", "6007", "6001"],
        name: "Snowflake tile ",
        size: "XL",
        xmas: true,
    },
    220701101: {
        themeID: "99017",
        colors: ["6006", "6005", "6001"],
        name: "Snowflake tile ",
        size: "XL",
        xmas: true,
    },
    220701102: {
        themeID: "99017",
        colors: ["6008", "6011", "6010"],
        name: "Snowflake tile ",
        size: "XL",
        xmas: true,
    },
    220701103: {
        themeID: "99017",
        colors: ["6010", "6011", "6001"],
        name: "Snowflake tile ",
        size: "XL",
        xmas: true,
    },
    220701104: {
        themeID: "99017",
        colors: ["6011", "6010", "6001"],
        name: "Snowflake tile ",
        size: "XL",
        xmas: true,
    },
    220701200: { themeID: "99017", colors: ["6001"], name: "Snowy ground", size: "XL", xmas: true },
    220701201: { themeID: "99017", colors: ["6001", "6004"], name: "Snowy ground", size: "XL", xmas: true },
    220701202: { themeID: "99017", colors: ["6001"], name: "Snowy ground", size: "XL", xmas: true },
    220701203: { themeID: "99017", colors: ["6001", "6004"], name: "Snowy ground", size: "XL", xmas: true },
    220701204: { themeID: "99017", colors: ["6001", "6004"], name: "Snowy ground", size: "XL", xmas: true },
    220701205: { themeID: "99017", colors: ["6001", "6004"], name: "Snowy ground", size: "XL", xmas: true },
    220701300: { themeID: "99019", colors: ["6004"], name: "Stonehouse tile", size: "XL" },
    220701301: { themeID: "99019", colors: ["6002"], name: "Stonehouse tile", size: "XL" },
    220701302: { themeID: "99019", colors: ["6009"], name: "Stonehouse tile", size: "XL" },
    220701303: { themeID: "99019", colors: ["6001", "6004"], name: "Stonehouse tile", size: "XL" },
    220701400: { themeID: "99001", colors: [""], name: "Standard tile", size: "XL" },
    220701401: { themeID: "99001", colors: [""], name: "Standard tile", size: "XL" },
    220701402: { themeID: "99001", colors: [""], name: "Standard tile", size: "XL" },
    220701403: { themeID: "99001", colors: [""], name: "Standard tile", size: "XL" },
    220701500: { themeID: "99001", colors: ["6007", "6005", "6009", "6001"], name: "Urban tile", size: "XL" },
    220701501: { themeID: "99001", colors: ["6007", "6006", "6004", "6001"], name: "Urban tile", size: "XL" },
    220701502: { themeID: "99001", colors: ["6008", "6004", "6001"], name: "Urban tile", size: "XL" },
    220701503: { themeID: "99001", colors: ["6011", "6004", "6001"], name: "Urban tile", size: "XL" },
    220701504: { themeID: "99001", colors: ["6008", "6011", "6010"], name: "Urban tile", size: "XL" },
    220701600: { themeID: "99004", colors: ["6009"], name: "Wicker floor", size: "XL" },
    220701601: { themeID: "99004", colors: ["6007"], name: "Wicker floor", size: "XL" },
    220701602: { themeID: "99004", colors: ["6002", "6004"], name: "Wicker floor", size: "XL" },
    220701603: { themeID: "99004", colors: ["6010", "6001"], name: "Wicker floor", size: "XL" },
    220701604: { themeID: "99004", colors: ["6006", "6009"], name: "Wicker floor", size: "XL" },
    220701700: { themeID: "99006", colors: ["6005", "6006"], name: "Vintage square", size: "XL" },
    220701701: { themeID: "99006", colors: ["6008"], name: "Vintage square", size: "XL" },
    220701702: { themeID: "99006", colors: ["6011"], name: "Vintage square", size: "XL" },
    220701703: { themeID: "99006", colors: ["6010", "6002"], name: "Vintage square", size: "XL" },
    220701704: { themeID: "99006", colors: ["6009", "6004"], name: "Vintage square", size: "XL" },
    220701705: { themeID: "99006", colors: ["6007", "6002"], name: "Vintage square", size: "XL" },
    220701800: { themeID: "99006", colors: ["6006", "6004"], name: "Vintage circle", size: "XL" },
    220701801: { themeID: "99006", colors: ["6005", "6011", "6004"], name: "Vintage circle", size: "XL" },
    220701802: { themeID: "99006", colors: ["6009", "6005", "6004"], name: "Vintage circle", size: "XL" },
    220701803: { themeID: "99006", colors: ["6008", "6004"], name: "Vintage circle", size: "XL" },
    220701804: { themeID: "99006", colors: ["6010"], name: "Vintage circle", size: "XL" },
    220701900: { themeID: "99005", colors: ["6006", "6001", "6009"], name: "Sunny Day", size: "XL" },
    220701901: { themeID: "99005", colors: ["6011", "6003", "6001"], name: "Sunny Day", size: "XL" },
    220701902: { themeID: "99005", colors: ["6004", "6010", "6001"], name: "Sunny Day", size: "XL" },
    220701903: { themeID: "99005", colors: ["6001", "6008", "6004"], name: "Sunny Day", size: "XL" },
    220701904: { themeID: "99005", colors: ["6008", "6007", "6001"], name: "Sunny Day", size: "XL" },
    220701905: { themeID: "99005", colors: ["6007", "6001"], name: "Sunny Day", size: "XL" },
    220702000: { themeID: "99005", colors: ["6009", "6001"], name: "Little stars", size: "XL" },
    220702001: { themeID: "99005", colors: ["6007", "6001"], name: "Little stars", size: "XL" },
    220702002: { themeID: "99005", colors: ["6008", "6001"], name: "Little stars", size: "XL" },
    220702003: { themeID: "99005", colors: ["6004", "6010", "6001"], name: "Little stars", size: "XL" },
    220702100: { themeID: "99008", colors: ["6005", "6004"], name: "Boho tile", size: "XL" },
    220702101: { themeID: "99008", colors: ["6001", "6005"], name: "Boho tile", size: "XL" },
    220702102: { themeID: "99008", colors: ["6011", "6003", "6004"], name: "Boho tile", size: "XL" },
    220702103: { themeID: "99008", colors: ["6010", "6004", "6001"], name: "Boho tile", size: "XL" },
    220702104: { themeID: "99008", colors: ["6008", "6001"], name: "Boho tile", size: "XL" },
    220702105: { themeID: "99008", colors: ["6007", "6001"], name: "Boho tile", size: "XL" },
    220702200: {
        themeID: "99015",
        colors: ["6005", "6004", "6001", "6008"],
        name: "Refined ceramic tile",
        size: "XL",
    },
    220702201: {
        themeID: "99015",
        colors: ["6007", "6009", "6001"],
        name: "Refined ceramic tile",
        size: "XL",
    },
    220702202: {
        themeID: "99015",
        colors: ["6008", "6009", "6001"],
        name: "Refined ceramic tile",
        size: "XL",
    },
    220702203: {
        themeID: "99015",
        colors: ["6011", "6008", "6001"],
        name: "Refined ceramic tile",
        size: "XL",
    },
    220702300: { themeID: "99008", colors: ["6004", "6001"], name: "Chevron pattern", size: "XL" },
    220702301: { themeID: "99008", colors: ["6007", "6004", "6006"], name: "Chevron pattern", size: "XL" },
    220702302: { themeID: "99008", colors: ["6008", "6004", "6001"], name: "Chevron pattern", size: "XL" },
    220702303: { themeID: "99008", colors: ["6010", "6004", "6001"], name: "Chevron pattern", size: "XL" },
    220702400: { themeID: "99007", colors: ["6004", "6008"], name: "Country Tile", size: "XL" },
    220702401: { themeID: "99007", colors: ["6006", "6004"], name: "Country Tile", size: "XL" },
    220702402: { themeID: "99007", colors: ["6011", "6004"], name: "Country Tile", size: "XL" },
    220702403: { themeID: "99007", colors: ["6009", "6004"], name: "Country Tile", size: "XL" },
    220702500: { themeID: "99008", colors: ["6006", "6004"], name: "Boho marble tile", size: "XL" },
    220702501: { themeID: "99008", colors: ["6007", "6004"], name: "Boho marble tile", size: "XL" },
    220702502: { themeID: "99008", colors: ["6011", "6010", "6004"], name: "Boho marble tile", size: "XL" },
    220702503: { themeID: "99008", colors: ["6008", "6004"], name: "Boho marble tile", size: "XL" },
    220702504: { themeID: "99008", colors: ["6004", "6001"], name: "Boho marble tile", size: "XL" },
    230100100: { themeID: "99002", colors: ["6006"], name: "Planky Wall", size: "XL" },
    230100101: { themeID: "99002", colors: ["6009"], name: "Planky Wall", size: "XL" },
    230100102: { themeID: "99002", colors: ["6001"], name: "Planky Wall", size: "XL" },
    230100103: { themeID: "99002", colors: ["6008"], name: "Planky Wall", size: "XL" },
    230100104: { themeID: "99002", colors: ["6011"], name: "Planky Wall", size: "XL" },
    230100105: { themeID: "99002", colors: ["6002"], name: "Planky Wall", size: "XL" },
    230100200: { themeID: "99002", colors: ["6006", "6001"], name: "Striped planky wall", size: "XL" },
    230100201: { themeID: "99002", colors: ["6008", "6001"], name: "Striped planky wall", size: "XL" },
    230100202: { themeID: "99002", colors: ["6011", "6001"], name: "Striped planky wall", size: "XL" },
    230100203: { themeID: "99002", colors: ["6004", "6001"], name: "Striped planky wall", size: "XL" },
    230100300: { themeID: "99001", colors: ["6005", "6006", "6009"], name: "Urban plain wall", size: "XL" },
    230100301: { themeID: "99001", colors: ["6007", "6009"], name: "Urban plain wall", size: "XL" },
    230100302: { themeID: "99001", colors: ["6008", "6010"], name: "Urban plain wall", size: "XL" },
    230100303: { themeID: "99001", colors: ["6011", "6009"], name: "Urban plain wall", size: "XL" },
    230100304: { themeID: "99001", colors: ["6004", "6009"], name: "Urban plain wall", size: "XL" },
    230100305: { themeID: "99001", colors: ["6003", "6002"], name: "Urban plain wall", size: "XL" },
    230100400: { themeID: "99006", colors: ["6006", "6009", "6004"], name: "Damask wall", size: "XL" },
    230100401: { themeID: "99006", colors: ["6009"], name: "Damask wall", size: "XL" },
    230100402: { themeID: "99006", colors: ["6008", "6009", "6004"], name: "Damask wall", size: "XL" },
    230100403: { themeID: "99006", colors: ["6003", "6004", "6009"], name: "Damask wall", size: "XL" },
    230100500: {
        themeID: "99003",
        colors: ["6011", "6003", "6001"],
        name: "Girlie striped wall",
        size: "XL",
    },
    230100501: {
        themeID: "99003",
        colors: ["6009", "6005", "6006", "6001"],
        name: "Girlie striped wall",
        size: "XL",
    },
    230100502: {
        themeID: "99003",
        colors: ["6004", "6010", "6001", "6002"],
        name: "Girlie striped wall",
        size: "XL",
    },
    230100600: {
        themeID: "99003",
        colors: ["6003", "6011", "6009"],
        name: "Girlie lattice wall",
        size: "XL",
    },
    230100601: {
        themeID: "99003",
        colors: ["6005", "6009", "6006"],
        name: "Girlie lattice wall",
        size: "XL",
    },
    230100602: {
        themeID: "99003",
        colors: ["6010", "6004", "6002"],
        name: "Girlie lattice wall",
        size: "XL",
    },
    230100700: { themeID: "99004", colors: ["6009", "6006"], name: "Bamboo wall", size: "XL" },
    230100701: { themeID: "99004", colors: ["6007", "6006"], name: "Bamboo wall", size: "XL" },
    230100702: { themeID: "99004", colors: ["6001", "6004"], name: "Bamboo wall", size: "XL" },
    230100703: { themeID: "99004", colors: ["6008", "6001"], name: "Bamboo wall", size: "XL" },
    230100704: { themeID: "99004", colors: ["6011", "6001"], name: "Bamboo wall", size: "XL" },
    230100800: { themeID: "99015", colors: ["6009", "6006"], name: "Wooden slice wall", size: "XL" },
    230100801: { themeID: "99015", colors: ["6008", "6009", "6006"], name: "Wooden slice wall", size: "XL" },
    230100802: { themeID: "99015", colors: ["6007", "6006", "6009"], name: "Wooden slice wall", size: "XL" },
    230100803: {
        themeID: "99015",
        colors: ["6011", "6003", "6009", "6006"],
        name: "Wooden slice wall",
        size: "XL",
    },
    230100900: { themeID: "99009", colors: ["6001", "6002"], name: "Classy brick wall", size: "XL" },
    230100901: { themeID: "99009", colors: ["6009", "6002", "6004"], name: "Classy brick wall", size: "XL" },
    230100902: { themeID: "99009", colors: ["6011", "6002"], name: "Classy brick wall", size: "XL" },
    230101000: { themeID: "99009", colors: ["6001", "6004"], name: "Antique wallpaper", size: "XL" },
    230101001: { themeID: "99009", colors: ["6005", "6001", "6009"], name: "Antique wallpaper", size: "XL" },
    230101002: { themeID: "99009", colors: ["6011", "6004", "6001"], name: "Antique wallpaper", size: "XL" },
    230101003: { themeID: "99009", colors: ["6009", "6001", "6004"], name: "Antique wallpaper", size: "XL" },
    230101100: {
        themeID: "99009",
        colors: ["6009", "6002", "6004"],
        name: "Antique floral wallpaper",
        size: "XL",
    },
    230101101: {
        themeID: "99009",
        colors: ["6010", "6004", "6002"],
        name: "Antique floral wallpaper",
        size: "XL",
    },
    230101102: {
        themeID: "99009",
        colors: ["6011", "6002", "6003"],
        name: "Antique floral wallpaper",
        size: "XL",
    },
    230700100: { themeID: "99011", colors: ["6004", "6001"], name: "Lacey Plaid Pattern", size: "XL" },
    230700101: {
        themeID: "99011",
        colors: ["6006", "6001", "6005"],
        name: "Lacey Plaid Pattern",
        size: "XL",
    },
    230700102: { themeID: "99011", colors: ["6011", "6001"], name: "Lacey Plaid Pattern", size: "XL" },
    230700103: { themeID: "99011", colors: ["6008", "6001"], name: "Lacey Plaid Pattern", size: "XL" },
    230700104: { themeID: "99011", colors: ["6010", "6001"], name: "Lacey Plaid Pattern", size: "XL" },
    230700200: { themeID: "99011", colors: ["6006", "6001", "6009"], name: "Dolly Polka Lace", size: "XL" },
    230700201: { themeID: "99011", colors: ["6010", "6001", "6004"], name: "Dolly Polka Lace", size: "XL" },
    230700202: { themeID: "99011", colors: ["6011", "6003", "6001"], name: "Dolly Polka Lace", size: "XL" },
    230700203: { themeID: "99011", colors: ["6008", "6001"], name: "Dolly Polka Lace", size: "XL" },
    230700204: { themeID: "99011", colors: ["6004", "6002", "6001"], name: "Dolly Polka Lace", size: "XL" },
    230700300: {
        themeID: "99007",
        colors: ["6006", "6001", "6009", "6007"],
        name: "Country Garden",
        size: "XL",
    },
    230700301: { themeID: "99007", colors: ["6011", "6010", "6001"], name: "Country Garden", size: "XL" },
    230700302: { themeID: "99007", colors: ["6007", "6001"], name: "Country Garden", size: "XL" },
    230700400: { themeID: "99007", colors: ["6006", "6009"], name: "Polka Barley", size: "XL" },
    230700401: { themeID: "99007", colors: ["6011", "6001"], name: "Polka Barley", size: "XL" },
    230700402: { themeID: "99007", colors: ["6007", "6006", "6001"], name: "Polka Barley", size: "XL" },
    230700500: {
        themeID: "99007",
        colors: ["6006", "6003", "6009", "6007"],
        name: "Cutesy Mushy",
        size: "XL",
    },
    230700501: {
        themeID: "99007",
        colors: ["6001", "6011", "6010", "6005"],
        name: "Cutesy Mushy",
        size: "XL",
    },
    230700600: { themeID: "99007", colors: ["6005", "6007", "6001", "6006"], name: "Sunny Hill", size: "XL" },
    230700601: {
        themeID: "99007",
        colors: ["6010", "6011", "6003", "6001", "6006"],
        name: "Sunny Hill",
        size: "XL",
    },
    230700602: { themeID: "99007", colors: ["6007", "6001", "6005"], name: "Sunny Hill", size: "XL" },
    230701000: {
        themeID: "99001",
        colors: ["6008", "6005", "6001", "6007"],
        name: "Day Wallpaper",
        size: "XL",
    },
    230701001: { themeID: "99001", colors: ["6008", "6007", "6001"], name: "Day Wallpaper", size: "XL" },
    230701002: { themeID: "99001", colors: ["6008", "6007", "6001"], name: "Day Wallpaper", size: "XL" },
    230701100: { themeID: "99001", colors: ["6010", "6004"], name: "Night Wallpaper", size: "XL" },
    230701101: { themeID: "99001", colors: ["6010", "6004"], name: "Night Wallpaper", size: "XL" },
    230701102: { themeID: "99001", colors: ["6010", "6004", "6006"], name: "Night Wallpaper", size: "XL" },
    230701103: { themeID: "99001", colors: ["6010", "6004"], name: "Night Wallpaper", size: "XL" },
    230701400: {
        themeID: "99017",
        colors: ["6003", "6007", "6001"],
        name: "Snowflake wallpaper",
        size: "XL",
        xmas: true,
    },
    230701401: {
        themeID: "99017",
        colors: ["6009", "6005", "6001"],
        name: "Snowflake wallpaper",
        size: "XL",
        xmas: true,
    },
    230701402: {
        themeID: "99017",
        colors: ["6008", "6011", "6001"],
        name: "Snowflake wallpaper",
        size: "XL",
        xmas: true,
    },
    230701403: {
        themeID: "99017",
        colors: ["6001", "6006", "6004"],
        name: "Snowflake wallpaper",
        size: "XL",
        xmas: true,
    },
    230701500: {
        themeID: "99017",
        colors: ["6005", "6001", "6007"],
        name: "Xmas brick wall",
        size: "XL",
        xmas: true,
    },
    230701501: {
        themeID: "99017",
        colors: ["6006", "6009", "6001"],
        name: "Xmas brick wall",
        size: "XL",
        xmas: true,
    },
    230701502: {
        themeID: "99017",
        colors: ["6008", "6001", "6011"],
        name: "Xmas brick wall",
        size: "XL",
        xmas: true,
    },
    230701503: {
        themeID: "99017",
        colors: ["6011", "6001", "6010"],
        name: "Xmas brick wall",
        size: "XL",
        xmas: true,
    },
    230701600: { themeID: "99019", colors: ["6004"], name: "Stoney wall", size: "XL" },
    230701601: { themeID: "99019", colors: ["6009", "6004"], name: "Stoney wall", size: "XL" },
    230701602: { themeID: "99019", colors: ["6004", "6011", "6003"], name: "Stoney wall", size: "XL" },
    230701603: { themeID: "99019", colors: ["6004", "6001"], name: "Stoney wall", size: "XL" },
    230701700: { themeID: "99019", colors: ["6004", "6009"], name: "Stoney brick wall", size: "XL" },
    230701701: { themeID: "99019", colors: ["6004", "6007"], name: "Stoney brick wall", size: "XL" },
    230701702: { themeID: "99019", colors: ["6011", "6004"], name: "Stoney brick wall", size: "XL" },
    230701703: { themeID: "99019", colors: ["6008"], name: "Stoney brick wall", size: "XL" },
    230701800: { themeID: "99019", colors: ["6009", "6004"], name: "Stoney wood wall", size: "XL" },
    230701801: { themeID: "99019", colors: ["6004", "6011"], name: "Stoney wood wall", size: "XL" },
    230701802: { themeID: "99019", colors: ["6011", "6009", "6004"], name: "Stoney wood wall", size: "XL" },
    230701803: { themeID: "99019", colors: ["6008", "6002"], name: "Stoney wood wall", size: "XL" },
    230701900: { themeID: "99019", colors: ["6009", "6004"], name: "Wooden stoney wall", size: "XL" },
    230701901: { themeID: "99019", colors: ["6004"], name: "Wooden stoney wall", size: "XL" },
    230701902: { themeID: "99019", colors: ["6009", "6011"], name: "Wooden stoney wall", size: "XL" },
    230701903: { themeID: "99019", colors: ["6002", "6008"], name: "Wooden stoney wall", size: "XL" },
    230702000: { themeID: "99004", colors: ["6004", "6010"], name: "Rattan Wall", size: "XL" },
    230702001: { themeID: "99004", colors: ["6005", "6009"], name: "Rattan Wall", size: "XL" },
    230702002: { themeID: "99004", colors: ["6008"], name: "Rattan Wall", size: "XL" },
    230702003: { themeID: "99004", colors: ["6011", "6009"], name: "Rattan Wall", size: "XL" },
    230702004: { themeID: "99004", colors: ["6007"], name: "Rattan Wall", size: "XL" },
    230702005: { themeID: "99004", colors: ["6001", "6004"], name: "Rattan Wall", size: "XL" },
    230702100: { themeID: "99004", colors: ["6006", "6005", "6009"], name: "Happy Pineapple", size: "XL" },
    230702101: { themeID: "99004", colors: ["6008", "6001"], name: "Happy Pineapple", size: "XL" },
    230702102: { themeID: "99004", colors: ["6009", "6006", "6005"], name: "Happy Pineapple", size: "XL" },
    230702103: { themeID: "99004", colors: ["6005", "6003", "6011"], name: "Happy Pineapple", size: "XL" },
    230702104: { themeID: "99004", colors: ["6011", "6010"], name: "Happy Pineapple", size: "XL" },
    230702200: { themeID: "99005", colors: ["6006", "6005", "6009"], name: "Micro Wall", size: "XL" },
    230702201: { themeID: "99005", colors: ["6005", "6006", "6009"], name: "Micro Wall", size: "XL" },
    230702202: { themeID: "99005", colors: ["6001", "6011", "6003"], name: "Micro Wall", size: "XL" },
    230702203: { themeID: "99005", colors: ["6011", "6003", "6001"], name: "Micro Wall", size: "XL" },
    230702204: { themeID: "99005", colors: ["6008", "6007", "6001"], name: "Micro Wall", size: "XL" },
    230702205: { themeID: "99005", colors: ["6008", "6001", "6006"], name: "Micro Wall", size: "XL" },
    230702206: { themeID: "99005", colors: ["6007", "6006", "6001"], name: "Micro Wall", size: "XL" },
    230702300: { themeID: "99005", colors: ["6006", "6005", "6001"], name: "Little Scientist", size: "XL" },
    230702301: { themeID: "99005", colors: ["6011", "6003", "6001"], name: "Little Scientist", size: "XL" },
    230702302: { themeID: "99005", colors: ["6008", "6001"], name: "Little Scientist", size: "XL" },
    230702303: { themeID: "99005", colors: ["6007", "6001"], name: "Little Scientist", size: "XL" },
    230702400: { themeID: "99011", colors: ["6001", "6004"], name: "Polkadot Line", size: "XL" },
    230702401: { themeID: "99011", colors: ["6011", "6001"], name: "Polkadot Line", size: "XL" },
    230702402: { themeID: "99011", colors: ["6008", "6001"], name: "Polkadot Line", size: "XL" },
    230702403: { themeID: "99011", colors: ["6006", "6009", "6001"], name: "Polkadot Line", size: "XL" },
    230702404: { themeID: "99011", colors: ["6002", "6004", "6001"], name: "Polkadot Line", size: "XL" },
    230702405: { themeID: "99011", colors: ["6010", "6011", "6001"], name: "Polkadot Line", size: "XL" },
    230702500: { themeID: "99011", colors: ["6001", "6004"], name: "Dolly Polkadots", size: "XL" },
    230702501: { themeID: "99011", colors: ["6006", "6009", "6001"], name: "Dolly Polkadots", size: "XL" },
    230702502: { themeID: "99011", colors: ["6011", "6001"], name: "Dolly Polkadots", size: "XL" },
    230702503: { themeID: "99011", colors: ["6008", "6001"], name: "Dolly Polkadots", size: "XL" },
    230702504: { themeID: "99011", colors: ["6010", "6001"], name: "Dolly Polkadots", size: "XL" },
    230702505: { themeID: "99011", colors: ["6004", "6002", "6001"], name: "Dolly Polkadots", size: "XL" },
    230702600: {
        themeID: "99008",
        colors: ["6005", "6009", "6001", "6006"],
        name: "Bohemian pattern",
        size: "XL",
    },
    230702601: { themeID: "99008", colors: ["6006", "6009", "6001"], name: "Bohemian pattern", size: "XL" },
    230702602: {
        themeID: "99008",
        colors: ["6011", "6004", "6001", "6003"],
        name: "Bohemian pattern",
        size: "XL",
    },
    230702603: { themeID: "99008", colors: ["6008", "6001", "6003"], name: "Bohemian pattern", size: "XL" },
    230702604: { themeID: "99008", colors: ["6010", "6004", "6001"], name: "Bohemian pattern", size: "XL" },
    230702700: {
        themeID: "99008",
        colors: ["6006", "6004", "6008", "6009"],
        name: "Boho wallpaper",
        size: "XL",
    },
    230702701: {
        themeID: "99008",
        colors: ["6011", "6010", "6005", "6004"],
        name: "Boho wallpaper",
        size: "XL",
    },
    230702800: { themeID: "99008", colors: ["6006", "6005", "6009"], name: "Boho line pattern", size: "XL" },
    230702801: { themeID: "99008", colors: ["6001", "6006", "6003"], name: "Boho line pattern", size: "XL" },
    230702802: { themeID: "99008", colors: ["6011", "6003", "6001"], name: "Boho line pattern", size: "XL" },
    230702803: { themeID: "99008", colors: ["6010", "6004", "6001"], name: "Boho line pattern", size: "XL" },
    230702804: { themeID: "99008", colors: ["6007", "6004", "6001"], name: "Boho line pattern", size: "XL" },
    230703300: { themeID: "99007", colors: ["6007", "6004"], name: "Floral Wave", size: "XL" },
    230703301: { themeID: "99007", colors: ["6008", "6004"], name: "Floral Wave", size: "XL" },
    230703302: { themeID: "99007", colors: ["6004", "6011", "6007"], name: "Floral Wave", size: "XL" },
    230703303: { themeID: "99007", colors: ["6011", "6003", "6004"], name: "Floral Wave", size: "XL" },
    230703400: { themeID: "99015", colors: ["6004", "6009"], name: "Wavering stripe", size: "XL" },
    230703401: { themeID: "99015", colors: ["6007", "6009", "6004"], name: "Wavering stripe", size: "XL" },
    230703402: { themeID: "99015", colors: ["6011", "6004"], name: "Wavering stripe", size: "XL" },
    230703403: { themeID: "99015", colors: ["6008", "6009"], name: "Wavering stripe", size: "XL" },
    230703404: { themeID: "99015", colors: ["6002", "6004", "6003"], name: "Wavering stripe", size: "XL" },
    230703500: { themeID: "99015", colors: ["6004", "6006"], name: "Freestyle basketweave", size: "XL" },
    230703501: { themeID: "99015", colors: ["6007", "6006"], name: "Freestyle basketweave", size: "XL" },
    230703502: { themeID: "99015", colors: ["6011", "6004"], name: "Freestyle basketweave", size: "XL" },
    230703503: { themeID: "99015", colors: ["6008", "6004"], name: "Freestyle basketweave", size: "XL" },
    230703504: { themeID: "99015", colors: ["6004"], name: "Freestyle basketweave", size: "XL" },
    230703600: { themeID: "99015", colors: ["6004", "6001"], name: "Barley chevron wall", size: "XL" },
    230703601: { themeID: "99015", colors: ["6007", "6004"], name: "Barley chevron wall", size: "XL" },
    230703602: { themeID: "99015", colors: ["6011", "6004"], name: "Barley chevron wall", size: "XL" },
    230703603: { themeID: "99015", colors: ["6008", "6004"], name: "Barley chevron wall", size: "XL" },
    230703604: { themeID: "99015", colors: ["6004"], name: "Barley chevron wall", size: "XL" },
};
