import { KAWAIIVERSE_CHAIN_ID } from "./blockchain";

export const MARKETPLACE_ADDRESS = process.env.REACT_APP_MARKETPLACE_ADDRESS;
export const MARKETPLACE_KWT_ADDRESS = process.env.REACT_APP_MARKETPLACE_KWT_ADDRESS;
export const KAWAII_CORE_ADDRESS = process.env.REACT_APP_KAWAII_CORE_ADDRESS;
export const KAWAII_CORE_ADDRESS_V2 = process.env.REACT_APP_KAWAII_CORE_ADDRESS_V2;
export const AIRI_ADDRESS = process.env.REACT_APP_AIRI_ADDRESS;
export const KAWAII_ADDRESS = process.env.REACT_APP_KAWAII_ADDRESS;
export const KAWAII_ADDRESS_V2 = process.env.REACT_APP_KAWAII_ADDRESS_V2;
export const RELAY_ADDRESS = process.env.REACT_APP_RELAY_ADDRESS;
export const AIRDROP_ADDRESS = process.env.REACT_APP_AIRDROP_ADDRESS;
export const PACK_ADDRESS = process.env.REACT_APP_PACK_ADDRESS;
export const AIRI_MAINNET_ADDRESS = process.env.REACT_APP_AIRI_MAINNET_ADDRESS;
export const PACK_EXCLUSIVE_ADDRESS = process.env.REACT_APP_PACK_EXCLUSIVE_ADDRESS;
export const DISCOUNT_PACK_ADDRESS = process.env.REACT_APP_DISCOUNT_PACK_ADDRESS;
export const FARMING_ADDRESS = process.env.REACT_APP_FARMING_ADDRESS;
export const PRIVATE_SALE_ADDRESS = process.env.REACT_APP_PRIVATE_SALE_ADDRESS;
export const BOOST_FARMING_ADDRESS = process.env.REACT_APP_BOOST_FARMING_ADDRESS;
export const BOOST_FARMING_ADDRESS_V2 = process.env.REACT_APP_BOOST_FARMING_ADDRESS_V2;
export const KWT_STAKING_ADDRESS = process.env.REACT_APP_KWT_STAKING_ADDRESS;
export const BURN_ADDRESS = process.env.REACT_APP_BOOST_BURN_ADDRESS;
export const REVENUE_SHARE_ADDRESS = process.env.REACT_APP_REVENUE_SHARE_ADDRESS;
export const AIRDROP_QUIZ_ADDRESS = process.env.REACT_APP_AIRDROP_QUIZ_ADDRESS;
export const HALLOWEEN_AIRDROP_ADDRESS = process.env.REACT_APP_HALLOWEEN_AIRDROP_ADDRESS;
export const MILKY_BNB_BOND_ADDRESS = process.env.REACT_APP_MILKY_BNB_BOND_ADDRESS;
export const MILKY_ADDRESS = process.env.REACT_APP_MILKY_ADDRESS;
export const MILKY_BNB_ADDRESS = process.env.REACT_APP_MILKY_BNB_ADDRESS;
// export const PRICE_FEED_ADDRESS = process.env.REACT_APP_PRICE_FEED_ADDRESS;
export const PRICE_FEED_ADDRESS = "0xBFC891b9e2b31EaaD59ce5A11779232be1796a09";
export const TREASURY_ADDRESS = process.env.REACT_APP_TREASURY_ADDRESS;
export const MILKY_BNB_sMILKY_ADDRESS = process.env.REACT_APP_MILKY_BNB_sMILKY_ADDRESS;
export const MILKY_BNB_STAKING_ADDRESS = process.env.REACT_APP_MILKY_BNB_STAKING_ADDRESS;
export const sMILKY_ADDRESS = process.env.REACT_APP_sMILKY_ADDRESS;
export const BOND_STAKING_ADDRESS = process.env.REACT_APP_BOND_STAKING_ADDRESS;
export const BRIDGE_ADDRESS = process.env.REACT_APP_BRIDGE_ADDRESS;
export const AIRDROP_V3_ADDRESS = process.env.REACT_APP_AIRDROP_V3_ADDRESS;
export const NFT_BRIDGE_ADDRESS = process.env.REACT_APP_NFT_BRIDGE_ADDRESS;
export const AIRDROP_POLKA_ADDRESS = process.env.REACT_APP_AIRDROP_POLKA_ADDRESS;
export const REVENUE_SHARE_ADDRESS_V2 = process.env.REACT_APP_REVENUE_SHARE_ADDRESS_V2;
export const REVENUE_SHARE_ADDRESS_V3 = "0xfF57Eae5598878Ec6D7C469cF79e6f29db1Fba35";
export const REWARD_MERKLE_ADDRESS = process.env.REACT_APP_REWARD_MERKLE;
export const AIRDROP_V5_ADDRESS = process.env.REACT_APP_AIRDROP_V5_ADDRESS;
export const TRANSFER_ADDRESS = process.env.REACT_APP_TRANSFER_ADDRESS;
export const MARKETPLACE_V4_ADDRESS = process.env.REACT_APP_MARKETPLACE_V4;
export const NFT_721_ADDRESS = "0xc4AD58EAa53fA146698111FfeEfBe14740575Ac6";
export const MARKETPLACE_KAWAIIVERSE = process.env.REACT_APP_MARKETPLACE_KAWAIIVERSE;
export const KAWAIIVERSE_CORE = process.env.REACT_APP_KAWAIIVERSE_CORE;
// export const KAWAIIVERSE_CORE = "0xECA02af6281Dc718b66218178D0a3f2C79059c41"; //testnet
export const KAWAII_LOCK_FARMING = "0x2DbA4b668b492aa8D969d4d449Eb4ea0D018c841";
export const KWT_KAWAIIVERSE_ADDRESS = "0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd";
// export const KWT_KAWAIIVERSE_ADDRESS = "0xe21E441d637D92c0FAdAF5D16035523Bb3Ca5496";
export const FARMING_NO_LOCK_ADDRESS = "0xcAbE0E6C60E767c9E94De760bD2580823C591891";
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const NFT_BRIDGE_V2_ADDRESS = "0x44bCa1EEb4a305fAe283dBFD3Ccc17C43Ba5996F";
export const NFT_BRIDGE_KAWAIIVERSE_ADDRESS = "0x349c8215A562B478e6e8CB9950EEcF7947A46f97";
export const NFT_BRIDGE_KAWAIIVERSE_TO_GAME_ADDRESS = "0x2aB1370197145130948e3201F49B727Bde56Ffbd";
export const AIRDROP_ISLANDS = "0xa9A08A1187d013f597009a1d15E063BeF1d29146";
export const BSC_GET_1155 = "0xcd6482c879b417cf5b06fd24a23e4f323b2bd0be";
export const KAWAIIVERSE_GET_1155 = "0x52EAC884dA9640270C271a6d83f65A481f049Dd7";
export const AIRDROP_ANNIVERSARY_ADDRESS = "0xAAAf33Ada7308f8f864B361305Aa183F99df3CCa";
export const LEAGUE_REWARD_ADDRESS = "0xD2210ba4Fa38f7699a182624201fB5e49D42A775";
export const CONNECT_DISCORD_ENDPOINT = process.env.REACT_APP_KAWAIIVERSE_CONNECT_DISCORD_API;
export const MILKY_KAWAIIVERSE_ADDRESS = "0xd567b3d7b8fe3c79a1ad8da978812cfc4fa05e75";
// export const MILKY_KAWAIIVERSE_ADDRESS = "0x72F9f549E3f1657Ab46a1Bd608344Af2d14ddd59"; //testnet
export const BUY_GEM_ADDRESS = "0x36D25C848Fc2D69c842b6F61c313635f10FB39CC";
export const BAN_USERS = [
    "0x868006d16090bcf079ab0f43a213dd5393c21dbb",
    "0xbc472cfaf2fc9c9ffb29f7f7966b4f3c2dd9988f",
    "0xdd496406ce93578f459431e2ec593001478ab4fa",
    "0xdac97c2c398b58374c9128f515974c545183b7a3",
    "0x3a373e3b971a3aa5af2f65a51d64b5dceff4966f",
    "0x27117147cceb08fb1b126ed7c938896fc272b30a",
    "0x4c0f06e7aa70e189a39bd349e0c6865cc673798b",
    "0x909229e3991b73a45034f6f702746d6c8c3a0186",
    "0xf81faf4db1dc864cb7eb5cef1ea4b9b4ff6343a2",
    "0x5c58a0b08b8abb2f82d4fcd7d98cea2a5f9ead28",
];
export const BUY_PASS_ADDRESS = "0xaE0f7fbcA1F8C63EEEe5347A20829f75c070e3ca";
export const CURRENT_SPENDING_ADDRESS = "0xa947d82a14D35a8157Ef9B89982fD122837988Af";
export const MILKY_REFUND_ADDRESS = "0x972e2252b67af9a289047a54b86D7B4DE62F9488";
export const KAWAIIVERSE_MINT_NFT_INGAME = "0x626886fe34952D25eae1925Ba765069b9B8250f1";
export const KAWAIIVERSE_PROXY_ADDRESS = "0x156aaA973e027784D0715A36C4458FB2cA7Db18B";
export const BREEDING_ADDRESS = "0x6b00D4cb793d8971b6931B71c959C4cf23094e62";
// export const BREEDING_ADDRESS = "0x6720Fe127FA9033d0990d4d96A56C6226F010d1A";
export const FUSION_ADDRESS = "0xdA719e9B4827639D30De3bB861BC3f1D38880E73";
// export const FUSION_ADDRESS = "0x52b4691a172B450F860F08BAadc54d98a06BE435"; //testnet

export const getKawaiiCore = (chainId: any) => {
    switch (chainId) {
        case KAWAIIVERSE_CHAIN_ID:
            return KAWAIIVERSE_CORE;
        default:
            return KAWAII_CORE_ADDRESS;
    }
};

export const getKawaiiAddress = (chainId: any) => {
    switch (chainId) {
        case KAWAIIVERSE_CHAIN_ID:
            return KWT_KAWAIIVERSE_ADDRESS;
        default:
            return KAWAII_ADDRESS;
    }
};
