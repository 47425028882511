import React from "react";

const TotalSaleIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
      <svg 
      className={className}
      width="36" height="36" viewBox="0 0 36 36" fill="white" xmlns="http://www.w3.org/2000/svg">
<path d="M36 33.8906H33.8906V12.6562H27.4922V33.8906H25.3828V21.0938H19.0547V33.8906H16.9453V16.875H10.6172V33.8906H8.50781V23.2031H2.10938V33.8906H0V36H36V33.8906Z" fill="white"/>
<path d="M36.0001 8.4375V0H27.4923V2.10938H32.3993L22.2188 12.2195L13.7813 3.78204L0.309082 17.184L1.80041 18.6754L13.7813 6.76484L22.2188 15.2023L33.8907 3.60077V8.4375H36.0001Z" fill="white"/>
</svg>

    );
};

export default TotalSaleIcon;
