//@ts-nocheck
import React, { useState } from "react";
import WrapLink from "../WrapLink/WrapLink";
import { Link, useLocation, useHistory } from "react-router-dom";
import cn from "classnames/bind";
import { NavDropdown } from "react-bootstrap";
import styles from "./NavLinks.module.scss";
import { useCheckMobileScreen } from "src/utils/hooks";
import { useWeb3React } from "@web3-react/core";
import { getChainId, KAWAIIVERSE_CHAIN_ID } from "src/consts/blockchain";

const cx = cn.bind(styles);

type LinkProps = {
    path: string;
    title: any;
    description?: string;
    items?: LinkProps[];
    icon?: string;
};

type NavLinksProps = {
    items?: LinkProps[];
    current: string;
    onItemClick?: Function;
};

export const categoryLinks = [
    "/plants",
    "/creatures",
    "/materials",
    "/dyes",
    "/fields",
    "/decors",
    "/fashions",
    "/bundles",
    "/islands",
];

export const Links: LinkProps[] = [
    {
        path: "/",
        title: "Dashboard",
    },
    {
        path: "/marketplace",
        title: "Marketplace",
    },
    {
        title: "Boost Farming",
        path: "/boost-farming-v2",
    },

    {
        path: "/bonding",
        title: "Bonding",
    },
    {
        path: "/staking",
        title: "Staking",
    },
    {
        title: "Exclusive Events",
        path: "#events",
        items: [
            {
                title: "Kawaiiverse Hometown",
                path: "/kawaiiverse-hometown",
            },
            {
                title: "Revenue sharing",
                path: "/revenue-share-v3",
            },
            {
                title: "Activate wallet",
                path: "https://scan.kawaii.global/activate-wallet",
            },
            {
                title: "Decor Contest",
                path: "/decor-contest",
            },
            {
                title: "First Anniversary",
                path: "/golden-box",
            },
            {
                title: "Milky Refund",
                path: "/milky-refund",
            },
        ],
    },
];

const NavLinkChildren: React.FC<LinkProps> = ({ items, title, path, onItemClick }) => {
    const [show, setShow] = useState(false);
    const isMobileScreenLTE = useCheckMobileScreen(1199);
    const showDropdown = () => {
        setShow(!show);
    };
    const hideDropdown = () => {
        setShow(false);
    };
    return (
        <NavDropdown
            className={cx("nav-dropdown")}
            id={path}
            title={title}
            show={isMobileScreenLTE || show}
            onMouseEnter={showDropdown}
            onMouseLeave={hideDropdown}
            onClick={() => {
                setShow(!show);
                onItemClick();
            }}
        >
            {items?.map(({ path, title, description }) => {
                if (path?.startsWith("http")) {
                    return (
                        <NavDropdown.Item title={description} href={path} key={path} target="_blank">
                            {title}
                        </NavDropdown.Item>
                    );
                }
                return (
                    <NavDropdown.Item key={path} as={Link} title={description} to={path}>
                        {title}
                    </NavDropdown.Item>
                );
            })}
        </NavDropdown>
    );
};

const NavLinks: React.FC<NavLinksProps> = ({ items = Links, onItemClick }) => {
    const location = useLocation();

    return (
        <>
            {items.map((child) => {
                if (child?.items) {
                    return <NavLinkChildren key={child.path} {...child} onItemClick={onItemClick} />;
                }
                const { path, title, description } = child;
                let active;
                if (path === "/marketplace" && categoryLinks.includes(location.pathname)) active = true;
                else if (location.pathname === path) active = true;
                else if (location.pathname.startsWith("/bonding") && path === "/bonding") active = true;
                return (
                    <WrapLink
                        onClick={onItemClick}
                        key={path}
                        className={cx("nav-link", {
                            active,
                        })}
                        to={path}
                    >
                        {title}
                    </WrapLink>
                );
            })}
        </>
    );
};

export const AccountNavLinks: React.FC<NavLinksProps> = ({ onItemClick }) => {
    const { chainId } = useWeb3React();
    const CHAIN_ID = getChainId(chainId);
    const location = useLocation();
    const history = useHistory();
    let items: LinkProps[] = [
        {
            path: "account",
            icon: "account.svg",
            title: "Account",
        },
        {
            path: "league-purchase",
            icon: "purchase.svg",
            title: "League Purchase",
            items: [
                {
                    path: "premium-ticket",
                    icon: "ticket-icon.svg",
                    title: "Premium Ticket",
                },
                {
                    path: "buy-gem",
                    icon: "buy_gem.svg",
                    title: "Buy Gem",
                },
            ],
        },
        {
            path: "nft-lab",
            icon: "nft-lab.svg",
            title: "NFT Lab",
            items: [
                {
                    path: "bridge",
                    icon: "bridge.svg",
                    title: "Bridge",
                },
                {
                    path: "mint",
                    icon: "nft-lab.svg",
                    title: "Mint",
                },
                // {
                //     path: "nurture",
                //     icon: "nft-lab.svg",
                //     title: "Nurture",
                // },
                {
                    path: "fusion",
                    icon: "nft-lab.svg",
                    title: "Fusion",
                },
            ],
        },
        {
            path: "history",
            icon: "history.svg",
            title: "History",
        },
    ];

    return (
        <>
            {items.map((item, index) => (
                <div
                    className={cx("account-menu")}
                    key={index}
                    onClick={() => {
                        if (item.title.includes("Coming soon")) return;
                        history.push(`/profile/${item.path}`);
                        onItemClick();
                    }}
                >
                    <div className={cx("menu-title")}>
                        <img src={require(`src/assets/icons/${item.icon}`)} />
                        <span> {item.title}</span>
                    </div>
                    <div className={cx("sub-menu")}>
                        {item.items?.map((subItem, id) => (
                            <div
                                className={cx("sub-menu-item")}
                                onClick={() => {
                                    if (item.title.includes("Coming soon")) return;
                                    history.push(`/profile/${item.path}/${subItem.path}`);
                                    onItemClick();
                                }}
                                key={id}
                            >
                                {subItem.title}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
};

export default NavLinks;
