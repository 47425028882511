import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import priceSlice from "src/store/slices/priceSlice";
import balanceSlice from "./slices/balanceSlice";
import tabSlice from "./slices/tabSlice";
import bondsSlice from "./slices/bondsSlice";
import accountSlice from "./slices/accountSlice";
import querySlice from "./slices/querySlice";
import bundleQuerySlice from "./slices/bundleQuerySlice";
import userSlice from "./slices/userSlice";
import discordAccountSlide from "./slices/discordAccountSlide";

const reducers = combineReducers({
    price: priceSlice,
    balance: balanceSlice,
    tab: tabSlice,
    bonds: bondsSlice,
    account: accountSlice,
    query: querySlice,
    bundleQuery: bundleQuerySlice,
    user: userSlice,
	discordAccount: discordAccountSlide,
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["price", "user"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: [thunk],
});

export default store;
