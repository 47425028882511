// @ts-nocheck
import React, { useRef, useState, useEffect } from "react";
import cn from "classnames/bind";
import { Nav, Navbar } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core";
import { useEagerConnect, useInactiveListener } from "src/utils/hooks";
import { useHistory } from "react-router-dom";
import { useCheckMobileScreen } from "src/utils/hooks";
import NavLinks, { AccountNavLinks } from "./NavLinks";
import styles from "./Header.module.scss";
import logo from "src/assets/icons/logo.png";
import CloseIcon from "@material-ui/icons/Close";
import ConnectWalletButton from "./ConnectWaletButton";
import ConnectWalletModal from "./ConnectWalletModal";
import LogOut from "src/assets/icons/log-out.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUser } from "src/store/slices/userSlice";
import moment from "moment";
import ConnectDiscordButton from "src/components/pages/ConnectDiscordCallback/ConnectButton";

const cx = cn.bind(styles);

const Header: React.FC<{}> = () => {
    const context = useWeb3React();
    const { connector, account, deactivate } = context;
    const [activatingConnector, setActivatingConnector] = useState();
    const [showConnectModal, setShowConnectModal] = useState(false);
    const isMobileScreenLTE = useCheckMobileScreen(1199);
    const isMobile = useCheckMobileScreen(768);
    const history = useHistory();
    const navbar = useRef();
    const triedEager = useEagerConnect();
    const userData = useSelector(selectUser);

    useInactiveListener(!triedEager || !!activatingConnector);

    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    const hideDropdown = () => {
        if (!navbar?.current?.classList?.contains?.("collapsed")) {
            navbar?.current?.click();
        }
    };

    return (
        <>
            {isMobileScreenLTE ? (
                <>
                    <Navbar expand="xl" className={cx("navbar")}>
                        <div className={cx("navbar-container")}>
                            <Navbar.Toggle ref={navbar} className={cx("nav-toggle")} aria-controls="basic-navbar-nav">
                                <img src={require("src/assets/icons/menu.png")} />
                            </Navbar.Toggle>
                            <Navbar.Brand className="navbar-brand">
                                <a href="https://kawaii.global/" target="_blank">
                                    <img className={cx("navbar-brand-image")} src={logo} alt="Kawaii" />
                                </a>
                            </Navbar.Brand>
                            <ConnectWalletButton onClick={() => setShowConnectModal(!showConnectModal)} />
                        </div>
                        <Navbar.Collapse className={cx("navbar-custom")} id="basic-navbar-nav">
                            <CloseIcon onClick={hideDropdown} className={cx("icon-cancel")} />
                            <Nav className={`justify-content-end align-items-center ${cx("navbar-dropdown")}`}>
                                <NavLinks onItemClick={hideDropdown} current={history.location.pathname} />
                            </Nav>
                            {account && (
                                <>
                                    <div className={cx("account")}>
                                        <div className={cx("account-top")}>
                                            <div className={cx("account-top-avatar")}>
                                                <img
                                                    className={cx("account-top-avatar-circle")}
                                                    src={
                                                        "https://images.kawaii.global/kawaii-landing-image/images/team-circle.png"
                                                    }
                                                />
                                                <img
                                                    className={cx("account-top-avatar-laurel")}
                                                    src={
                                                        "https://images.kawaii.global/kawaii-landing-image/images/team-laurel.png"
                                                    }
                                                />
                                                <img
                                                    className={cx("account-top-avatar-image")}
                                                    src={
                                                        "https://images.kawaii.global/kawaii-marketplace-image/avatar.png"
                                                    }
                                                />
                                                <img
                                                    className={cx("account-top-avatar-level")}
                                                    src={
                                                        "https://images.kawaii.global/kawaii-marketplace-image/icons/lv.svg"
                                                    }
                                                />
                                                <div className={cx("account-top-avatar-text")}>
                                                    Lv.{userData.Level || 0}
                                                </div>
                                            </div>
                                            {userData.Created && (
                                                <div className={cx("join")}>
                                                    Joined Kawaiiverse since&nbsp;
                                                    <span>
                                                        {moment(new Date(userData.Created)).format("MMM DD YYYY")}
                                                    </span>
                                                </div>
                                            )}
                                            {userData.LeagueRank && (
                                                <div className={cx("league")}>
                                                    <div className={cx("rank")}>
                                                        <img
                                                            src={`https://images.kawaii.global/kawaii-marketplace-image/${userData.LeagueRank}.png`}
                                                        />
                                                    </div>
                                                    {userData.LeaguePoint && (
                                                        <div className={cx("point")}>{userData.LeaguePoint}</div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <ConnectDiscordButton />

                                    <AccountNavLinks onItemClick={hideDropdown} current={history.location.pathname} />
                                    {/* <div
                                        className={cx("log-out")}
                                        onClick={() => {
                                            history.replace("/");
                                            deactivate();
                                            hideDropdown();
                                        }}
                                    >
                                        <img src={LogOut} />
                                        Log out
                                    </div> */}
                                </>
                            )}
                        </Navbar.Collapse>
                    </Navbar>
                </>
            ) : (
                <Navbar expand="xl" className={cx("navbar")}>
                    <Navbar.Brand className="navbar-brand">
                        <a href="https://kawaii.global/" target="_blank">
                            <img className={cx("navbar-brand-image")} src={logo} alt="Kawaii" />
                        </a>
                    </Navbar.Brand>
                    <Navbar.Collapse className={cx("")} id="basic-navbar-nav">
                        <Nav className={`justify-content-end align-items-center ${cx("navbar-dropdown")}`}>
                            <NavLinks onItemClick={hideDropdown} current={history.location.pathname} />
                            <ConnectWalletButton onClick={() => setShowConnectModal(!showConnectModal)} />
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            )}
            <ConnectWalletModal show={showConnectModal} setShow={setShowConnectModal} />
        </>
    );
};

export default Header;
