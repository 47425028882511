import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { read } from "src/services/web3";
import { BSC_CHAIN_ID } from "src/consts/blockchain";
import web3 from "web3";

const MILKY_BNB_MAINNET_ADDRESS = "0x210616dfdc938a084d812152922893f9d3efb4f2";
// const MILKY_BNB_MAINNET_ADDRESS = "0x2334d7a30bb2876f8bcc6020f73e7adada40e38d";

const setBondState = (state: any, payload: any) => {
    const bond = payload.name;
    const newState = { ...state[bond], ...payload };
    state[bond] = newState;
    state.loading = false;
};

export const calcBondDetails = createAsyncThunk("bonds/calcBondDetails", async (payload: any) => {
    const { bond, milkyPrice, toast, bnbPrice } = payload;
    try {
        let getPrice;
        if (bond.name === "BNB")
            getPrice = read("getPrice", 56, bond.addresses[BSC_CHAIN_ID].priceFeedAddress, bond.priceFeedABI, [
                "MILKY",
            ]);
        else
            getPrice = read("getPrice", 56, bond.addresses[BSC_CHAIN_ID].priceFeedAddress, bond.priceFeedABI, [
                bond.priceFeedString,
                "MILKY",
            ]);

        let [purchased, terms, maxPayout, priceRatio, totalSupply, reserves, paused] = await Promise.all([
            read("balanceOf", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].reserveAddress, bond.reserveABI, [
                bond.addresses[BSC_CHAIN_ID].treasuryAddress,
            ]),
            read("terms", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].bondAddress, bond.bondABI, []),
            read("maxPayout", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].bondAddress, bond.bondABI, []),
            getPrice,
            read("totalSupply", 56, MILKY_BNB_MAINNET_ADDRESS, bond.lpABI, []),
            read("getReserves", 56, MILKY_BNB_MAINNET_ADDRESS, bond.lpABI, []),
            read("paused", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].bondAddress, bond.bondABI, []),
        ]);
        if (bond.name === "BNB") priceRatio = 1 / +web3.utils.fromWei(priceRatio.rate);
        else priceRatio = +web3.utils.fromWei(priceRatio.rate);

        let realPriceRatio = (reserves[0] / totalSupply) * 2;

        if (bond.name === "BNB") realPriceRatio = reserves[0] / reserves[1];
        else realPriceRatio = (reserves[0] / totalSupply) * 2;

        const bondPrice = (milkyPrice * realPriceRatio * 10000) / (priceRatio * terms.controlVariable);
        if (bond.name === "MILKY-BNB-V1") purchased = 200000;
        else if (bond.name === "MILKY-BNB-V2")
            purchased = +web3.utils.fromWei(purchased) * milkyPrice * priceRatio + 72560;
        else purchased = (+web3.utils.fromWei(purchased) + 111.74) * bnbPrice;
        return {
            name: bond.name,
            terms: terms,
            bondPrice,
            maxPayout: +web3.utils.fromWei(maxPayout),
            priceRatio,
            purchased,
            paused,
        };
    } catch (error: any) {
        console.log(error);
        toast(error?.message || "An error occurred!!");
    }
});

export const bondsSlice = createSlice({
    name: "bonds",
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(calcBondDetails.pending, (state: any) => {
                state.loading = true;
            })
            .addCase(calcBondDetails.fulfilled, (state: any, action) => {
                if (action.payload) {
                    setBondState(state, action.payload);
                }
                state.loading = false;
            })
            .addCase(calcBondDetails.rejected, (state: any) => {
                state.loading = false;
            });
    },
});

export default bondsSlice.reducer;
