export const BSC_CHAIN_ID = Number(process.env.REACT_APP_BSC_CHAIN_ID);
export const BSC_TESTNET_CHAIN_ID = Number(process.env.REACT_APP_BSC_TESTNET_CHAIN_ID);
export const BSC_CHAIN_ID_HEX = process.env.REACT_APP_BSC_CHAIN_ID_HEX;
export const BSC_rpcUrls = process.env.REACT_APP_BSC_rpcUrls || null;
export const BSC_blockExplorerUrls = process.env.REACT_APP_BSC_blockExplorerUrls;
export const MARKETPLACE_ENDPOINT = process.env.REACT_APP_MARKETPLACE_ENDPOINT;
export const MARKETPLACE_V4_ENDPOINT = process.env.REACT_APP_MARKETPLACE_V4_ENDPOINT;
export const KAWAIIVERSE_CHAIN_ID = Number(process.env.REACT_APP_KAWAIIVERSE_CHAIN_ID);
export const KAWAIIVERSE_CHAIN_ID_HEX = process.env.REACT_APP_KAWAIIVERSE_CHAIN_ID_HEX;
export const KAWAIIVERSE_rpcUrls = process.env.REACT_APP_KAWAIIVERSE_rpcUrls || null;
export const KAWAIIVERSE_blockExplorerUrls = process.env.REACT_APP_KAWAIIVERSE_blockExplorerUrls;
export const KAWAIIVERSE_MARKET_ENDPOINT = "https://developers.kawaii.global/kawaii-bundle-marketplace";
export const PRICE_MARKET_ENDPOINT = "https://price.market.orai.io";
export const KAWAIIVERSE_ENDPOINT = "https://developers.kawaii.global";
export const KAWAII_ISLAND_NFT_LAB_ENDPOINT = "https://kawaii-islands-29b3e.df.r.appspot.com";

export const getChainId = (chainId: any) => {
    switch (chainId) {
        case 6886:
            return KAWAIIVERSE_CHAIN_ID;
        default:
            return BSC_CHAIN_ID;
    }
};
export const getChainIdHex = (chainId: any) => {
    switch (chainId) {
        case 6886:
            return KAWAIIVERSE_CHAIN_ID_HEX;
        default:
            return BSC_CHAIN_ID_HEX;
    }
};
export const getRpcUrls = (chainId: any) => {
    switch (chainId) {
        case 6886:
            return KAWAIIVERSE_rpcUrls;
        default:
            return BSC_rpcUrls;
    }
};
export const getBlockExplorerUrls = (chainId: any) => {
    switch (chainId) {
        case 6886:
            return KAWAIIVERSE_blockExplorerUrls;
        default:
            return BSC_blockExplorerUrls;
    }
};
export const getScanName = (chainId: any) => {
    switch (chainId) {
        case 6886:
            return "KawaiiverseScan";
        default:
            return "BscScan";
    }
};
export const getChainName = (chainId: any) => {
    switch (chainId) {
        case 6886:
            return "KAWAIIVERSE";
        default:
            return "Binance Smart Chain";
    }
};
export const getNativeToken = (chainId: any) => {
    switch (chainId) {
        case 6886:
            return "ORAIE";
        default:
            return "BNB";
    }
};
export const randomDomain = (domain: any) => {
    const randomNum = Math.ceil(3 * Math.random());
    const temp = domain.split(".");
    temp[0] = `${temp[0].slice(0, temp[0].length - 1)}${randomNum}`;
    return temp.join(".");
};
