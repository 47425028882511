import { createSlice } from "@reduxjs/toolkit";

export const bundleQuerySlice = createSlice({
    name: "bundleQuery",
    initialState: {isNFTHolder: true},
    reducers: {
        setBundleQuery: (state: any, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const { setBundleQuery } = bundleQuerySlice.actions;

export const selectBundleQuery = (state: any) => {
    return state.bundleQuery;
};

export default bundleQuerySlice.reducer;
