import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { read, getCurrentBlock, getEthBalance } from "src/services/web3";
import { BSC_CHAIN_ID } from "src/consts/blockchain";
import ERC20_ABI from "src/utils/abi/kawaii.json";
import web3 from "web3";

export const calcStakeData = createAsyncThunk("account/calcStakeData", async (payload: any) => {
    const { bond, milkyPrice, toast } = payload;
    try {
        let [pendingRewardRebase, circulatingSupply, deposited, epoch, currentBlock] = await Promise.all([
            read("pendingRewardRebase", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].stakingAddress, bond.stakingABI, []),
            read("circulatingSupply", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].sMilkyAddress, bond.sMilkyABI, []),
            read("balanceOf", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].milkyAddress, ERC20_ABI, [
                bond.addresses[BSC_CHAIN_ID].stakingAddress,
            ]),
            read("epoch", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].stakingAddress, bond.stakingABI, []),
            getCurrentBlock(),
        ]);
        const stakingRebase = pendingRewardRebase / circulatingSupply;
        let apy;
        if (bond.name === "BNB") apy = 200;
        else if(bond.name === "MILKY-BNB-V2") apy = 8.499;
        else apy = 10.975;
        // else apy = Math.pow(1 + stakingRebase, 365 * 3) - 1;
        deposited = +web3.utils.fromWei(deposited);

        return {
            name: bond.name,
            stakingRebase,
            apy: apy * 100,
            rebaseBlock: +epoch.endBlock,
            currentBlock: +currentBlock,
            deposited: deposited * milkyPrice,
        };
    } catch (error: any) {
        console.log(error);
        toast(error?.message || "An error occurred!!");
    }
});

export const calcUserStakeData = createAsyncThunk("account/calcUserStakeData", async (payload: any) => {
    const { bond, account, toast } = payload;
    try {
        let [stakedBalance, allowance] = await Promise.all([
            read("balanceOf", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].sMilkyAddress, bond.sMilkyABI, [account]),
            read("allowance", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].sMilkyAddress, bond.sMilkyABI, [
                account,
                bond.addresses[BSC_CHAIN_ID].stakingAddress,
            ]),
        ]);
        return {
            name: bond.name,
            stakedBalance: +web3.utils.fromWei(stakedBalance),
            allowance: +web3.utils.fromWei(allowance),
        };
    } catch (error: any) {
        console.log(error);
        toast(error?.message || "An error occurred!!");
    }
});

export const calculateUserBondDetails = createAsyncThunk("account/calculateUserBondDetails", async (payload: any) => {
    const { bond, account, toast } = payload;
    try {
        let getBalance, getAllowance;
        if (bond.name === "BNB") {
            getBalance = getEthBalance(account);
            getAllowance = "0";
        } else {
            getBalance = read("balanceOf", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].reserveAddress, bond.reserveABI, [
                account,
            ]);
            getAllowance = read(
                "allowance",
                BSC_CHAIN_ID,
                bond.addresses[BSC_CHAIN_ID].reserveAddress,
                bond.reserveABI,
                [account, bond.addresses[BSC_CHAIN_ID].bondAddress]
            );
        }

        let [balance, allowance, currentBlock, bondInfo, pendingPayout] = await Promise.all([
            getBalance,
            getAllowance,
            getCurrentBlock(),
            read("bondInfo", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].bondAddress, bond.bondABI, [account]),
            read("pendingPayoutFor", BSC_CHAIN_ID, bond.addresses[BSC_CHAIN_ID].bondAddress, bond.bondABI, [account]),
        ]);
        const maturationBlock = +bondInfo.vesting + +bondInfo.lastBlock;
        return {
            name: bond.name,
            balance: +web3.utils.fromWei(balance),
            allowance: +web3.utils.fromWei(allowance),
            payout: +web3.utils.fromWei(bondInfo.payout),
            pendingPayout: +web3.utils.fromWei(pendingPayout),
            currentBlock,
            maturationBlock,
        };
    } catch (error: any) {
        console.log(error);
        toast(error?.message || "An error occurred!!");
    }
});

export const accountSlice = createSlice({
    name: "account",
    initialState: {
        bonds: {},
        userStake: {},
        stake: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(calculateUserBondDetails.pending, (state: any) => {
                state.loading = true;
            })
            .addCase(calculateUserBondDetails.fulfilled, (state: any, action: any) => {
                if (action.payload) {
                    const bond = action.payload.name;
                    state.bonds[bond] = action.payload;
                }
                state.loading = false;
            })
            .addCase(calculateUserBondDetails.rejected, (state: any) => {
                state.loading = false;
            })
            .addCase(calcUserStakeData.pending, (state: any) => {
                state.loading = true;
            })
            .addCase(calcUserStakeData.fulfilled, (state: any, action: any) => {
                if (action.payload) {
                    const bond = action.payload.name;
                    state.userStake[bond] = action.payload;
                }
                state.loading = false;
            })
            .addCase(calcUserStakeData.rejected, (state: any) => {
                state.loading = false;
            })
            .addCase(calcStakeData.pending, (state: any) => {
                state.loading = true;
            })
            .addCase(calcStakeData.fulfilled, (state: any, action: any) => {
                if (action.payload) {
                    const bond = action.payload.name;
                    state.stake[bond] = action.payload;
                }
                state.loading = false;
            })
            .addCase(calcStakeData.rejected, (state: any) => {
                state.loading = false;
            });
    },
});

export default accountSlice.reducer;
