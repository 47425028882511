import { Modal } from "react-bootstrap";
import cn from "classnames/bind";
import styles from "./index.module.scss";
import { Button } from "@mui/material";
import logo from "src/assets/images/kawaii-islands.png";

const cx = cn.bind(styles);

const ModalCloseProject = () => {
    return (
        <Modal dialogClassName={cx("modal")} show={true} animation={true} centered>
            <img
                className={cx("navbar-brand-image")}
                src={logo}
                alt="Kawaii-island"
                style={{ width: "200px", height: "auto" }}
            />
            <div
                style={{
                    textTransform: "uppercase",
                    fontSize: 36,
                    fontWeight: "700",
                    color: "#f25858",
                    marginTop: "20px",
                }}
            >
                Important Notice!
            </div>
            <div
                style={{
                    textAlign: "center",
                    padding: "20px 60px",
                    fontSize: "18px",
                    lineHeight: "150%",
                    color: "#833f1d",
                }}
            >
                <div>All server operations and liquidity mining programs were terminated on September 1st.</div>
                <div>For more information, click below.</div>
            </div>

            <Button
                style={{
                    background: "#f25858",
                    color: "#fff",
                    fontSize: "18px",
                    lineHeight: "150%",
                    padding: "12px 36px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    fontWeight: "500",
                    marginTop: "30px",
                }}
                onClick={() => {
                    window.open("https://bit.ly/43gh6yT", "_blank", "noopener");
                }}
            >
                More Detail &nbsp; &nbsp; {`>>`}
            </Button>
        </Modal>
    );
};

export default ModalCloseProject;
