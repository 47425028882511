// @ts-nocheck
import React from "react";
import cn from "classnames/bind";
import styles from "./ConnectWalletButton.module.scss";
import { useWeb3React } from "@web3-react/core";
import { useLocation, useHistory } from "react-router-dom";

const cx = cn.bind(styles);

const ConnectWalletButton: React.FC<{ onClick }> = ({ onClick }) => {
    const { account } = useWeb3React();
    const history = useHistory();
    const location = useLocation();
    return (
        <div
            className={cx("connect-button")}
            onClick={() => {
                if (account) {
                    history.push("/profile/account");
                } else {
                    onClick();
                }
            }}
            data-toggle="modal"
            data-target="#connect-wallet-modal"
        >
            {account ? "My account" : "Connect wallet"}
        </div>
    );
};

export default ConnectWalletButton;
