// eslint-disable-next-line import/no-anonymous-default-export
export default {
    10109011: {
        name: "Curls with horns",
        description: "Item from the exclusive Spooky Girl costume.",
    },
    10209011: {
        name: "Spooky face",
        description: "Item from the exclusive Spooky Girl costume.",
    },
    10309008: {
        name: "Gem stickers",
        description: "Item from the exclusive Spooky Girl costume.",
    },
    10609014: {
        name: "Spooky dress",
        description: "Item from the exclusive Spooky Girl costume.",
    },
    10709013: {
        name: "Devil boots",
        description: "Item from the exclusive Spooky Girl costume.",
    },
    10809009: {
        name: "Devil hairpin",
        description: "Item from the exclusive Spooky Girl costume.",
    },
    10909006: {
        name: "Spooky monocle",
        description: "Item from the exclusive Spooky Girl costume.",
    },
    11009010: {
        name: "Spooky cross earrings",
        description: "Item from the exclusive Spooky Girl costume.",
    },
    11409001: {
        name: "Spooky bracelets",
        description: "Item from the exclusive Spooky Girl costume.",
    },
    11209007: { name: "Pumpkin basket", description: "Item from the exclusive Spooky Girl costume." },
    10107014: { name: "Wren Winterland hairstyle", description: "Item from the exclusive Winterland costume." },
    10107015: { name: "Frostine Winterland hairstyle", description: "Item from the exclusive Winterland costume." },
    10406007: { name: "Blue winter coat", description: "Item from the exclusive Winterland costume." },
    10406008: { name: "Beige winter coat", description: "Item from the exclusive Winterland costume." },
    10406009: { name: "Yellow winter coat", description: "Item from the exclusive Winterland costume." },
    10506007: { name: "Purple winter shorts", description: "Item from the exclusive Winterland costume." },
    10506008: { name: "Navy winter shorts", description: "Item from the exclusive Winterland costume." },
    10506009: { name: "Beige winter shorts", description: "Item from the exclusive Winterland costume." },
    10606003: { name: "Blue winter dress", description: "Item from the exclusive Winterland costume." },
    10606004: { name: "Red winter dress", description: "Item from the exclusive Winterland costume." },
    10606005: { name: "Mint winter dress", description: "Item from the exclusive Winterland costume." },
    10706009: { name: "Blue winter boots", description: "Item from the exclusive Winterland costume." },
    10706010: { name: "Red winter boots", description: "Item from the exclusive Winterland costume." },
    10706011: { name: "Mint winter boots", description: "Item from the exclusive Winterland costume." },
    10706012: { name: "Purple winter shoes", description: "Item from the exclusive Winterland costume." },
    10706013: { name: "Red winter shoes", description: "Item from the exclusive Winterland costume." },
    10706014: { name: "Turquoise winter shoes", description: "Item from the exclusive Winterland costume." },
    10806003: { name: "Blue winter hat", description: "Item from the exclusive Winterland costume." },
    10806004: { name: "Red winter hat", description: "Item from the exclusive Winterland costume." },
    10806005: { name: "Pink winter hat", description: "Item from the exclusive Winterland costume." },
    11306001: { name: "Blue winter scarf", description: "Item from the exclusive Winterland costume." },
    11306002: { name: "Silver winter scarf", description: "Item from the exclusive Winterland costume." },
    11306003: { name: "Amber winter scarf", description: "Item from the exclusive Winterland costume." },
    11406001: { name: "Brown winter gloves", description: "Item from the exclusive Winterland costume." },
    11406002: { name: "Red winter gloves", description: "Item from the exclusive Winterland costume." },
    11406003: { name: "Pink winter gloves", description: "Item from the exclusive Winterland costume." },
    10107006: { name: "Classic man bun", description: "Special fashion item with oriental style design." },
    10207005: {
        name: "Strong gaze ",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    10101003: { name: "Oriental Bun hair", description: "Exclusive Fashion item for Kawaiiverse 1 year anniversary." },
    10207004: {
        name: "Power gaze",
        description: "Exclusive Fashion item for Kawaiiverse 1 year anniversary.",
    },
    10607001: {
        name: "Sakura Hakama",
        description: "Exclusive Fashion item for Kawaiiverse 1 year anniversary.",
    },
    10607002: {
        name: "Purple Hakama",
        description: "Exclusive Fashion item for Kawaiiverse 1 year anniversary.",
    },
    11701002: { name: "Oriental socks", description: "Special fashion item with oriental style design." },
    10701002: { name: "Oriental sandals", description: "Exclusive Fashion item for Kawaiiverse 1 year anniversary." },
    11209006: {
        name: "Orai coin",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    10609012: {
        name: "Validator set blue",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    10609013: {
        name: "Validator set green",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    10709011: {
        name: "Validator shoes blue",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    10709012: {
        name: "Validator shoes green",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    11109008: {
        name: "ORAI flag blue",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    11109009: {
        name: "ORAI flag green",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    10401009: {
        name: "Delegator Haori",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    10501007: {
        name: "Delegator Hakama",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    10701003: {
        name: "Delegator Shoes",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    11601001: {
        name: "Delegator Waist Armor",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    11107001: {
        name: "Red Orai flag",
        description: "Exclusive fashion item for ORAICHAIN - strategic partner of Kawaii Islands.",
    },
    11207001: { name: "Bamboo sword", description: "Special fashion item with oriental style design." },
    10109003: { name: "Girlish curtain bangs", description: "Exclusive fashion item from Friend Referral Program." },
    10109004: { name: "Boyish curtain bangs", description: "Exclusive fashion item from Friend Referral Program." },
    10209003: { name: "Frisky girl", description: "Exclusive fashion item from Friend Referral Program." },
    10209004: { name: "Frisky boy", description: "Exclusive fashion item from Friend Referral Program." },
    10309002: { name: "Star face paint", description: "Exclusive fashion item from Friend Referral Program." },
    10309003: { name: "Mischievous blush", description: "Exclusive fashion item from Friend Referral Program." },
    10409003: { name: "Frisky croptop", description: "Exclusive fashion item from Friend Referral Program." },
    10409004: { name: "Frisky tanktop", description: "Exclusive fashion item from Friend Referral Program." },
    10409005: { name: "Girlish frisky jacket", description: "Exclusive fashion item from Friend Referral Program." },
    10409006: { name: "Boyish frisky jacket", description: "Exclusive fashion item from Friend Referral Program." },
    10509003: { name: "Frisky shorts", description: "Exclusive fashion item from Friend Referral Program." },
    10509004: { name: "Frisky pants", description: "Exclusive fashion item from Friend Referral Program." },
    10709003: { name: "Vivid sneakers", description: "Exclusive fashion item from Friend Referral Program." },
    10809003: { name: "Frisky kitty headphone", description: "Exclusive fashion item from Friend Referral Program." },
    10909002: { name: "Frisky kitty shades", description: "Exclusive fashion item from Friend Referral Program." },
    11009002: { name: "Striped bandage", description: "Exclusive fashion item from Friend Referral Program." },
    11209002: { name: "Rainbow cotton candy", description: "Exclusive fashion item from Friend Referral Program." },
    11509002: { name: "Corgi tote bag", description: "Exclusive fashion item from Friend Referral Program." },
    11709002: { name: "Frisky socks", description: "Exclusive fashion item from Friend Referral Program." },
    10109012: { name: "Spring buns", description: "Exclusive Lunar New Year fashion item." },
    10109013: { name: "Spring cut", description: "Exclusive Lunar New Year fashion item." },
    10209012: { name: "Lunar soul", description: "Exclusive Lunar New Year fashion item." },
    10209013: { name: "Blessed soul", description: "Exclusive Lunar New Year fashion item." },
    10309009: { name: "Spring mark", description: "Exclusive Lunar New Year fashion item." },
    10309010: { name: "Lucky charm mark", description: "Exclusive Lunar New Year fashion item." },
    10409007: { name: "Spring ao dai", description: "Exclusive Lunar New Year fashion item." },
    10409008: { name: "Yin-yang shirt", description: "Exclusive Lunar New Year fashion item." },
    10509005: { name: "Silk trousers", description: "Exclusive Lunar New Year fashion item." },
    10509006: { name: "Yin-yang trousers", description: "Exclusive Lunar New Year fashion item." },
    10709014: { name: "Spring heels", description: "Exclusive Lunar New Year fashion item." },
    10709015: { name: "Yin-yang shoes", description: "Exclusive Lunar New Year fashion item." },
    11209008: { name: "Koi purse", description: "Exclusive Lunar New Year fashion item." },
    11309004: { name: "Koi bag", description: "Exclusive Lunar New Year fashion item." },
    11409002: { name: "Lunar beads", description: "Exclusive Lunar New Year fashion item." },
    11409003: { name: "Spring beads", description: "Exclusive Lunar New Year fashion item." },
    10807028: { name: "Leathare Hat" },
    10109021: {
        name: "Mecha bob cut",
        description: "Exclusive Fashion for Kawaiiverse Citizen Islands - Mecha Plains edition",
    },
    10109022: {
        name: "Mecha messy cut",
        description: "Exclusive Fashion for Kawaiiverse Citizen Islands - Mecha Plains edition",
    },
    10209022: {
        name: "Mechanic girl",
        description: "Exclusive Fashion for Kawaiiverse Citizen Islands - Mecha Plains edition",
    },
    10209023: {
        name: "Mechanic boy",
        description: "Exclusive Fashion for Kawaiiverse Citizen Islands - Mecha Plains edition",
    },
    11009015: {
        name: "Cat paw bandage",
        description: "Exclusive Fashion for Kawaiiverse Citizen Islands - Mecha Plains edition",
    },
    10609021: {
        name: "Girls' mecha overalls",
        description: "Exclusive Fashion for Kawaiiverse Citizen Islands - Mecha Plains edition",
    },
    10609022: {
        name: "Boys' mecha overalls",
        description: "Exclusive Fashion for Kawaiiverse Citizen Islands - Mecha Plains edition",
    },
    10709022: {
        name: "Mecha boots",
        description: "Exclusive Fashion for Kawaiiverse Citizen Islands - Mecha Plains edition",
    },
    10809020: {
        name: "Cat goggles",
        description: "Exclusive Fashion for Kawaiiverse Citizen Islands - Mecha Plains edition",
    },
    11409009: {
        name: "Mecha fingerless gloves",
        description: "Exclusive Fashion for Kawaiiverse Citizen Islands - Mecha Plains edition",
    },
    11209017: {
        name: "Mecha wrench",
        description: "Exclusive Fashion for Kawaiiverse Citizen Islands - Mecha Plains edition",
    },
    11107008: {
        name: "Cellyfish backpack",
        description: "Exclusive Fashion from Kawaii League 3",
    },
    10807029: {
        name: "Rockie Helmet",
        description: "Exclusive Fashion from Kawaii League 4",
    },
    11507001: {
        name: "Plasto Crossbag",
        description: "Exclusive Fashion from Kawaii League 5",
    },
    11207005: {
        name: "Rubbie lantern",
        description: "Exclusive Fashion from Kawaii League 6",
    },
    11207006: {
        name: "Shelly Candy",
        description: "Exclusive Fashion from Kawaii League 7",
    },
    10407023: {
        name: "Christmas coat",
        description: "Exclusive Fashion Item from Kawaii Casting Christmas 2022.",
    },
    10507023: {
        name: "Loose trouser",
        description: "Exclusive Fashion Item from Kawaii Casting Christmas 2022.",
    },
    10707024: {
        name: "Ugg boot Xmas",
        description: "Exclusive Fashion Item from Kawaii Casting Christmas 2022.",
    },
    10807007: {
        name: "Santa's hat ",
        description: "Exclusive Fashion Item from Kawaii Casting Christmas 2022.",
    },
    10801005: {
        name: "Metal Fox Ears",
        description: "Exclusive Fashion from Kawaii League 8",
    },
    11301004: {
        name: "Cloray Scarf",
        description: "Exclusive Fashion from Kawaii League 9",
    },
    11201003: {
        name: "Woodie Hammer",
        description: "Exclusive Fashion from Kawaii League 10",
    },
    11001005: {
        name: "Hedglass Crystals",
        description: "Exclusive Fashion from Kawaii League 11",
    },
    10807034: {
        name: "Beach Hat",
        description: "Exclusive Fashion from Kawaii League 12",
    },
    1507002: {
        name: "Crab Crossbag",
        description: "Exclusive Fashion from Kawaii League 13",
    },
    11507002: {
        name: "Crab Crossbag",
        description: "Exclusive Fashion from Kawaii League 13",
    },
    11207007: {
        name: "Pufferfish Ball",
        description: "Exclusive Fashion from Kawaii League 14",
    },
    10409016: {
        name: "Anchor top",
        description: "Exclusive Fashion from Kawaii League 15",
    },
    10509014: {
        name: "Anchor bottom",
        description: "Exclusive Fashion from Kawaii League 16",
    },
    11309016: {
        name: "Anchor neckwear",
        description: "Exclusive Fashion from Kawaii League 17",
    },
    10709030: {
        name: "Anchor sneakers",
        description: "Exclusive Fashion from Kawaii League 17",
    },
    10109030: {
        name: "Anchor guy's hair",
        description: "Exclusive Fashion from Kawaii League 18",
    },
    10209031: {
        name: "Anchor face",
        description: "Exclusive Fashion from Kawaii League 18",
    },
    10606001: {
        name: "Cyan Long Dress",
        description: "Exclusive Fashion from Kawaii League 19",
    },
    11206001: {
        name: "Brown Bear Handbag",
        description: "Exclusive Fashion from Kawaii League 20",
    },
    10706003: {
        name: "Cyan Heels",
        description: "Exclusive Fashion from Kawaii League 21",
    },
    10106003: {
        name: "Brown Bob Hair",
        description: "Exclusive Fashion from Kawaii League 21",
    },
    10406010: {
        name: "Red Tho Cam top",
        description: "Exclusive Fashion from Kawaii League 22",
    },
};
