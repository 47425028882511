import { createSlice } from "@reduxjs/toolkit";

export const tabSlice = createSlice({
    name: "tab",
    initialState: {
        currentTab: "",
    },
    reducers: {
        setCurrentTab: (state: any, action) => {
            return {
                ...state,
                currentTab: action.payload,
            };
        },
    },
});

export const { setCurrentTab } = tabSlice.actions;

export const selectCurrentTab = (state: any) => {
    return state.tab.currentTab;
};

export default tabSlice.reducer;
