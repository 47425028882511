import React from "react";

const ForwardIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
        <svg
            className={className}
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1 10C1.13134 10.0001 1.26141 9.97434 1.38274 9.92406C1.50407 9.87377 1.61427 9.80001 1.707 9.707L5.707 5.707C5.79988 5.61417 5.87356 5.50395 5.92383 5.38263C5.9741 5.26132 5.99997 5.13129 5.99997 4.99997C5.99997 4.86865 5.9741 4.73862 5.92383 4.61731C5.87356 4.49599 5.79988 4.38577 5.707 4.29294L1.707 0.29294C1.51908 0.107378 1.26538 0.00369768 1.00128 0.00453424C0.737185 0.00537081 0.484146 0.110656 0.297405 0.297405C0.110664 0.484154 0.00538921 0.737198 0.00456429 1.00129C0.00373936 1.26539 0.10743 1.51909 0.292999 1.707L3.58594 5L0.29294 8.293C0.153127 8.43286 0.0579195 8.61102 0.0193539 8.80498C-0.0192118 8.99894 0.000595093 9.19998 0.076273 9.38268C0.151951 9.56538 0.2801 9.72155 0.444519 9.83143C0.608938 9.94131 0.802244 9.99997 1 10ZM9 10C9.26522 10 9.51957 9.89464 9.70711 9.70711C9.89464 9.51957 10 9.26522 10 9V1C10 0.734784 9.89464 0.48043 9.70711 0.292893C9.51957 0.105357 9.26522 0 9 0C8.73478 0 8.48043 0.105357 8.29289 0.292893C8.10536 0.48043 8 0.734784 8 1V9C8 9.26522 8.10536 9.51957 8.29289 9.70711C8.48043 9.89464 8.73478 10 9 10Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ForwardIcon;
