export default {
    202001: { name: "Snowberry" },
    202002: { name: "Eboney" },
    202003: { name: "Heartberry" },
    202004: { name: "Silverey" },
    202005: { name: "Floral biscuit" },
    202006: { name: "Goldberry" },
    202007: { name: "Greenoo" },
    202008: { name: "Skyberry" },
    202009: { name: "Brownberry" },
    202010: { name: "Parple" },
    202011: { name: "Pinkberry" },
};
