export default {
    5001: {
        name: "Wood",
        description:
            "Converted from Woodie Egg. Used as a material in crafting items.",
    },
    5002: {
        name: "Metal",
        description:
            "Converted from Metalfox Egg. Used as a material in crafting items.",
    },
    5003: {
        name: "Stone",
        description:
            "Converted from Rockie Egg. Used as a material in crafting items.",
    },
    5004: {
        name: "Cloth",
        description:
            "Converted from Cloray Egg. Used as a material in crafting items.",
    },
    5005: {
        name: "Leather",
        description:
            "Converted from Leathare Egg. Used as a material in crafting items.",
    },
    5006: {
        name: "Glass",
        description:
            "Converted from Hedglass Egg. Used as a material in crafting items.",
    },
    5007: {
        name: "Rubber",
        description:
            "Converted from Rubbie Egg. Used as a material in crafting items.",
    },
    5008: {
        name: "Ceramic",
        description:
            "Converted from Cellyfish Egg. Used as a material in crafting items.",
    },
    5009: {
        name: "Brick",
        description:
            "Converted from Clayshelly Egg. Used as a material in crafting items.",
    },
    5010: {
        name: "Plastic",
        description:
            "Converted from Plasto Egg. Used as a material in crafting items.",
    },
};
