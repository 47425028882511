import React from "react";

const NextIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
        <svg
            className={className}
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.00003 9.99535C1.13136 9.9955 1.26144 9.96969 1.38277 9.91941C1.5041 9.86912 1.6143 9.79536 1.70703 9.70235L5.70703 5.70235C5.79991 5.60952 5.87359 5.4993 5.92386 5.37798C5.97413 5.25667 6 5.12664 6 4.99532C6 4.864 5.97413 4.73397 5.92386 4.61266C5.87359 4.49134 5.79991 4.38112 5.70703 4.28829L1.70703 0.288289C1.5191 0.102727 1.2654 -0.000953616 1.00131 -0.000117047C0.737212 0.000719522 0.484172 0.106005 0.297431 0.292754C0.11069 0.479503 0.00541544 0.732547 0.00459003 0.996643C0.00376463 1.26074 0.107455 1.51444 0.293025 1.70235L3.58597 4.99535L0.292966 8.28835C0.153153 8.4282 0.0579453 8.60637 0.0193796 8.80033C-0.019186 8.99429 0.000621319 9.19533 0.0762987 9.37803C0.151976 9.56073 0.280126 9.7169 0.444545 9.82678C0.608964 9.93666 0.80227 9.99532 1.00003 9.99535Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default NextIcon;
