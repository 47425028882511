import { createSlice } from "@reduxjs/toolkit";

export const balanceSlice = createSlice({
    name: "balance",
    initialState: {
        airi: 0,
        kwt: 0,
        bnb: 0,
        milky: 0,
        kwtBalance: {
            56: 0,
            6886: 0,
        },
    },
    reducers: {
        setBalanceAiri: (state: any, action) => {
            return {
                ...state,
                airi: action.payload,
            };
        },
        setBalanceKwt: (state: any, action) => {
            return {
                ...state,
                kwt: action.payload,
            };
        },
        setBalanceBnb: (state: any, action) => {
            return {
                ...state,
                bnb: action.payload,
            };
        },
        setBalanceMilky: (state: any, action) => {
            return {
                ...state,
                milky: action.payload,
            };
        },
        setBalanceKWT: (state: any, action) => {
            return {
                ...state,
                kwtBalance: {
                    ...state.kwtBalance,
                    ...action.payload,
                },
            };
        },
    },
});

export const { setBalanceAiri, setBalanceKwt, setBalanceBnb, setBalanceMilky, setBalanceKWT } = balanceSlice.actions;

export const selectBalance = (state: any) => {
    return state.balance;
};

export default balanceSlice.reducer;
