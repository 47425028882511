const plants = {
    201001: {
        name: "Snowberry",
        output: [202001],
        breedType: "origin",
        description:
            "‌A magical plant that bears magical fruits as white as snow. Its fruit is not only the main feed that the magical creature Cellyfish greatly enjoys, but can also be used to extract the pristine White Dye.",
    },
    201002: {
        name: "Eboney",
        output: [202002],
        breedType: "origin",
        description:
            "‌The fruit of this magical plant can be extracted into the mysterious Black Dye. Rockie and Cloray would thrive on these Eboney fruits everyday.",
    },
    201003: {
        name: "Heartberry",
        output: [202003],
        breedType: "origin",
        description:
            "The fruit of this magical plant is said to resemble a passionate heart, hence the name Heartberry. It can be used to extract the desirable Red Dye, and is the favorite nourishment of Leathare and Clayshelly.",
    },
    201004: {
        name: "Silverey",
        output: [202004],
        breedType: "origin",
        description:
            "A magical plant that bears fruits with marvelous silver shine. Its fruit is the source to extract Grey Dye, and is the favorite food for Metalfox and Rockie.",
    },
    201005: {
        name: "Floral biscuit",
        output: [202005],
        breedType: "origin",
        description:
            "‌The fruit of this magical plant bears a resemblance to a flower-shaped biscuit. It is the favorite food source of shy Clayshelly and Woodie, and can be extracted into a bright Orange Dye.",
    },
    201006: {
        name: "Goldberry",
        output: [202006],
        breedType: "origin",
        description:
            "A magical plant that bears fruits with the color of gold. Its fruit can be extracted into Yellow Dye. The Goldberry fruits can be favorite feeds of Plasto and Cellyfish, the Magical Creatures. Goldberry lifespan from a sapling to full-grown state to harvest fruit and feed creatures can be seen from below illustration.",
    },
    201007: {
        name: "Greenoo",
        output: [202007],
        breedType: "origin",
        description:
            "‌The Greenoo fruit is the main source for Green Dye. Cloray and Hedglass are the two Magical Creatures that always crave Greenoo fruits.",
    },
    201008: {
        name: "Skyberry",
        output: [202008],
        breedType: "origin",
        description:
            "A magical plant that bears fruits with the color of the marvelous blue sky. Its fruit is the sole source to extract Blue Dye, and is used to feed two Kawaii Magical Creatures: Hedglass and Plasto.",
    },
    201009: {
        name: "Brownberry",
        output: [202009],
        breedType: "origin",
        description:
            "The fruit of this magical plant can be used to extract Brown Dye, an earthy color, and is a reliable source of food for the two magical creatures: Woodie and Leathare.",
    },
    201010: {
        name: "Parple",
        output: [202010],
        breedType: "origin",
        description:
            "The fruit of this magical plant looks like a quirky purple apple. It is the source to extract the mysterious Purple Dye, and also the food extremely favored by Rubbie and Metalfox.",
    },
    201011: {
        name: "Pinkberry",
        output: [202011],
        breedType: "origin",
        description:
            "The fruit of this magical plant is said to resemble a pinkish pear. It can be used to extract the lovely Pink Dye, the most favorite color among the adorable Kawaii girls, and is also the reliable source of food for our bubbly Rubbie.",
    },
    201101: {
        name: "Snowberry (Nurtured)",
        output: [202001],
        breedType: "f1",
        description:
            "‌A magical plant that bears magical fruits as white as snow. Its fruit is not only the main feed that the magical creature Cellyfish greatly enjoys, but can also be used to extract the pristine White Dye.",
    },
    201102: {
        name: "Eboney (Nurtured)",
        output: [202002],
        breedType: "f1",
        description:
            "‌The fruit of this magical plant can be extracted into the mysterious Black Dye. Rockie and Cloray would thrive on these Eboney fruits everyday.",
    },
    201103: {
        name: "Heartberry (Nurtured)",
        output: [202003],
        breedType: "f1",
        description:
            "The fruit of this magical plant is said to resemble a passionate heart, hence the name Heartberry. It can be used to extract the desirable Red Dye, and is the favorite nourishment of Leathare and Clayshelly.",
    },
    201104: {
        name: "Silverey (Nurtured)",
        output: [202004],
        breedType: "f1",
        description:
            "A magical plant that bears fruits with marvelous silver shine. Its fruit is the source to extract Grey Dye, and is the favorite food for Metalfox and Rockie.",
    },
    201105: {
        name: "Floral biscuit (Nurtured)",
        output: [202005],
        breedType: "f1",
        description:
            "‌The fruit of this magical plant bears a resemblance to a flower-shaped biscuit. It is the favorite food source of shy Clayshelly and Woodie, and can be extracted into a bright Orange Dye.",
    },
    201106: {
        name: "Goldberry (Nurtured)",
        output: [202006],
        breedType: "f1",
        description:
            "A magical plant that bears fruits with the color of gold. Its fruit can be extracted into Yellow Dye. The Goldberry fruits can be favorite feeds of Plasto and Cellyfish, the Magical Creatures. Goldberry lifespan from a sapling to full-grown state to harvest fruit and feed creatures can be seen from below illustration.",
    },
    201107: {
        name: "Greenoo (Nurtured)",
        output: [202007],
        breedType: "f1",
        description:
            "‌The Greenoo fruit is the main source for Green Dye. Cloray and Hedglass are the two Magical Creatures that always crave Greenoo fruits.",
    },
    201108: {
        name: "Skyberry (Nurtured)",
        output: [202008],
        breedType: "f1",
        description:
            "A magical plant that bears fruits with the color of the marvelous blue sky. Its fruit is the sole source to extract Blue Dye, and is used to feed two Kawaii Magical Creatures: Hedglass and Plasto.",
    },
    201109: {
        name: "Brownberry (Nurtured)",
        output: [202009],
        breedType: "f1",
        description:
            "The fruit of this magical plant can be used to extract Brown Dye, an earthy color, and is a reliable source of food for the two magical creatures: Woodie and Leathare.",
    },
    201110: {
        name: "Parple (Nurtured)",
        output: [202010],
        breedType: "f1",
        description:
            "The fruit of this magical plant looks like a quirky purple apple. It is the source to extract the mysterious Purple Dye, and also the food extremely favored by Rubbie and Metalfox.",
    },
    201111: {
        name: "Pinkberry (Nurtured)",
        output: [202011],
        breedType: "f1",
        description:
            "The fruit of this magical plant is said to resemble a pinkish pear. It can be used to extract the lovely Pink Dye, the most favorite color among the adorable Kawaii girls, and is also the reliable source of food for our bubbly Rubbie.",
    },
    201301: {
        name: "Snowberry (Fusion)",
        output: [202001],
        breedType: "f2",
        description:
            "‌A magical plant that bears magical fruits as white as snow. Its fruit is not only the main feed that the magical creature Cellyfish greatly enjoys, but can also be used to extract the pristine White Dye.",
    },
    201302: {
        name: "Eboney (Fusion)",
        output: [202002],
        breedType: "f2",
        description:
            "‌The fruit of this magical plant can be extracted into the mysterious Black Dye. Rockie and Cloray would thrive on these Eboney fruits everyday.",
    },
    201303: {
        name: "Heartberry (Fusion)",
        output: [202003],
        breedType: "f2",
        description:
            "The fruit of this magical plant is said to resemble a passionate heart, hence the name Heartberry. It can be used to extract the desirable Red Dye, and is the favorite nourishment of Leathare and Clayshelly.",
    },
    201304: {
        name: "Silverey (Fusion)",
        output: [202004],
        breedType: "f2",
        description:
            "A magical plant that bears fruits with marvelous silver shine. Its fruit is the source to extract Grey Dye, and is the favorite food for Metalfox and Rockie.",
    },
    201305: {
        name: "Floral biscuit (Fusion)",
        output: [202005],
        breedType: "f2",
        description:
            "‌The fruit of this magical plant bears a resemblance to a flower-shaped biscuit. It is the favorite food source of shy Clayshelly and Woodie, and can be extracted into a bright Orange Dye.",
    },
    201306: {
        name: "Goldberry (Fusion)",
        output: [202006],
        breedType: "f2",
        description:
            "A magical plant that bears fruits with the color of gold. Its fruit can be extracted into Yellow Dye. The Goldberry fruits can be favorite feeds of Plasto and Cellyfish, the Magical Creatures. Goldberry lifespan from a sapling to full-grown state to harvest fruit and feed creatures can be seen from below illustration.",
    },
    201307: {
        name: "Greenoo (Fusion)",
        output: [202007],
        breedType: "f2",
        description:
            "‌The Greenoo fruit is the main source for Green Dye. Cloray and Hedglass are the two Magical Creatures that always crave Greenoo fruits.",
    },
    201308: {
        name: "Skyberry (Fusion)",
        output: [202008],
        breedType: "f2",
        description:
            "A magical plant that bears fruits with the color of the marvelous blue sky. Its fruit is the sole source to extract Blue Dye, and is used to feed two Kawaii Magical Creatures: Hedglass and Plasto.",
    },
    201309: {
        name: "Brownberry (Fusion)",
        output: [202009],
        breedType: "f2",
        description:
            "The fruit of this magical plant can be used to extract Brown Dye, an earthy color, and is a reliable source of food for the two magical creatures: Woodie and Leathare.",
    },
    201310: {
        name: "Parple (Fusion)",
        output: [202010],
        breedType: "f2",
        description:
            "The fruit of this magical plant looks like a quirky purple apple. It is the source to extract the mysterious Purple Dye, and also the food extremely favored by Rubbie and Metalfox.",
    },
    201311: {
        name: "Pinkberry (Fusion)",
        output: [202011],
        breedType: "f2",
        description:
            "The fruit of this magical plant is said to resemble a pinkish pear. It can be used to extract the lovely Pink Dye, the most favorite color among the adorable Kawaii girls, and is also the reliable source of food for our bubbly Rubbie.",
    },
};
export default plants;
