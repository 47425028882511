import getIsland from "./items/getIsland";

export default [
    {
        name: "trees",
        icon: "Icon_Plant",
    },
    {
        name: "animals",
        icon: "Icon_Creature",
    },
    {
        name: "materials",
        icon: "Icon_Material",
    },
    {
        name: "dyes",
        icon: "Icon_Dye",
    },
    {
        name: "fields",
        icon: "Icon_Field",
    },
    {
        name: "fashions",
        icon: "Icon_Clother",
    },
    {
        name: "decors",
        icon: "Icon_deco",
    },
    {
        name: "islands",
        icon: "Icon_island",
    },
];

export const getCategory = (id) => {
    const islands = getIsland(id);
    if (islands) return "islands";
    const REGEX = {
        decors: /^\d{9}$/,
        fashions: /^\d{8}$/,
        fields: /^205\d{3}$/,
        trees: /^201\d{3}$/,
        animals: /^206\d{3}$/,
        materials: /^50\d{2}$/,
        dyes: /^60\d{2}$/,
    };
    let category;
    Object.keys(REGEX).some((key) => {
        if (REGEX[key].test(id)) {
            category = key;
            return true;
        }
        return false;
    });
    return category;
};

export const categoriesBreeding = {
    trees: {
        name: "trees",
        label: "Plants",
        ingredient1: "fruits",
        apiName: "Plant",
    },
    animals: {
        name: "animals",
        label: "Creatures",
        ingredient1: "eggs",
        apiName: "Animal",
    },
    fields: {
        name: "fields",
        label: "Fields",
        ingredient1: "materials",
        ingredient2: "dyes",
        apiName: "Field",
    },
};
