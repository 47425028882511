import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {},
    reducers: {
        setUser: (state: any, action) => {
            return action.payload;
        },
    },
});

export const { setUser } = userSlice.actions;

export const selectUser = (state: any) => {
    return state.user;
};

export default userSlice.reducer;
