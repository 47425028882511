//@ts-nocheck
import React, { useState } from "react";
import styles from "./Tabs.module.scss";
import cn from "classnames/bind";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectPrice } from "src/store/slices/priceSlice";
import { selectCurrentTab } from "src/store/slices/tabSlice";
import { selectQuery } from "src/store/slices/querySlice";
import { selectBundleQuery } from "src/store/slices/bundleQuerySlice";
import { categoryLinks } from "src/components/common/Header/NavLinks";
import { Input, InputAdornment } from "@material-ui/core";
import { BSC_CHAIN_ID, getChainId, KAWAIIVERSE_CHAIN_ID } from "src/consts/blockchain";

const cx = cn.bind(styles);
const TYPES = [
    {
        name: "Fields",
        image: "Icon_Field",
    },
    {
        name: "Plants",
        image: "Icon_Plant",
    },
    {
        name: "Creatures",
        image: "Icon_Creature",
    },
    {
        name: "Materials",
        image: "Icon_Material",
    },
    {
        name: "Dyes",
        image: "Icon_Dye",
    },
    {
        name: "Decors",
        image: "Icon_deco",
    },
    {
        name: "Fashions",
        image: "Icon_Clother",
    },
    {
        name: "Bundles",
        image: "bundle",
    },
    {
        name: "Islands",
        image: "Icon_island",
    },
];
const tabLinks = [...categoryLinks, "/nft", "/auction", "/bundle"];

const MAP_TAB = {
    Trees: "Plants",
    Animals: "Creatures",
};

const Tabs: React.FC<{}> = () => {
    let currentTab = useSelector(selectCurrentTab);
    if (Object.keys(MAP_TAB).includes(currentTab)) {
        currentTab = MAP_TAB[currentTab];
    }
    const { kwtPrice, milkyPrice } = useSelector(selectPrice);
    const [kwtAmount, setKwtAmount] = useState(1);
    const { chainId } = useSelector(currentTab === "Bundles" ? selectBundleQuery : selectQuery);
    const [milkyAmount, setMilkyAmount] = useState(milkyPrice ? (kwtPrice / milkyPrice).toFixed(2) : 0);
    const history = useHistory();
    const location = useLocation();
    const CHAIN_ID = getChainId(chainId);
    const numberTab =
        currentTab === "Islands" && CHAIN_ID === KAWAIIVERSE_CHAIN_ID ? 9 : CHAIN_ID === KAWAIIVERSE_CHAIN_ID ? 9 : 8;
    const hasTab = tabLinks.some((link) => {
        if (location.pathname.startsWith("/nft-farming")) return false;
        return location.pathname.startsWith(link);
    });
    if (!hasTab) {
        return <></>;
    }
    return (
        <div className={cx("sub-header")}>
            <div className={cx("tabs")}>
                {TYPES.slice(0, numberTab).map((type) => (
                    <div
                        className={cx("tabs-tab", currentTab === type.name && "active")}
                        key={type.name}
                        onClick={() => {
                            if (chainId == BSC_CHAIN_ID) history.push(`/${type.name.toLowerCase()}?chainId=${chainId}&&isNFTHolder=true`);
                            else history.push(`/${type.name.toLowerCase()}?isNFTHolder=true`);
                        }}
                    >
                        <img
                            src={`https://images.kawaii.global/kawaii-marketplace-image/category/${type.image}.png`}
                            style={{ marginLeft: 5 }}
                            alt={type.name}
                        />
                        {type.name}
                    </div>
                ))}
            </div>
            <div className={cx("currency-convert")}>
                <Input
                    disableUnderline
                    value={kwtAmount}
                    onChange={(e) => {
                        if (!e.target.value) {
                            setKwtAmount("");
                            setMilkyAmount("");
                            return;
                        }
                        if (isNaN(e.target.value)) return;
                        setKwtAmount(e.target.value);
                        setMilkyAmount(((e.target.value * kwtPrice) / milkyPrice).toFixed(3));
                    }}
                    placeholder="0"
                    className={cx("input")}
                    inputMode="decimal"
                    endAdornment={
                        <InputAdornment position="end">
                            <img src={require("src/assets/icons/kwt-logo.png")} />
                        </InputAdornment>
                    }
                />
                <img src={require("src/assets/icons/convert.svg").default} />
                <Input
                    disableUnderline
                    value={milkyAmount}
                    onChange={(e) => {
                        if (!e.target.value) {
                            setKwtAmount("");
                            setMilkyAmount("");
                            return;
                        }
                        if (isNaN(e.target.value)) return;
                        setMilkyAmount(e.target.value);
                        setKwtAmount(((e.target.value * milkyPrice) / kwtPrice).toFixed(2));
                    }}
                    placeholder="0"
                    className={cx("input")}
                    inputMode="decimal"
                    endAdornment={
                        <InputAdornment position="end">
                            <img src={"https://images.kawaii.global/kawaii-marketplace-image/MILKY-token.png"} />
                        </InputAdornment>
                    }
                />
            </div>
        </div>
    );
};

export default Tabs;
