const animals = {
    206001: {
        name: "Woodie",
        description:
            "A magical creature with wood log body parts that make a perfect trunk disguise. Its favorite foods are the Floral Biscuits and Brownberries. Feeding Woodie when it is hungry will reward players with eggs that can be turned into wood.",
        output: [207001],
        input: [202005, 202009],
        breedType: "origin",
    },
    206002: {
        name: "Metalfox",
        description:
            "A magical creature that seems like a fluffy fox at first glance but covered with fur that is hard as steel. It only feeds on Silvereys and Parples. As long as it is well taken care of and produces eggs, Metalfox eggs can be used to produce metal.",
        output: [207002],
        input: [202004, 202010],
        breedType: "origin",
    },
    206003: {
        name: "Rockie",
        description:
            "Always full of energy unless it's hungry, healthy Rockie would hop everywhere as a way to exercise. Its dream is to become a rockstar with a perfect body, and only accepts Eboneys and Silvereys as its diet food. A well-cared Rockie can occasionally lay eggs that can be converted into stone.",
        output: [207003],
        input: [202002, 202004],
        breedType: "origin",
    },
    206004: {
        name: "Cloray",
        description:
            "Cloray is a Magical Creature that looks like a green ray fish. Its fins are constantly flapping to keep it floating in the air on the Kawaii Islands. If properly fed Greenoos or Eboneys from time to time, it can lay eggs that are used to produce cloth.",
        output: [207004],
        input: [202007, 202002],
        breedType: "origin",
    },
    206005: {
        name: "Leathare",
        description:
            "A fluffy creature that loves to eat Brownberries and Heartberries and sleeps all day. If well-fed and taken care of, it will express affection by laying eggs that can be transformed into leather.",
        output: [207005],
        input: [202009, 202003],
        breedType: "origin",
    },
    206006: {
        name: "Hedglass",
        description:
            "Hedglass is a magical creature that looks like a hedgehog with dazzling clear crystal spikes. There is nothing on Kawaii Islands that it enjoys eating more than Skyberries and Greenoos. In an environment where it feels safe, full and comfortable, Hedglass will lay eggs that can be used to produce shiny pieces of glass.",
        output: [207006],
        input: [202008, 202007],
        breedType: "origin",
    },
    206007: {
        name: "Rubbie",
        description:
            "This bouncy creature looks like a balloon seal with pointy ears. It has quite a bubbly personality, and is totally a sucker for Pinkberries and Parples. If well-fed and properly looked after, it will lay eggs that can be converted into rubber.",
        output: [207007],
        input: [202011, 202010],
        breedType: "origin",
    },
    206008: {
        name: "Cellyfish",
        description:
            "An adorable, delightful creature that looks like a jellyfish with a smiley face and cotton candy ears. Always cheerful and energetic, Cellyfish would lay eggs that could be converted into ceramic if properly provided with its favorite food Snowberries and Goldberries.",
        output: [207008],
        input: [202001, 202006],
        breedType: "origin",
    },
    206009: {
        name: "Clayshelly",
        description:
            "Shy Clayshelly is a creature that always hides itself inside a clay shell. Clayshelly only cares about 2 things in Kawaii Islands floating world: gossips and its favorite fruits, Heartberries and Floral Biscuits. In ideal conditions with on time feeding, it can lay eggs that are used to produce bricks, an important material in crafting items.",
        output: [207009],
        input: [202003, 202005],
        breedType: "origin",
    },
    206010: {
        name: "Plasto",
        description:
            "A magical creature with a laid-back, easy-going personality. Its wide smile can easily put our minds at ease. If properly provided with its favorite food Goldberries and Skyberries, it would lay eggs that can be used in making plastic.",
        output: [207010],
        input: [202006, 202008],
        breedType: "origin",
    },
    206101: {
        name: "Woodie (Nurtured)",
        description:
            "A magical creature with wood log body parts that make a perfect trunk disguise. Its favorite foods are the Floral Biscuits and Brownberries. Feeding Woodie when it is hungry will reward players with eggs that can be turned into wood.",
        output: [207001],
        input: [202005, 202009],
        breedType: "f1",
    },
    206102: {
        name: "Metalfox (Nurtured)",
        description:
            "A magical creature that seems like a fluffy fox at first glance but covered with fur that is hard as steel. It only feeds on Silvereys and Parples. As long as it is well taken care of and produces eggs, Metalfox eggs can be used to produce metal.",
        output: [207002],
        input: [202004, 202010],
        breedType: "f1",
    },
    206103: {
        name: "Rockie (Nurtured)",
        description:
            "Always full of energy unless it's hungry, healthy Rockie would hop everywhere as a way to exercise. Its dream is to become a rockstar with a perfect body, and only accepts Eboneys and Silvereys as its diet food. A well-cared Rockie can occasionally lay eggs that can be converted into stone.",
        output: [207003],
        input: [202002, 202004],
        breedType: "f1",
    },
    206104: {
        name: "Cloray (Nurtured)",
        description:
            "Cloray is a Magical Creature that looks like a green ray fish. Its fins are constantly flapping to keep it floating in the air on the Kawaii Islands. If properly fed Greenoos or Eboneys from time to time, it can lay eggs that are used to produce cloth.",
        output: [207004],
        input: [202007, 202002],
        breedType: "f1",
    },
    206105: {
        name: "Leathare (Nurtured)",
        description:
            "A fluffy creature that loves to eat Brownberries and Heartberries and sleeps all day. If well-fed and taken care of, it will express affection by laying eggs that can be transformed into leather.",
        output: [207005],
        input: [202009, 202003],
        breedType: "f1",
    },
    206106: {
        name: "Hedglass (Nurtured)",
        description:
            "Hedglass is a magical creature that looks like a hedgehog with dazzling clear crystal spikes. There is nothing on Kawaii Islands that it enjoys eating more than Skyberries and Greenoos. In an environment where it feels safe, full and comfortable, Hedglass will lay eggs that can be used to produce shiny pieces of glass.",
        output: [207006],
        input: [202008, 202007],
        breedType: "f1",
    },
    206107: {
        name: "Rubbie (Nurtured)",
        description:
            "This bouncy creature looks like a balloon seal with pointy ears. It has quite a bubbly personality, and is totally a sucker for Pinkberries and Parples. If well-fed and properly looked after, it will lay eggs that can be converted into rubber.",
        output: [207007],
        input: [202011, 202010],
        breedType: "f1",
    },
    206108: {
        name: "Cellyfish (Nurtured)",
        description:
            "An adorable, delightful creature that looks like a jellyfish with a smiley face and cotton candy ears. Always cheerful and energetic, Cellyfish would lay eggs that could be converted into ceramic if properly provided with its favorite food Snowberries and Goldberries.",
        output: [207008],
        input: [202001, 202006],
        breedType: "f1",
    },
    206109: {
        name: "Clayshelly (Nurtured)",
        description:
            "Shy Clayshelly is a creature that always hides itself inside a clay shell. Clayshelly only cares about 2 things in Kawaii Islands floating world: gossips and its favorite fruits, Heartberries and Floral Biscuits. In ideal conditions with on time feeding, it can lay eggs that are used to produce bricks, an important material in crafting items.",
        output: [207009],
        input: [202003, 202005],
        breedType: "f1",
    },
    206110: {
        name: "Plasto (Nurtured)",
        description:
            "A magical creature with a laid-back, easy-going personality. Its wide smile can easily put our minds at ease. If properly provided with its favorite food Goldberries and Skyberries, it would lay eggs that can be used in making plastic.",
        output: [207010],
        input: [202006, 202008],
        breedType: "f1",
    },
    206401: {
        name: "Woodie (Fusion)",
        description:
            "A magical creature with wood log body parts that make a perfect trunk disguise. Its favorite foods are the Floral Biscuits and Brownberries. Feeding Woodie when it is hungry will reward players with eggs that can be turned into wood.",
        output: [207001],
        input: [202005, 202009],
        breedType: "f2",
    },
    206402: {
        name: "Metalfox (Fusion)",
        description:
            "A magical creature that seems like a fluffy fox at first glance but covered with fur that is hard as steel. It only feeds on Silvereys and Parples. As long as it is well taken care of and produces eggs, Metalfox eggs can be used to produce metal.",
        output: [207002],
        input: [202004, 202010],
        breedType: "f2",
    },
    206403: {
        name: "Rockie (Fusion)",
        description:
            "Always full of energy unless it's hungry, healthy Rockie would hop everywhere as a way to exercise. Its dream is to become a rockstar with a perfect body, and only accepts Eboneys and Silvereys as its diet food. A well-cared Rockie can occasionally lay eggs that can be converted into stone.",
        output: [207003],
        input: [202002, 202004],
        breedType: "f2",
    },
    206404: {
        name: "Cloray (Fusion)",
        description:
            "Cloray is a Magical Creature that looks like a green ray fish. Its fins are constantly flapping to keep it floating in the air on the Kawaii Islands. If properly fed Greenoos or Eboneys from time to time, it can lay eggs that are used to produce cloth.",
        output: [207004],
        input: [202007, 202002],
        breedType: "f2",
    },
    206405: {
        name: "Leathare (Fusion)",
        description:
            "A fluffy creature that loves to eat Brownberries and Heartberries and sleeps all day. If well-fed and taken care of, it will express affection by laying eggs that can be transformed into leather.",
        output: [207005],
        input: [202009, 202003],
        breedType: "f2",
    },
    206406: {
        name: "Hedglass (Fusion)",
        description:
            "Hedglass is a magical creature that looks like a hedgehog with dazzling clear crystal spikes. There is nothing on Kawaii Islands that it enjoys eating more than Skyberries and Greenoos. In an environment where it feels safe, full and comfortable, Hedglass will lay eggs that can be used to produce shiny pieces of glass.",
        output: [207006],
        input: [202008, 202007],
        breedType: "f2",
    },
    206407: {
        name: "Rubbie (Fusion)",
        description:
            "This bouncy creature looks like a balloon seal with pointy ears. It has quite a bubbly personality, and is totally a sucker for Pinkberries and Parples. If well-fed and properly looked after, it will lay eggs that can be converted into rubber.",
        output: [207007],
        input: [202011, 202010],
        breedType: "f2",
    },
    206408: {
        name: "Cellyfish (Nurtured)",
        description:
            "An adorable, delightful creature that looks like a jellyfish with a smiley face and cotton candy ears. Always cheerful and energetic, Cellyfish would lay eggs that could be converted into ceramic if properly provided with its favorite food Snowberries and Goldberries.",
        output: [207008],
        input: [202001, 202006],
        breedType: "f2",
    },
    206409: {
        name: "Clayshelly (Nurtured)",
        description:
            "Shy Clayshelly is a creature that always hides itself inside a clay shell. Clayshelly only cares about 2 things in Kawaii Islands floating world: gossips and its favorite fruits, Heartberries and Floral Biscuits. In ideal conditions with on time feeding, it can lay eggs that are used to produce bricks, an important material in crafting items.",
        output: [207009],
        input: [202003, 202005],
        breedType: "f2",
    },
    206410: {
        name: "Plasto (Fusion)",
        description:
            "A magical creature with a laid-back, easy-going personality. Its wide smile can easily put our minds at ease. If properly provided with its favorite food Goldberries and Skyberries, it would lay eggs that can be used in making plastic.",
        output: [207010],
        input: [202006, 202008],
        breedType: "f2",
    },
};
export default animals;
