import { createSlice } from "@reduxjs/toolkit";

export const querySlice = createSlice({
    name: "query",
    initialState: { generation: "all", isNFTHolder: true },
    reducers: {
        setQuery: (state: any, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const { setQuery } = querySlice.actions;

export const selectQuery = (state: any) => {
    return state.query;
};

export default querySlice.reducer;
