import React from "react";

const PreviousIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
        <svg
            className={className}
            viewBox="0 0 6 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.99997 9.99535C4.86864 9.9955 4.73856 9.96969 4.61723 9.91941C4.4959 9.86912 4.3857 9.79536 4.29297 9.70235L0.292974 5.70235C0.200094 5.60952 0.126414 5.4993 0.0761442 5.37798C0.0258747 5.25667 0 5.12664 0 4.99532C0 4.864 0.0258747 4.73397 0.0761442 4.61266C0.126414 4.49134 0.200094 4.38112 0.292974 4.28829L4.29297 0.288289C4.4809 0.102727 4.7346 -0.000953616 4.99869 -0.000117047C5.26279 0.000719522 5.51583 0.106005 5.70257 0.292754C5.88931 0.479503 5.99458 0.732547 5.99541 0.996643C5.99624 1.26074 5.89255 1.51444 5.70697 1.70235L2.41403 4.99535L5.70703 8.28835C5.84685 8.4282 5.94205 8.60637 5.98062 8.80033C6.01919 8.99429 5.99938 9.19533 5.9237 9.37803C5.84802 9.56073 5.71987 9.7169 5.55546 9.82678C5.39104 9.93666 5.19773 9.99532 4.99997 9.99535Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default PreviousIcon;
