import { createSlice } from "@reduxjs/toolkit";

export const discordAccountSlide = createSlice({
    name: "discordAccount",
    initialState: "",
    reducers: {
        setDiscordAccount: (state: any, action) => {
            return action.payload;
        },
    },
});

export const { setDiscordAccount } = discordAccountSlide.actions;

export const selectDiscordAccount = (state: any) => {
    return state.discordAccount;
};

export default discordAccountSlide.reducer;
