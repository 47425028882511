import Web3 from "web3";
import { RPC_URLS } from "src/utils/connectors";
import { splitSignature } from "@ethersproject/bytes";
import {
    getChainId,
    getChainName,
    getChainIdHex,
    getNativeToken,
    getRpcUrls,
    getBlockExplorerUrls,
    BSC_CHAIN_ID,
    KAWAIIVERSE_CHAIN_ID,
    randomDomain,
    KAWAIIVERSE_CHAIN_ID_HEX,
} from "src/consts/blockchain";
import {
    AIRI_ADDRESS,
    KAWAII_ADDRESS,
    KWT_KAWAIIVERSE_ADDRESS,
    MILKY_ADDRESS,
    MILKY_KAWAIIVERSE_ADDRESS,
} from "src/consts/address";
import AIRI_ABI from "src/utils/abi/airi.json";
import KWT_ABI from "src/utils/abi/kawaii.json";

export const createNetworkOrSwitch = async (provider, chainId = BSC_CHAIN_ID) => {
    const CHAIN_ID = getChainId(chainId);
    const chainName = getChainName(CHAIN_ID);
    const CHAIN_ID_HEX = getChainIdHex(chainId);
    const NATIVE_TOKEN = getNativeToken(chainId);
    let rpcUrls = getRpcUrls(chainId);
    if (CHAIN_ID === KAWAIIVERSE_CHAIN_ID) rpcUrls = randomDomain(rpcUrls);
    const blockExplorerUrls = getBlockExplorerUrls(chainId);
    if (!provider.isMetaMask) {
        throw new Error(`Please change network to ${chainName}`);
    }
    let ethereum = window.ethereum;

    if (!ethereum) throw new Error(`Please change network to ${chainName}`);

    const data = [
        {
            chainId: CHAIN_ID_HEX,
            chainName: chainName,
            nativeCurrency: {
                name: NATIVE_TOKEN,
                symbol: NATIVE_TOKEN,
                decimals: 18,
            },
            rpcUrls: [rpcUrls],
            blockExplorerUrls: [blockExplorerUrls],
        },
    ];

    try {
        await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: CHAIN_ID_HEX }],
        });
    } catch (switchError) {
        if (switchError.code === 4902) {
            try {
                await ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: data,
                });
            } catch (addError) {
                console.log(addError);
            }
        } else {
            throw Error("Switch network fail!");
        }
    }
};

export const write = async (method, provider, address, abi, params, sendObj, callback) => {
    if (provider.chainId === KAWAIIVERSE_CHAIN_ID_HEX) {
        const [oraie, kwt, milky] = await Promise.all([
            getEthBalance(sendObj.from, KAWAIIVERSE_CHAIN_ID),
            getKwtBalance(sendObj.from, KAWAIIVERSE_CHAIN_ID),
            getMilkyBalance(sendObj.from, KAWAIIVERSE_CHAIN_ID),
        ]);
        if (oraie == 0 && kwt == 0 && milky == 0) throw new Error("Not have ORAIE, KWT and MILKY");
    }
    const web3 = new Web3(provider);
    const contract = new web3.eth.Contract(abi, address);
    let response;
    await contract.methods[method](...params)
        .send(sendObj)
        .on("transactionHash", (hash) => {
            console.log("txhash", hash);
            if (callback) {
                callback(hash);
            }
        })
        .on("receipt", (receipt) => {
            console.log(receipt);
            response = receipt;
        });
    return response;
};

export const sign = async (account, data, provider) => {
    let res = await provider.send("eth_signTypedData_v4", [account, data]);
    if (res.result) {
        res = res.result;
    }
    return splitSignature(res);
};

export const read = async (method, chainId, address, abi, params) => {
    let url = RPC_URLS[chainId];
    if (chainId === KAWAIIVERSE_CHAIN_ID) {
        const kawaiiverseRpc = localStorage.getItem("rpc");
        if (kawaiiverseRpc) url = kawaiiverseRpc;
    }
    const web3 = new Web3(url);
    const contract = new web3.eth.Contract(abi, address);
    const res = await contract.methods[method](...params).call();
    return res;
};

export const getEthBalance = (address, chainId = BSC_CHAIN_ID) => {
    let url = RPC_URLS[chainId];
    if (chainId === KAWAIIVERSE_CHAIN_ID) {
        const kawaiiverseRpc = localStorage.getItem("rpc");
        if (kawaiiverseRpc) url = kawaiiverseRpc;
    }
    const web3 = new Web3(url);
    return web3.eth.getBalance(address);
};

export const getAiriBalance = (address, chainId = BSC_CHAIN_ID) => {
    return read("balanceOf", chainId, AIRI_ADDRESS, AIRI_ABI, [address]);
};

export const getKwtBalance = (address, chainId = BSC_CHAIN_ID) => {
    return read("balanceOf", chainId, chainId === BSC_CHAIN_ID ? KAWAII_ADDRESS : KWT_KAWAIIVERSE_ADDRESS, KWT_ABI, [
        address,
    ]);
};

export const getMilkyBalance = (address, chainId = BSC_CHAIN_ID) => {
    return read("balanceOf", chainId, chainId === BSC_CHAIN_ID ? MILKY_ADDRESS : MILKY_KAWAIIVERSE_ADDRESS, KWT_ABI, [
        address,
    ]);
};

export const getCurrentBlock = () => {
    const web3 = new Web3(RPC_URLS[BSC_CHAIN_ID]);
    return web3.eth.getBlockNumber();
};
