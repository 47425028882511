import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.scss";

import App from "./App";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_SENTRY_ENVIRONMENT === "production") {
    Sentry.init({
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        dsn: "https://4f7161823f06495fa92edd791748f028@o1323226.ingest.sentry.io/4504930963488768",
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 0.7,
        denyUrls: [
            /extensions\//i,
            /extension/i,
            /vendor/i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            /^moz-extension:\/\//i,
        ],
        ignoreErrors: ["Request rejected", "Failed to fetch", "Load failed"],
    });
}

function getLibrary(provider: any) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
}

ReactDOM.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <App />
    </Web3ReactProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
}
