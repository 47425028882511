import { createSlice } from "@reduxjs/toolkit";

export const priceSlice = createSlice({
    name: "price",
    initialState: {
        airiPrice: 0,
        bnbPrice: 0,
        kwtPrice: 0,
        milkyPrice: 0,
        oraiPrice: 0,
    },
    reducers: {
        setAiriPrice: (state: any, action) => {
            return {
                ...state,
                airiPrice: action.payload,
            };
        },
        setBnbPrice: (state: any, action) => {
            return {
                ...state,
                bnbPrice: action.payload,
            };
        },
        setKwtPrice: (state: any, action) => {
            return {
                ...state,
                kwtPrice: action.payload,
            };
        },
        setMilkyPrice: (state: any, action) => {
            return {
                ...state,
                milkyPrice: action.payload,
            };
        },
        setOraiPrice: (state: any, action) => {
            return {
                ...state,
                oraiPrice: action.payload,
            };
        },
    },
});

export const { setAiriPrice, setBnbPrice, setKwtPrice, setMilkyPrice, setOraiPrice } = priceSlice.actions;

export const selectPrice = (state: any) => {
    return state.price;
};

export default priceSlice.reducer;
