export default {
    6001: {
        name: "White dye",
        description:
            "Extracted from Snowberries. Used in crafting to determine the item's color.",
    },
    6002: {
        name: "Black dye",
        description:
            "Extracted from Eboneys. Used in crafting to determine the item's color.",
    },
    6003: {
        name: "Red dye",
        description:
            "Extracted from Heartberries. Used in crafting to determine the item's color.",
    },
    6004: {
        name: "Grey dye",
        description:
            "Extracted from Silvereys. Used in crafting to determine the item's color.",
    },
    6005: {
        name: "Orange dye",
        description:
            "Extracted from Floral Biscuits. Used in crafting to determine the item's color.",
    },
    6006: {
        name: "Yellow dye",
        description:
            "Extracted from Goldberries. Used in crafting to determine the item's color.",
    },
    6007: {
        name: "Green dye",
        description:
            "Extracted from Greenoos. Used in crafting to determine the item's color.",
    },
    6008: {
        name: "Blue dye",
        description:
            "Extracted from Skyberries. Used in crafting to determine the item's color.",
    },
    6009: {
        name: "Brown dye",
        description:
            "Extracted from Brownberries. Used in crafting to determine the item's color.",
    },
    6010: {
        name: "Purple dye",
        description:
            "Extracted from Parples. Used in crafting to determine the item's color.",
    },
    6011: {
        name: "Pink dye",
        description:
            "Extracted from Pinkberries. Used in crafting to determine the item's color.",
    },
};
