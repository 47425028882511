// @ts-nocheck
import React, { useEffect, useState } from "react";
import cn from "classnames/bind";
import styles from "./ConnectWalletModal.module.scss";
import "./ConnectWalletModal.scss";
import { useWeb3React } from "@web3-react/core";
import { Modal } from "react-bootstrap";
import { connectorsByName } from "src/consts/connectors";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";

import LogOut from "src/assets/icons/log-out.svg";
const metamask = "https://images.kawaii.global/kawaii-marketplace-image/metamask.svg";
const walletconnectIcon = "https://images.kawaii.global/kawaii-marketplace-image/wallet-connect.svg";

const cx = cn.bind(styles);

const MetaMask = {
    name: "Metamask",
    icon: metamask,
    id: "MetaMask",
    connector: InjectedConnector,
    connectorName: "MetaMask",
};

const WalletConnect = {
    name: "Wallet Connect",
    icon: walletconnectIcon,
    id: "WalletConnect",
    connector: WalletConnectConnector,
    connectorName: "WalletConnect",
};

const CONNECTORS = [MetaMask, WalletConnect];

const ConnectWalletModal: React.FC<{
    show: boolean;
    setShow: Function;
    alwaysShow?: boolean;
}> = ({ show, setShow, alwaysShow }) => {
    const context = useWeb3React();
    const { connector, activate, account, deactivate } = context;
    const [activatingConnector, setActivatingConnector] = useState();
    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    const connectWallet = async (name: any) => {
        const currentConnector: any = connectorsByName[name];
        setActivatingConnector(currentConnector);
        await activate(connectorsByName[name]);
        setShow(false);
    };

    return (
        <Modal
            dialogClassName={cx("connect-modal")}
            show={show}
            animation={true}
            onHide={() => {
                if (alwaysShow) return;
                setShow(false);
            }}
        >
            <Modal.Body>
                <div className={cx("title")}>Connect wallet</div>
                <div className={cx("description")}>
                    Connect with one of available wallet providers or create a new wallet.
                </div>
                {CONNECTORS.map((connector, idx) => (
                    <div className={cx("button")} key={idx} onClick={() => connectWallet(connector.connectorName, idx)}>
                        <img src={connector.icon} />
                        {connector.name}
                    </div>
                ))}
                {/* {account && (
                    <div className={cx("log-out")} onClick={deactivate}>
                        <img src={LogOut} />
                        Log out
                    </div>
                )} */}
            </Modal.Body>
        </Modal>
    );
};

export default ConnectWalletModal;
