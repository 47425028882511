export default function getIsland(id) {
    id = +id;
    if (id >= 101010001 && id <= 101010100) {
        return {
            id,
            areaID: "Mecha 1",
            name: "Cogvilles",
            description: (
                <>
                    In the center of our Kawaiiverse, the four Hometown archipelagos are the official home to the
                    Kawaiians which bring unique citizen rights to their island owners.
                    <br /> The harsh nature and inclement climate turns this region into the dwelling of only the most
                    resourceful people. Inhabitants of this archipelago are famous for their skills in creative
                    engineering and manufacturing. The special perks that come with islands on Mecha Plains will
                    specialize in machines upgrading.
                    <br /> Central region of the Mecha Plains, where the master crafters reside. Their craftsmanship has
                    reached a top-notch level with unmatched proficiency. With the highest level of Workshop efficiency,
                    the Cogvilles islands can&nbsp;
                    <b>shorten craft time by 20%</b>, bringing huge advantages to our Kawaiians in playing and joining
                    the leagues
                </>
            ),
        };
    } else if (id >= 101020001 && id <= 101020200) {
        return {
            id,
            areaID: "Mecha 2",
            name: "Alchemy Lake",
        };
    } else if (id >= 101030001 && id <= 101030200) {
        return {
            id,
            areaID: "Mecha 3",
            name: "Tintley Savannah",
        };
    } else if (id >= 101040001 && id <= 101040500) {
        return {
            id,
            areaID: "Mecha 4",
            name: "Blacksmith Industry",
            description: (
                <>
                    In the center of our Kawaiiverse, the four Hometown archipelagos are the official home to the
                    Kawaiians which bring unique citizen rights to their island owners.
                    <br /> The harsh nature and inclement climate turns this region into the dwelling of only the most
                    resourceful people. Inhabitants of this archipelago are famous for their skills in creative
                    engineering and manufacturing. The special perks that come with islands on Mecha Plains will
                    specialize in machines upgrading.
                    <br /> Blacksmith Industry is the residence of crafting experts with vast experience and skills
                    accumulated through generations of hard work. They are capable of creating furniture of high quality
                    within an impressive time frame. Doubling the craft time effect of the Everwright Sierra islands,
                    these islands will &nbsp;<b>reduce the Workshop craft time by 10%</b>. With the number of orders not
                    being 20-hours limited in the new econ and mobile versions, 10% craft time can bring big support to
                    players.
                </>
            ),
        };
    } else if (id >= 101050001 && id <= 101051000) {
        return {
            id,
            areaID: "Mecha 5",
            name: "Transcloud Arctic",
        };
    } else if (id >= 101060001 && id <= 101061500) {
        return {
            id,
            areaID: "Mecha 6",
            name: "Elixir Lagoon",
        };
    } else if (id >= 101070001 && id <= 101071000) {
        return {
            id,
            areaID: "Mecha 7",
            name: "Everwright Sierra",
            description: (
                <>
                    In the center of our Kawaiiverse, the four Hometown archipelagos are the official home to the
                    Kawaiians which bring unique citizen rights to their island owners.
                    <br /> The harsh nature and inclement climate turns this region into the dwelling of only the most
                    resourceful people. Inhabitants of this archipelago are famous for their skills in creative
                    engineering and manufacturing. The special perks that come with islands on Mecha Plains will
                    specialize in machines upgrading.
                    <br /> In the Mecha Plains, Everwright Sierra is the vast region where the aspiring wrights gather
                    together for training. Their distinguished efficiency clearly shows their effort and willingness to
                    grow beyond their limits. These islands will help its owners&nbsp;{" "}
                    <b>reduce 5% of all Workshop crafting time</b>, slightly improving the game play efficiency.
                </>
            ),
        };
    } else if (id >= 101080001 && id <= 101081000) {
        return {
            id,
            areaID: "Mecha 8",
            name: "Mt. Alterley",
        };
    } else if (id >= 101090001 && id <= 101090200) {
        return {
            id,
            areaID: "Mecha 9",
            name: "Quintessence Dale",
        };
    }
    return false;
}
